import { Dialog, Transition } from "@headlessui/react";
import DOMPurify from "dompurify";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { API } from "../../api/APIClient";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { QUILL_FORMATS } from "../../utils/Quill";
import Toast from "../../utils/Toasts";

const receivers = [
	{ receiver: "Tous les dossiers valides", type: 1 },
	{ receiver: "Tous les dossiers invalides", type: 2 },
	{ receiver: "Tous les dossiers incomplets", type: 4 }
];

function SendMailModal({
	open,
	lang,
	setOpen,
	receiver,
	idInscription,
	event,
	runs,
	refetch
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	receiver: string | null;
	idInscription: number;
	event: any;
	runs: any;
	refetch: () => void;
}) {
	const [openInsight, setOpenInsight] = useState<boolean>(false);
	const [object, setObject] = useState<string>("");
	const [body, setBody] = useState<string>("");
	const [selectedRun, setSelectedRun] = useState([]);
	const [selectedRunner, setSelectedRunner] = useState([]);
	const [loading, setLoading] = useState(false);
	const { user } = useContext(UserContext);
	const { slug } = useParams();

	const disabled = loading || !object || !body || body == "<p><br></p>";

	const userName = (user?.firstname as string) + " " + user?.lastname;

	const baseObjectMail = `[Chronométrage.com - Inscription en ligne - ${event.name}] - `;
	const bottomMail = `<br/><br /><a href='https://www.chronometrage.com' target='_blank'><img src='https://chronometrage.com/logo_with_title.png' style='width:400px;height:auto'/></a>`;

	const topObject = object + "<br /><br />";
	const entireBody = baseObjectMail + topObject + body + bottomMail;

	const sanitizedData = () => ({
		__html: DOMPurify.sanitize(entireBody, {
			ALLOWED_ATTR: ["style", "class", "type", "href", "rel", "src", "alt"],
			ALLOWED_TAGS: [
				"iframe",
				"img",
				"p",
				"a",
				"div",
				"h1",
				"h2",
				"h3",
				"h4",
				"h5",
				"h6",
				"span",
				"strong",
				"br"
			]
		})
	});

	const initialState = () => {
		setOpenInsight(false);
		setObject("");
		setBody("");
		setOpen(false);
	};

	// HANDLE SAVE BUTTON
	const onSaveClick = async () => {
		try {
			setLoading(true);
			let bodyToSend: any = {
				slug: slug,
				object: object,
				body: body,
				type: selectedRunner,
				filters: selectedRun,
				user: userName
			};

			if (receiver !== null) bodyToSend.receiver = receiver;

			// API
			if (idInscription) {
				await API.post(
					`event/organizer/sendOneMail/${slug}/${idInscription}`,
					bodyToSend
				);
			} else {
				await API.post(
					`event/organizer/sendCollectiveMail/${slug}`,
					bodyToSend
				);
			}

			initialState();
			setLoading(false);
			setOpen(false);
			await refetch();
			Toast.success(trad[lang].emailSendedSuccess);
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].emailSendedError);
			setLoading(false);
		}
	};

	const InputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (InputRef.current) {
			InputRef.current.focus();
		}
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					{!openInsight && (
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-1/2 sm:p-6 sm:align-middle">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => initialState()}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="w-full sm:flex sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h2"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											<div>
												{receiver === null
													? trad[lang].sendEmailTitle
													: trad[lang].sendOneEmailTitle}
											</div>
											{receiver !== null && (
												<div className="text-sm text-gray-500">
													({receiver})
												</div>
											)}
										</Dialog.Title>

										{/*Formulaire*/}

										{/*Selection de la course*/}

										<div className="my-5 flex w-full flex-col gap-1">
											{!idInscription && (
												<>
													<label className="mb-2 block text-left text-sm font-medium text-gray-700">
														{trad[lang].selectRunMail}
													</label>
													<div className="wrap w-full flex-wrap items-center gap-2 sm:flex">
														<div className="flex items-center gap-2 sm:w-[32%]">
															<input
																type="checkbox"
																checked={selectedRun.length == 0}
																onChange={(e) => {
																	if (e.target.checked) {
																		setSelectedRun([]);
																	}
																}}
															/>
															<span>Toutes les courses</span>
														</div>

														{runs.map((run: any, key: any) => (
															<div
																className="flex items-center gap-2 sm:w-[32%]"
																key={key}
															>
																<input
																	type="checkbox"
																	checked={selectedRun.includes(
																		run.id as never
																	)}
																	onChange={(e) => {
																		const currentRuns = [...selectedRun];
																		if (e.target.checked) {
																			currentRuns.push(run.id as never);
																		} else {
																			const index = currentRuns.indexOf(
																				run.id as never
																			);
																			if (index > -1) {
																				currentRuns.splice(index, 1);
																			}
																		}
																		setSelectedRun(currentRuns);
																	}}
																/>
																<span>{run.name}</span>
															</div>
														))}
													</div>
													<label className="mb-2 mt-1 block text-left text-sm font-medium text-gray-700">
														Envoyer à
													</label>
													<div className="wrap w-full flex-wrap gap-2 sm:flex sm:items-center">
														<div className="flex items-center gap-2 sm:w-[48%]">
															<input
																type="checkbox"
																checked={selectedRunner.length == 0}
																onChange={(e) => {
																	if (e.target.checked) {
																		setSelectedRunner([]);
																	}
																}}
															/>
															<span>Tous les coureurs</span>
														</div>
														{receivers.map((item: any, key: any) => (
															<div
																className="flex items-center gap-2 text-start sm:w-[48%]"
																key={key}
															>
																<input
																	type="checkbox"
																	checked={selectedRunner.includes(
																		item.type as never
																	)}
																	key={item.type}
																	onChange={(e) => {
																		const currentRunners = [...selectedRunner];
																		if (e.target.checked) {
																			currentRunners.push(item.type as never);
																		} else {
																			const index = currentRunners.indexOf(
																				item.type as never
																			);
																			if (index > -1) {
																				currentRunners.splice(index, 1);
																			}
																		}
																		setSelectedRunner(currentRunners);
																	}}
																/>
																<span>{item.receiver}</span>
															</div>
														))}
													</div>
												</>
											)}
											{/*Objet du mail*/}
											<label className="mb-2 mt-1 block text-left text-sm font-medium text-gray-700">
												{trad[lang].object}
											</label>
											<div className="mt-1">
												<input
													id="object"
													name="object"
													placeholder={trad[lang].enter_mail_object}
													type="text"
													className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-200 focus:ring-blue-600 sm:text-sm"
													onChange={(e: any) => {
														setObject(e.target.value);
													}}
													value={object}
													ref={InputRef}
													autoFocus
												/>
											</div>
										</div>

										{/*Corps du mail*/}
										<ReactQuill
											theme="snow"
											onChange={setBody}
											value={body}
											className="mb-10 max-h-60 overflow-y-scroll"
											placeholder={trad[lang].enter_mail_content}
											formats={QUILL_FORMATS}
										/>

										{/* Actions buttons */}
										{
											<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
												<button
													type="button"
													disabled={disabled}
													className={`mx-2 mt-3 inline-flex w-full justify-center rounded-md border border-transparent ${
														disabled
															? "bg-gray-300 hover:bg-gray-300"
															: "bg-secondary hover:bg-primary"
													}  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
													onClick={() => onSaveClick()}
												>
													{trad[lang].send}
												</button>{" "}
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:w-auto sm:text-sm"
													onClick={() => initialState()}
												>
													{trad[lang].cancel}
												</button>
												<button
													type="button"
													className="mr-3 mt-3 inline-flex w-full items-center justify-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-blue-300 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:w-auto sm:text-sm"
													onClick={() => {
														setOpenInsight(!openInsight);
													}}
												>
													{trad[lang].preview}
													<RiEyeFill className="h-6 w-6" />
												</button>
											</div>
										}
									</div>
								</div>
							</div>
						</Transition.Child>
					)}

					{openInsight && (
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-1/2 sm:p-6 sm:align-middle">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => initialState()}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="w-full sm:flex sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h2"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{trad[lang].insight}
										</Dialog.Title>

										{/*Corps du mail*/}
										<div className="my-5 w-full">
											<div
												id="mail-body"
												className="block w-full rounded-md border-gray-300 pr-14 shadow-sm focus:border-gray-300 focus:ring-transparent sm:text-sm"
												dangerouslySetInnerHTML={sanitizedData()}
											/>
										</div>

										{/* Actions buttons */}
										{
											<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
												<button
													type="button"
													disabled={loading}
													className="mx-2 mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
													onClick={() => onSaveClick()}
												>
													{trad[lang].send}
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:w-auto sm:text-sm"
													onClick={() => initialState()}
												>
													{trad[lang].cancel}
												</button>
												<button
													type="button"
													className="mr-3 mt-3 inline-flex w-full items-center justify-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-blue-300 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:w-auto sm:text-sm"
													onClick={() => {
														setOpenInsight(!openInsight);
													}}
												>
													{trad[lang].preview_off}
													<RiEyeOffFill className="h-6 w-6" />
												</button>
											</div>
										}
									</div>
								</div>
							</div>
						</Transition.Child>
					)}
				</div>
			</Dialog>
		</Transition.Root>
	);
}

const requiredInput = {
	color: "red"
};

export default SendMailModal;
