import dayjs from "dayjs";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from "react";
import { AiOutlineClose } from "react-icons/ai";
import { postMessage } from "../../api/hook";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { milisecondsISODate } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";
import Modal from "../common/Modal";

const ManageDetectionsItem = ({
	detection,
	refetch,
	credential,
	timezone,
	idCalendrier,
	count,
	setCount
}: {
	detection: any;
	refetch: any;
	credential: string;
	timezone: string;
	idCalendrier: number;
	count: number;
	setCount: Dispatch<SetStateAction<number>>;
}) => {
	const { lang } = useContext(AppContext);
	const [openedEdit, setOpenedEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [detectionForm, setDetectionForm] = useState({
		bib: detection.dossard,
		date: dayjs(detection.date)
			.tz(timezone)
			.tz("Europe/Paris", true)
			.format("YYYY-MM-DDTHH:mm:ss.SSS"),
		line: detection.ligne
	});

	const bg_colors = () => {
		switch (detection.statut) {
			case 0:
				return "bg-red-50 hover:bg-red-100";
			case 1:
				return "bg-white hover:bg-gray-50";
			case 2:
				return "bg-yellow-50 hover:bg-yellow-100";
			case 3:
				return "bg-green-50 hover:bg-green-100";
			default:
				return "bg-white hover:bg-gray-50";
		}
	};

	const handleDeletion = async () => {
		try {
			if (!idCalendrier) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			await postMessage(idCalendrier, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detectionForm.line,
					Information: detectionForm.bib,
					DateDetection: new Date(
						dayjs(milisecondsISODate(detectionForm.date))
							.tz(timezone, true)
							.toISOString()
					),
					Supprimee: 1,
					Modifie: 0
				}
			});

			await refetch();

			setOpen(false);

			Toast.success(trad[lang].deletionSuccess);
			setCount(count);
		} catch (error) {
			Toast.error(trad[lang].deletionError);
		}
	};

	const handleRestore = async () => {
		try {
			if (!idCalendrier) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			await postMessage(idCalendrier, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detectionForm.line,
					Information: detectionForm.bib,
					DateDetection: new Date(
						dayjs(milisecondsISODate(detectionForm.date))
							.tz(timezone, true)
							.toISOString()
					),
					Supprimee: 0,
					Modifie: 0
				}
			});

			await refetch();

			Toast.success(trad[lang].deletionSuccess);
		} catch (error) {
			Toast.error(trad[lang].deletionError);
		}
	};

	const handleForm = (key: string, value: any) =>
		setDetectionForm((old) => ({ ...old, [key]: value }));

	const toggleModal = () => setOpenedEdit((old) => !old);

	const closeModal = () => setOpenedEdit(false);

	const handleUpdateDetection = async () => {
		try {
			if (!idCalendrier) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			await postMessage(idCalendrier, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detectionForm.line,
					Information: detectionForm.bib,
					DateDetection: new Date(
						dayjs(milisecondsISODate(detectionForm.date))
							.tz(timezone, true)
							.toISOString()
					),
					Supprimee: 0,
					Modifie: 1
				}
			});

			await refetch();

			closeModal();

			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].updateError);
		}
	};

	useEffect(() => {
		setDetectionForm({
			bib: detection.dossard,
			date: dayjs(detection.date)
				.tz(timezone)
				.tz("Europe/Paris", true)
				.format("YYYY-MM-DDTHH:mm:ss.SSS"),
			line: detection.ligne
		});
	}, [detection]);

	return (
		<>
			<tr className={`transition-colors ${bg_colors()}`}>
				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div className="flex items-center justify-center gap-x-2">
						<h2
							className={
								"rounded-2xl bg-blue-100 px-4 py-1 font-medium text-gray-800"
							}
						>
							{detection.dossard}
						</h2>
					</div>
				</td>

				<td
					className={`hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 lg:table-cell`}
				>
					<div className="flex items-center justify-center gap-x-2">
						<h2
							className={"font-medium text-gray-800"}
						>{`${detection.nom} ${detection.prenom}`}</h2>
					</div>
				</td>

				<td
					className={`hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 lg:table-cell`}
				>
					<div className="flex items-center justify-center gap-x-2">
						<h2 className={"font-medium text-gray-800"}>
							{detection.nomCalendrier}
						</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div className="flex items-center justify-center gap-x-2">
						<h2 className={"font-medium text-gray-800"}>
							{detection?.nomPointage}
						</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div className="flex items-center justify-center gap-x-2">
						<h2 className={"font-medium text-gray-800"}>
							{new Date(detection.date).toLocaleString("fr-FR", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
								hour: "2-digit",
								minute: "2-digit",
								second: "2-digit",
								fractionalSecondDigits: 3,
								timeZone: timezone
							})}
						</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div
						className="flex cursor-pointer items-center justify-center gap-x-2 rounded-md bg-primary px-3 py-2 text-white transition-colors hover:bg-primarydark"
						onClick={toggleModal}
					>
						{trad[lang].edit}
					</div>
				</td>

				{detection.statut == 0 ? (
					<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
						<div
							className="flex cursor-pointer items-center justify-center gap-x-2 rounded-md bg-green-600 px-3 py-2 text-white transition-colors hover:bg-red-800"
							onClick={handleRestore}
						>
							{trad[lang].restore_detection}
						</div>
					</td>
				) : (
					<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
						<div
							className="flex cursor-pointer items-center justify-center gap-x-2 rounded-md bg-red-600 px-3 py-2 text-white transition-colors hover:bg-red-800"
							onClick={() => setOpen(true)}
						>
							{trad[lang].delete}
						</div>
					</td>
				)}
			</tr>

			{open && (
				<Modal
					children={
						<span> Êtes vous sur de vouloir supprimer cette detection ? </span>
					}
					onClickButton={handleDeletion}
					closeModal={() => setOpen(false)}
					deleteBtn
					titleButton={"Supprimer"}
					height="0%"
					style={{ width: "50%", justifyContent: "space-around" }}
				/>
			)}

			{openedEdit && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].edit_detection}
							</h3>
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={closeModal}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex gap-6 p-6">
							<input
								type="number"
								name="bib"
								id="bib"
								className="block rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
								onChange={(e) => handleForm("bib", e.target.value)}
								value={detectionForm.bib}
								placeholder={trad[lang].number_bib}
							/>

							<input
								type="datetime-local"
								name="date"
								id="date"
								className="block rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
								onChange={(e) => {
									handleForm("date", e.target.value);
								}}
								value={detectionForm.date}
								step={0.001}
							/>

							<input
								type="number"
								name="line"
								id="line"
								className="block rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
								onChange={(e) => handleForm("line", e.target.value)}
								value={detectionForm.line}
								placeholder={trad[lang].number_line}
							/>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={handleUpdateDetection}
							>
								{trad[lang].edit_detection}
							</button>
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={closeModal}
							>
								{trad[lang].cancel}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ManageDetectionsItem;
