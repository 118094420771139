import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileCsv } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { getExportElogica } from "../../api/event";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { exportFormat } from "../../utils/ExportFormat";
import ExportModal from "../modals/ExportModal";
import ImportModal from "../modals/ImportModal";
import AccessGuard, { AccessKeys, AccessType } from "../navigation/AccessGuard";

function ImportExport({
	lang,
	refetch,
	numSubs,
	eventName,
	data
}: {
	lang: ILang;
	refetch: any;
	numSubs: number;
	eventName: string;
	data: any;
}) {
	const [open, setOpen] = React.useState(false);
	const [openImport, setOpenImport] = React.useState(false);
	const [openExport, setOpenExport] = React.useState(false);
	const [format, setFormat] = React.useState("");
	const { slug } = useParams();
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const toggleFilter = () => setIsComponentVisible((old) => !old);

	const handleExportLogica = async (format: string, extension: string) => {
		const { data, headers } = await getExportElogica(slug as string);

		const type = exportFormat.filter((type: any) => type.format === format);

		const blob = new Blob([data], {
			type: type.mimeType
		});

		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = `${
			eventName ? eventName + "_Inscriptions_FFA_e-logica" : "Inscriptions e-logica"
		}.${extension}`;
		document.body.appendChild(link);
		link.click();

		URL.revokeObjectURL(url);
	};

	const handleExport = async (format: string, extension: string) => {
		setOpenExport(true);
		setFormat(format);
	};

	return (
		<>
			<div className="flex flex-row gap-3" ref={ref}>
				<button
					id="dropdownDividerButton"
					onClick={() => {
						setOpenImport(true);
					}}
					className="flex w-full flex-row items-center justify-center gap-1 self-start rounded-md border border-secondary py-2 px-3 text-secondary duration-150 hover:border-primary hover:text-primary sm:w-auto"
					type="button"
				>
					{trad[lang].import}
				</button>
				<div className="relative">
					<button
						id="dropdownDividerButton"
						onClick={() => {
							toggleFilter();
						}}
						className="flex w-full flex-row items-center justify-center gap-1 self-start rounded-md border border-secondary py-2 px-3 text-secondary duration-150 hover:border-primary hover:text-primary sm:w-auto"
						type="button"
					>
						Export <AiOutlineDown />
					</button>
					{isComponentVisible && (
						<div
							id="dropdownDivider"
							className="absolute z-10 w-52 divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow dark:divide-gray-600 dark:bg-gray-700"
						>
							{exportFormat &&
								exportFormat.map(
									(xport: any, index: number) =>
										xport.active && (
											<p key={index}>
												<AccessGuard
													permissionKey={AccessKeys.SUBSCRIPTIONS_EXPORT}
													type={AccessType.BUTTON}
												>
													<button
														className="flex w-full items-center gap-x-2 px-4 py-2 text-gray-700 hover:bg-gray-100 disabled:text-gray-400 disabled:opacity-70 disabled:hover:bg-gray-50 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
														onClick={() => {
															if (xport.format === "ffa_elogica") {
																handleExportLogica(
																	xport.format,
																	xport.extension
																);
															} else
																handleExport(xport.format, xport.extension);
														}}
													>
														{xport.name}
														{xport.format === "excel" ? (
															<RiFileExcel2Fill color="green" />
														) : xport.format === "pdf" ? (
															<BsFileEarmarkPdfFill color="darkred" />
														) : xport.format === "csv" ? (
															<FaFileCsv color="black" />
														) : xport.format === "gmdag" ? (
															<HiDownload color="black" />
														) : (
															<HiDownload color="black" />
														)}
													</button>
												</AccessGuard>
											</p>
										)
								)}
						</div>
					)}
				</div>
			</div>

			{
				<ImportModal
					open={openImport}
					setOpen={setOpenImport}
					lang={lang}
					refetch={refetch}
					numSubs={numSubs}
					dataSubs={data}
				/>
			}

			{
				<ExportModal
					open={openExport}
					setOpen={setOpenExport}
					lang={lang}
					refetch={refetch}
					numSubs={numSubs}
					format={format}
				/>
			}
		</>
	);
}

export default ImportExport;
