import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
	createSegment,
	getCheckpointsByRun,
	updateSegment
} from "../../../api/event";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import Toast from "../../../utils/Toasts";

const SportSegmentModal = ({
	segment,
	closeModal,
	refetch
}: {
	segment: any;
	closeModal: any;
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { route, idparcours } = useParams();
	const [segmentForm, setSegmentForm] = useState({
		id: segment.idSegment || 0,
		libelleSegment: segment.libelleSegment || "",
		idCheckpointDebut: segment.checkpointDebut?.idCheckpoint || 0,
		idCheckpointFin: segment.checkpointFin?.idCheckpoint || 0
	});

	const { data } = useQuery({
		queryKey: ["checkpoints", route],
		queryFn: () => getCheckpointsByRun(route as string),
		refetchOnWindowFocus: false,
		enabled: !!route
	});

	const handleChange = (key: string, value: any) =>
		setSegmentForm((old: any) => ({ ...old, [key]: value }));

	const saveSegment = async () => {
		try {
			if (!segmentForm.libelleSegment || !segmentForm.idCheckpointFin) {
				Toast.error(trad[lang].event_form_required);
				return;
			}

			if (segmentForm.id) {
				await updateSegment(segmentForm.id, segmentForm);
			} else {
				await createSegment(parseInt(idparcours as string, 10), segmentForm);
			}

			refetch();
			Toast.success(trad[lang].success_creation_segment);
			closeModal();
		} catch (error) {
			Toast.error(trad[lang].error_creation_segment);
		}
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[80vh] w-4/5 min-w-[320px] max-w-screen-lg overflow-y-auto rounded-md border bg-white p-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{segment.libelleSegment || trad[lang].create_segment}
				</h2>

				<div className="flex flex-col gap-3 md:grid md:grid-cols-2">
					{/* Nom de l'étape */}
					<div className={`col-span-2 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_name"}>
							{trad[lang].segment_name_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="step_name"
							className="rounded-md"
							onChange={(e) => handleChange("libelleSegment", e.target.value)}
							value={segmentForm.libelleSegment}
						/>
					</div>

					{/* CheckpointDebut */}
					<div className={`flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="checkpointDebut">
							{trad[lang].checkpoint_debut_label}
						</label>

						<select
							className="rounded-md"
							id="checkpointDebut"
							onChange={(e) =>
								handleChange("idCheckpointDebut", e.target.value)
							}
							value={segmentForm.idCheckpointDebut}
						>
							<option>Choisissez un checkpoint</option>

							{data?.map((item: any) => (
								<option
									value={item.idCheckpoint}
									key={item.idCheckpoint}
								>{`${item.idDag} - ${item.libelleCheckpoint}`}</option>
							))}
						</select>
					</div>

					{/* CheckpointFin */}
					<div className={`flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="checkpointDebut">
							{trad[lang].checkpoint_fin_label}
							<RequiredComponent />
						</label>

						<select
							className="rounded-md"
							id="checkpointDebut"
							onChange={(e) => handleChange("idCheckpointFin", e.target.value)}
							value={segmentForm.idCheckpointFin}
						>
							<option value={0}>Choisissez un checkpoint</option>

							{data?.map((item: any) => (
								<option
									value={item.idCheckpoint}
									key={item.idCheckpoint}
								>{`${item.idDag} - ${item.libelleCheckpoint}`}</option>
							))}
						</select>
					</div>
				</div>

				<div className="mt-3 flex flex-row gap-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].cancel}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={saveSegment}
					>
						{segmentForm.id
							? trad[lang].update_segment
							: trad[lang].create_segment}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportSegmentModal;
