import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventIdentityConfig, getEventRunsConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import useScript from "../hooks/useScript";
import trad from "../lang/traduction";

const Jetcodes = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data: RunsInfos = [],
		isLoading: RunsLoading,
		refetch: RefetchRuns
	} = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	useScript(
		`https://jetcodesport.dag-system.com/jetcodes/${lang}?customerID=65b9745138704e83330d7acd6be26dc824355bd3e0378c276f1df8d41096e1cc`,
		[lang, EventLoading, EventInfos, RunsLoading]
	);

	const stepsConfig = () => [
		{
			type: "engine",
			title:
				"Code du moteur du jetcode à copier 1 fois dans le header de votre site :",
			code: `<script src="https://jetcodesport.dag-system.com/jetcodes/${lang}?customerID=65b9745138704e83330d7acd6be26dc824355bd3e0378c276f1df8d41096e1cc" defer></script>`
		},
		{
			type: "basket",
			title: "Code du panier à copier 1 fois sur chaque page de votre site :",
			code: '<div class="basket-jetcode" data-basket-id="0"></div>',
			component: <div className="basket-jetcode" data-basket-id="0"></div>
		},
		{
			type: "jetcodes",
			title: "Code spécifique aux courses :",
			jetcodes: RunsInfos.map((item: any) => ({
				id: item.id_produit,
				code: `<div class="eventtarif-jetcode" data-eventtarif-id='${item.id_produit}' ></div>`,
				component: (
					<div
						className="eventtarif-jetcode"
						data-eventtarif-id={item.id_produit}
					></div>
				)
			}))
		}
	];

	if (EventLoading || RunsLoading) {
		return <PageLoader menu_key="jetcodes" />;
	}

	return (
		<Layout active_key="jetcodes">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: EventInfos.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "jetcodes", to: "#", active: true }
					]}
				/>

				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
					{`${EventInfos?.name} - ${trad[lang].jetcodes}`}
				</h1>

				<div className="flex w-full flex-col">
					{stepsConfig().map((item, index: number) => (
						<div
							className={`flex flex-row gap-3 py-5 ${
								index + 1 !== stepsConfig().length && "border-b"
							}`}
							key={index}
						>
							<p className="flex h-10 w-10 flex-row items-center justify-center rounded-3xl bg-primary text-xl text-white">
								{index + 1}
							</p>
							<div className="w-full">
								<p className="text-xl font-bold text-gray-700">{item.title}</p>

								{item.code && <code>{item.code}</code>}
								{item.type == "basket" && item.component}

								{item.type == "jetcodes" &&
									item.jetcodes.map((item: any) => (
										<div
											className="mb-6 mt-2 w-full border-b p-4"
											key={item.id}
										>
											<code>{item.code}</code>

											{item.component}
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</div>

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
};

export default Jetcodes;
