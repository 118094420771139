import { API } from "./APIClient";

export const getCategories = async (slug: string) => {
	try {
		if (!slug) throw new Error("No slug gived");
		const { data } = await API.get(`/subscription/${slug}/categories`);
		return data;
	} catch (error) {
		console.warn(["[getCategories error]"]);
		throw error;
	}
};
