import { Credentials } from "../types/Credentials";
import { API } from "./APIClient";

export const signUp = async (body: any) => {
	try {
		const { data } = await API.post("/auth/signup?orga=true", {
			...body,
			idApplication: process.env.REACT_APP_ID_APPLICATION,
			invitationKey: localStorage.getItem("invitationKey")
		});

		return data;
	} catch (error) {
		console.warn("[signUp]");
		throw error;
	}
};

export async function logIn(credentials: Credentials) {
	try {
		const { data } = await API.post("/auth/login", {
			...credentials,
			invitationKey: localStorage.getItem("invitationKey")
		});

		return data;
	} catch (error) {
		console.warn("[logIn]");
		throw error;
	}
}

export async function logOut() {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		return API.get("/auth/logout", headers);
	} catch (error) {
		console.warn("[logOut]");
		throw error;
	}
}

export async function getProfile(userId: number) {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		const { data } = await API.get(`/user/${userId}`, headers);
		return data;
	} catch (error) {
		console.warn("[getProfile]");
		throw error;
	}
}

export const findUser = async (getPermissions: boolean = false) => {
	try {
		const invitationKey: string = localStorage.getItem("invitationKey") || "";
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		let url: string = "/auth/user";
		if (invitationKey)
			url += `?invitationKey=${invitationKey}&getPermissions=1`;
		else if (getPermissions === true) url += "?getPermissions=1";
		const { data } = await API.get(url, headers);

		// Delete invitation key from local storage (rights have been added to the user)
		if (invitationKey) localStorage.removeItem("invitationKey");

		return data;
	} catch (error) {
		throw error;
	}
};

export const becomeOrga = async (idApplication: any, idPersonne: any) => {
	try {
		const { data } = await API.post(
			`/auth/${idApplication}/becomeOrga/${idPersonne}`
		);

		return data;
	} catch (error) {
		throw error;
	}
};
