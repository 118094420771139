import { useContext } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import { LiveContext } from "../../contexts/LiveContext";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import AccessGuard, { AccessKeys } from "../navigation/AccessGuard";

function AddSubButton({
	lang,
	refetch,
	setOpenModal,
	setRaceData
}: {
	lang: ILang;
	refetch: any;
	setOpenModal: any;
	setRaceData: any;
}) {
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const { slug } = useParams();
	const { isLoadingLiveConfig, liveConfig } = useContext(LiveContext);

	const toggleDropdown = () => {
		setIsComponentVisible(true);
	};

	return (
		<div ref={ref}>
			<AccessGuard
				type="button"
				permissionKey={AccessKeys.SUBSCRIPTIONS_ADD_MANUAL}
			>
				<button
					id="dropdownDividerButton"
					onClick={toggleDropdown}
					disabled={isLoadingLiveConfig}
					className="flex w-full flex-row items-center justify-center gap-1 rounded-md border border-secondary py-2 px-3 text-secondary duration-150 hover:border-primary hover:text-primary disabled:border-gray-400 disabled:text-gray-400 disabled:opacity-70 sm:w-auto"
					type="button"
				>
					{trad[lang].addRegistration}
					{isLoadingLiveConfig ? (
						<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
					) : (
						<HiOutlineChevronDown className="ml-2 h-[18px] w-[18px]" />
					)}
				</button>
			</AccessGuard>

			{isComponentVisible && (
				<div
					id="dropdownDivider"
					className="absolute z-10 divide-y divide-gray-100 rounded-lg bg-white shadow"
				>
					<ul className="py-2 text-sm" aria-labelledby="dropdownDividerButton">
						{liveConfig?.calendrier_child?.length > 0 ? (
							liveConfig.calendrier_child?.map((race: any, index: number) => (
								<li key={index}>
									<button
										onClick={() => {
											toggleDropdown();
											setRaceData(race);
											setOpenModal(true);
										}}
										className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
									>
										<span className="mr-1 inline-block rounded bg-sky-200 py-1 px-2 text-xs font-semibold uppercase text-sky-600 last:mr-0">
											{race.configuration_calendrier.nb_equipier <= 1 ? (
												<div className="flex">{trad[lang].invididual}</div>
											) : (
												<div className="flex">
													{trad[lang].teamOf}{" "}
													{race.configuration_calendrier.nb_equipier}
												</div>
											)}
										</span>
										<span className="px-2 uppercase">{race.nom}</span>
									</button>
								</li>
							))
						) : (
							<li>
								<div className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
									<span className="px-2 uppercase">{trad[lang].noRace}</span>
								</div>
							</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
}

export default AddSubButton;
