import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const EventParcoursModule = ({
	parcours,
	openParcours,
	deleteParcours
}: {
	parcours: any;
	openParcours: any;
	deleteParcours: any;
}) => {
	const { lang } = useContext(AppContext);

	return (
		<div
			className={classNames(
				"flex w-full flex-col items-center justify-between gap-4 border-b p-3 px-8 transition-colors hover:bg-slate-100 md:flex-row"
			)}
		>
			<div className="flex flex-row items-baseline gap-3">
				<h2 className="text-xl font-bold">
					{`${trad[lang].parcours} - ${parcours.nom}`}
				</h2>
			</div>

			<div className="col-span-1 flex flex-row justify-end gap-3">
				<Link
					to={`${parcours.id}`}
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
				>
					{trad[lang].configure_segments}
				</Link>

				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
					onClick={() => openParcours(parcours)}
				>
					{trad[lang].edit_parcours}
				</button>

				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
					onClick={() => deleteParcours(parcours.id, parcours.nom)}
				>
					{trad[lang].delete_parcours}
				</button>
			</div>
		</div>
	);
};

export default EventParcoursModule;
