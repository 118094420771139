import {
	LocalizationProvider,
	MobileDateTimePicker,
	frFR
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");

const DateTimePickerTZ = ({
	handleChange,
	value = null,
	error = false,
	seconds = false,
	timezone = "Europe/Paris",
	minDateTime = null,
	maxDateTime = null
}: {
	handleChange: (val: Dayjs | null) => void;
	value: Dayjs | null;
	error?: boolean;
	seconds?: boolean;
	timezone?: string;
	minDateTime?: Dayjs | null;
	maxDateTime?: Dayjs | null;
}) => {
	const { lang } = useContext(AppContext);

	const style = `rounded-md ${
		error && "border-transparent outline outline-2 outline-red-500"
	}`;

	const locale =
		lang == "fr"
			? frFR.components.MuiLocalizationProvider.defaultProps.localeText
			: undefined;

	const format = seconds ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY HH:mm";

	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale={lang}
			localeText={locale}
		>
			<MobileDateTimePicker
				ampm={false}
				value={dayjs(value).tz(timezone)}
				className={style}
				format={format}
				minutesStep={1}
				minDateTime={minDateTime as Dayjs}
				maxDateTime={maxDateTime as Dayjs}
				onAccept={handleChange}
				slotProps={{
					textField: { error: false },
					actionBar: { actions: ["accept"] }
				}}
				timezone={timezone}
				showDaysOutsideCurrentMonth={true}
			/>
		</LocalizationProvider>
	);
};

export default DateTimePickerTZ;
