import { API } from "./APIClient";

export const publishStatus = async (slug: string, status: boolean) => {
	try {
		await API.patch(`event/slug/${slug}/publishStatus`, {
			status: status
		});
	} catch (error) {
		console.warn("[publishStatus error]", error);
		throw error;
	}
};

export const getDatabaseSubs = async (slug: string) => {
	try {
		const { data } = await API.get(`/admin/event/${slug}/getDatabaseSubs`, {
			headers: { Authorization: localStorage.getItem("token") }
		});
		return data;
	} catch (error) {
		console.warn("[getDatabaseSubs error]", error);
		throw error;
	}
};

export const delDatabaseSubs = async (slug: string) => {
	try {
		const { data } = await API.post(
			`/admin/event/${slug}/deleteDatabaseSubs`,
			null,
			{ headers: { Authorization: localStorage.getItem("token") } }
		);
		return data;
	} catch (error) {
		console.warn("[deleteDatabaseSubs error]", error);
		throw error;
	}
};
