import { useEffect, useRef, useState } from "react";

export const useOutsideClick = (initialValue: boolean) => {
	const [isComponentVisible, setIsComponentVisible] = useState(initialValue);
	const ref = useRef<any>(null);

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			(!ref.current.contains(event.target) || !ref.current == event.target)
		) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);

		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	return { ref, isComponentVisible, setIsComponentVisible };
};
