import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { createEtape, updateEtape } from "../../../api/event";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import Toast from "../../../utils/Toasts";
import DateTimePickerTZ from "../../common/DateTimePickerTZ";

const SportStepModal = ({
	step,
	closeModal,
	refetch,
	timezone
}: {
	step: any;
	closeModal: any;
	refetch: any;
	timezone: string;
}) => {
	const { lang } = useContext(AppContext);
	const { route } = useParams();
	const [stepForm, setStepForm] = useState({
		id: step.idEtape,
		nom: step.nom || "",
		dateDebut: step.dateDebut || dayjs.utc(),
		distance: step.distance || ""
	});

	const handleChange = (key: string, value: any) => {
		setStepForm((old: any) => ({ ...old, [key]: value }));
	};

	const saveStep = async () => {
		try {
			if (!stepForm.nom || !stepForm.dateDebut) {
				Toast.error(trad[lang].event_form_required);
				return;
			}

			if (stepForm.id) {
				await updateEtape(stepForm.id, stepForm);
			} else {
				await createEtape(route as string, stepForm);
			}

			Toast.success(trad[lang].success_creation_etape);
			refetch();
			closeModal();
		} catch (error) {
			Toast.error(trad[lang].error_creation_etape);
		}
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[80vh] w-4/5 min-w-[320px] max-w-screen-lg overflow-y-auto rounded-md border bg-white p-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{step.nom || trad[lang].create_step}
				</h2>

				<div className="flex flex-col gap-3 md:grid md:grid-cols-2">
					{/* Nom de l'étape */}
					<div className={`col-span-2 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_name"}>
							{trad[lang].step_name_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="step_name"
							className="rounded-md"
							onChange={(e) => handleChange("nom", e.target.value)}
							value={stepForm.nom}
						/>
					</div>

					{/* Date de début de l'étape */}
					<div className={`flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_start_date"}>
							{trad[lang].event_start_date_label}
							<RequiredComponent />
						</label>

						<DateTimePickerTZ
							handleChange={(e) => {
								if (e) {
									setStepForm((old) => ({
										...old,
										dateDebut: e.toISOString()
									}));
								}
							}}
							value={stepForm.dateDebut}
							timezone={timezone}
						/>
					</div>

					{/* Distance l'étape */}
					<div className={`flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_distance"}>
							{trad[lang].event_distance_label}
						</label>

						<input
							type="number"
							id="step_distance"
							min={0}
							step={1}
							className="rounded-md"
							onChange={(e) => handleChange("distance", e.target.value)}
							value={stepForm.distance}
						/>
					</div>

					{/* Temps mini 
          <div className="flex w-1/2 flex-col-reverse pt-1 md:flex-row md:items-center md:gap-6">
            <div className="w-full">
              <label className="block text-left text-sm font-medium text-gray-700">
                {trad[lang].miniTime}
              </label>
              <div className="mt-1 w-full">
                <LocalizationProvider
                  adapterLocale={lang}
                  dateAdapter={AdapterDayjs}
                >
                  <MobileTimePicker
                    defaultValue={stepForm.miniTime}
                    views={["hours", "minutes", "seconds"]}
                    format="HH:mm:ss"
                    onChange={(e: any) => {
                      handleChange("miniTime", e.format("HH:mm:ss"));
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>*/}
				</div>

				<div className="mt-3 flex flex-row gap-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].cancel}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={saveStep}
					>
						{stepForm.id ? trad[lang].edit_step : trad[lang].create_step}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportStepModal;
