import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import TabsBtn from "../common/TabsBtn";

function NavTabs({
	selectedTab,
	setSelectedTab
}: {
	selectedTab: string;
	setSelectedTab: (selectedTab: string) => void;
}) {
	const { lang } = useContext(AppContext);

	return (
		<TabsBtn
			selectedTab={selectedTab}
			setSelectedTab={setSelectedTab}
			tabs={[
				{ name: trad[lang].toCome, value: "future" },
				{ name: trad[lang].today, value: "today" },
				{ name: trad[lang].past, value: "past" }
			]}
		/>
	);
}

export default NavTabs;
