import { useContext } from "react";
import Logo from "../assets/logo_solo.png";
import LoginForm from "../components/login/LoginForm";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function Login() {
	const { lang } = useContext(AppContext);

	return (
		<div className="flex h-screen flex-col items-center justify-center">
			{/* Logo */}
			<div className="flex flex-row items-center gap-1">
				<img src={Logo} alt="" />
				<h1 className="text-2xl font-bold text-primary">Chronometrage.com</h1>
			</div>

			{/* Title */}
			<h2 className="text-center font-bold">{trad[lang].log_title}</h2>

			{/* Form */}
			<LoginForm />
		</div>
	);
}

export default Login;
