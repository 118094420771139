import { Fragment, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const RegistrationsOrderTable = ({ data }: { data: any }) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();

	const totalTTC = data
		? data
				.map((item: any) =>
					item.rows
						.map((row: any) => parseFloat(row.PrixTTC))
						.reduce((sum: any, price: any) => sum + price, 0)
				)
				.reduce((total: any, sum: any) => total + sum, 0)
		: 0;

	return (
		<div className="w-[100%] py-2 align-middle">
			{data.map((subscription: any) => (
				<Fragment key={subscription.subscription.id}>
					<div className="flex flex-row items-center justify-between">
						<h2 className="mb-2">{`${subscription.rows[0].personne.lastname} ${subscription.rows[0].personne.firstname}`}</h2>

						{subscription.refund == null && (
							<Link
								className="mb-2 block rounded-md bg-primary py-3 px-3 text-center text-sm text-white duration-300 hover:bg-primarymedium"
								to={`${slug}/registration-refund/${subscription.subscription.id}`}
							>
								{trad[lang].reimburse}
							</Link>
						)}
					</div>

					<div className="mb-4 overflow-x-auto border border-gray-200">
						<table className="w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
									>
										{trad[lang].product_name}
									</th>
									<th
										scope="col"
										className="w-1/3 select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
									>
										Date de la commande
									</th>
									<th
										scope="col"
										className="w-1/4 select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
									>
										{trad[lang].refund_price}
									</th>
								</tr>
							</thead>

							<tbody className="divide-y divide-gray-200 bg-white">
								{subscription.rows.map((row: any) => (
									<tr
										className="transition-colors hover:bg-gray-50"
										key={row.IDLigneCommande}
									>
										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{row?.produit?.DesignationInternet ||
												trad[lang].subscription_fees}
										</td>
										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{new Date(row.DateDebut).toLocaleDateString("fr-FR")}
										</td>

										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{`${parseFloat(row.PrixTTC).toFixed(2)} €`}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{subscription.refund != null && (
						<div className="mb-4 overflow-x-auto border border-gray-200">
							<table className="w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											{trad[lang].product_name}
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											{trad[lang].date}
										</th>
										<th
											scope="col"
											className="w-1/4 select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											{trad[lang].refunded_amount}
										</th>
									</tr>
								</thead>

								<tbody className="divide-y divide-gray-200 bg-white">
									<tr
										className="cursor-pointer transition-colors hover:bg-gray-50"
										key={subscription.refund.IDLigneCommande}
									>
										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{subscription.refund?.produit?.DesignationInternet ||
												trad[lang].refund_product}
										</td>
										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{new Date(subscription.refund.CommandeLe).toLocaleString(
												"fr-FR"
											)}
										</td>
										<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
											{`${Math.abs(
												parseFloat(subscription.refund.TotalTTC)
											).toFixed(2)} €`}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</Fragment>
			))}
			Montant total : {totalTTC.toFixed(2)} € TTC
		</div>
	);
};

export default RegistrationsOrderTable;
