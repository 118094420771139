import {
	createContext,
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useEffect,
	useRef,
	useState
} from "react";
import { ILang } from "../types/Lang";

export type ISubscriptionSort = {
	bib: number | null;
	run: number | null;
	contact: number | null;
	name: number | null;
	status: number | null;
	date: number | null;
};

export type ISubscriptionFilter = {
	bib: string;
	run: string;
	contact: string;
	name: string;
	status: string;
	date: string;
};

export type IDetectionFilter = {
	run: string;
	detector: string;
};

type AppContextType = {
	lang: ILang;
	storeLang: (value: ILang) => void;
	subscriptionsSorting: ISubscriptionSort;
	setSubscriptionsSorting: Dispatch<SetStateAction<ISubscriptionSort>>;
	subscriptionsFilters: ISubscriptionFilter;
	setSubscriptionsFilter: Dispatch<SetStateAction<ISubscriptionFilter>>;
	detectionFilters: IDetectionFilter;
	setDetectionFilter: Dispatch<SetStateAction<IDetectionFilter>>;
	unsavedChangesRef: MutableRefObject<boolean>;
	reduced: boolean;
	setReduced: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const [reduced, setReduced] = useState(false);
	const [lang, setLang] = useState<ILang>("fr");

	const [subscriptionsSorting, setSubscriptionsSorting] = useState<any>({
		bib: 1,
		run: null,
		contact: null,
		name: null,
		status: null,
		date: null
	});

	const [subscriptionsFilters, setSubscriptionsFilter] = useState({
		bib: "",
		run: "",
		contact: "",
		name: "",
		status: "",
		date: ""
	});

	const [detectionFilters, setDetectionFilter] = useState({
		run: "",
		detector: ""
	});

	// STOCK LANG IN LOCAL STORAGE
	const storeLang = async (value: ILang) => {
		try {
			await localStorage.setItem("lang", value);
			setLang(value);
		} catch (error) {
			console.error(error);
		}
	};

	const unsavedChangesRef = useRef(false);

	// GET LANG FROM LOCAL STORAGE
	useEffect(() => {
		const getLang = async () => {
			try {
				const storedLang = (await localStorage.getItem("lang")) as ILang;

				if (!storedLang) {
					const browser_lang = window.navigator.language.split("-")[0] as ILang;

					if (["fr", "en"].includes(browser_lang)) {
						storeLang(browser_lang);
					} else {
						storeLang("en");
					}
				} else {
					setLang(storedLang);
				}
			} catch (error) {
				console.error(error);
			}
		};

		getLang();
	}, []);

	return (
		<AppContext.Provider
			value={{
				lang,
				storeLang,
				detectionFilters,
				setDetectionFilter,
				subscriptionsSorting,
				setSubscriptionsSorting,
				subscriptionsFilters,
				setSubscriptionsFilter,
				unsavedChangesRef,
				reduced,
				setReduced
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;
