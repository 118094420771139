interface ButtonProps {
	title: string;
	buttonType: "button" | "submit" | "reset" | undefined;
	disabled?: boolean;
	light?: boolean;
	onClick: () => void;
}

const Button = ({
	title,
	disabled,
	buttonType,
	light,
	onClick
}: ButtonProps): JSX.Element => {
	return (
		<button
			type={buttonType}
			disabled={disabled}
			className={`
          ${
						disabled
							? "border-transparent bg-gray-300 text-white"
							: light
							? "border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
							: "border-transparent bg-secondary text-white hover:bg-primary"
					}
          mx-2 inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm
        `}
			onClick={onClick}
		>
			{title}
		</button>
	);
};

export default Button;
