import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useQuery } from "react-query";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { API } from "../../../api/APIClient";
import {
	createSportEventIdentity,
	getEventIdentityConfig,
	getEventSubtypes,
	updateSportEventIdentity
} from "../../../api/event";
import BreadCrumb from "../../../components/common/BreadCrumb";
import PageLoader from "../../../components/common/PageLoader";
import EventConfigurationBottomBar from "../../../components/event_configuration/EventConfigurationBottomBar";
import EventInput from "../../../components/event_configuration/EventInput";
import { AccessKeys } from "../../../components/navigation/AccessGuard";
import Layout from "../../../components/navigation/Layout";
import { SportIdentityConfig } from "../../../config/EventForms/Sport/SportIdentity";
import { AppContext } from "../../../contexts/AppContext";
import { UserContext } from "../../../contexts/UserContext";
import trad from "../../../lang/traduction";
import { IIdentityForm } from "../../../types/EventForms/ISportIdentity";
import { fileToB64 } from "../../../utils/Base64";
import Toast from "../../../utils/Toasts";

dayjs.extend(utc);
dayjs.extend(timezone);

const SportEventIdentity = () => {
	const { state } = useLocation();
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { getUser } = useContext(UserContext);
	const { slug } = useParams();
	const navigate = useNavigate();

	// State du chargement
	const [loading, setLoading] = useState(false);
	const [timezoneLoading, setTimezoneLoading] = useState(true);

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	const [openedFile, setOpenedFile] = useState<any>({});
	const closeFile = () => setOpenedFile((old: any) => ({}));

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	const resizeIframe = () => {
		const iframe: HTMLIFrameElement | null =
			document.querySelector("#document_file");
		if (!iframe) return;

		const scroll_height = iframe?.contentWindow?.document.body.scrollHeight;
		const scroll_width = iframe?.contentWindow?.document.body.scrollHeight;

		if (!scroll_height || !scroll_width) return;

		iframe.height = scroll_height?.toString();
		iframe.width = scroll_width?.toString();
	};

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	// State du formulaire
	const [errors, setErrors] = useState<string[]>([]);
	const [identityForm, setIdentityForm] = useState<IIdentityForm>({
		eventType: 0,
		eventCategory: 1,
		subjectToVAT: false,
		name: "",
		startDate: new Date().toISOString(),
		endDate: new Date().toISOString(),
		availablePlaces: "",
		longDescription: "",
		shortDescription: "",
		timezone: dayjs.tz.guess(),
		medias: [],
		ruleFile: null,
		city: "",
		postalCode: "",
		address: "",
		country: "",
		contacts: [],
		organizingRules: "",
		siffa: ""
	});
	const debouncedIdentityForm = useDebounce(identityForm, 500);

	// Si un slug est présent, récupération des infos existante
	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	// Récupération des types de course
	const { data: subtypes = [], isLoading: TypesLoading } = useQuery({
		queryKey: "subtypes_sport",
		refetchOnWindowFocus: false,
		queryFn: () => getEventSubtypes("sport")
	});

	const sport_categorie =
		identityForm.eventType && subtypes.length
			? subtypes.find((type: any) => type.id == identityForm.eventType)
					?.type_categorie_type_evenement[0]?.type_categorie?.id
			: 0;

	const INPUT_CONFIG = SportIdentityConfig(
		subtypes,
		identityForm.startDate,
		identityForm.endDate,
		sport_categorie,
		slug
	);

	const handleChange = (key: string, value: any) => {
		setErrors((old) => {
			const newErrors = [...old];

			const index = newErrors.indexOf(key);

			if (index >= 0) {
				newErrors.splice(index, 1);
			}

			return newErrors;
		});

		setIdentityForm((old: any) => ({
			...old,
			[key]: value
		}));

		unsavedChangesRef.current = true;
	};

	useEffect(() => {
		if (
			debouncedIdentityForm.siffa &&
			debouncedIdentityForm.siffa.length > 3 &&
			debouncedIdentityForm.startDate
		) {
			validatePPS();
		}
	}, [debouncedIdentityForm]);

	// Validate PPS by requesting FFA API
	const validatePPS = async () => {
		try {
			// Prepare data to send
			// Get and format birthdate
			const regex = /^\d{4}-\d{2}-\d{2}$/;
			let ddn = "25/01/1982";
			let cmpdate = identityForm.startDate.toISOString
				? identityForm.startDate.toISOString().split("T")[0]
				: identityForm.startDate;
			if (/^\d{4}/.test(cmpdate)) {
				const dateCompetition = new Date(cmpdate) || new Date();

				cmpdate = `${dateCompetition.getDate()}/${
					dateCompetition.getMonth() + 1
				}/${dateCompetition.getFullYear()}`;
			}

			const body: any = {};
			body.nom = "nom";
			body.prenom = "prenom";
			body.numrel = "numlicence";
			body.sexe = "M";
			body.dateNaissance = ddn;
			body.cnilCode = 1;
			body.cmpcod = identityForm.siffa;
			body.cmpdate = cmpdate;

			const headers =
				typeof window !== "undefined" && localStorage.getItem("token")
					? {
							headers: {
								authorization: localStorage.getItem("token") as string
							}
					  }
					: {};

			const res = await API.post(
				`event/interest/slug/sendDataFFA`,
				body,
				headers
			);
			const data = res.data;
			if (!data || data[0] != "O" || data[data.length - 1].match(/PROx(\d+)/)) {
				const match = data[data.length - 1].match(/PROx(\d+)/);
				if (match) {
					if (match[1] == "011") {
						setErrors((old) => [...old, "siffa"]);
						return { isValid: false, code: "011" };
					} else if (match[1] == "014") {
						setErrors((old) => [...old, "startDate"]);
						return { isValid: false, code: "014" };
					}
				}
			}
			return { isValid: true };
		} catch (error) {
			console.error(error);
		}
		return { isValid: true };
	};

	useEffect(() => {
		if (dayjs(identityForm.startDate) > dayjs(identityForm.endDate)) {
			setIdentityForm((old) => ({
				...old,
				endDate: identityForm.startDate
			}));
		}
	}, [identityForm.startDate]);

	const handleFiles = async (
		files: FileList | null,
		type: number,
		id: string
	) => {
		try {
			if (!files) return;
			const file = files[0];

			(document.querySelector(`#${id}`) as HTMLInputElement).value = "";

			const alreadyExist = identityForm.medias.findIndex(
				(item: any) => item.name == file.name && item.type == type
			);

			if (alreadyExist >= 0) return Toast.error(trad[lang].file_exist);

			const fileb64 = await fileToB64(file);

			setIdentityForm((old: any) => ({
				...old,
				medias: [
					...old.medias,
					{ name: file.name, file: fileb64, type, extension: file.type }
				]
			}));
			unsavedChangesRef.current = true;
		} catch (error) {
			Toast.error(trad[lang].file_error);
		}
	};

	const handleRulesFile = async (files: FileList | null, id: string) => {
		try {
			// Remove file (trash icon clicked)
			if (!files) {
				setIdentityForm((old: any) => ({
					...old,
					ruleFile: null
				}));
				return;
			}

			const file = files[0];

			// If not PDF, return
			if (file.type != "application/pdf") {
				Toast.error(trad[lang].file_error);
				return;
			}

			// Reset input
			(document.querySelector(`#${id}`) as HTMLInputElement).value = "";

			// Store in form
			const fileb64 = await fileToB64(file);
			setIdentityForm((old: any) => ({
				...old,
				ruleFile: { name: file.name, file: fileb64, filetype: file.type }
			}));
			unsavedChangesRef.current = true;
		} catch (error) {
			Toast.error(trad[lang].file_error);
		}
	};

	const deleteFiles = async (type: number) =>
		setIdentityForm((old) => {
			const fileIndex = old.medias.findIndex((item) => item.type == type);

			if (fileIndex >= 0) {
				const file_array = [...old.medias];
				file_array.splice(fileIndex, 1);
				unsavedChangesRef.current = true;

				return {
					...old,
					medias: file_array
				};
			}

			return old;
		});

	const checkInputsValid = async () => {
		const errors = INPUT_CONFIG.filter((item) => item.required)
			.map((item) => {
				if (
					((item.type == "text" || item.type == "textarea") &&
						(!identityForm[item.key] || identityForm[item.key].length < 3)) ||
					(item.max && identityForm[item.key]?.length > item.max)
				) {
					return item.key;
				}

				if (item.type == "select" && identityForm[item.key] == "") {
					return item.key;
				}

				if (
					item.type == "datetime-local" &&
					identityForm[item.key] < new Date()
				) {
					return item.key;
				}
			})
			.filter((item) => item);

		if (identityForm["siffa"] && identityForm["siffa"].length > 3) {
			const { isValid, code } = await validatePPS();
			if (!isValid) {
				errors.push(code === "011" ? "siffa" : "startDate");
			}
		}
		if (errors.length > 0) {
			setErrors(errors as string[]);

			return true;
		}

		return false;
	};

	const saveStep = async () => {
		try {
			const has_errors = await checkInputsValid();

			if (has_errors) {
				Toast.error(trad[lang].event_form_required);
				return;
			}

			setLoading(true);

			if (slug) {
				await updateSportEventIdentity(slug, identityForm);
				unsavedChangesRef.current = false;
				Toast.success(trad[lang].sport_identity_success);
				return;
			}

			// Faire un appel pour créer l'évènement
			const new_slug = await createSportEventIdentity(identityForm);

			// Refresh permissions
			await getUser();

			unsavedChangesRef.current = false;
			navigate(`/${new_slug}/event-configuration/sport/`, {
				state: {
					newEvent: true
				}
			});

			Toast.success(trad[lang].sport_identity_success);
		} catch (error) {
			const errorType = (error as any).response.data.message;

			if (errorType == "POI_NAME_TAKEN") {
				return Toast.error(trad[lang].sport_identity_error_name_taken);
			}

			Toast.error(trad[lang].sport_identity_error);
		} finally {
			setLoading(false);
		}
	};

	const saveStepAndNext = async () => {
		try {
			const has_errors = await checkInputsValid();

			if (has_errors) {
				Toast.error(trad[lang].event_form_required);
				return;
			}
			setLoading(true);

			if (slug) {
				unsavedChangesRef.current = false;
				await updateSportEventIdentity(slug, identityForm);
				Toast.success(trad[lang].sport_identity_success);
				return;
			}

			const new_slug = await createSportEventIdentity(identityForm);

			// Refresh permissions
			await getUser();

			unsavedChangesRef.current = false;
			navigate(`/${new_slug}/event-configuration/sport/runs`);

			Toast.success(trad[lang].sport_identity_success);
		} catch (error) {
			throw Toast.error(trad[lang].sport_identity_error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (slug && isError) {
			Toast.error(trad[lang].event_does_not_exist);
			navigate(`/`);
		}
	}, [isError]);

	useEffect(() => {
		if (EventInfos) {
			setIdentityForm(EventInfos);
		}
	}, [EventInfos]);

	useEffect(() => {
		setTimezoneLoading(true);

		setTimeout(() => setTimezoneLoading(false), 1000);
	}, [identityForm.timezone]);

	if (EventLoading || TypesLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout active_key="configuration">
			<div className="relative flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				{/* Breadcrumb */}
				{slug && !state?.newEvent && (
					<BreadCrumb
						items={[
							{
								key: "event",
								text: EventInfos.name,
								to: `/${slug}/event-details`,
								active: false
							},
							{ key: "event_identity", to: "#", active: true }
						]}
					/>
				)}

				{state?.newEvent && (
					<BreadCrumb
						items={[
							{ key: "dashboard", to: "/", active: false },
							{
								key: "event_configuration",
								to: "/event-configuration",
								active: false
							},
							{ key: "event_identity", to: "#", active: true },
							{ key: "event_subscription", to: "#", active: false }
						]}
					/>
				)}

				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
					{trad[lang].sport_event_identity}
				</h1>

				{/* Grille du formulaire : 2 colonne de taille identique avec une goutière centrale */}
				<div className="mb-16 flex w-full flex-col gap-4 md:grid md:grid-cols-2">
					{/* Mapping de la config du formulaire */}
					{INPUT_CONFIG.filter((item) => !(item.visible == false)).map(
						(item) => (
							<EventInput
								config={item}
								handleChange={handleChange}
								handleFiles={handleFiles}
								handleRulesFile={handleRulesFile}
								deleteFiles={deleteFiles}
								values={identityForm}
								key={item.id}
								error={errors.includes(item.key)}
								timezone={identityForm.timezone}
								timezoneLoading={timezoneLoading}
								startDateISO={identityForm.startDate}
								endDateISO={identityForm.endDate}
								setOpenedFile={setOpenedFile}
								onClickMobileConfirm={saveStep}
							/>
						)
					)}
				</div>
			</div>

			<EventConfigurationBottomBar
				slug={slug}
				loading={loading}
				stepValid={true}
				save={saveStep}
				saveAndNext={saveStepAndNext}
				tradKey="save_and_go_run"
				permissionsKey={AccessKeys.EVENT_IDENTITY_UPDATE}
			/>

			{openedFile && openedFile.file && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="max-h-[100vh] w-full max-w-[300px] rounded-md border bg-white sm:max-w-[760px] md:max-w-[1200px]">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].manage_documents}
							</h3>
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={closeFile}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex w-full items-center justify-center text-center">
							{openedFile.file && openedFile.file.includes("data:image") ? (
								<img src={openedFile.file} className="m-auto h-[80vh] w-full" />
							) : (
								<iframe
									src={openedFile.file}
									id="document_file"
									className="h-[80vh] w-full"
									onLoad={resizeIframe}
									seamless={true}
								/>
							)}
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={closeFile}
							>
								{trad[lang].close_documents}
							</button>
						</div>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default SportEventIdentity;
