import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Layout from "../navigation/Layout";

const PageLoader = ({ menu_key }: { menu_key: string }) => {
	return (
		<Layout active_key={menu_key}>
			<div className="relative flex h-screen w-full items-center justify-center">
				<AiOutlineLoading3Quarters size={45} className="ml-2 animate-spin" />
			</div>
		</Layout>
	);
};

export default PageLoader;
