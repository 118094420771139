import { useContext } from "react";
import { To, useNavigate, useParams } from "react-router-dom";
import EditRunnerModal from "../components/ManageRunners/EditRunnerModal";
import HandleDetectionModal from "../components/ManageRunners/HandleDetectionModal";
import ManageRunnerHeader from "../components/ManageRunners/ManageRunnerHeader";
import ManageRunnerMenu from "../components/ManageRunners/ManageRunnerMenu";
import ManageRunnerPagination from "../components/ManageRunners/ManageRunnerPagination";
import ManageRunnerReset from "../components/ManageRunners/ManageRunnerReset";
import ManageRunnerResults from "../components/ManageRunners/ManageRunnerResults";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import { ManageRunnerContext } from "../contexts/ManageRunnerContext";
import trad from "../lang/traduction";
import CreateRunnerModal from "../components/ManageRunners/CreateRunnerModal";

const ManageRunners = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		LiveConfig,
		setOpenedSubscriptionWithoutId,
		openedSubscriptionWithoutId
	} = useContext(ManageRunnerContext);

	const navigate = useNavigate();
	const pathKeys = [
		{
			id: 1,
			path: `/${slug}/live-details`,
			title: "Configuration"
		},
		{
			id: 2,
			path: `/${slug}/manage-detections`,
			title: "Détections"
		}
	];

	const navigate_to = (path: string) => navigate(path as To);

	return (
		<Layout active_key="live" forceReduced={true}>
			<ManageRunnerMenu />

			<div className="mb-[104px] flex h-screen w-full flex-col p-5">
				<div className="relative w-full min-w-[1200px] border border-slate-400">
					{/* HEADER */}
					<ManageRunnerHeader />

					{LiveConfig?.prestation ? (
						<ManageRunnerResults />
					) : (
						<div className="flex p-5 text-center">
							<h2 className="w-full">{trad[lang].noPrestationStarted}</h2>
						</div>
					)}

					{/* FOOTER */}
				</div>
				<div>
					<div className="fixed bottom-0 flex h-[56px] w-full items-center border-t border-t-slate-400 bg-white">
						<div className="ml-4 flex items-center justify-center gap-3">
							{pathKeys.map((item: any) => {
								return (
									<div key={item.id} className="flex gap-3">
										<button
											className="flex h-full items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primarymedium hover:text-white md:uppercase"
											onClick={() => navigate_to(item.path)}
										>
											{item.title}
										</button>
									</div>
								);
							})}
						</div>
						<ManageRunnerPagination />
					</div>
				</div>
			</div>
			<EditRunnerModal />

			<HandleDetectionModal />

			<ManageRunnerReset />

			{openedSubscriptionWithoutId && (
				<CreateRunnerModal
					onClose={() => setOpenedSubscriptionWithoutId(false)}
				/>
			)}

			{/* <BottomBarNavigation back_to={`/${slug}/manage-detections`} /> */}
		</Layout>
	);
};

export default ManageRunners;
