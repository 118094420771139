import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEvent, getEventCommissions } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const Commissions = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const { data, isLoading } = useQuery({
		queryKey: [slug],
		queryFn: () => getEvent(slug),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: CommisionsData = [], isLoading: CommisionsLoading } = useQuery({
		queryKey: ["commissions", slug],
		queryFn: () => getEventCommissions(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	if (isLoading || CommisionsLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout active_key="configuration">
			<div className="relative flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: data.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "commissions", to: "#", active: true }
					]}
				/>

				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
					{data.name}
				</h1>

				<h2 className="text-xl font-bold">Inscription</h2>

				<table className="mt-4 w-full divide-y divide-gray-200 border border-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].title}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].quantity}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].unit_prix_ht}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].unit_prix_ttc}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].total_ht}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].total_ttc}
							</th>
						</tr>
					</thead>
					<tbody>
						{CommisionsData.map((item: any, index: number) => (
							<tr className={index % 2 ? "bg-gray-50" : ""} key={index}>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{item.Designation}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{item.Quantity}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{parseFloat(item.PrixHT).toFixed(2)} €
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{parseFloat(item.PrixTTC).toFixed(2)} €
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{parseFloat(item.TotalHT).toFixed(2)} €
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{parseFloat(item.TotalTTC).toFixed(2)} €
								</td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-gray-50">
						<tr>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								Total
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								{CommisionsData.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.Quantity),
									0
								)}
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"></td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"></td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								{CommisionsData.reduce(
									(acc: number, cur: any) =>
										acc + parseFloat(parseFloat(cur.TotalHT).toFixed(2)),
									0
								).toFixed(2)}{" "}
								€
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								{CommisionsData.reduce(
									(acc: number, cur: any) =>
										acc + parseFloat(parseFloat(cur.TotalTTC).toFixed(2)),
									0
								).toFixed(2)}{" "}
								€
							</td>
						</tr>
					</tfoot>
				</table>
			</div>

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
};

export default Commissions;
