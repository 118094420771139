import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./contexts/AppContext";
import SessionProvider from "./contexts/SessionContext";
import UserProvider from "./contexts/UserContext";
import GeneralRoutes from "./routes/GeneralRoutes.routes";

const Client = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={Client}>
			<AppProvider>
				<UserProvider>
					<SessionProvider />
					<ToastContainer />
					<GeneralRoutes />
				</UserProvider>
			</AppProvider>
		</QueryClientProvider>
	);
}

export default App;
