import { Fragment, useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { NavigationConfig } from "../../config/NavigationConfig";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import { classNames } from "../../utils/Classes";
import AccessGuard, { AccessType } from "../navigation/AccessGuard";

const Navigation = ({
	active_key,
	reduced
}: {
	active_key: string;
	reduced: boolean;
}) => {
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const { slug } = useParams();

	const userIsAdmin = useMemo(() => {
		if (!user) return false;
		return user?.roles?.includes("ROLE_ADMIN");
	}, [user]);

	const NavigationItems = NavigationConfig(lang, userIsAdmin).filter(
		(item) => item.visible
	);

	return (
		<nav className="flex-1 space-y-1" aria-label="Sidebar">
			{NavigationItems.map((item) => (
				<Fragment key={`${item.key}`}>
					{!item.needEvent && (
						<Link to={item.accessible ? item.href : ""}>
							{item.permissionKey ? (
								<AccessGuard
									permissionKey={item.permissionKey}
									type={AccessType.BUTTON}
								>
									<button
										className={classNames(
											reduced ? "justify-center" : "",
											active_key == item.key
												? "border-primary bg-background text-primary"
												: "border-transparent text-gray-600 hover:bg-gray-200 hover:text-gloom",
											item.accessible
												? "pointer-events-auto opacity-100"
												: "pointer-events-none cursor-not-allowed opacity-40",
											"group flex w-full items-center border-l-4 px-3 py-2 text-sm font-medium"
										)}
									>
										<item.icon
											className={classNames(
												reduced ? "" : "mr-3",
												active_key == item.key
													? "text-primary"
													: "text-gray-400 group-hover:text-gloom",
												"h-6 w-6 flex-shrink-0"
											)}
											aria-hidden="true"
										/>
										{!reduced && item.name}
									</button>
								</AccessGuard>
							) : (
								<button
									className={classNames(
										reduced ? "justify-center" : "",
										active_key == item.key
											? "border-primary bg-background text-primary"
											: "border-transparent text-gray-600 hover:bg-gray-200 hover:text-gloom",
										item.accessible
											? "pointer-events-auto opacity-100"
											: "pointer-events-none cursor-not-allowed opacity-40",
										"group flex w-full items-center border-l-4 px-3 py-2 text-sm font-medium"
									)}
								>
									<item.icon
										className={classNames(
											reduced ? "" : "mr-3",
											active_key == item.key
												? "text-primary"
												: "text-gray-400 group-hover:text-gloom",
											"h-6 w-6 flex-shrink-0"
										)}
										aria-hidden="true"
									/>
									{!reduced && item.name}
								</button>
							)}
						</Link>
					)}

					{item.needEvent && slug && (
						<Link to={item.accessible ? `/${slug}${item.href}` : ""}>
							<AccessGuard
								permissionKey={item.permissionKey}
								type={AccessType.BUTTON}
							>
								<button
									className={classNames(
										reduced ? "justify-center" : "",
										active_key == item.key
											? "border-primary bg-background"
											: "bg-gray-200",
										item.accessible
											? "pointer-events-auto opacity-100"
											: "pointer-events-none opacity-40",
										// reduced
										//   ? active_key == item.key
										//     ? "justify-center border-primary bg-background"
										//     : "justify-center bg-gray-200"
										//   : active_key == item.key
										//   ? "border-primary bg-background text-primary"
										//   : "border-transparent text-gray-600 hover:bg-gray-200 hover:text-gloom",
										"group flex w-full items-center border-l-4 px-3 py-2 text-sm font-medium disabled:cursor-not-allowed disabled:opacity-50"
									)}
								>
									<item.icon
										className={classNames(
											reduced ? "" : "ml-9 mr-3",
											active_key == item.key
												? "text-primary"
												: "text-gray-400 group-hover:text-gloom",
											"h-6 w-6 flex-shrink-0"
										)}
										aria-hidden="true"
									/>
									{!reduced && item.name}
								</button>
							</AccessGuard>
						</Link>
					)}
				</Fragment>
			))}
		</nav>
	);
};

export default Navigation;
