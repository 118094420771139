import dayjs from "dayjs";
import "dayjs/locale/fr";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useParams
} from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { updateLiveConfigCalendar } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IFormKeys } from "../../types/LiveForm";
import { formatLikeISO } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");

const LiveConfigurationCalendar = ({
	liveConfig,
	refetchLiveConfig,
	refetchSegments
}: {
	liveConfig: any;
	refetchLiveConfig: any;
	refetchSegments: any;
}) => {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { slug, idcalendrier } = useParams();
	const [saveNeeded, setSaveNeeded] = useState(false);
	const [runForm, setRunForm] = useState({
		type: { value: liveConfig.type, error: false },
		distance: { value: liveConfig.distance, error: false },
		laps: { value: liveConfig.distance, error: false },
		date: {
			value: formatLikeISO(
				dayjs(liveConfig.date)
					.tz(liveConfig.timezone, true)
					.utc(liveConfig.date)
					.toDate()
			),
			error: false
		}
	});
	const [loading, setLoading] = useState(false);

	const debouncedRunForm = useDebounce(runForm, 1000);

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const firstStepValidation: {
		key: IFormKeys;
		dependency?: IFormKeys;
		hasError: (value: any, dependecy: any) => boolean;
	}[] = [
		{ key: "type", hasError: (value: any) => !value },
		{
			key: "distance",
			hasError: (value: any) => value <= 0
		},
		{
			key: "laps",
			dependency: "type",
			hasError: (value: any, dependency: any) =>
				(dependency == 2 || dependency == 4) && !value
		}
	];

	const setFormValue = async function (key: keyof typeof runForm, value: any) {
		setSaveNeeded(true);
		setRunForm((old) => ({ ...old, [key]: { value, error: false } }));
		unsavedChangesRef.current = true;
	};

	useEffect(() => {
		ValidateLiveConfigCalendar();
	}, [debouncedRunForm]);

	const setFormError = (key: keyof typeof runForm, value: boolean) =>
		setRunForm((old) => {
			return { ...old, [key]: { ...old[key], error: value } };
		});

	const ValidateLiveConfigCalendar = async () => {
		try {
			if (!slug || !idcalendrier) return;
			let error = false;

			for (const item of firstStepValidation) {
				const hasError = item.hasError(
					runForm[item.key].value,
					item.dependency ? runForm[item.dependency].value : undefined
				);

				if (hasError) {
					error = true;
					if (runForm[item.key].error !== true) setFormError(item.key, true);
				}
			}

			if (error) {
				throw new Error("Données du formulaire invalides");
			}

			setLoading(true);

			try {
				if (saveNeeded)
					await updateLiveConfigCalendar(slug, idcalendrier, {
						type: runForm.type.value,
						distance: runForm.distance.value,
						laps: runForm.laps.value,
						date: dayjs(runForm.date.value)
							.tz(liveConfig.timezone, true)
							.toISOString()
					});
			} catch (error) {
				console.error(error);
				Toast.error(trad[lang].saveConfigRunError);
			}

			if (saveNeeded) Toast.success(trad[lang].saveConfigRunSuccess);
			unsavedChangesRef.current = false;
			setSaveNeeded(false);

			await refetchLiveConfig();
			await refetchSegments();
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValue("date", e.target.value);
	};

	return (
		<div className="relative w-full">
			<div
				className={`grid w-full grid-cols-2 gap-5 border-b-2 border-gray-200 p-5 transition-opacity ${
					!loading ? "opacity-100" : "opacity-50"
				}`}
			>
				<div className="col-span-1 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].liveTypeOfRun}</p>

					<select
						value={runForm.type.value}
						onChange={(e) => setFormValue("type", parseInt(e.target.value))}
						className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
					>
						<option value={0}>{trad[lang].chooseCalendarRunType}</option>
						<option value={1}>{trad[lang].chooseCalendarRunTypeSimple}</option>
						<option value={2}>{trad[lang].chooseCalendarRunTypeLaps}</option>
						<option value={3}>
							{trad[lang].chooseCalendarRunTypeSimpleVirtual}
						</option>
						<option value={4}>
							{trad[lang].chooseCalendarRunTypeLapsVirtual}
						</option>
						<option value={5}>
							{trad[lang].chooseCalendarRunTypeTriathlon}
						</option>
					</select>

					{runForm.type.error && (
						<p className="rounded-md bg-red-50 p-3 text-red-600">
							{trad[lang].runFormTypeError}
						</p>
					)}
				</div>

				<div className="col-span-1 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].calendarRunDistance}</p>

					{liveConfig?.type == 5 ? (
						<select
							value={runForm.distance.value}
							onChange={(e) =>
								setFormValue("distance", parseInt(e.target.value))
							}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						>
							<option value={0}>{trad[lang].runFormDistanceType}</option>
							<option value={12900}>XS</option>
							<option value={25750}>S</option>
							<option value={51500}>M</option>
							<option value={113000}>L</option>
							<option value={154000}>XL</option>
							<option value={225995}>XXL</option>
						</select>
					) : (
						<input
							type="number"
							step={1}
							min={0}
							value={runForm.distance.value}
							onChange={(e) =>
								setFormValue("distance", parseInt(e.target.value))
							}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						/>
					)}

					{runForm.distance.error && (
						<p className="rounded-md bg-red-50 p-3 text-red-600">
							{trad[lang].runFormDistanceError}
						</p>
					)}
				</div>
				{(runForm.type.value == 2 || runForm.type.value == 4) && (
					<div className="col-span-1 flex flex-col gap-2">
						<p className="font-bold">{trad[lang].calendarRunLaps}</p>

						<input
							type="number"
							step={1}
							min={0}
							value={runForm.laps.value}
							onChange={(e) => setFormValue("laps", parseInt(e.target.value))}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						/>

						{runForm.laps.error && (
							<p className="rounded-md bg-red-50 p-3 text-red-600">
								{trad[lang].runFormLapsError}
							</p>
						)}
					</div>
				)}
				<div className="col-span-1 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].liveDateOfRun}</p>
					<input
						type="datetime-local"
						step={0.001}
						value={runForm.date.value}
						onChange={handleDateChange}
						className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
					/>
				</div>
			</div>
		</div>
	);
};

export default LiveConfigurationCalendar;
