import { useContext, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-tailwindcss-select";
import {
	addExistingRibApi,
	getEventIdentityConfig,
	getRibs,
	getUserRibs
} from "../../../api/event";
import BottomBarNavigation from "../../../components/common/BottomBarNavigation";
import BreadCrumb from "../../../components/common/BreadCrumb";
import PageLoader from "../../../components/common/PageLoader";
import DeleteRibModal from "../../../components/event_configuration/EventDeleteRibModal";
import EventRib from "../../../components/event_configuration/EventRib";
import EventRibFormModal from "../../../components/event_configuration/EventRibFormModal";
import AccessGuard, {
	AccessType
} from "../../../components/navigation/AccessGuard";
import Layout from "../../../components/navigation/Layout";
import { AppContext } from "../../../contexts/AppContext";
import { UserContext } from "../../../contexts/UserContext";
import trad from "../../../lang/traduction";
import Toast from "../../../utils/Toasts";

const BASE_RIB = {
	designation: "",
	ide_type_rib: 0,
	nomBanque: "",
	iban: "",
	bic: "",
	inCharge: true,
	personne: {
		lastname: "",
		firstname: "",
		birthdate: "",
		sex: 1,
		email: "",
		adresse1: "",
		city: "",
		postal_code: "",
		country: "",
		phone: ""
	}
};

const SportEventRib = () => {
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const { slug } = useParams();
	const navigate = useNavigate();
	const [openedRib, setOpenedRib] = useState(null);
	const [selectedRib, setSelectedRib] = useState<any>(null);
	const [confirmDeleteRib, setConfirmDeleteRib] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	const {
		data: RibsInfos,
		isLoading: RibsInfosLoading,
		refetch: RefetchRibs
	} = useQuery({
		queryKey: ["contacts_info", slug],
		queryFn: () => getRibs(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: EventInfos, isLoading: EventInfosLoading } = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data: Ribs, isLoading: RibsLoading } = useQuery({
		queryKey: ["user_rib", user],
		queryFn: getUserRibs,
		refetchOnWindowFocus: false
	});

	const openRibModal = (values: any) => setOpenedRib(values);
	const closeRibModal = () => setOpenedRib(null);

	const addExistingRib = async () => {
		try {
			if (!slug) return;
			setLoading(true);

			await addExistingRibApi(slug, selectedRib.value);

			await RefetchRibs();

			Toast.success(trad[lang].toastSuccessAddingRib);
		} catch (error) {
			Toast.error(trad[lang].toastErrorAddingRib);
		} finally {
			setLoading(false);
		}
	};

	if (RibsInfosLoading || EventInfosLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout active_key="configuration">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: EventInfos.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "event_rib", to: "#", active: true }
					]}
				/>

				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
					{trad[lang].sport_event_ribs}
				</h1>

				<div className="mb-5 flex w-full flex-col items-center justify-center gap-5 lg:flex-row">
					<Select
						placeholder={trad[lang].placeholder_select_rib}
						searchInputPlaceholder={trad[lang].placeholder_select_rib}
						noOptionsMessage={trad[lang].select_no_options}
						value={selectedRib}
						onChange={(val: any) => setSelectedRib(val)}
						options={Ribs?.map((item: any) => ({
							value: item.id,
							label: `${item.designation} - ${item.nomBanque}`
						}))}
						primaryColor="sky"
						isSearchable
						isClearable
						loading={RibsLoading}
					/>

					<button
						className="flex h-10 w-full flex-row items-center justify-center rounded-md bg-blue-600 text-white lg:w-1/5"
						onClick={addExistingRib}
					>
						Ajouter ce rib
					</button>
				</div>

				<div className="flex w-full flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
					{RibsInfos.map((item: any) => (
						<div className="group relative" key={item.index}>
							<EventRib rib={item} />

							<div className="absolute -top-2 -right-2 flex flex-row gap-2 md:hidden md:group-hover:flex">
								<AccessGuard
									permissionKey="event.rib.edit_contact"
									type={AccessType.BUTTON}
								>
									<button
										className="rounded-md bg-primary p-2 shadow-sm hover:bg-primarymedium disabled:bg-gray-300"
										onClick={() => openRibModal(item)}
									>
										<AiOutlineEdit size={18} color="#ffffff" />
									</button>
								</AccessGuard>
								<AccessGuard
									permissionKey="event.rib.delete_contact"
									type={AccessType.BUTTON}
								>
									<button
										className="rounded-md bg-red-600 p-2 shadow-sm hover:bg-red-700 disabled:bg-gray-300"
										onClick={() =>
											setConfirmDeleteRib({
												id: item.id,
												title: item.designation
											})
										}
									>
										<AiOutlineDelete size={18} color="#ffffff" />
									</button>
								</AccessGuard>
							</div>
						</div>
					))}
					<button
						className="flex min-h-[162px] cursor-pointer flex-col items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
						onClick={() => openRibModal(BASE_RIB)}
					>
						<AiOutlinePlus size={36} color="#ffffff" />
						<span className="text-white">{trad[lang].event_add_rib}</span>
					</button>
				</div>

				{openedRib != null && (
					<EventRibFormModal
						closeModal={closeRibModal}
						rib={openedRib}
						refetch={RefetchRibs}
					/>
				)}

				{confirmDeleteRib != null && (
					<DeleteRibModal
						setConfirmDeleteRib={setConfirmDeleteRib}
						confirmDeleteRib={confirmDeleteRib}
						refetch={RefetchRibs}
					/>
				)}
			</div>

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
};

export default SportEventRib;
