import { useContext } from "react";
import { useQuery } from "react-query";
import { getEventTypes } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import TypeCard from "../components/event_configuration/TypeCard";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const EventConfiguration = () => {
	const { lang } = useContext(AppContext);

	const { data } = useQuery({
		queryKey: ["event_types"],
		queryFn: getEventTypes,
		refetchOnWindowFocus: false
	});

	return (
		<Layout active_key="configuration">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event_configuration",
							to: "#",
							active: true
						},
						{ key: "event_identity", to: "#", active: false },
						{ key: "event_subscription", to: "#", active: false }
					]}
				/>

				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
					{trad[lang].choose_type}
				</h1>

				<div className="flex w-full flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
					{data?.map((item: any) => (
						<TypeCard type={item} key={item.id} />
					))}
				</div>
			</div>

			<BottomBarNavigation back_to="/" />
		</Layout>
	);
};

export default EventConfiguration;
