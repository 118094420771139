import { useContext } from "react";
import Logo from "../assets/logo_solo.png";
import SignupForm from "../components/signup/SignupForm";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

export const Signup = () => {
	const { lang } = useContext(AppContext);

	return (
		<div className="flex h-screen flex-col items-center overflow-y-auto">
			{/* Logo */}
			<div className="flex flex-row items-center gap-1">
				<img src={Logo} alt="" />
				<h1 className="text-2xl font-bold text-primary">Chronometrage.com</h1>
			</div>

			{/* Title */}
			<h2 className="text-center font-bold">{trad[lang].signup_title}</h2>

			{/* Form */}
			<SignupForm />
		</div>
	);
};

export default Signup;
