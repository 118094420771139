import React, { createContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getLiveConfig } from "../api/live";

type LiveContextType = {
	liveConfig: any;
	isLoadingLiveConfig: boolean;
	isFFAEvent: boolean;
	refetchLiveConfig: () => void;
};

export const LiveContext = createContext<LiveContextType>(
	{} as LiveContextType
);

const LiveProvider = ({ children }: { children: React.ReactNode }) => {
	const { slug } = useParams();

	const {
		data: liveConfig,
		isLoading: isLoadingLiveConfig,
		refetch: refetchLiveConfig
	} = useQuery({
		queryKey: ["liveConfig", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const [isFFAEvent, setIsFFAEvent] = React.useState(false);

	useEffect(() => {
		if (liveConfig) {
			try {
				const observation = JSON.parse(liveConfig.observation || "{}");
				// setIsFFAEvent(observation?.CMPCOD && observation?.CMPCOD !== "287568" && observation?.CMPCOD !== "284198");
				setIsFFAEvent(observation?.CMPCOD?.length > 0);
			} catch (e: unknown) {
				console.warn("Error parsing observation", e);
			}
		}
	}, [liveConfig]);

	return (
		<LiveContext.Provider
			value={{
				liveConfig,
				isLoadingLiveConfig,
				refetchLiveConfig,
				isFFAEvent,
			}}
		>
			{children}
		</LiveContext.Provider>
	);
};

export default LiveProvider;
