import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import AccessGuard, { AccessType } from "../navigation/AccessGuard";

const EventConfigurationBottomBar = ({
	slug,
	loading,
	stepValid,
	goBack,
	save,
	saveAndNext,
	tradKey,
	permissionsKey
}: {
	slug: string | undefined;
	stepValid?: boolean;
	loading?: boolean;
	goBack?: any;
	save?: any;
	saveAndNext?: any;
	tradKey?: string;
	permissionsKey: string;
}) => {
	const { lang } = useContext(AppContext);
	const navigate = useNavigate();
	const { state } = useLocation();

	const handleGoBack = () => navigate("/event-configuration");

	const handleEditGoBack = () => navigate(`/${slug}/event-details`);

	return (
		<div className="static flex h-16 w-full flex-row justify-between border-t bg-white p-3">
			<div className="flex flex-row gap-3">
				<button
					className="flex h-full flex-row items-center gap-1 rounded-md border border-red-500 bg-white py-3 px-3 text-xs text-red-500 duration-150 hover:bg-red-500 hover:text-white md:uppercase"
					onClick={
						slug && !state?.newEvent ? goBack || handleEditGoBack : handleGoBack
					}
					disabled={loading}
				>
					{trad[lang].go_back}
				</button>
			</div>

			<div className="flex flex-row gap-3">
				{save != undefined && (
					<AccessGuard type={AccessType.BUTTON} permissionKey={permissionsKey}>
						<button
							className={classNames(
								"flex h-full flex-row items-center gap-1 rounded-md border bg-white py-2 px-3 text-xs duration-150 md:uppercase",
								loading || !stepValid
									? "border-gray-300 text-gray-300"
									: "cursor-pointer border-primary text-primary hover:bg-primary hover:text-white",
								"disabled:border-gray-300 disabled:text-gray-300 disabled:hover:bg-gray-100"
							)}
							onClick={save}
							disabled={loading || !stepValid}
						>
							{trad[lang].save}
						</button>
					</AccessGuard>
				)}

				{state?.newEvent && saveAndNext != undefined && (
					<button
						className={classNames(
							"flex h-full flex-row items-center gap-1 rounded-md border bg-primary py-2 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase",
							loading || !stepValid
								? "border-gray-300 text-gray-300"
								: "cursor-pointer"
						)}
						onClick={saveAndNext}
						disabled={loading || !stepValid}
					>
						{/* @ts-ignore */}
						{tradKey ? trad[lang][tradKey] : trad[lang].save}
					</button>
				)}
			</div>
		</div>
	);
};

export default EventConfigurationBottomBar;
