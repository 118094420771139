import { API, APIDagLive } from "./APIClient";

export const getDetectionsResume = async (slug: string) => {
	try {
		const { data } = await API.get(`/live/getDetectionsResume/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getAllDetections]", error);
	}
};

export const getAllDetections = async (
	slug: string,
	search: string,
	nmbPerPage: string,
	page: number,
	detector: number | null,
	races: string,
	all: boolean
) => {
	try {
		const { data } = await API.get(
			`/live/getAllDetections/${slug}?search=${search}&races=${races}&nmbPerPage=${nmbPerPage}&page=${page}&all=${
				all ? "true" : ""
			}${detector ? `&detector=${detector}` : ""}`
		);

		return data;
	} catch (error) {
		console.warn("[getAllDetections]", error);
		throw error;
	}
};

export const getRunnerDetections = async (idCoureur: number) => {
	try {
		const { data } = await API.get(`/live/getDetectionByRunner/${idCoureur}`);

		return data;
	} catch (error) {
		console.warn("[getRunnerDetections]", error);
		throw error;
	}
};

export const deleteAllDetection = async (slug: string, races: any) => {
	try {
		const { data } = await API.post(`/live/deleteAllDetections/${slug}`, {
			races
		});

		return data;
	} catch (error) {
		console.warn("[deleteAllDetection]", error);
		throw error;
	}
};

export const insertDetection = async (
	line: string,
	trame: string,
	credential: string
) => {
	try {
		const { data } = await APIDagLive.get(
			`/insertdetection?linenumber=${line}&type=2&trame=${trame}&credential=${credential}`
		);

		return data;
	} catch (error) {
		console.warn("[insertDetection]", error);
		throw error;
	}
};

export const createRunnerDetection = async (body: any) => {
	try {
		const { data } = await API.post("/live/createDetection", body);

		return data;
	} catch (error) {
		console.warn("[CreateRunnerDetection]", error);
		throw error;
	}
};

export const updateRunnerDetection = async (body: any) => {
	try {
		const { data } = await API.put("/live/updateDetection", body);

		return data;
	} catch (error) {
		console.warn("[CreateRunnerDetection]", error);
		throw error;
	}
};

export const deleteRunnerDetection = async (idPassage: number) => {
	try {
		const { data } = await API.delete(`/live/deleteDetection/${idPassage}`);

		return data;
	} catch (error) {
		console.warn("[CreateRunnerDetection]", error);
		throw error;
	}
};
