import {
	Dispatch,
	SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import {
	getLiveConfig,
	getLiveConfigPoints,
	getRunnersRanking
} from "../api/live";
import { IRunnerResponse } from "../types/ILive";

interface IManageRunnerContext {
	// Gestion du menu
	selectedRunnerIndex: number;
	setSelectedRunnerIndex: Dispatch<SetStateAction<number>>;
	source: [number, number];
	order: { column: string; order: number };
	search: string;
	debouncedSearch: string;

	// Gestion du tableau
	nmbPerPage: number;
	page: number;
	run: number;
	pagination: number[];
	maxPage: number;

	// Gestion des modifications
	openedSubscription: number;
	setOpenedSubscription: Dispatch<SetStateAction<number>>;
	openedDetections: number;
	setOpenedDetections: Dispatch<SetStateAction<number>>;
	openedReset: number;
	setOpenedReset: Dispatch<SetStateAction<number>>;
	openedSubscriptionWithoutId: boolean;
	setOpenedSubscriptionWithoutId: Dispatch<SetStateAction<boolean>>;
	statutCourse: string;
	setStatutCourse: Dispatch<SetStateAction<string>>;
	admin: string;
	setAdmin: Dispatch<SetStateAction<string>>;
	finishedRace: string;
	setFinishedRace: Dispatch<SetStateAction<string>>;

	LiveConfig: any;
	LiveConfigLoading: boolean;
	Points: any;
	LiveData: IRunnerResponse;
	LiveDataLoading: boolean;
	LiveDataError: any;
	LiveDataRefetch: any;
	selectedRunner: any;
	handleChangeNmbPerPage: (nmbPerPage: number) => void;
	handleChangePage: (page: number) => void;
	handleChangeRun: (run: number) => void;
	handleChangeSearch: (search: string) => void;
	handleChangeOrder: (key: string) => void;
	handleChangeSource: (index: number, source: number) => void;
	handleChangeStatus: (statutCourse: string) => void;
}

export const ManageRunnerContext = createContext<IManageRunnerContext>(
	{} as IManageRunnerContext
);

const ManageRunnerProvider = ({ children }: { children: React.ReactNode }) => {
	const { slug } = useParams();

	// Gestion du menu
	const [selectedRunnerIndex, setSelectedRunnerIndex] = useState(0);
	const [source, setSource] = useState<[number, number]>([0, 0]);
	const [run, setRun] = useState(0);
	const [statutCourse, setStatutCourse] = useState("");
	const [admin, setAdmin] = useState("1");
	const [finishedRace, setFinishedRace] = useState("false");
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);

	// Gestion du tableau
	const [order, setOrder] = useState({ column: "classement", order: 1 });
	const [nmbPerPage, setNmbPerPage] = useState(50);
	const [page, setPage] = useState(1);

	// Gestion des modifications
	const [openedSubscription, setOpenedSubscription] = useState(0);
	const [openedDetections, setOpenedDetections] = useState(0);
	const [openedReset, setOpenedReset] = useState(0);
	const [openedSubscriptionWithoutId, setOpenedSubscriptionWithoutId] =
		useState(false);

	// Récupération de la config Live
	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	// Récupération des Points de detection
	const { data: Points = [] } = useQuery({
		queryKey: ["points", slug, run, statutCourse, finishedRace],
		queryFn: () => getLiveConfigPoints(slug as string, run.toString()),
		enabled: !!slug && !!run
	});

	// Automatiquent set comme source finale l'arrivée s'il n'y a pas déjà une source enregistrée en local storage
	useEffect(() => {
		if (Points && run && source && source[1] === 0) {
			const arrival = Points.find((item: any) => item.type === 3);
			if (arrival && arrival.id) handleChangeSource(1, arrival.id);
		}
	}, [Points, run, source]);

	const {
		data: LiveData = { count: 0, result: [] },
		isLoading: LiveDataLoading,
		error: LiveDataError,
		refetch: LiveDataRefetch
	} = useQuery({
		queryKey: [
			"runners_ranking",
			slug,
			nmbPerPage,
			page,
			run,
			debouncedSearch,
			order,
			source,
			"1",
			statutCourse,
			finishedRace
		],
		queryFn: () =>
			getRunnersRanking(
				slug as string,
				nmbPerPage,
				page,
				run,
				debouncedSearch,
				order.column,
				order.order,
				source,
				false,
				"1",
				statutCourse,
				finishedRace
			),
		refetchOnWindowFocus: false,
		refetchInterval: 5000,
		refetchOnReconnect: false,
		refetchOnMount: true,
		enabled: !!slug
	});

	const selectedRunner =
		LiveData.result.length >= selectedRunnerIndex
			? LiveData.result[selectedRunnerIndex]
			: {};

	// Gestion de la pagination
	const pagination = useMemo(() => {
		if (!LiveData.count) return [];

		const pages = [page - 2, page - 1, page, page + 1, page + 2];

		return Array.from(
			new Set(
				pages.filter(
					(item) => item > 0 && item <= Math.ceil(LiveData.count / nmbPerPage)
				)
			)
		).sort((a, b) => a - b);
	}, [LiveData, page]);

	const maxPage = Math.ceil(LiveData.count / nmbPerPage);

	// Gestion du clavier
	const handleKey = (e: KeyboardEvent) => {
		if (
			!["ArrowDown", "ArrowUp", "Enter", "ArrowRight", "ArrowLeft"].includes(
				e.key
			)
		) {
			return;
		}

		e.preventDefault();

		if (e.key === "ArrowRight") {
			setPage((old) =>
				Math.min(Math.ceil(LiveData.count / nmbPerPage), old + 1)
			);
		} else if (e.key === "ArrowLeft") {
			setPage((old) => Math.max(1, old - 1));
		} else if (e.key === "ArrowDown") {
			setSelectedRunnerIndex((old) => {
				if (old + 1 > LiveData.result.length) {
					setPage((old) =>
						Math.min(Math.ceil(LiveData.count / nmbPerPage), old + 1)
					);

					return 0;
				} else {
					return old + 1;
				}
			});
		} else if (e.key === "ArrowUp") {
			setSelectedRunnerIndex((old) => {
				if (old - 1 < 1) {
					setPage((old) => Math.max(1, old - 1));

					return 0;
				} else {
					return old - 1;
				}
			});
		}
	};

	useEffect(() => {
		if (
			!openedSubscription &&
			!openedDetections &&
			!openedReset &&
			!openedSubscriptionWithoutId
		) {
			window.addEventListener("keydown", handleKey);
		}

		return () => {
			window.removeEventListener("keydown", handleKey);
		};
	}, [
		LiveData,
		openedSubscription,
		openedDetections,
		openedReset,
		openedSubscriptionWithoutId
	]);

	useEffect(() => {
		setSelectedRunnerIndex(0);
	}, [LiveData]);

	const handleChangeNmbPerPage = (nmb: number) => {
		setPage(1);
		setNmbPerPage(nmb);
		localStorage.setItem("manageRunnersNmbPerPage", nmb.toString());
	};

	const handleChangePage = (nmb: number) => {
		setPage(nmb);
		localStorage.setItem("manageRunnersPage", nmb.toString());
	};

	const handleChangeRun = (run: number) => {
		setPage(1);
		setRun(run);
		setSource([0, 0]);
		localStorage.setItem("manageRunnersRun", run.toString());
		localStorage.setItem("manageRunnersPage", "1");
		localStorage.setItem("manageRunnersSource", JSON.stringify([0, 0]));
	};

	const handleChangeSearch = (search: string) => {
		setSearch(search);
		localStorage.setItem("manageRunnersSearch", search);
	};

	const handleChangeStatus = (statutCourse: string) => {
		setStatutCourse(statutCourse);
		localStorage.setItem("manageRegistrantsFilter", statutCourse);
	};

	const handleChangeOrder = (key: string) => {
		setOrder((old) => {
			if (old.column == key) {
				return {
					column: key,
					order: old.order * -1
				};
			}

			return {
				column: key,
				order: 1
			};
		});
		localStorage.setItem("manageRunnersOrder", JSON.stringify(order));
	};

	const handleChangeSource = (index: number, source: number) => {
		setPage(1);
		setSource((old) => {
			const new_source = [...old];

			new_source[index] = source || 0;

			localStorage.setItem("manageRunnersSource", JSON.stringify(new_source));
			return new_source as [number, number];
		});
	};

	useEffect(() => {
		const localNmbPerPage = localStorage.getItem("manageRunnersNmbPerPage");
		const localPage = localStorage.getItem("manageRunnersPage");
		const localRun = localStorage.getItem("manageRunnersRun");
		const localSearch = localStorage.getItem("manageRunnersSearch");
		const localOrder = localStorage.getItem("manageRunnersOrder");
		const localSource = localStorage.getItem("manageRunnersSource");
		const localRegistrantsFilter = localStorage.getItem(
			"manageRegistrantsFilter"
		);

		if (localNmbPerPage) {
			setNmbPerPage(parseInt(localNmbPerPage));
		}
		if (localPage) {
			setPage(parseInt(localPage));
		}
		if (localRun) {
			setRun(parseInt(localRun));
		}
		if (localSearch) {
			setSearch(localSearch);
		}
		if (localOrder) {
			setOrder(JSON.parse(localOrder));
		}
		if (localSource && localSource != "undefined") {
			setSource(JSON.parse(localSource));
		}
		if (localRegistrantsFilter) {
			setStatutCourse(localRegistrantsFilter);
		}
	}, []);

	useEffect(() => {
		if (!slug) {
			return;
		}

		const localSlug = localStorage.getItem("manageRunnersSlug");

		if (localSlug != slug) {
			localStorage.setItem("manageRunnersSlug", slug);

			localStorage.removeItem("manageRunnersNmbPerPage");
			localStorage.removeItem("manageRunnersPage");
			localStorage.removeItem("manageRunnersRun");
			localStorage.removeItem("manageRunnersSearch");
			localStorage.removeItem("manageRunnersOrder");
			localStorage.removeItem("manageRunnersSource");
			localStorage.removeItem("manageRegistrantsFilter");

			setNmbPerPage(50);
			setPage(1);
			setRun(0);
			setSearch("");
			setOrder({ column: "classement", order: 1 });
			setSource([0, 0]);
			setStatutCourse("");
		}
	}, [slug]);

	useEffect(() => {
		setPage(1);
	}, [debouncedSearch, statutCourse, finishedRace]);

	return (
		<ManageRunnerContext.Provider
			value={{
				selectedRunnerIndex,
				setSelectedRunnerIndex,
				finishedRace,
				setFinishedRace,
				source,
				order,
				LiveConfig,
				LiveConfigLoading,
				nmbPerPage,
				page,
				Points,
				run,
				LiveData,
				LiveDataLoading,
				LiveDataError,
				LiveDataRefetch,
				search,
				debouncedSearch,
				pagination,
				maxPage,
				selectedRunner,
				statutCourse,
				setStatutCourse,
				admin,
				setAdmin,
				openedSubscription,
				setOpenedSubscription,
				openedDetections,
				setOpenedDetections,
				openedReset,
				setOpenedSubscriptionWithoutId,
				openedSubscriptionWithoutId,
				setOpenedReset,
				handleChangeNmbPerPage,
				handleChangePage,
				handleChangeRun,
				handleChangeSearch,
				handleChangeOrder,
				handleChangeSource,
				handleChangeStatus
			}}
		>
			{children}
		</ManageRunnerContext.Provider>
	);
};

export default ManageRunnerProvider;
