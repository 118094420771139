import { useSearchParams } from "react-router-dom";

export const useQueryParams = (initialValues: { [key: string]: string }) => {
	const [searchQuery, setSearchQuery] = useSearchParams(initialValues);

	const getQueryParam = (key: string) => {
		return searchQuery.get(key) || initialValues[key] || "";
	};

	const setQueryParam = (key: string, value: string) => {
		const initial_keys = Object.keys(initialValues);

		const new_param: { [key: string]: string } = {};

		initial_keys.forEach((element) => {
			new_param[element] = searchQuery.get(element) || "";
		});

		setSearchQuery({ ...new_param, [key]: value });
	};

	return { searchQuery, getQueryParam, setQueryParam };
};
