import { Tooltip, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { addSub, createTeam, getTeamsAndClubs } from "../../api/event";
import { checkDuplicatesBib, getLiveConfig, getRunner } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";
import { API } from "../../api/APIClient";
interface CreateRunnerModalProps {
	onClose: () => void;
}

const CreateRunnerModal = ({
	onClose
}: CreateRunnerModalProps): JSX.Element => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const { LiveDataRefetch } = useContext(ManageRunnerContext);
	const [loading, setLoading] = useState(false);
	const [isUsedMail, setIsUsedMail] = useState(false);
	const [isValidMail, setIsValidMail] = useState(true);
	const [isUsedBib, setIsUsedBib] = useState(false);
	const [errors, setErrors] = useState({
		lastName: false,
		firstName: false,
		birthdate: false,
		gender: false
	});
	const [editForm, setEditForm] = useState({
		bib: "0",
		lastName: "",
		firstName: "",
		gender: "H",
		email: "",
		birthdate: "",
		idCalendrier: "",
		idEquipe: 0,
		handicap: "00:00:00",
		nomCategorie: "",
		teamName: "",
		team: 0
	});

	const { data: LiveConfig } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const handleForm = (key: string, value: any) =>
		setEditForm((old) => ({ ...old, [key]: value }));

	const {
		data: Teams = { teams: [] },
		isLoading: isLoadingTeams,
		refetch: refetchTeams
	} = useQuery({
		queryKey: ["teamClub", slug],
		queryFn: () => getTeamsAndClubs(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const save = async () => {
		const requiredFields = ["lastName", "firstName", "birthdate", "gender"];
		const newErrors = {
			lastName: !editForm.lastName,
			firstName: !editForm.firstName,
			birthdate: !editForm.birthdate,
			gender: !editForm.gender
		};
		setErrors(newErrors);

		if (
			requiredFields.some((field) => !editForm[field as keyof typeof editForm])
		) {
			Toast.error("Veuillez remplir tous les champs obligatoires");
			return;
		}
		const editFormToSend = {
			birthdate: editForm.birthdate,
			firstName: editForm.firstName.toString(),
			lastName: editForm.lastName,
			isEmailUsed: isUsedMail,
			gender: editForm.gender,
			idEquipe: editForm.idEquipe,
			bib: parseInt(editForm.bib)
		};
		try {
			let data = {
				teamName: editForm.teamName,
				idEquipe: editForm.idEquipe,
				raceId: editForm.idCalendrier,
				list: [editFormToSend]
			};

			setLoading(true);
			if (!isUsedBib && !isUsedMail && isValidMail) {
				await addSub(slug as string, data);
				await LiveDataRefetch();
				Toast.success("Coureur inscrit avec succès");
				onClose();
			} else {
				Toast.error("Veuillez vérifier que les champs sont correctes");
			}
		} catch (error) {
			Toast.error("Erreur lors de l'inscription du coureur");
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const isEmailValid = (email: string) => {
		let result: boolean = false;

		if (email.length === 0) result = true;
		else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
			result = true;

		// Save result in form
		setIsValidMail(result);

		return result;
	};

	const checkDuplicateMail = async () => {
		isEmailValid(editForm.email);
		const result = await API.get(
			`/user/checkDuplicateEmail/${editForm.email}/${editForm.lastName}/${editForm.firstName}`
		);
		setIsUsedMail(result.data);
	};

	const checkDuplicateBib = async () => {
		const duplicatedBib = await checkDuplicatesBib(
			slug as string,
			0,
			parseInt(editForm.bib)
		);
		if (duplicatedBib.length === 0) {
			setIsUsedBib(false);
		} else {
			setIsUsedBib(true);
		}
	};
	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-3/5 lg:w-2/5 ">
				{/* Modal Header */}
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold">{"Inscrire un coureur"}</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={onClose}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				<div className="max-h-[70vh] overflow-y-auto px-4 pt-3 pb-3 md:flex md:flex-row">
					<div className="w-full pb-4 md:w-1/2 md:pr-4 md:pb-0">
						<h2 className="mb-4 flex items-center gap-2 font-bold">
							Identité
							<Tooltip
								className="text-md z-50 text-center"
								content={
									<Typography>{trad[lang].editRunnerTooltip}</Typography>
								}
							>
								<p>
									<AiOutlineInfoCircle />
								</p>
							</Tooltip>
						</h2>

						<div
							className={`mb-2 flex flex-col gap-1 ${
								errors.lastName ? "text-red-500" : "text-black"
							}`}
						>
							<p>
								{trad[lang].name} {errors.lastName ? " (Champs requis)" : ""}
							</p>

							<input
								type="text"
								value={editForm.lastName}
								onChange={(e) => handleForm("lastName", e.target.value)}
								className={`${
									errors.lastName ? "border-red-500" : "border-gray-300"
								} borde h-12 rounded-md pl-2 focus:outline-none`}
							/>
						</div>

						<div
							className={`mb-2 flex flex-col gap-1 ${
								errors.firstName ? "text-red-500" : "text-black"
							}`}
						>
							<p>
								{trad[lang].firstname}{" "}
								{errors.firstName ? " (Champs requis)" : ""}
							</p>

							<input
								type="text"
								value={editForm.firstName}
								onChange={(e) => handleForm("firstName", e.target.value)}
								className={`${
									errors.firstName ? "border-red-500" : "border-gray-300"
								} borde h-12 rounded-md pl-2 focus:outline-none`}
							/>
						</div>

						<div
							className={`mb-2 flex flex-col gap-1 ${
								errors.gender ? "text-red-500" : "text-black"
							}`}
						>
							<p>
								{trad[lang].sexeChoice}
								{errors.gender ? "(Champs requis)" : ""}
							</p>

							<select
								value={editForm.gender}
								onChange={(e) => handleForm("gender", e.target.value)}
								className={`${
									errors.gender ? "border-red-500" : "border-gray-300"
								} borde h-12 rounded-md pl-2 focus:outline-none`}
							>
								<option value={0}>
									{trad[lang].placeholder_select_gender}
								</option>
								<option value={"H"}>{trad[lang].man}</option>
								<option value={"F"}>{trad[lang].woman}</option>
								<option value={"X"}>{trad[lang].other_gender}</option>
							</select>
						</div>

						<div className={"mb-2 flex flex-col gap-1"}>
							<p
								className={`${
									isUsedMail || !isValidMail ? "text-red-600" : "text-black"
								}`}
							>
								{trad[lang].email}
								{isUsedMail ? " (Déjà utilisé)" : ""}
								{!isValidMail ? " (Mauvais format)" : ""}
							</p>

							<input
								type="text"
								value={editForm.email}
								onChange={(e) => handleForm("email", e.target.value)}
								className={`h-12 rounded-md border ${
									isUsedMail
										? "border-red-600 text-red-600"
										: "text black border-gray-300"
								} pl-2 focus:outline-none`}
								onBlur={() => checkDuplicateMail()}
							/>
						</div>

						<div className={"mb-2 flex flex-col gap-1 "}>
							<p
								className={`${
									errors.birthdate ? "text-red-500" : "text-black"
								}`}
							>
								{trad[lang].birthDate}
								{errors.birthdate ? " (Champs requis)" : ""}
							</p>

							<input
								type="date"
								value={editForm.birthdate}
								onChange={(e) => handleForm("birthdate", e.target.value)}
								className={`${
									errors.birthdate ? "border-red-500" : "border-gray-300"
								} borde h-12 rounded-md pl-2 focus:outline-none`}
							/>
						</div>
					</div>

					<div className="w-full md:w-1/2">
						<h2 className="mb-4 flex items-center gap-2 font-bold">
							Course
							<Tooltip
								className="text-md z-50 text-center"
								content={
									<Typography>{trad[lang].editRunnerRunTooltip}</Typography>
								}
							>
								<p>
									<AiOutlineInfoCircle />
								</p>
							</Tooltip>
						</h2>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].run}</p>

							<select
								value={editForm.idCalendrier}
								onChange={(e) =>
									handleForm("idCalendrier", parseInt(e.target.value))
								}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							>
								<option value={0}>{trad[lang].selectRunPlaceholder}</option>
								{LiveConfig?.calendrier_child?.map((item: any) => (
									<option value={item.id} key={item.id}>
										{item.nom}
									</option>
								))}
							</select>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p className={`${isUsedBib ? "text-red-600" : "text-black"}`}>
								{trad[lang].bib} {isUsedBib ? "(Déjà utilisé)" : ""}
							</p>

							<input
								type="text"
								value={editForm.bib}
								min="1"
								onChange={(e) => handleForm("bib", e.target.value)}
								className={`h-12 rounded-md border ${
									isUsedBib
										? "border-red-600 text-red-600"
										: "border-gray-300 text-black"
								} pl-2 focus:outline-none`}
								onBlur={() => checkDuplicateBib()}
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].team}</p>

							{editForm.idEquipe === -1 ? (
								<div className="flex flex-row gap-2">
									<input
										type="text"
										onChange={(e) => handleForm("teamName", e.target.value)}
										className="h-12 flex-grow rounded-md border border-gray-300 pl-2 focus:outline-none"
									/>

									<button
										className="flex h-12 w-12 items-center justify-center rounded-md bg-red-600 transition-colors hover:bg-red-700"
										onClick={() => handleForm("idEquipe", 0)}
									>
										<AiOutlineClose size={25} color="#fff" />
									</button>
								</div>
							) : (
								<select
									value={editForm.idEquipe}
									onChange={(e) => {
										const value = parseInt(e.target.value);
										if (value == -1) {
											handleForm("idEquipe", -1);
											handleForm("teamName", "");
										} else {
											handleForm("idEquipe", value);
										}
									}}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								>
									<option value={0}>{trad[lang].chooseTeam}</option>
									<option value={-1}>{trad[lang].createTeam}</option>
									{Teams.teams?.map((item: any) => (
										<option value={item.id} key={item.id}>
											{item.nom}
										</option>
									))}
								</select>
							)}
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].handicap}</p>

							<input
								type="time"
								value={editForm.handicap}
								step={1}
								onChange={(e) => handleForm("handicap", e.target.value)}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						type="button"
						className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading}
						onClick={onClose}
					>
						{trad[lang].close}
					</button>
					<button
						type="button"
						className={`rounded-lg border bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-primarymedium focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading}
						onClick={save}
					>
						{trad[lang].save}
					</button>
				</div>
			</div>
		</div>
	);
};

export default CreateRunnerModal;
