export const QUILL_FORMATS = [
	"background",
	"bold",
	"color",
	"font",
	"code",
	"italic",
	"link",
	"size",
	"strike",
	"script",
	"underline",
	"blockquote",
	"header",
	"indent",
	"list",
	"align",
	"direction",
	"code-block",
	"formula"
	// 'image',
	// 'video'
];
