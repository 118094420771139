import React from "react";
import MobileNavBar from "./MobileNavBar";
import Sidebar from "./Sidebar";

function Layout({
	children,
	active_key = "dashboard",
	forceReduced = false
}: {
	children: React.ReactNode;
	active_key?: string;
	forceReduced?: boolean;
}) {
	return (
		<div className="flex flex-col md:grid md:grid-cols-[auto_1fr]">
			<Sidebar active_key={active_key} forceReduced={forceReduced} />
			<MobileNavBar active_key={active_key} />

			<div className="flex h-screen flex-col flex-nowrap overflow-x-auto">
				{children}
			</div>
		</div>
	);
}

export default Layout;
