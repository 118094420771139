interface DropDownOptionsProps {
	firstIcon?: JSX.Element;
	secondIcon?: JSX.Element;
	firstSubtitle: string;
	secondSubtitle?: string;
	onClickFirstOption: () => void;
	onClickSecondOption?: () => void;
}

const DropDownOptions = ({
	firstIcon,
	secondIcon,
	firstSubtitle,
	secondSubtitle,
	onClickFirstOption,
	onClickSecondOption
}: DropDownOptionsProps): JSX.Element => {
	return (
		<div
			id="dropdownDivider"
			className="absolute
       mt-[35px] ml-[-10px] divide-y divide-gray-100 rounded-lg bg-white shadow"
		>
			<ul className="py-2 text-sm" aria-labelledby="dropdownDividerButton">
				<li className="mb-1">
					<button
						onClick={onClickFirstOption}
						className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
					>
						<div>{firstIcon}</div>
						<span className="px-2 uppercase">{firstSubtitle}</span>
					</button>
					<button
						onClick={onClickSecondOption}
						className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
					>
						<div>{secondIcon}</div>
						<span className="px-2 uppercase">{secondSubtitle}</span>
					</button>
				</li>
			</ul>
		</div>
	);
};
export default DropDownOptions;
