import { useContext } from "react";
import { To, useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const BottomBarNavigation = ({
	back_to,
	go_to,
	go_to_trad,
	action,
	action_trad,
	scd_go_to,
	scd_go_to_trad
}: {
	back_to?: string;
	go_to?: string;
	go_to_trad?: string;
	action?: () => void;
	action_trad?: string;
	scd_go_to?: string;
	scd_go_to_trad?: string;
}) => {
	const navigate = useNavigate();
	const { lang } = useContext(AppContext);

	const go_back = () => navigate((back_to || -1) as To);

	const navigate_to = () => navigate(go_to as To);
	const scd_navigate_to = () => navigate(scd_go_to as To);

	return (
		<div className="flex-start static flex h-16 w-full flex-row gap-4 border-t bg-white p-3">
			{back_to && (
				<div className="flex flex-row gap-3">
					<button
						className="flex h-full flex-row items-center gap-1 rounded-md border border-red-500 bg-white py-3 px-3 text-xs text-red-500 duration-150 hover:bg-red-500 hover:text-white md:uppercase"
						onClick={go_back}
					>
						{trad[lang].go_back}
					</button>
				</div>
			)}

			{go_to && go_to_trad && (
				<div className="flex flex-row gap-3">
					<button
						className="flex h-full flex-row items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primarymedium hover:text-white md:uppercase"
						onClick={navigate_to}
					>
						{go_to_trad}
					</button>
				</div>
			)}

			{scd_go_to && scd_go_to_trad && (
				<div className="flex flex-row gap-3">
					<button
						className="flex h-full flex-row items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primarymedium hover:text-white md:uppercase"
						onClick={scd_navigate_to}
					>
						{scd_go_to_trad}
					</button>
				</div>
			)}

			{action && action_trad && (
				<div className="flex flex-row gap-3">
					<button
						className="flex h-full flex-row items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primarymedium hover:text-white md:uppercase"
						onClick={action}
					>
						{action_trad}
					</button>
				</div>
			)}
		</div>
	);
};

export default BottomBarNavigation;
