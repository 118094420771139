import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { createContact, updateContact } from "../../api/event";
import { EventContactConfig } from "../../config/GeneralEvents/EventContact";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IContact } from "../../types/EventForms/EventContacts";
import { REGEXP } from "../../utils/RegExp";
import Toast from "../../utils/Toasts";
import EventInput from "./EventInput";

const SportEventContactFormModal = ({
	contact,
	closeModal,
	refetch
}: {
	contact: IContact;
	closeModal: any;
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [localForm, setLocalForm] = useState<IContact>(contact);
	const [errors, setErrors] = useState<string[]>([]);

	const confirmForm = async () => {
		try {
			const localErrors = [];
			if (!localForm.raisonSocial) {
				localErrors.push("raisonSocial");
			}
			if (!localForm.prenom) {
				localErrors.push("prenom");
			}
			if (!localForm.nom) {
				localErrors.push("nom");
			}

			if (localForm.telephone1 && !REGEXP.phone.test(localForm.telephone1)) {
				localErrors.push("telephone1");
			}
			if (localForm.telephone2 && !REGEXP.phone.test(localForm.telephone2)) {
				localErrors.push("telephone2");
			}

			if (localForm.mail && !REGEXP.mail.test(localForm.mail)) {
				localErrors.push("mail");
			}

			if (localErrors.length) {
				setErrors(localErrors);
				return;
			}

			if (contact.id) {
				await updateContact(contact.id, localForm);
			} else {
				await createContact(slug as string, localForm);
			}

			Toast.success(trad[lang].success_updating_contact);
			refetch();
			closeModal();
		} catch (error) {
			Toast.error(trad[lang].error_updating_contact);
		}
	};

	const handleChange = (key: string, value: string) =>
		setLocalForm((old) => ({ ...old, [key]: value }));

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg rounded-md border bg-white py-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{trad[lang].edit_contact_title}
				</h2>

				<div className="flex max-h-[60vh] flex-col gap-3 overflow-y-auto px-3 md:grid md:grid-cols-2">
					{EventContactConfig.map((item) => (
						<EventInput
							config={item}
							handleChange={handleChange}
							values={localForm}
							key={item.id}
							error={errors.includes(item.key)}
						/>
					))}
				</div>

				<div className="mt-3 flex flex-row gap-3 px-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={confirmForm}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportEventContactFormModal;
