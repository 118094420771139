import SelectCountry from "./SelectCountry";

interface NationalityFieldProps {
	id?: string;
	label: string;
	value: string | number;
	defaultCountryCode: any;
	onChange: any;
	lang: any;
}

const NationalityField = ({
	id,
	label,
	defaultCountryCode,
	onChange,
	lang,
	value
}: NationalityFieldProps): JSX.Element => {
	return (
		<div className="w-full">
			<label className="block text-left text-sm font-medium text-gray-700">
				{label}
			</label>
			<SelectCountry
				lang={lang}
				listTailwind="max-h-[180px]"
				defaultCountryCode={defaultCountryCode}
				onChange={onChange}
			/>
		</div>
	);
};

export default NationalityField;
