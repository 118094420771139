import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	Option
} from "@material-tailwind/react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { AiFillDelete } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { useQuery } from "react-query";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useNavigate,
	useParams
} from "react-router-dom";
import {
	getEventIdentityConfig,
	getEventOptionsConfig,
	updateEventGlobalOptions,
	updateEventOptions
} from "../../../api/event";
import BottomBarNavigation from "../../../components/common/BottomBarNavigation";
import BreadCrumb from "../../../components/common/BreadCrumb";
import DateTimePickerTZ from "../../../components/common/DateTimePickerTZ";
import PageLoader from "../../../components/common/PageLoader";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import Layout from "../../../components/navigation/Layout";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IConfirmationModalConfig } from "../../../types/Modals";
import { classNames } from "../../../utils/Classes";
import { clampISODate } from "../../../utils/DateFormater";
import Toast from "../../../utils/Toasts";

dayjs.extend(timezone);

type Option = {
	designation: string;
	type: number;
	products?: Product[];
	prices?: Price[];
	typeName: string;
	idPackage?: number;
	idProduit?: number;
	minQuantity?: number;
	maxQuantity?: number;
};

type Price = {
	designation: string;
	price: number;
	startDate: string;
	endDate: string;
	typeName: string;
};

type Product = {
	designation: string;
	prices: Price[];
	typeName: string;
};

type Error = {
	optIndex: number;
	productIndex: number;
	priceIndex: number;
	message: string;
	obj: any;
};

const SportEventOptions = () => {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { slug, slug_race } = useParams();
	const navigate = useNavigate();

	// State du formulaire
	const [identityForm, setIdentityForm] = useState({});
	const stepValid = true;

	// State du chargement
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<Option[]>([]);
	const [optionsByRaceSlug, setOptionsByRaceId] = useState<any>({});
	const [race, setRace] = useState<any>();
	const [error, setError] = useState<Error | undefined>();

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	// Modal
	const [confirmModalConfig, setConfirmModalConfig] =
		useState<IConfirmationModalConfig>();

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const { data: EventInfos, isLoading: EventInfosLoading } = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const handleGoBack = () => navigate(`/${slug}/event-details`);

	const getFirstError = function (
		options: any[],
		depth: number
	): Error | undefined {
		let error: Error | undefined;
		for (let i = 0; i < options.length; i++) {
			let opt = options[i];

			if (opt.startDate && opt.endDate) {
				let start: any = dayjs(opt.startDate);
				let end: any = dayjs(opt.endDate);
				if (start.isAfter(end) || start.isSame(end)) {
					return {
						optIndex: i,
						productIndex: -1,
						priceIndex: -1,
						message: trad[lang].event_invalid_dates,
						obj: opt
					};
				}
			}

			// Check designation & type field
			if (!opt.designation || !opt.typeName)
				return {
					optIndex: i,
					productIndex: -1,
					priceIndex: -1,
					message: trad[lang].event_form_required,
					obj: opt
				};

			// Check quantity field
			if (opt.typeName == "numbers") {
				if (opt.minQuantity <= 0 || opt.maxQuantity <= 0)
					return {
						optIndex: i,
						productIndex: -1,
						priceIndex: -1,
						message: trad[lang].error_qty_min_0,
						obj: opt
					};
				if (!opt.maxQuantity || !opt.minQuantity)
					return {
						optIndex: i,
						productIndex: -1,
						priceIndex: -1,
						message: trad[lang].event_form_required,
						obj: opt
					};
				if (opt.minQuantity >= opt.maxQuantity)
					return {
						optIndex: i,
						productIndex: -1,
						priceIndex: -1,
						message: trad[lang].error_qty_min_max,
						obj: opt
					};
			}

			// Loop over products & prices
			if (opt.prices) {
				error = getFirstError(opt.prices, depth + 1);
				if (error) error.priceIndex = error.optIndex;
			} else if (opt.products) {
				error = getFirstError(opt.products, depth + 1);
				if (error) error.productIndex = error.optIndex;
			}
			if (error) {
				return {
					optIndex: i,
					productIndex: error.productIndex,
					priceIndex: error.priceIndex,
					message: error.message,
					obj: error.obj
				};
			}
		}

		return undefined;
	};

	const onSave = async () => {
		try {
			setLoading(true);
			let error: Error | undefined = getFirstError(options, 0);
			if (error) {
				setError(error);
				setOpenedOption(error.optIndex);
				setOpenedProduct(error.productIndex);
				setOpenedPrice(error.priceIndex);
				Toast.error(error.message);
				throw new Error(error.message);
			} else setError(undefined);
			const json: any = { options: options };
			unsavedChangesRef.current = false;
			// Update options of the focused race
			if (slug_race) await updateEventOptions(slug_race, json);
			// Add global options (applied on all races)
			else if (slug) {
				await updateEventGlobalOptions(slug, json);
				handleGoBack();
			}
			Toast.success(trad[lang].sport_options_success);
		} catch (error) {
			Toast.error(trad[lang].sport_options_error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (slug_race) loadOptions(slug_race);
		// Add one option to configure (Global option)
		else {
			addNewOption();
		}
	}, []);

	const loadOptions = async function (slug: string) {
		try {
			const res = await getEventOptionsConfig(slug);
			if (res) {
				setOptions(res["options"]);
				setRace(res["race"]);
			}
		} catch (error) {
			setOptions([]);
		}
	};

	const [open, setOpen] = useState(1);
	const [openedOption, setOpenedOption] = useState(0);
	const [openedProduct, setOpenedProduct] = useState(-1);
	const [openedPrice, setOpenedPrice] = useState(-1);

	const openOption = function (index: number) {
		setOpenedOption(index === openedOption ? -1 : index);
	};

	const openProduct = function (index: number) {
		setOpenedProduct(index === openedProduct ? -1 : index);
	};

	const openPrice = function (index: number) {
		setOpenedPrice(index === openedPrice ? -1 : index);
	};

	const handleOpen = (value: number) => {
		setOpen(open === value ? 0 : value);
	};

	function Icon(id: number, open: number) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className={`${
					id === open ? "rotate-180" : ""
				} h-5 w-5 transition-transform`}
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				strokeWidth={2}
			>
				<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
			</svg>
		);
	}

	const requiredFields: string[] = [
		"designation",
		"type",
		"quantityMin",
		"quantityMax",
		"price",
		"dateStart",
		"dateEnd"
	];
	const requiredInput = {
		color: "red"
	};

	const getOptionTypeSelect = function (
		id: string,
		item: Option,
		label: string
	) {
		return (
			<div className="w-[100%] pt-1 pb-4">
				<label className="block text-left text-base font-medium text-gray-700">
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="relative flex flex-grow items-stretch focus-within:z-10">
					<select
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
						defaultValue={item?.type?.toString()}
						onChange={(e) => {
							// Get type
							item.type = parseInt(e.target.value);

							// Dates
							const today = new Date(
								new Date().setHours(0, -new Date().getTimezoneOffset(), 0, 0)
							);
							const todayIso = today.toISOString();
							let endDate = race?.endDate
								? new Date(race.endDate)
								: EventInfos.endDate;
							if (endDate < today) endDate = today;

							// Reset opt
							delete item.idPackage;
							delete item.idProduit;
							delete item.minQuantity;
							delete item.maxQuantity;

							// Dropdown
							if (item.type === 1) {
								item.typeName = "dropdown";
								delete item.prices;
								item.products = [
									{
										designation: "",
										typeName: "dropdown_product",
										prices: [getNewPriceData()]
									}
								];
							}
							// Checkbox
							else if (item.type === 2) {
								item.typeName = "checkbox";
								delete item.products;
								item.prices = [getNewPriceData()];
							}
							// Numbers
							else if (item.type === 3) {
								item.typeName = "numbers";
								delete item.products;
								item.minQuantity = 1;
								item.maxQuantity = 2;
								item.prices = [getNewPriceData()];
							}

							setOpenedProduct(0);
							setOpenedPrice(0);
							const newOptions = [...options];
							setOptions(newOptions);
							unsavedChangesRef.current = true;
						}}
					>
						<option key="1" value="1">
							{trad[lang].type_dropdown_description}
						</option>
						<option key="2" value="2">
							{trad[lang].type_check_description}
						</option>
						<option key="3" value="3">
							{trad[lang].type_quantity_description}
						</option>
					</select>
				</div>
			</div>
		);
	};

	const getDatefield = function (
		item: any,
		id: string,
		label: string,
		value: string
	) {
		return (
			<div className="text-bold w-[50%]">
				<label
					className={classNames(
						"block text-left text-base font-medium text-gray-700"
					)}
				>
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="relative flex flex-col pt-1 focus-within:z-10">
					<DateTimePickerTZ
						handleChange={(e) => {
							if (e) {
								item[id] = dayjs(e).toISOString();
								if (id === "startDate" && dayjs(e).isAfter(dayjs(item.endDate)))
									item.endDate = dayjs(e).toISOString();
								setOptions([...options]);
								unsavedChangesRef.current = true;
							}
						}}
						minDateTime={id === "endDate" ? dayjs(item.startDate) : undefined}
						maxDateTime={dayjs(race?.endDate || EventInfos?.endDate)}
						value={
							id === "endDate"
								? dayjs(
										clampISODate(
											value,
											item.startDate,
											race?.endDate || EventInfos?.endDate
										)
								  )
								: dayjs(value)
						}
						timezone={EventInfos?.timezone}
					/>
				</div>
			</div>
		);
	};

	const getQuantityField = function (
		item: any,
		id: string,
		label: string,
		value: string
	) {
		return (
			<div className="text-bold w-[50%]">
				<label
					className={classNames(
						"block text-left text-base font-medium text-gray-700"
					)}
				>
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="mt-1 mb-4">
					<input
						type="number"
						min="1"
						className="block w-full rounded-md border-slate-300 shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm"
						defaultValue={value}
						onChange={(e: any) => {
							if (id === "minQuantity" || id === "maxQuantity") {
								item[id] = parseInt(e.target.value);
								const newOptions = [...options];
								setOptions(newOptions);
								unsavedChangesRef.current = true;
							}
						}}
						onBlur={(e: any) => {}}
					/>
				</div>
			</div>
		);
	};

	const getTextfield = function (
		type: Category,
		item: any,
		id: string,
		label: string,
		value: string
	) {
		return (
			<div className="text-bold w-[100%]">
				<label
					className={classNames(
						"block text-left text-base font-medium text-gray-700"
					)}
				>
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="mt-1 mb-4">
					<input
						type="text"
						className="block w-full rounded-md border-slate-300 shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm"
						defaultValue={value}
						onChange={(e: any) => {
							if (id === "designation") {
								item.designation = e.target.value;
								const newOptions = [...options];
								setOptions(newOptions);
								unsavedChangesRef.current = true;
							} else if (id === "price") {
								e.target.value = e.target.value.replace(/[^0-9\.\,]/g, "");
							}
						}}
						onBlur={(e: any) => {
							if (id === "price") {
								// Format price
								let strFloat = e.target.value
									.replace(",", ".")
									.replace(/[^0-9\.]/g, "");
								item.price = parseFloat(strFloat || "0").toFixed(2);
								e.target.value = item.price
									.toString()
									.replace(".", (e.target.value.includes(",") && ",") || ".");

								// Save price
								const newOptions = [...options];
								setOptions(newOptions);
								unsavedChangesRef.current = true;
							}
						}}
					/>
				</div>
			</div>
		);
	};

	type Category = {
		id: string;
		header: string;
	};

	const TYPES: {
		[key: string]: Category;
	} = useMemo(() => {
		return {
			OPTION: {
				id: "OPTION",
				header: trad[lang].option
			},
			PRODUCT: {
				id: "PRODUCT",
				header: trad[lang].product
			},
			PRICE: {
				id: "PRICE",
				header: trad[lang].price
			}
		};
	}, [options]);

	function renderAccordion(
		type: Category,
		list: Option[] | Product[] | Price[],
		option: Option | null,
		product: Product | null,
		openCallback: Function,
		openedIndex: number
	) {
		return (
			<>
				{list?.map((item: any, index: number) => (
					<Fragment key={index}>
						<Accordion
							open={openedIndex === index}
							className="mb-2 rounded-lg border"
							icon={Icon(index, openedIndex)}
						>
							<div
								className={`flex max-h-10 justify-between rounded-lg px-8 ${
									openedIndex !== index
										? ""
										: error && error.obj === item
										? "bg-red-300"
										: "bg-primary"
								}`}
							>
								<AccordionHeader
									onClick={() => {
										openCallback(index);
									}}
									className={`border-b-0 text-base font-bold transition-colors ${
										openedIndex === index ? "text-white" : "text-gray-500"
									}`}
								>
									{type.header + " : "}
									{item.designation || "???"}
									{type.id === "PRICE" && " - " + parseFloat(item.price) + "€"}
								</AccordionHeader>
								<AiFillDelete
									className={`ml-2 mt-2 h-6 w-6 cursor-pointer ${
										openedIndex === index ? "text-white" : "text-gray-500"
									}`}
									onClick={() => {
										setConfirmModalConfig({
											setter: setConfirmModalConfig,
											opened: true,
											title: trad[lang].delete_title + " (" + type.header + ")",
											description:
												trad[lang].delete_message +
												" '" +
												item.designation +
												"' ?\n" +
												trad[lang].warning_delete,
											onConfirm: () => {
												list.splice(index, 1);
												const newOptions: Option[] = [...options];
												setOptions(newOptions);
												unsavedChangesRef.current = true;
											}
										});
									}}
								/>
							</div>
							<AccordionBody className="px-8">
								{/* PRICE FIELDS (price, designation, dates) */}
								{error && error.obj === item && (
									<div className="mb-2 text-base text-red-500">
										({error.message})
									</div>
								)}
								{type === TYPES.PRICE && (
									<>
										<div className="flex w-full flex-row gap-6">
											{getTextfield(
												type,
												item,
												"designation",
												trad[lang].designation?.toUpperCase(),
												item.designation
											)}
											{getTextfield(
												type,
												item,
												"price",
												trad[lang].price?.toUpperCase(),
												parseFloat(item.price).toString()
											)}
										</div>
										<div className="flex w-full flex-row gap-6">
											{getDatefield(
												item,
												"startDate",
												trad[lang].price_start_date?.toUpperCase(),
												item.startDate
											)}
											{getDatefield(
												item,
												"endDate",
												trad[lang].price_end_date?.toUpperCase(),
												item.endDate
											)}
										</div>
									</>
								)}

								{(type === TYPES.OPTION || type === TYPES.PRODUCT) && (
									<>
										{getTextfield(
											type,
											item,
											"designation",
											trad[lang].designation?.toUpperCase(),
											item.designation
										)}
									</>
								)}

								{/* SELECT OPTION TYPE */}
								{type === TYPES.OPTION &&
									getOptionTypeSelect(
										"type",
										item,
										trad[lang].option_type?.toUpperCase()
									)}

								{/* REQUIRED ? */}
								{type === TYPES.OPTION ? (
									getOptionCheckbox("required", item)
								) : (
									<></>
								)}

								{/* QUANTITY */}
								{type === TYPES.OPTION &&
									item.typeName === "numbers" &&
									getQuantityField(
										item,
										"minQuantity",
										trad[lang].min_quantity?.toUpperCase(),
										item.minQuantity?.toString()
									)}
								{type === TYPES.OPTION &&
									item.typeName === "numbers" &&
									getQuantityField(
										item,
										"maxQuantity",
										trad[lang].max_quantity?.toUpperCase(),
										item.maxQuantity?.toString()
									)}

								{type === TYPES.OPTION &&
									item.typeName === "dropdown" &&
									renderAccordion(
										TYPES.PRODUCT,
										item.products,
										item,
										null,
										openProduct,
										openedProduct
									)}
								{(type === TYPES.PRODUCT ||
									(type === TYPES.OPTION &&
										(item.typeName === "checkbox" ||
											item.typeName === "numbers"))) &&
									renderAccordion(
										TYPES.PRICE,
										item.prices,
										option,
										item,
										openPrice,
										openedPrice
									)}
							</AccordionBody>
						</Accordion>
					</Fragment>
				))}
				<button
					type="button"
					className="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
					onClick={() => {
						const today = new Date(
							new Date().setHours(0, -new Date().getTimezoneOffset(), 0, 0)
						);
						const todayIso = today.toISOString();
						let endDate = race?.endDate ? new Date(race.endDate) : today;
						if (endDate < today) endDate = today;

						// Add a new price
						if (product) {
							const newOptions = [...options];
							const newPrices = [...list];

							// Duplicate last price if it exists or create a new one
							let lastPrice: any = newPrices[newPrices.length - 1];
							if (lastPrice) {
								const newPData = JSON.parse(JSON.stringify(lastPrice));
								delete newPData.idTarif;
								delete newPData.idTarifProduit;
								lastPrice = newPData;
							} else lastPrice = getNewPriceData();
							newPrices.push(lastPrice);
							product.prices = newPrices as Price[];
							setOpenedPrice(newPrices.length - 1);
							setOptions(newOptions);
							unsavedChangesRef.current = true;
						}
						// Add a new product
						else if (!product && option) {
							const newOptions = [...options];
							const newProducts = [...list];

							// Duplicate last product if it exists or create a new one
							let lastProduct: any = newProducts[newProducts.length - 1];
							if (lastProduct) {
								const newPData = JSON.parse(JSON.stringify(lastProduct));
								delete newPData.idProduit;
								delete newPData.idPackage;
								newProducts.push(newPData);
							} else {
								newProducts.push({
									designation: "",
									typeName: "dropdown_product",
									prices: [getNewPriceData()]
								});
							}
							option.products = newProducts as Product[];
							setOpenedProduct(newProducts.length - 1);
							setOptions(newOptions);
							unsavedChangesRef.current = true;
						} else {
							addNewOption();
							unsavedChangesRef.current = true;
						}
					}}
				>
					<MdOutlineAdd className="mr-2 -ml-2 inline-block" size={24} />
					{product
						? trad[lang].add_price
						: option
						? trad[lang].add_product
						: trad[lang].add_option}
				</button>
			</>
		);
	}

	const getNewPriceData = function (): any {
		const today = new Date(
			new Date().setHours(0, -new Date().getTimezoneOffset(), 0, 0)
		);
		const startDate =
			race?.startDate || EventInfos?.startDate
				? dayjs(race?.startDate || EventInfos?.startDate).toISOString()
				: today.toISOString();
		let endDate = race?.endDate
			? dayjs(race.endDate).tz(EventInfos.timezone).toISOString()
			: today;

		return {
			designation: "",
			typeName: "price",
			price: 0,
			startDate: startDate,
			endDate: endDate
		};
	};

	const addNewOption = function () {
		const newOptions = [...options];
		newOptions.push({
			designation: "",
			typeName: "checkbox",
			type: 2,
			prices: [getNewPriceData()]
		});
		setOpenedOption(newOptions.length - 1);
		setOptions(newOptions);
	};

	const getOptionCheckbox = function (id: string, item: any) {
		// let quantity = parseInt(opt.optSub.Quantite);
		return (
			<div className="w-full pt-1">
				<label className="block text-left text-base font-medium text-gray-700">
					{trad[lang].is_required?.toUpperCase()}
				</label>
				<div className="relative mt-2 flex flex-grow items-stretch pb-6 focus-within:z-10">
					<input
						type="checkbox"
						defaultChecked={item?.required}
						onChange={(e) => {
							item.required = e.target.checked;
							const newOptions = [...options];
							setOptions(newOptions);
							unsavedChangesRef.current = true;
						}}
						className="h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-0"
					/>
				</div>
			</div>
		);
	};

	if (EventInfosLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout active_key="configuration">
			<div className="relative flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: EventInfos.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{
							key: "event_options",
							text: race?.name,
							to: "#",
							active: true
						}
					]}
				/>

				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
					{trad[lang].sport_event_options}
				</h1>

				<h3 className="mb-6 text-lg font-bold text-gloom">
					{slug_race
						? race?.name
							? race.name
							: ""
						: trad[lang].add_global_option}
				</h3>

				{renderAccordion(
					TYPES["OPTION"],
					options,
					null,
					null,
					openOption,
					openedOption
				)}

				<div className="mb-10"></div>
			</div>

			<BottomBarNavigation
				action={onSave}
				action_trad={trad[lang].save}
				back_to={`/${slug}/event-details`}
			/>

			{confirmModalConfig && confirmModalConfig.opened === true && (
				<ConfirmationModal config={confirmModalConfig} />
			)}
		</Layout>
	);
};

export default SportEventOptions;
