import { useContext, useEffect, useMemo, useState } from "react";
import {
	HiOutlineArrowRightOnRectangle,
	HiOutlineChevronDown,
	HiOutlineUserCircle
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../api/auth";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";
import { getUserInitials } from "../../utils/UserInitials";

const UserMenu = ({ reduced }: { reduced: boolean }) => {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { user, setUser } = useContext(UserContext);
	const [userMenu, setUserMenu] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const username = useMemo(() => {
		if (!user) return "";

		return `${user.firstname} ${user.lastname}`;
	}, [user]);

	const logout = async () => {
		try {
			if (unsavedChangesRef.current) {
				let userDiscarded = window.confirm(trad[lang].discard_changes);

				if (!userDiscarded) return;
			}

			unsavedChangesRef.current = false;

			await logOut();

			localStorage.removeItem("newConnexion");
			localStorage.removeItem("token");

			setUser(undefined);

			navigate("/");
			Toast.success(trad[lang].logoutSuccess);
		} catch (error) {
			Toast.error(trad[lang].logoutError);
		}
	};

	useEffect(() => {
		unsavedChangesRef.current = false;
	}, [location]);

	return (
		<>
			<button onClick={() => setUserMenu(!userMenu)}>
				<div
					className={"my-2 flex flex-row items-center justify-evenly uppercase"}
				>
					<div
						className={`flex ${
							reduced ? "h-8 w-8 text-sm" : "h-10 w-10 text-lg"
						} items-center justify-center rounded-full bg-gloom font-bold text-darkerBg`}
					>
						{getUserInitials(username)}
					</div>

					{!reduced && (
						<span className="w-28 text-sm">
							{username.length > 18 ? user?.firstname : username}
						</span>
					)}
					<HiOutlineChevronDown className={reduced ? "h-3 w-3" : "h-5 w-5"} />
				</div>
			</button>

			{userMenu && (
				<div
					className={`mb-2 flex w-full flex-col gap-2 bg-gray-200 py-2 ${
						reduced ? "items-center justify-center" : "items-start pl-12"
					} text-gray-600 shadow-inner`}
				>
					<p className="flex flex-row items-center gap-2 py-2 text-sm font-medium opacity-50 duration-150">
						<HiOutlineUserCircle className="h-6 w-6" />
						{!reduced && trad[lang].myAccount}
					</p>
					<button
						className="flex flex-row items-center gap-2 py-2 text-sm font-medium duration-150 hover:text-warning"
						onClick={logout}
					>
						<HiOutlineArrowRightOnRectangle className="h-6 w-6" />
						{!reduced && trad[lang].logout}
					</button>
				</div>
			)}
		</>
	);
};

export default UserMenu;
