import { useContext, useMemo } from "react";
import {
	AiOutlineClockCircle,
	AiOutlineClose,
	AiOutlineEdit,
	AiOutlineNumber,
	AiOutlineTrophy,
	AiOutlineUser
} from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const ManageDetectionHeader = () => {
	const { lang, setDetectionFilter, detectionFilters } = useContext(AppContext);

	const keys = useMemo(
		() => [
			{
				title: trad[lang].bib,
				icon: () => <AiOutlineNumber size={18} />,
				visible: true
			},
			{
				title: trad[lang].runner,
				icon: () => <AiOutlineUser size={18} />,
				visible: true,
				classes: "hidden lg:table-cell"
			},
			{
				title: trad[lang].run,
				icon: () => <AiOutlineTrophy size={18} />,
				visible: true,
				classes: "hidden lg:table-cell"
			},
			{
				key: "detector",
				title: trad[lang].detectors,
				icon: () => <AiOutlineNumber size={18} />,
				visible: true,
				filters: true
			},
			{
				title: trad[lang].hours,
				icon: () => <AiOutlineClockCircle size={18} />,
				visible: true
			},
			{
				title: trad[lang].edit,
				icon: () => <AiOutlineEdit size={18} />,
				visible: false
			},
			{
				title: trad[lang].delete,
				icon: () => <AiOutlineClose size={18} />,
				visible: false
			}
		],
		[lang]
	);

	return (
		<thead className="rounded-lg bg-gray-50">
			<tr>
				{keys.map((item, index: number) => (
					<th
						scope="col"
						className={`select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right ${item.classes}`}
						key={index}
					>
						<div
							className={classNames(
								"flex justify-center",
								item.visible ? "" : "sr-only"
							)}
						>
							<div className="flex h-[30px] items-center gap-x-2">
								<span>{item.title}</span>
								{item.icon && <item.icon />}
							</div>
						</div>
					</th>
				))}
			</tr>
		</thead>
	);
};

export default ManageDetectionHeader;
