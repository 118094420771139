import { useContext, useMemo } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const PermissionsTableHeader = () => {
	const { lang } = useContext(AppContext);

	const keys: {
		key: string;
		title: string;
		icon?: (() => JSX.Element) | undefined;
		visible: boolean;
		list?: { label: string; value: any }[] | undefined;
		filters?: boolean;
		classes?: string;
	}[] = useMemo(
		() => [
			{
				key: "email",
				title: trad[lang].mail,
				visible: true,
				filters: false,
				classes: "hidden lg:table-cell"
			},
			{
				key: "name",
				title: trad[lang].name,
				visible: true,
				filters: false
			},
			{
				key: "status",
				title: trad[lang].status,
				visible: true,
				filters: false
			},
			{
				key: "roles",
				title: trad[lang].roles,
				visible: true,
				filters: false
			},
			{
				key: "actions",
				title: trad[lang].actions,
				visible: true
			}
		],
		[lang]
	);

	return (
		<thead className="bg-gray-50">
			<tr>
				{keys.map((item, index: number) => (
					<th
						scope="col"
						className={`select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 ${item.classes}`}
						key={index}
					>
						<div className="flex">
							<div className="flex items-center gap-x-2">
								<span>{item.visible ? item.title : ""}</span>
								{item.icon && <item.icon />}
							</div>
						</div>
					</th>
				))}
			</tr>
		</thead>
	);
};

export default PermissionsTableHeader;
