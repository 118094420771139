import { useContext, useMemo } from "react";
import {
	AiOutlineAppstore,
	AiOutlineCheck,
	AiOutlineClose,
	AiOutlineCloseCircle,
	AiOutlineMinusCircle,
	AiOutlineNumber,
	AiOutlineRetweet,
	AiOutlineUser
} from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const DetectionResumeCard = ({
	data,
	timezone
}: {
	data: any;
	timezone: string;
}) => {
	const { lang } = useContext(AppContext);

	const keys = useMemo(
		() => [
			{
				title: trad[lang].detectors,
				icon: () => <AiOutlineNumber size={18} />,
				visible: true
			},
			{
				title: trad[lang].verification,
				icon: () => <AiOutlineNumber size={18} />,
				visible: true
			},
			{
				title: trad[lang].waiting,
				icon: () => <AiOutlineNumber size={18} />,
				visible: true
			},
			{
				title: trad[lang].percentage,
				icon: () => <AiOutlineNumber size={18} />,
				visible: false
			}
		],
		[]
	);

	const stats = useMemo(() => {
		if (!data) return [];

		return [
			{
				key: "finishers",
				title: trad[lang].finishers,
				value: data.finishers,
				icon: () => <AiOutlineCheck />
			},
			{
				key: "number_runners",
				title: trad[lang].number_runners,
				value: data.number_runners,
				icon: () => <AiOutlineUser />
			},
			{
				key: "number_runners",
				title: trad[lang].abandon,
				value: data.surrended,
				icon: () => <AiOutlineCloseCircle />
			},
			{
				key: "number_runners",
				title: trad[lang].disqualified,
				value: data.disqualified,
				icon: () => <AiOutlineMinusCircle />
			},
			{
				key: "number_runners",
				title: trad[lang].grid,
				value: 0,
				icon: () => <AiOutlineAppstore />
			},
			{
				key: "number_runners",
				title: trad[lang].official_starters,
				value: 0,
				icon: () => <AiOutlineRetweet />
			},
			{
				key: "non_starters",
				title: trad[lang].non_starters,
				value: data.number_runners - data.starters,
				icon: () => <AiOutlineClose />
			}
		];
	}, [data]);

	return (
		<div className="min-w-full align-middle">
			<div className="mb-5">
				<h2 className="text-center text-2xl">{data.libelleCourse}</h2>

				<p className="text-center">{`${trad[lang].start_the} ${new Date(
					data.start_date
				).toLocaleString("fr-FR", {
					timeZone: timezone
				})}`}</p>
			</div>

			<div className="mb-3 flex justify-around">
				{stats.map((item, index: number) => (
					<div
						className="flex items-center gap-1 rounded-md bg-primary py-1 px-4 text-white"
						key={index}
					>
						<p>{item.icon && <item.icon />}</p>
						<p>{item.value}</p>
						<p>{item.title}</p>
					</div>
				))}
			</div>

			<div className="mb-5 border border-gray-200">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="rounded-lg bg-gray-50">
						<tr>
							{keys.map((item, index: number) => (
								<th
									scope="col"
									className="w-1/4 select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
									key={index}
								>
									<div
										className={classNames(
											"flex",
											item.visible ? "" : "sr-only"
										)}
									>
										<div className="flex items-center gap-x-2">
											<span>{item.title}</span>

											{item.icon && <item.icon />}
										</div>
									</div>
								</th>
							))}
						</tr>
					</thead>

					<tbody className="divide-y divide-gray-200 bg-white">
						{data.checkpoints.map((checkpoint: any, index: number) => (
							<tr key={index}>
								<>
									<td className="w-1/4 whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<div className="flex items-center gap-x-2">
											<h2 className={"font-medium text-gray-800"}>
												{checkpoint?.libelleCheckpoint || "oui"}
											</h2>
										</div>
									</td>

									<td className="w-1/4 whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<div className="flex items-center gap-x-2">
											<h2 className={"font-medium text-gray-800"}>
												{checkpoint.passages}
											</h2>
										</div>
									</td>

									<td className="w-1/4 whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<div className="flex items-center gap-x-2">
											<h2 className={"font-medium text-gray-800"}>
												{checkpoint.verif_passages}
											</h2>
										</div>
									</td>

									<td className="w-1/4 whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<div className="flex items-center gap-x-2">
											<h2 className="h-5 w-full rounded-full bg-gray-800">
												<div
													className="h-5 rounded-full bg-blue-600 text-center text-white"
													style={{
														width: `${(
															(checkpoint.passages /
																(checkpoint.passages +
																	checkpoint.verif_passages)) *
																100 || 0
														).toFixed(0)}%`
													}}
												>
													<span className="mx-1">
														{`${(
															(checkpoint.passages /
																(checkpoint.passages +
																	checkpoint.verif_passages)) *
																100 || 0
														).toFixed(2)}%`}
													</span>
												</div>
											</h2>
										</div>
									</td>
								</>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default DetectionResumeCard;
