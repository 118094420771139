import { KeyboardEvent } from "react";

export const handleReturnKey = (
	event: KeyboardEvent<HTMLInputElement>,
	callback: () => void
): void => {
	if (event.code === "Enter" || event.code === "NumpadEnter") {
		callback();
	}
};
