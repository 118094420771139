import dayjs from "dayjs";
import { countriesList } from "../../../utils/CountriesList";
import { Timezones } from "../../../utils/Timezone";

export const FORM_MAX_IMAGES = 1;
export const FORM_MAX_BANNERS = 1;
export const FFA_EVENT_TYPE_CATEGORIE = 3;

export const SportIdentityConfig = (
	subtypes: any[],
	startDate: string,
	endDate: string,
	sport_category: number,
	slug?: string
) => [
	{
		type: "text",
		id: "event_name",
		key: "name",
		col: 1,
		required: true
	},
	{
		type: "select",
		id: "event_sport",
		key: "eventType",
		col: 1,
		required: true,
		options: { key: "id", label: "title", values: subtypes },
		disable: !!slug
	},
	{
		type: "select",
		id: "event_timezone",
		key: "timezone",
		col: FFA_EVENT_TYPE_CATEGORIE == sport_category ? 1 : 2,
		required: true,
		options: { key: "tzCode", label: "label", values: Timezones }
	},
	{
		type: "text",
		id: "event_ffa_siffa",
		key: "siffa",
		col: 1,
		visible: FFA_EVENT_TYPE_CATEGORIE == sport_category
	},
	{
		type: "datetime-local",
		id: "event_start_date",
		key: "startDate",
		min: dayjs(),
		max: endDate,
		step: 60,
		col: 1,
		required: true
	},
	{
		type: "datetime-local",
		id: "event_end_date",
		key: "endDate",
		min: dayjs(startDate),
		step: 60,
		col: 1,
		required: true,
		clamp: true,
		clampKey: "startDate"
	},
	// {
	//   type: "number",
	//   id: "event_places",
	//   key: "availablePlaces",
	//   min: 0,
	//   col: 1,
	// },
	{
		type: "images",
		id: "event_poster",
		key: "medias",
		col: 1,
		max_image: FORM_MAX_IMAGES,
		image_type: 1
	},
	{
		type: "images",
		id: "event_banner",
		key: "medias",
		col: 1,
		max_image: FORM_MAX_BANNERS,
		image_type: 2
	},
	{
		type: "text",
		id: "event_address",
		key: "address",
		col: 1,
		required: true
	},
	{
		type: "text",
		id: "event_postal_code",
		key: "postalCode",
		col: 1,
		required: true
	},
	{
		type: "text",
		id: "event_city",
		key: "city",
		col: 1,
		required: true
	},
	{
		type: "select",
		id: "event_country",
		key: "country",
		col: 1,
		required: true,
		options: { key: "code", label: "name", lang: true, values: countriesList }
	},
	{
		type: "textarea",
		id: "event_short_description",
		key: "shortDescription",
		max: 190,
		col: 2,
		required: true
	},
	{
		type: "quill",
		id: "event_long_description",
		key: "longDescription",
		col: 2
	},
	{
		type: "rules",
		id: "event_rules",
		key: "organizingRules",
		fileKey: "ruleFile",
		col: 2
	}
];
