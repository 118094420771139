import { useContext, useEffect, useState } from "react";
import { AiFillWarning, AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { API } from "../api/APIClient";
import Logo from "../assets/logo_solo.png";
import LangToggle from "../components/common/LangToggle";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";
import { classNames } from "../utils/Classes";
import { REGEXP } from "../utils/RegExp";
import { handleReturnKey } from "../utils/ReturnKey";
import Toast from "../utils/Toasts";

function ForgottenPassowrd() {
	const { lang } = useContext(AppContext);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [mailNotFound, setMailNotFound] = useState(false);
	const [isMailValid, setIsMailValid] = useState(true);
	const debouncedEmail = useDebounce<string>(email, 500);

	const onSubmit = async () => {
		setLoading(true);

		let success = false;

		try {
			const { data } = await API.post("/auth/orga/sendForgottenPasswordMail", {
				email: email
			});
			Toast.success(trad[lang].send_forgotten_mail_success);
			navigate("/login");
			setMailNotFound(false);
		} catch (e: any) {
			if (e.response?.status === 404) {
				setMailNotFound(true);
			}
			Toast.error(trad[lang].sendMailError);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (REGEXP.mail.test(debouncedEmail) || debouncedEmail === "")
			setIsMailValid(true);
		else setIsMailValid(false);
	}, [debouncedEmail]);

	return (
		<div className="flex h-screen flex-col items-center justify-center">
			{/* Logo */}
			<Link to="/" className="text-primary">
				<div className="flex flex-row items-center gap-1">
					<img src={Logo} alt="" />
					<h1 className="text-2xl font-bold text-primary">Chronometrage.com</h1>
				</div>
			</Link>

			{/* Title */}
			<h2 className="text-center font-bold">{trad[lang].forgotten_title}</h2>

			{/* Form */}
			<div className="mt-8 flex w-4/5 flex-col gap-4 sm:w-[480px]">
				{/* Email */}
				<div>
					<label
						htmlFor="email"
						className="block text-sm font-medium text-gray-700"
					>
						{trad[lang].email}
					</label>
					<div className="mt-1">
						<input
							id="email"
							name="email"
							type="email"
							autoComplete="email"
							required
							className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							onKeyDown={(e) => handleReturnKey(e, onSubmit)}
						/>
					</div>
					{mailNotFound && (
						<div className="mt-2 flex flex-row">
							<AiFillWarning className="h-6 w-6 text-red-500" />
							<label className="mt-0.5 ml-2 block text-sm font-medium text-red-500">
								Email introuvable
								{/* {trad[lang].email} */}
							</label>
						</div>
					)}
					{isMailValid === false && (
						<div className="mt-2 flex flex-row">
							<AiFillWarning className="h-6 w-6 text-red-500" />
							<label className="mt-0.5 ml-2 block text-sm font-medium text-red-500">
								Email invalide
								{/* {trad[lang].email} */}
							</label>
						</div>
					)}
				</div>

				{/* Submit */}
				<div>
					<button
						type="button"
						className={classNames(
							"flex w-full items-center justify-center rounded-md border  border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm duration-150 focus:outline-none focus:ring-0 focus:ring-offset-0",
							"disabled:opacity-50",
							loading ? "bg-gray-700" : "bg-primary hover:bg-primarymedium"
						)}
						onClick={onSubmit}
						disabled={loading || !isMailValid || email === ""}
					>
						{loading ? (
							<>
								{trad[lang].loading}
								<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
							</>
						) : (
							trad[lang].reset_password
						)}
					</button>
				</div>

				<div>
					<div className="flex flex-col text-center">
						<Link to="/" className="mb-2 text-primary">
							{trad[lang].back_to_home}
						</Link>
					</div>
				</div>

				{/* Lang Toggle */}
				<div className="flex w-full flex-row justify-center px-4">
					<LangToggle />
				</div>
			</div>
		</div>
	);
}

export default ForgottenPassowrd;
