import { IoMdArrowRoundDown, IoMdArrowRoundUp } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { useContext } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import trad from "../../../lang/traduction";
import { IRunForm } from "../../../types/EventForms/ISportRun";
import { API } from "../../../api/APIClient";
import Toast from "../../../utils/Toasts";

function SportRunSwitchOrderButton({
	run,
	runs,
	refetch,
	setIsDisplayOrderUpdated
}: {
	run: IRunForm;
	runs: IRunForm[];
	refetch: any;
	setIsDisplayOrderUpdated: (isDisplayOrderUpdated: boolean) => void;
}) {
	const { lang } = useContext(AppContext);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const { slug } = useParams();

	const toggleDropdown = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	const isFirst = () => {
		// Find the smallest displayOrder id in the array
		const smallestDisplayOrder = Math.min(
			...runs.map((run) => run.displayOrder)
		);
		return run.displayOrder === smallestDisplayOrder;
	};

	const isLast = () => {
		// Find the biggest displayOrder id in the array
		const biggestDisplayOrder = Math.max(
			...runs.map((run) => run.displayOrder)
		);
		return run.displayOrder === biggestDisplayOrder;
	};

	const moveUp = (run: IRunForm, runs: IRunForm[], atTop: boolean) => {
		// Find all runs with a displayOrder smaller than the current run
		const runsBefore = runs.filter(
			(runBefore) => runBefore.displayOrder < run.displayOrder
		);

		// Find the run with the biggest displayOrder in the runsBefore array (or smallest if atTop)
		let runBefore: IRunForm;
		if (atTop)
			runBefore = runsBefore.reduce((prev, current) =>
				prev.displayOrder < current.displayOrder ? prev : current
			);
		else
			runBefore = runsBefore.reduce((prev, current) =>
				prev.displayOrder > current.displayOrder ? prev : current
			);

		// Swap
		swapRunsOrder(runBefore, run);
	};

	const moveDown = (run: IRunForm, runs: IRunForm[], atBottom: boolean) => {
		// Find all runs with a displayOrder bigger than the current run
		const runsAfter = runs.filter(
			(runAfter) => runAfter.displayOrder > run.displayOrder
		);

		// Find the run with the smallest displayOrder in the runsAfter array

		let runAfter: IRunForm;
		if (atBottom)
			runAfter = runsAfter.reduce((prev, current) =>
				prev.displayOrder > current.displayOrder ? prev : current
			);
		else
			runAfter = runsAfter.reduce((prev, current) =>
				prev.displayOrder < current.displayOrder ? prev : current
			);

		// Swap
		swapRunsOrder(runAfter, run);
	};

	const swapRunsOrder = async (run1: IRunForm, run2: IRunForm) => {
		// Swap the displayOrder of the two runs
		const cpy = run1.displayOrder;
		run1.displayOrder = run2.displayOrder;
		run2.displayOrder = cpy;

		cleanDisplayOrder();

		try {
			// Update all runs display order
			let body: any = { list: [] };
			for (let run of runs)
				body.list.push({
					idProduit: run.id_produit,
					displayOrder: run.displayOrder,
					name: run.name
				});
			const { data } = await API.patch(
				`/event/slug/${slug}/updateRunsDisplayOrder`,
				body
			);
			Toast.success(trad[lang].updateSuccess);
		} catch (err) {
			Toast.error(trad[lang].updateError);
			// Revert copy
			run2.displayOrder = run1.displayOrder;
			run1.displayOrder = cpy;
			cleanDisplayOrder();
		}

		setIsDisplayOrderUpdated(true);
		setIsComponentVisible(false);
	};

	const cleanDisplayOrder = async () => {
		// Update displayOrder of runs, starting from displayOrder 1
		let displayOrder = 1;
		runs.sort((a, b) => a.displayOrder - b.displayOrder);
		for (let run of runs) run.displayOrder = displayOrder++;
	};

	return (
		<div ref={ref} className="relative">
			<button
				id="dropdownDividerButton"
				onClick={toggleDropdown}
				// disabled={isLoadingRuns}
				className="flex h-[48px] cursor-pointer flex-row items-center gap-1 rounded-md border border-primary bg-white py-2 px-2 text-xs text-primary duration-150 hover:bg-primary hover:text-white md:uppercase"
				type="button"
			>
				{trad[lang].displayOrder.toUpperCase()}
				<HiOutlineChevronDown className="ml-2 h-[18px] w-[18px]" />
			</button>

			{isComponentVisible && (
				<div
					id="dropdownDivider"
					className="absolute divide-y divide-gray-100 whitespace-nowrap rounded-lg bg-white shadow"
				>
					<ul className="py-2 text-sm" aria-labelledby="dropdownDividerButton">
						<li className="mb-1">
							<button
								onClick={() => {
									moveUp(run, runs, true);
								}}
								className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
								disabled={isFirst()}
							>
								<IoMdArrowRoundUp size="16" />
								<span className="px-2 uppercase">{trad[lang].moveTop}</span>
							</button>
						</li>
						<li className="mb-1">
							<button
								onClick={() => {
									moveUp(run, runs, false);
								}}
								className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
								disabled={isFirst()}
							>
								<IoMdArrowRoundUp size="16" />
								<span className="px-2 uppercase">{trad[lang].moveAbove}</span>
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									moveDown(run, runs, false);
								}}
								className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
								disabled={isLast()}
							>
								<IoMdArrowRoundDown size="16" />
								<span className="px-2 uppercase">{trad[lang].moveBelow}</span>
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									moveDown(run, runs, true);
								}}
								className="flex w-[100%] flex-row px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 disabled:opacity-30"
								disabled={isLast()}
							>
								<IoMdArrowRoundDown size="16" />
								<span className="px-2 uppercase">{trad[lang].moveBottom}</span>
							</button>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
}

export default SportRunSwitchOrderButton;
