interface DateFieldProps {
	label: string;
	value: string;
	id?: string | number;
	error?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const DateField = ({
	label,
	value,
	error,
	onChange
}: DateFieldProps): JSX.Element => {
	return (
		<div className="w-full pt-1">
			<label
				className={`block text-left text-sm font-medium${
					error ? "text-red-500" : "text-gray-700"
				}`}
			>
				{label}
			</label>
			<div className="relative flex flex-grow items-stretch focus-within:z-10">
				<input
					type="date"
					className={`
            ${
							error
								? "border-red-500 fill-red-500 text-red-500 focus:ring-red-500"
								: "border-gray-300 focus:ring-primarydark"
						}
            "w-full focus:ring-2" rounded-md border px-4 text-sm focus:border-transparent focus:outline-none
          `}
					max={
						new Date(new Date().setFullYear(new Date().getFullYear() - 3))
							.toISOString()
							.split("T")[0]
					}
					defaultValue={value}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};
export default DateField;
