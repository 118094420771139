import { useCallback, useContext, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useParams
} from "react-router-dom";
import {
	deleteLiveConfigPoint,
	postLiveConfigPoint,
	resetPoint
} from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IDetectionKeys } from "../../types/LiveForm";
import Toast from "../../utils/Toasts";

const BASE_POINT = {
	id: "",
	name: { value: "", error: false },
	ligne: { value: "", error: false },
	distance: { value: "", error: false },
	type: { value: 0, error: false },
	nombrePassageMin: { value: 0, error: false },
	nombrePassageMax: { value: 1, error: false },
	latitude: { value: 0, error: false },
	longitude: { value: 0, error: false },
	radius: { value: 60, error: false },
	handicap: { value: "00:00:00", error: false },
	tempsInterpassage: { value: 60, error: false }
};

const LiveConfigurationPoints = ({
	points,
	refetchPoints,
	refetchSegments
}: {
	points: any;
	refetchPoints: any;
	refetchSegments: any;
}) => {
	const { slug, idcalendrier } = useParams();
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const [openedPoint, setOpenedPoint] = useState(BASE_POINT);
	const [loading, setLoading] = useState(false);
	const [duplicatedLine, setDuplicatedLine] = useState(false);
	const [resetPointOnRunner, setResetPointOnRunner] = useState({
		line: 0,
		id: 0
	});
	const [resetForm, setResetForm] = useState({ bib: 0, time: "", type: 0 });

	const verifiedPoints = points.map((item: any) => {
		return item;
	});

	const isDuplicateLine = () => {
		const isDuplicate = verifiedPoints.some(
			(ligne: any) =>
				ligne.id != openedPoint.id && ligne.ligne == openedPoint.ligne.value
		);
		return isDuplicate;
	};

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const onClickClose = function () {
		if (unsavedChangesRef.current) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) {
				setOpenedPoint(BASE_POINT);
				unsavedChangesRef.current = false;
			}
		} else {
			setOpenedPoint(BASE_POINT);
		}
	};

	const detectionValidation: {
		key: IDetectionKeys;
		hasError: (value: any) => boolean;
	}[] = [
		{
			key: "name",
			hasError: (value: any) => !value
		},
		{
			key: "ligne",
			hasError: (value: any) => !value || isDuplicateLine()
		},
		{ key: "distance", hasError: (value: any) => value < 0 },
		{
			key: "type",
			hasError: (value: any) => !value
		}
	];

	const addDetecteur = () => setOpenedPoint({ ...BASE_POINT, id: "new" });

	const setDetectionValue = (key: keyof typeof openedPoint, value: any) => {
		unsavedChangesRef.current = true;
		if (key === "id") {
			setOpenedPoint((old) => ({ ...old, [key]: value }));
			return;
		}

		const updatedPoint = { ...openedPoint, [key]: { value, error: false } };
		setOpenedPoint(updatedPoint);

		if (key === "ligne" && isDuplicateLine()) {
			setDetectionError(key, true);
			setDuplicatedLine(true);
		} else {
			setDetectionError(key, false);
		}
	};

	const setDetectionError = (key: keyof typeof openedPoint, value: boolean) => {
		if (key == "id") {
			return;
		}

		setOpenedPoint((old) => {
			return { ...old, [key]: { ...old[key], error: value } };
		});
	};

	const openPoint = (point: any) =>
		setOpenedPoint({
			id: point.id,
			name: { value: point.name, error: false },
			ligne: { value: point.ligne, error: false },
			distance: { value: point.distance, error: false },
			type: { value: point.type, error: false },
			nombrePassageMin: { value: point.nombrePassageMin, error: false },
			nombrePassageMax: { value: point.nombrePassageMax, error: false },
			latitude: { value: point.latitude, error: false },
			longitude: { value: point.longitude, error: false },
			radius: { value: point.radius, error: false },
			handicap: { value: point.handicap, error: false },
			tempsInterpassage: { value: point.tempsInterpassage, error: false }
		});

	const deletePoint = async (id: number) => {
		try {
			if (!slug || !idcalendrier) return;

			await deleteLiveConfigPoint(slug, idcalendrier, id);
			Toast.success(trad[lang].deleteConfigPointSuccess);

			await refetchPoints();
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].deleteConfigPointError);
		}
	};

	const submitPoint = async () => {
		try {
			if (!slug || !idcalendrier) return;
			let error = false;

			for (const item of detectionValidation) {
				const hasError = item.hasError(openedPoint[item.key].value);

				if (hasError) {
					error = true;
					setDetectionError(item.key, true);
				}
			}

			if (error) {
				Toast.error(trad[lang].saveConfigPointError);
				throw error;
			}

			setLoading(true);
			await postLiveConfigPoint(slug, idcalendrier, {
				id: openedPoint.id,
				name: openedPoint.name.value,
				ligne: openedPoint.ligne.value,
				distance: openedPoint.distance.value,
				type: openedPoint.type.value,
				nombrePassageMin: openedPoint.nombrePassageMin.value,
				nombrePassageMax: openedPoint.nombrePassageMax.value,
				latitude: openedPoint.latitude.value,
				longitude: openedPoint.longitude.value,
				radius: openedPoint.radius.value,
				handicap: openedPoint.handicap.value,
				tempsInterpassage: openedPoint.tempsInterpassage.value
			});
			Toast.success(trad[lang].saveConfigPointSuccess);

			unsavedChangesRef.current = false;

			// await generateSegments(idcalendrier);

			await refetchPoints();
			await refetchSegments();
			setLoading(false);

			setOpenedPoint(BASE_POINT);
		} catch (error) {
			Toast.error(trad[lang].saveConfigPointError);
			setLoading(false);
			console.error(error);
		}
	};

	const handleResetOnRunner = async () => {
		try {
			if (!slug || !idcalendrier) return;
			setLoading(true);

			await resetPoint(slug, idcalendrier, resetPointOnRunner.id, {
				...resetForm,
				idCalendrier: parseInt(idcalendrier)
			});

			setResetPointOnRunner({ id: 0, line: 0 });
		} catch (error) {
			Toast.error(trad[lang].error_boundary_title);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="grid w-full grid-cols-2 gap-5 border-b-2 border-gray-200 p-5 transition-opacity">
				<div className="col-span-2 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].calendarRunDetectionPoints}</p>
					<div className="grid grid-cols-1 gap-5 lg:grid-cols-2 2lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
						{points.map((item: any) => (
							<div key={item.id}>
								<div
									className="group relative col-span-1 mb-2 flex h-36 flex-col justify-between rounded-md p-4 shadow-md transition"
									key={item.id}
								>
									<p className="text-lg font-bold">
										{item.name} -{" "}
										<span className="text-sm font-normal">
											{trad[lang][`typeLine${item.type as 1 | 2 | 3}`]}
										</span>
									</p>
									<p className="font-normal">
										{trad[lang].line} : {item.ligne}
									</p>
									<p className="font-normal">
										{trad[lang].distance} : {item.distance}
									</p>
									<p className="font-normal">
										{trad[lang].interpassTime} : {item.tempsInterpassage}s
									</p>

									<div className="absolute -top-2 -right-2 flex flex-row gap-2 md:hidden md:group-hover:flex">
										<button
											className="rounded-md bg-primary p-2 shadow-sm hover:bg-primarymedium"
											onClick={() => openPoint(item)}
										>
											<AiOutlineEdit size={18} color="#ffffff" />
										</button>

										<button
											className="rounded-md bg-red-600 p-2 shadow-sm hover:bg-red-700"
											onClick={() => deletePoint(item.id)}
										>
											<AiOutlineDelete size={18} color="#ffffff" />
										</button>
									</div>
								</div>
								{item.type != 1 && (
									<button
										className="h-10 w-full rounded bg-primary text-white shadow-md transition-colors hover:bg-primarymedium"
										onClick={() =>
											setResetPointOnRunner({ id: item.id, line: item.ligne })
										}
									>
										{trad[lang].resetOnRunner}
									</button>
								)}
							</div>
						))}

						<button
							onClick={addDetecteur}
							className="col-span-1 flex h-36 flex-col items-center justify-center rounded-md bg-gray-400 text-white transition hover:bg-gray-500"
						>
							<AiOutlinePlus size={40} />
							{trad[lang].calendarRunDetectionAddPoint}
						</button>
					</div>
				</div>
			</div>

			{openedPoint.id != "" && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">
								{trad[lang].calendarRunDetectionConfigurePoint}
							</h2>
						</div>

						<div className="grid grid-cols-2 gap-5 p-5">
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].name}</p>

								<input
									type="string"
									value={openedPoint.name.value}
									onChange={(e) => setDetectionValue("name", e.target.value)}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>

								{openedPoint.name.error && (
									<p className="rounded-md bg-red-50 p-3 text-red-600">
										{duplicatedLine
											? "Cette Ligne existe déjà"
											: trad[lang].runFormPointNameError}
									</p>
								)}
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].distance}</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.distance.value}
									onChange={(e) =>
										setDetectionValue("distance", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>

								{openedPoint.distance.error && (
									<p className="rounded-md bg-red-50 p-3 text-red-600">
										{trad[lang].runFormPointDistanceError}
									</p>
								)}
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].line}</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.ligne.value}
									onChange={(e) => setDetectionValue("ligne", e.target.value)}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>

								{openedPoint.ligne.error && (
									<p className="rounded-md bg-red-50 p-3 text-red-600">
										{duplicatedLine
											? "Cette ligne existe déjà"
											: trad[lang].runFormPointLineError}
									</p>
								)}
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">
									{trad[lang].calendarRunDetectionType}
								</p>

								<select
									value={openedPoint.type.value}
									onChange={(e) =>
										setDetectionValue("type", parseInt(e.target.value))
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								>
									<option value={0}>
										{trad[lang].calendarRunDetectionChooseType}
									</option>
									<option value={1}>{trad[lang].start_line}</option>
									<option value={2}>{trad[lang].intermediary}</option>
									<option value={3}>{trad[lang].end_line}</option>
								</select>

								{openedPoint.type.error && (
									<p className="rounded-md bg-red-50 p-3 text-red-600">
										{trad[lang].runFormPointTypeError}
									</p>
								)}
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].interpassTime} (s)</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.tempsInterpassage.value}
									onChange={(e) =>
										setDetectionValue("tempsInterpassage", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].handicap}</p>

								<input
									type="time"
									step={0.1}
									value={openedPoint.handicap.value}
									onChange={(e) =>
										setDetectionValue("handicap", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							{openedPoint.type.value == 2 && (
								<>
									<div className="col-span-1 flex flex-col gap-2">
										<p className="font-bold">{trad[lang].minNumberPass}</p>

										<input
											type="number"
											step={1}
											min={0}
											value={openedPoint.nombrePassageMin.value}
											onChange={(e) =>
												setDetectionValue("nombrePassageMin", e.target.value)
											}
											className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
										/>
									</div>
									<div className="col-span-1 flex flex-col gap-2">
										<p className="font-bold">{trad[lang].maxNumberPass}</p>

										<input
											type="number"
											step={1}
											min={0}
											value={openedPoint.nombrePassageMax.value}
											onChange={(e) =>
												setDetectionValue("nombrePassageMax", e.target.value)
											}
											className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
										/>
									</div>
								</>
							)}
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].latitude}</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.latitude.value}
									onChange={(e) =>
										setDetectionValue("latitude", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].longitude}</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.longitude.value}
									onChange={(e) =>
										setDetectionValue("longitude", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].radius}</p>

								<input
									type="number"
									step={1}
									min={0}
									value={openedPoint.radius.value}
									onChange={(e) => setDetectionValue("radius", e.target.value)}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
								onClick={() => onClickClose()}
							>
								{trad[lang].close}
							</button>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
								onClick={submitPoint}
							>
								{trad[lang].validate}
							</button>
						</div>
					</div>
				</div>
			)}

			{resetPointOnRunner.id > 0 && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">{`${trad[lang].resetOnRunner} - ${trad[lang].line} ${resetPointOnRunner.line}`}</h2>
						</div>

						<div className="grid grid-cols-2 gap-5 p-5">
							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].bib}</p>

								<input
									type="number"
									value={resetForm.bib}
									min={0}
									step={1}
									onChange={(e) =>
										setResetForm((old) => ({
											...old,
											bib: parseInt(e.target.value)
										}))
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].bibTime}</p>

								<input
									type="time"
									step={0.1}
									value={resetForm.time}
									onChange={(e) =>
										setResetForm((old) => ({ ...old, time: e.target.value }))
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
								onClick={() => setResetPointOnRunner({ line: 0, id: 0 })}
							>
								{trad[lang].close}
							</button>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
								onClick={handleResetOnRunner}
							>
								{trad[lang].validate}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LiveConfigurationPoints;
