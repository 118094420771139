import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const EventRib = ({ rib }: { rib: any }) => {
	const { lang } = useContext(AppContext);

	return (
		<div className="flex h-full flex-col gap-3 rounded-md border border-gray-300 bg-white p-3 shadow-md">
			<p>{rib.designation}</p>
			<p>{rib.nomBanque}</p>
			<p>
				<span>{`${trad[lang].iban_label} : `}</span>
				{rib.iban}
			</p>
			<p>
				<span>{`${trad[lang].bic_label} : `}</span>
				{rib.bic}
			</p>
		</div>
	);
};

export default EventRib;
