import { useEffect } from "react";

const useScript = (url: string, dependancies: any[]) => {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = url;
		script.async = true;
		script.defer = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [url, ...dependancies]);
};

export default useScript;
