import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import { API } from "../../api/APIClient";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { formatLikeISO } from "../../utils/DateFormater";
import InsertDetection from "./InsertDetection";

const ManageDetectionFilter = ({
	setSearchQuery,
	setPage,
	detector,
	setDetector,
	refetch,
	credential,
	all,
	setAll,
	data,
	setRaces,
	races,
	idCalendrier,
	timezone
}: {
	setSearchQuery: Dispatch<SetStateAction<string>>;
	setPage: Dispatch<SetStateAction<number>>;
	detector: number | null;
	setDetector: Dispatch<SetStateAction<number>>;
	refetch: any;
	credential: string;
	all: boolean;
	setAll: Dispatch<SetStateAction<boolean>>;
	data: any;
	setRaces: Dispatch<SetStateAction<any>>;
	races: any;
	idCalendrier: number;
	timezone: string;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [openedInsert, setOpenedInsert] = useState(false);
	const [uploading, setUploading] = useState({
		open: false,
		uploading: false,
		current_time: 0,
		numbers_upload: 0,
		length: 0,
		total: 0
	});
	const inputFileRef = useRef<any>();
	const [abortController, setAbortController] = useState<any>();

	const handleDagFile = async (e: any) => {
		try {
			const file = e.target.files[0];

			const file_text = await file.text();

			const lines = file_text.split("\r\n").filter((item: string) => item);
			setUploading((old) => ({
				...old,
				length: lines.length,
				uploading: true,
				open: true
			}));

			const chunks = [];
			const chunkSize = 200;

			for (let i = 0; i < lines.length; i += chunkSize) {
				chunks.push(lines.slice(i, i + chunkSize));
			}

			const date_started = new Date().getTime();

			const abortSignal = new AbortController();
			setAbortController(abortSignal);

			for (const chunk of chunks) {
				const { data } = await API.post(
					`/live/detections/${slug}/import`,
					chunk,
					{
						signal: abortSignal.signal
					}
				);

				let current = new Date().getTime();

				setUploading((old) => ({
					...old,
					current_time: current - date_started,
					numbers_upload: old.numbers_upload + 1,
					total: old.total + data.total
				}));
			}

			inputFileRef.current.value = "";
		} catch (error) {
			console.error("[handleDagFile error]", error);
		} finally {
			setUploading((old) => ({ ...old, uploading: false }));
		}
	};

	const resetUploading = () => {
		if (abortController) {
			abortController.abort();
		}

		inputFileRef.current.value = "";

		setUploading({
			open: false,
			uploading: false,
			current_time: 0,
			numbers_upload: 0,
			length: 0,
			total: 0
		});
	};

	const handleAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setDetector(0);
		setRaces([0]);
	};

	return (
		<>
			<div className="mb-4 flex w-full flex-col items-center gap-4 lg:flex-row">
				<div className="relative mt-4 rounded-md border border-gray-300 py-2 shadow-sm md:mt-0 md:w-80 md:max-w-xs">
					<label
						htmlFor="search"
						className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500"
					>
						{trad[lang].search}
					</label>

					<div className="relative mt-1 rounded-md">
						<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
							<HiOutlineMagnifyingGlass
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</div>

						<input
							type="text"
							name="search"
							id="search"
							className="block w-full rounded-md border-transparent py-0 pl-10 text-sm focus:border-transparent focus:ring-0"
							onChange={(e) => {
								setSearchQuery(e.target.value);
								setPage(1);
							}}
							placeholder={trad[lang].search_bib}
						/>
					</div>
				</div>

				<select
					name="detectors"
					id="detectors"
					className="appearance-none rounded-md border-gray-300 py-2 pr-10 placeholder-gray-400 focus:border-primary  focus:outline-none focus:ring-0 sm:text-sm"
					value={detector || ""}
					onChange={(e) => setDetector(parseInt(e.target.value))}
				>
					<option value={0} key={0}>
						{trad[lang].choose_detectors}
					</option>
					{data
						.map((item: any) => item.pointages)
						.flat(1)
						.map((detector: any) => (
							<option value={detector.id} key={detector.id}>
								{detector.name}
							</option>
						))}
				</select>

				<select
					name="races"
					id="races"
					className="appearance-none rounded-md border-gray-300 py-2 pr-10 placeholder-gray-400 focus:border-primary  focus:outline-none focus:ring-0 sm:text-sm"
					value={races || 0}
					onChange={(e) => setRaces([parseInt(e.target.value)])}
				>
					<option value={0} key={0}>
						{trad[lang].races}
					</option>
					{data?.map((race: any) => (
						<option value={race.id} key={race.id}>
							{race.nom}
						</option>
					))}
				</select>

				<button
					name="all"
					id="all"
					className="rounded-md border border-primary py-2 px-5 text-center text-primary hover:bg-primary hover:text-white sm:text-sm"
					defaultValue={0}
					onClick={handleAllClick}
					value={0}
				>
					Tous les résultats
				</button>

				<InsertDetection
					openedInsert={openedInsert}
					setOpenedInsert={setOpenedInsert}
					refetch={refetch}
					credential={credential}
				/>

				<div>
					<label
						htmlFor="DagFile"
						className="flex h-10 cursor-pointer items-center justify-center rounded-md bg-green-500 py-2 px-3 text-white transition-colors hover:bg-green-700 lg:py-0"
					>
						{trad[lang].upload_dag}
					</label>

					<input
						type="file"
						id="DagFile"
						name="DagFile"
						className="hidden"
						ref={inputFileRef}
						onChange={handleDagFile}
						accept=".dag"
					/>
				</div>
			</div>
			<div className="mb-4 flex w-full items-center justify-start gap-2">
				<input
					type="checkbox"
					name="all"
					id="all"
					checked={all}
					onChange={() => setAll((old) => !old)}
					className="rounded-sm"
				/>

				<label htmlFor="all" className="select-none">
					{trad[lang].seeAllDetections}
				</label>
			</div>

			{uploading.open && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].upload_dag}
							</h3>
						</div>

						<div className="flex flex-col gap-6 p-6">
							<div className="flex items-center justify-center">
								{uploading.uploading ? (
									<AiOutlineLoading3Quarters
										size={35}
										className="animate-spin"
									/>
								) : (
									<AiOutlineCheck size={35} />
								)}
							</div>

							<h2 className="w-full text-center text-xl">{`${trad[lang].insertion} ${uploading.total} / ${uploading.length}`}</h2>
							<div>
								<p className="text-md w-full text-center">
									{`${trad[lang].estimation} : ${(
										((uploading.current_time / uploading.total) *
											(uploading.length - uploading.total)) /
											1000 || 0
									).toFixed(2)} ${trad[lang].seconds}`}
								</p>
								<p className="text-md w-full text-center">
									{`${trad[lang].current_time} : ${(
										uploading.current_time / 1000
									).toFixed(2)} ${trad[lang].seconds}`}
								</p>
							</div>
						</div>

						<div className="flex justify-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={resetUploading}
							>
								{uploading.uploading ? trad[lang].interrupt : trad[lang].close}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ManageDetectionFilter;
