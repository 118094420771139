import { useContext } from "react";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import Layout from "../components/navigation/Layout";
import { PatchNotes } from "../config/PatchNotes";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const Version = () => {
	const { lang } = useContext(AppContext);

	return (
		<Layout active_key="none">
			<div className="relative flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				{/* Title */}
				<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
					{trad[lang].patchNotes}
				</h1>

				{PatchNotes(lang).map((item, index) => (
					<div className="w-2/3" key={index}>
						<h2 className="pb-2 pt-5 text-2xl font-semibold">{`Version ${item.version}`}</h2>
						<ul className="ml-8 list-disc">
							{item.features.map((feature, index) => (
								<li key={index}>{feature}</li>
							))}
						</ul>
					</div>
				))}
			</div>

			<BottomBarNavigation go_to="/" />
		</Layout>
	);
};

export default Version;
