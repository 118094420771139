import { useContext } from "react";
import { Link } from "react-router-dom";
import { publishStatus } from "../../api/admin";
import NO_POSTER from "../../assets/not-found.png";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { IConfirmationModalConfig } from "../../types/Modals";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";
import PublishToggle from "../common/PublishToggle";

function DashboardCard({
	event,
	setConfirmModalConfig,
	refetch
}: {
	event: any;
	setConfirmModalConfig: any;
	refetch: () => any;
}) {
	const { lang } = useContext(AppContext);
	const { userIsAdmin } = useContext(UserContext);

	// Display publish modal when user click on publish toggle
	const handlePublishModal = (e: any) => {
		e.preventDefault();
		// Only admin can publish/unpublish events
		if (!userIsAdmin) return;
		setConfirmModalConfig({
			setter: setConfirmModalConfig,
			opened: true,
			title: event.is_active
				? trad[lang].unpublishEvent
				: trad[lang].publishEvent,
			description: event.is_active
				? trad[lang].unpublishEventWarning
				: trad[lang].publishEventWarning,
			onConfirm: onConfirmPublish,
			data: { status: !event.is_active }
		});
	};

	const onConfirmPublish = async function (config: IConfirmationModalConfig) {
		try {
			await publishStatus(event.slug, config.data.status);
			refetch();
			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].updateError);
		}
	};

	return (
		<div className="flex w-full max-w-[1024px] flex-col gap-2 rounded-md sm:flex-row md:h-[180px] md:gap-4">
			{/* Event poster */}
			<Link
				className="h-[180px] w-full flex-none rounded-md object-cover shadow-md sm:h-[180px] sm:w-[130px]"
				to={`/${event.slug}/event-details`}
			>
				<img
					className="h-full w-full flex-none rounded-md object-cover shadow-md"
					src={
						event.medias?.length > 0
							? event.medias.find((img: any) => img.image_type == 1)?.url ||
							  event.medias[0]?.url
							: NO_POSTER
					}
					alt={event.name}
				/>
			</Link>

			{/* Event infos */}
			<div className="flex w-full flex-col">
				{/* Card heading */}
				<div className="flex w-full flex-row items-center justify-between">
					{/* Event name */}
					<Link to={`/${event.slug}/event-details`}>
						<h4 className="text-base font-semibold">{event.name}</h4>
					</Link>

					{/* Actions */}
					{userIsAdmin && (
						<div className="flex flex-row items-center">
							<span
								className={classNames(
									event.is_active
										? "text-success md:bg-green-100"
										: "text-warning md:bg-red-100",
									"flex flex-row items-center gap-2 rounded-md text-xs md:py-2 md:px-3 md:uppercase"
								)}
								onClick={handlePublishModal}
							>
								<PublishToggle enabled={event.is_active} />
								{event.is_active
									? trad[lang].published
									: trad[lang].unpublished}
							</span>
						</div>
					)}
				</div>

				{/* Event date */}
				<div className="mt-2 text-xs italic md:mt-0 md:text-sm">
					{new Date(event.start_date).toLocaleDateString("fr-FR", {
						day: "numeric",
						month: "long",
						year: "numeric",
						timeZone: event.timezone
					})}
				</div>

				{/* Registrations row */}
				<div className="mt-2 flex flex-row items-center justify-between text-sm md:mt-5 md:w-2/3 md:text-base">
					{/* Registered */}
					<Link to={`/${event.slug}/registrations-details`}>
						<span className="font-medium">
							<span className="text-primary">{event.subscriptions || 0}</span>{" "}
							{trad[lang].registered}
						</span>
					</Link>
					{/* To validate */}
					<Link
						to={`/${event.slug}/registrations-details`}
						state={{ status: "0" }}
					>
						<span className="font-medium">
							<span className="font-medium text-primary">
								{event.subscription_to_validate || 0}
							</span>{" "}
							{trad[lang].toValidate}
						</span>
					</Link>
				</div>

				{/* Additional row */}
				<div className="mt-2 flex flex-col text-sm md:w-2/3 md:flex-row md:items-center md:justify-between md:text-base">
					{/* Turnover amount */}
					{/* <span className="font-medium">
						<span className="text-primary">{total_collected}</span> €{" "}
						{trad[lang].ofTurnover}
					</span> */}
					{/* Manager */}
					<span className="mt-2 font-medium md:mt-0">{event.nomPersonne}</span>
				</div>
			</div>

			{/* //TODO: EDIT MODAL */}
		</div>
	);
}

export default DashboardCard;
