import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventParcoursConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import SportDeleteParcoursModal from "../components/event_configuration/Sport/SportDeleteParcoursModal";
import SportParcoursModal from "../components/event_configuration/Sport/SportParcoursModal";
import EventParcoursModule from "../components/liveResults/EventParcoursModule";
import Layout from "../components/navigation/Layout";
import { BASE_PARCOURS } from "../config/EventForms/Sport/SportStep";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const LiveParcoursConfig = () => {
	const { lang } = useContext(AppContext);
	const { slug, idetape, route } = useParams();
	const [parcoursState, setParcoursState] = useState([]);
	const [openedParcours, setOpenedParcours] = useState(null);
	const [deleteParcours, setDeleteParcours] = useState<{
		id: number;
		title: string;
	} | null>(null);

	const { data, isLoading, refetch } = useQuery({
		queryKey: ["parcours", slug, route, idetape],
		queryFn: () =>
			getEventParcoursConfig(
				slug as string,
				route as string,
				idetape as string
			),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		enabled: !!slug && !!route && !!idetape
	});

	const openParcours = (parcours: any) =>
		setOpenedParcours(parcours || BASE_PARCOURS);

	const closeModal = () => setOpenedParcours(null);

	const handleConfirmDeleteParcours = (id: number, title: string) =>
		setDeleteParcours({ id, title });

	useEffect(() => {
		if (data) {
			setParcoursState(data);
		}
	}, [data]);

	if (isLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout active_key="live">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{ key: "live_results", to: "/live", active: false },
						{
							key: "event",
							to: `/${slug}/live`,
							active: false
						},
						{
							key: "manage_steps",
							to: `/${slug}/manage-routes/${route}`,
							active: false
						},
						{
							key: "manage_parcours",
							to: "#",
							active: true
						},
						{
							key: "manage_segments",
							to: "#",
							active: false
						}
					]}
				/>

				<h1 className="mb-6 mt-24 text-2xl font-bold text-gloom md:mb-8 md:mt-16 md:text-3xl">
					{trad[lang].sport_event_parcours}
				</h1>

				{parcoursState.map((parcours: any) => (
					<EventParcoursModule
						parcours={parcours}
						key={parcours.id}
						openParcours={openParcours}
						deleteParcours={handleConfirmDeleteParcours}
					/>
				))}

				<div className="my-6 flex w-full">
					<button
						className="ml-8 flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={openParcours}
					>
						{trad[lang].create_parcours}
					</button>
				</div>

				{openedParcours && (
					<SportParcoursModal
						parcours={openedParcours}
						closeModal={closeModal}
						refetch={refetch}
					/>
				)}

				{deleteParcours && (
					<SportDeleteParcoursModal
						parcours={deleteParcours}
						setConfirmDeleteParcours={setDeleteParcours}
						refetch={refetch}
					/>
				)}
			</div>

			<BottomBarNavigation back_to={`/${slug}/manage-routes/${route}`} />
		</Layout>
	);
};

export default LiveParcoursConfig;
