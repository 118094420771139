import { useContext, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventAccesses } from "../api/accesses";
import { getEventIdentityConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import PageLoader from "../components/common/PageLoader";
import SectionHeading from "../components/common/SectionHeading";
import PermissionsModal from "../components/modals/PermissionsModal";
import Layout from "../components/navigation/Layout";
import PermissionsTableHeader from "../components/permissions/PermissionsTableHeader";
import PermissionsTableItem from "../components/permissions/PermissionsTableItem";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function Permissions() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [inviteBeingEdited, setInviteBeingEdited] = useState<any>(null);
	const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

	const { data: jsonInvites, refetch } = useQuery({
		queryKey: ["accesses", slug],
		queryFn: () => getEventAccesses(slug),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false
	});

	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (showInviteModal === false) setInviteBeingEdited(null);
	}, [showInviteModal]);

	if (EventLoading) {
		return <PageLoader menu_key="permissions" />;
	}

	return (
		<Layout active_key="permissions">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: EventInfos.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "permissions", to: "/permissions", active: true }
					]}
				/>

				{/* Page heading */}
				<DetailsPageHeading
					pageTitle={`${EventInfos?.name} - ${trad[lang].permissions}`}
				/>

				{/* Section heading */}
				<SectionHeading
					count={jsonInvites?.users?.count || 0}
					middleComponent={
						<>
							<button
								className="mt-5 ml-6 flex flex-row items-center gap-1 self-start rounded-md border border-secondary py-1 px-2 text-secondary duration-150 hover:border-primary hover:text-primary md:ml-0 md:px-3 md:py-2"
								onClick={() => {
									setShowInviteModal(true);
								}}
							>
								<BsPlus className="h-6 w-6" />
								{trad[lang].inviteNewUser}
							</button>
						</>
					}
				/>

				<div className="w-[100%] py-2 align-middle">
					<div className="overflow-x-auto border border-gray-200">
						<table className="w-full divide-y divide-gray-200">
							<PermissionsTableHeader />

							{jsonInvites?.users?.map((jsonInvite: any, index: number) => (
								<tbody
									key={index}
									className="divide-y divide-gray-200 bg-white"
								>
									<PermissionsTableItem
										jsonInvite={jsonInvite}
										refetch={refetch}
										setInviteBeingEdited={setInviteBeingEdited}
										setShowInviteModal={setShowInviteModal}
										showInviteModal={showInviteModal}
									/>
								</tbody>
							))}
						</table>
					</div>
				</div>
			</div>

			{showInviteModal && (
				<PermissionsModal
					inviteBeingEdited={inviteBeingEdited}
					setInviteBeingEdited={setInviteBeingEdited}
					open={showInviteModal}
					setOpen={setShowInviteModal}
					lang={lang}
					refetch={refetch}
				/>
			)}

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
}

export default Permissions;
