import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const EventSegmentModule = ({
	segment,
	openSegment,
	deleteSegment
}: {
	segment: any;
	openSegment: any;
	deleteSegment: any;
}) => {
	const { lang } = useContext(AppContext);

	return (
		<div
			className={classNames(
				"flex w-full flex-col items-center justify-between gap-4 border-b p-3 px-8 transition-colors hover:bg-slate-100 md:flex-row"
			)}
		>
			<div className="flex flex-row items-baseline gap-3">
				<h2 className="text-xl font-bold">
					{`${trad[lang].segment} - ${segment.libelleSegment}`}
				</h2>
			</div>

			<div className="col-span-1 flex flex-row justify-end gap-3">
				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
					onClick={() => openSegment(segment)}
				>
					{trad[lang].edit_segment}
				</button>

				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
					onClick={() =>
						deleteSegment(segment.idSegment, segment.libelleSegment)
					}
				>
					{trad[lang].delete_segment}
				</button>
			</div>
		</div>
	);
};

export default EventSegmentModule;
