// COUNTRIES LIST WITH NAMES TRANSLATED IN en AND fr WITH THEIR RESPECTIVE FLAGS
export interface ICountry {
	name: {
		[key: string]: string;
	};
	flag: string;
	code: string;
	idWindev: number;
	alpha3Code: string;
}

export interface IcountriesList {
	[key: string]: ICountry;
}

export const getCountryByWindevId = (
	idWindev: number
): ICountry | undefined => {
	// Fix type of idWindev in case of it is a string
	if (typeof idWindev === "string" && (idWindev as string).match(/^[0-9]+$/))
		idWindev = parseInt(idWindev as string);
	let item: ICountry | undefined = countriesList.find(
		(country) => country.idWindev === idWindev
	);
	if (item) return item;
	return undefined;
};

export const getCountryNameByWindevId = (
	idWindev: number,
	lang: string
): string => {
	let country: ICountry | undefined = getCountryByWindevId(idWindev);
	if (country) return country.name[lang];
	return "";
};

export const getWindevIdByCountryName = (name: string): number => {
	let n = name.toLowerCase();
	let country: ICountry | undefined = countriesList.find(
		(country) =>
			country.name.en.toLowerCase() === n || country.name.fr.toLowerCase() === n
	);
	if (country) return country.idWindev;
	country = countriesList.find(
		(country) =>
			country.code.toLowerCase() === n || country.alpha3Code.toLowerCase() === n
	);
	if (country) return country.idWindev;

	// If no country found, default to France (74)
	return getWindevIdByCountryName("France");
};

export const countriesList: ICountry[] = [
	{
		name: {
			en: "France",
			fr: "France"
		},
		flag: "https://flagpedia.net/data/flags/small/fr.png",
		code: "FR",
		idWindev: 74,
		alpha3Code: "FRA"
	},
	{
		name: {
			en: "Afghanistan",
			fr: "Afghanistan"
		},
		flag: "https://flagpedia.net/data/flags/small/af.png",
		code: "AF",
		idWindev: 1,
		alpha3Code: "AFG"
	},
	{
		name: {
			en: "Albania",
			fr: "Albanie"
		},
		flag: "https://flagpedia.net/data/flags/small/al.png",
		code: "AL",
		idWindev: 3,
		alpha3Code: "ALB"
	},
	{
		name: {
			en: "Algeria",
			fr: "Algérie"
		},
		flag: "https://flagpedia.net/data/flags/small/dz.png",
		code: "DZ",
		idWindev: 4,
		alpha3Code: "DZA"
	},
	{
		name: {
			en: "Andorra",
			fr: "Andorre"
		},
		flag: "https://flagpedia.net/data/flags/small/ad.png",
		code: "AD",
		idWindev: 6,
		alpha3Code: "AND"
	},
	{
		name: {
			en: "Angola",
			fr: "Angola"
		},
		flag: "https://flagpedia.net/data/flags/small/ao.png",
		code: "AO",
		idWindev: 7,
		alpha3Code: "AGO"
	},
	{
		name: {
			en: "Antigua and Barbuda",
			fr: "Antigua-et-Barbuda"
		},
		flag: "https://flagpedia.net/data/flags/small/ag.png",
		code: "AG",
		idWindev: 10,
		alpha3Code: "ATG"
	},
	{
		name: {
			en: "Argentina",
			fr: "Argentine"
		},
		flag: "https://flagpedia.net/data/flags/small/ar.png",
		code: "AR",
		idWindev: 11,
		alpha3Code: "ARG"
	},
	{
		name: {
			en: "Armenia",
			fr: "Arménie"
		},
		flag: "https://flagpedia.net/data/flags/small/am.png",
		code: "AM",
		idWindev: 12,
		alpha3Code: "ARM"
	},
	{
		name: {
			en: "Australia",
			fr: "Australie"
		},
		flag: "https://flagpedia.net/data/flags/small/au.png",
		code: "AU",
		idWindev: 14,
		alpha3Code: "AUS"
	},
	{
		name: {
			en: "Austria",
			fr: "Autriche"
		},
		flag: "https://flagpedia.net/data/flags/small/at.png",
		code: "AT",
		idWindev: 15,
		alpha3Code: "AUT"
	},
	{
		name: {
			en: "Azerbaijan",
			fr: "Azerbaïdjan"
		},
		flag: "https://flagpedia.net/data/flags/small/az.png",
		code: "AZ",
		idWindev: 16,
		alpha3Code: "AZE"
	},
	{
		name: {
			en: "Bahamas",
			fr: "Bahamas"
		},
		flag: "https://flagpedia.net/data/flags/small/bs.png",
		code: "BS",
		idWindev: 17,
		alpha3Code: "BHS"
	},
	{
		name: {
			en: "Bahrain",
			fr: "Bahreïn"
		},
		flag: "https://flagpedia.net/data/flags/small/bh.png",
		code: "BH",
		idWindev: 18,
		alpha3Code: "BHR"
	},
	{
		name: {
			en: "Bangladesh",
			fr: "Bangladesh"
		},
		flag: "https://flagpedia.net/data/flags/small/bd.png",
		code: "BD",
		idWindev: 19,
		alpha3Code: "BGD"
	},
	{
		name: {
			en: "Barbados",
			fr: "Barbade"
		},
		flag: "https://flagpedia.net/data/flags/small/bb.png",
		code: "BB",
		idWindev: 20,
		alpha3Code: "BRB"
	},
	{
		name: {
			en: "Belarus",
			fr: "Biélorussie"
		},
		flag: "https://flagpedia.net/data/flags/small/by.png",
		code: "BY",
		idWindev: 21,
		alpha3Code: "BLR"
	},
	{
		name: {
			en: "Belgium",
			fr: "Belgique"
		},
		flag: "https://flagpedia.net/data/flags/small/be.png",
		code: "BE",
		idWindev: 22,
		alpha3Code: "BEL"
	},
	{
		name: {
			en: "Belize",
			fr: "Belize"
		},
		flag: "https://flagpedia.net/data/flags/small/bz.png",
		code: "BZ",
		idWindev: 23,
		alpha3Code: "BLZ"
	},
	{
		name: {
			en: "Benin",
			fr: "Bénin"
		},
		flag: "https://flagpedia.net/data/flags/small/bj.png",
		code: "BJ",
		idWindev: 24,
		alpha3Code: "BEN"
	},
	{
		name: {
			en: "Bhutan",
			fr: "Bhoutan"
		},
		flag: "https://flagpedia.net/data/flags/small/bt.png",
		code: "BT",
		idWindev: 26,
		alpha3Code: "BTN"
	},
	{
		name: {
			en: "Bolivia",
			fr: "Bolivie"
		},
		flag: "https://flagpedia.net/data/flags/small/bo.png",
		code: "BO",
		idWindev: 27,
		alpha3Code: "BOL"
	},
	{
		name: {
			en: "Bosnia and Herzegovina",
			fr: "Bosnie-Herzégovine"
		},
		flag: "https://flagpedia.net/data/flags/small/ba.png",
		code: "BA",
		idWindev: 28,
		alpha3Code: "BIH"
	},
	{
		name: {
			en: "Botswana",
			fr: "Botswana"
		},
		flag: "https://flagpedia.net/data/flags/small/bw.png",
		code: "BW",
		idWindev: 29,
		alpha3Code: "BWA"
	},
	{
		name: {
			en: "Brazil",
			fr: "Brésil"
		},
		flag: "https://flagpedia.net/data/flags/small/br.png",
		code: "BR",
		idWindev: 31,
		alpha3Code: "BRA"
	},
	{
		name: {
			en: "Brunei",
			fr: "Brunéi"
		},
		flag: "https://flagpedia.net/data/flags/small/bn.png",
		code: "BN",
		idWindev: 33,
		alpha3Code: "BRN"
	},
	{
		name: {
			en: "Bulgaria",
			fr: "Bulgarie"
		},
		flag: "https://flagpedia.net/data/flags/small/bg.png",
		code: "BG",
		idWindev: 34,
		alpha3Code: "BGR"
	},
	{
		name: {
			en: "Burkina Faso",
			fr: "Burkina Faso"
		},
		flag: "https://flagpedia.net/data/flags/small/bf.png",
		code: "BF",
		idWindev: 35,
		alpha3Code: "BFA"
	},
	{
		name: {
			en: "Burma (Myanmar)",
			fr: "Birmanie (Myanmar)"
		},
		flag: "https://flagpedia.net/data/flags/small/mm.png",
		code: "MM",
		idWindev: 151,
		alpha3Code: "MMR"
	},
	{
		name: {
			en: "Burundi",
			fr: "Burundi"
		},
		flag: "https://flagpedia.net/data/flags/small/bi.png",
		code: "BI",
		idWindev: 36,
		alpha3Code: "BDI"
	},
	{
		name: {
			en: "Cabo Verde",
			fr: "Cabo Verde"
		},
		flag: "https://flagpedia.net/data/flags/small/cv.png",
		code: "CV",
		idWindev: 40,
		alpha3Code: "CPV"
	},
	{
		name: {
			en: "Cambodia",
			fr: "Cambodge"
		},
		flag: "https://flagpedia.net/data/flags/small/kh.png",
		code: "KH",
		idWindev: 37,
		alpha3Code: "KHM"
	},
	{
		name: {
			en: "Cameroon",
			fr: "Cameroun"
		},
		flag: "https://flagpedia.net/data/flags/small/cm.png",
		code: "CM",
		idWindev: 38,
		alpha3Code: "CMR"
	},
	{
		name: {
			en: "Canada",
			fr: "Canada"
		},
		flag: "https://flagpedia.net/data/flags/small/ca.png",
		code: "CA",
		idWindev: 39,
		alpha3Code: "CAN"
	},
	{
		name: {
			en: "Central African Republic",
			fr: "République centrafricaine"
		},
		flag: "https://flagpedia.net/data/flags/small/cf.png",
		code: "CF",
		idWindev: 42,
		alpha3Code: "CAF"
	},
	{
		name: {
			en: "Chad",
			fr: "Tchad"
		},
		flag: "https://flagpedia.net/data/flags/small/td.png",
		code: "TD",
		idWindev: 43,
		alpha3Code: "TCD"
	},
	{
		name: {
			en: "Chile",
			fr: "Chili"
		},
		flag: "https://flagpedia.net/data/flags/small/cl.png",
		code: "CL",
		idWindev: 44,
		alpha3Code: "CHL"
	},
	{
		name: {
			en: "China",
			fr: "Chine"
		},
		flag: "https://flagpedia.net/data/flags/small/cn.png",
		code: "CN",
		idWindev: 45,
		alpha3Code: "CHN"
	},
	{
		name: {
			en: "Colombia",
			fr: "Colombie"
		},
		flag: "https://flagpedia.net/data/flags/small/co.png",
		code: "CO",
		idWindev: 48,
		alpha3Code: "COL"
	},
	{
		name: {
			en: "Comoros",
			fr: "Comores"
		},
		flag: "https://flagpedia.net/data/flags/small/km.png",
		code: "KM",
		idWindev: 49,
		alpha3Code: "COM"
	},
	{
		name: {
			en: "Congo (Brazzaville)",
			fr: "Congo (Brazzaville)"
		},
		flag: "https://flagpedia.net/data/flags/small/cg.png",
		code: "CG",
		idWindev: 50,
		alpha3Code: "COG"
	},
	{
		name: {
			en: "Congo (Kinshasa)",
			fr: "Congo (Kinshasa)"
		},
		flag: "https://flagpedia.net/data/flags/small/cd.png",
		code: "CD",
		idWindev: 51,
		alpha3Code: "COD"
	},
	{
		name: {
			en: "Costa Rica",
			fr: "Costa Rica"
		},
		flag: "https://flagpedia.net/data/flags/small/cr.png",
		code: "CR",
		idWindev: 53,
		alpha3Code: "CRI"
	},
	{
		name: {
			en: "Cote d'Ivoire",
			fr: "Côte d'Ivoire"
		},
		flag: "https://flagpedia.net/data/flags/small/ci.png",
		code: "CI",
		idWindev: 54,
		alpha3Code: "CIV"
	},
	{
		name: {
			en: "Croatia",
			fr: "Croatie"
		},
		flag: "https://flagpedia.net/data/flags/small/hr.png",
		code: "HR",
		idWindev: 55,
		alpha3Code: "HRV"
	},
	{
		name: {
			en: "Cuba",
			fr: "Cuba"
		},
		flag: "https://flagpedia.net/data/flags/small/cu.png",
		code: "CU",
		idWindev: 56,
		alpha3Code: "CUB"
	},
	{
		name: {
			en: "Cyprus",
			fr: "Chypre"
		},
		flag: "https://flagpedia.net/data/flags/small/cy.png",
		code: "CY",
		idWindev: 57,
		alpha3Code: "CYP"
	},
	{
		name: {
			en: "Czech Republic",
			fr: "République tchèque"
		},
		flag: "https://flagpedia.net/data/flags/small/cz.png",
		code: "CZ",
		idWindev: 58,
		alpha3Code: "CZE"
	},
	{
		name: {
			en: "Denmark",
			fr: "Danemark"
		},
		flag: "https://flagpedia.net/data/flags/small/dk.png",
		code: "DK",
		idWindev: 59,
		alpha3Code: "DNK"
	},
	{
		name: {
			en: "Djibouti",
			fr: "Djibouti"
		},
		flag: "https://flagpedia.net/data/flags/small/dj.png",
		code: "DJ",
		idWindev: 60,
		alpha3Code: "DJI"
	},
	{
		name: {
			en: "Dominica",
			fr: "Dominique"
		},
		flag: "https://flagpedia.net/data/flags/small/dm.png",
		code: "DM",
		idWindev: 61,
		alpha3Code: "DMA"
	},
	{
		name: {
			en: "Dominican Republic",
			fr: "République dominicaine"
		},
		flag: "https://flagpedia.net/data/flags/small/do.png",
		code: "DO",
		idWindev: 62,
		alpha3Code: "DOM"
	},
	{
		name: {
			en: "Ecuador",
			fr: "Équateur"
		},
		flag: "https://flagpedia.net/data/flags/small/ec.png",
		code: "EC",
		idWindev: 63,
		alpha3Code: "ECU"
	},
	{
		name: {
			en: "Egypt",
			fr: "Égypte"
		},
		flag: "https://flagpedia.net/data/flags/small/eg.png",
		code: "EG",
		idWindev: 64,
		alpha3Code: "EGY"
	},
	{
		name: {
			en: "El Salvador",
			fr: "El Salvador"
		},
		flag: "https://flagpedia.net/data/flags/small/sv.png",
		code: "SV",
		idWindev: 65,
		alpha3Code: "SLV"
	},
	{
		name: {
			en: "Eritrea",
			fr: "Érythrée"
		},
		flag: "https://flagpedia.net/data/flags/small/er.png",
		code: "ER",
		idWindev: 67,
		alpha3Code: "ERI"
	},
	{
		name: {
			en: "Estonia",
			fr: "Estonie"
		},
		flag: "https://flagpedia.net/data/flags/small/ee.png",
		code: "EE",
		idWindev: 68,
		alpha3Code: "EST"
	},
	{
		name: {
			en: "Eswatini",
			fr: "Eswatini"
		},
		flag: "https://flagpedia.net/data/flags/small/sz.png",
		code: "SZ",
		idWindev: 212,
		alpha3Code: "SWZ"
	},
	{
		name: {
			en: "Ethiopia",
			fr: "Éthiopie"
		},
		flag: "https://flagpedia.net/data/flags/small/et.png",
		code: "ET",
		idWindev: 69,
		alpha3Code: "ETH"
	},
	{
		name: {
			en: "Fiji",
			fr: "Fidji"
		},
		flag: "https://flagpedia.net/data/flags/small/fj.png",
		code: "FJ",
		idWindev: 72,
		alpha3Code: "FJI"
	},
	{
		name: {
			en: "Finland",
			fr: "Finlande"
		},
		flag: "https://flagpedia.net/data/flags/small/fi.png",
		code: "FI",
		idWindev: 73,
		alpha3Code: "FIN"
	},
	{
		name: {
			en: "France",
			fr: "France"
		},
		flag: "https://flagpedia.net/data/flags/small/fr.png",
		code: "FR",
		idWindev: 74,
		alpha3Code: "FRA"
	},
	{
		name: {
			en: "Gabon",
			fr: "Gabon"
		},
		flag: "https://flagpedia.net/data/flags/small/ga.png",
		code: "GA",
		idWindev: 78,
		alpha3Code: "GAB"
	},
	{
		name: {
			en: "Gambia",
			fr: "Gambie"
		},
		flag: "https://flagpedia.net/data/flags/small/gm.png",
		code: "GM",
		idWindev: 79,
		alpha3Code: "GMB"
	},
	{
		name: {
			en: "Georgia",
			fr: "Géorgie"
		},
		flag: "https://flagpedia.net/data/flags/small/ge.png",
		code: "GE",
		idWindev: 80,
		alpha3Code: "GEO"
	},
	{
		name: {
			en: "Germany",
			fr: "Allemagne"
		},
		flag: "https://flagpedia.net/data/flags/small/de.png",
		code: "DE",
		idWindev: 81,
		alpha3Code: "DEU"
	},
	{
		name: {
			en: "Ghana",
			fr: "Ghana"
		},
		flag: "https://flagpedia.net/data/flags/small/gh.png",
		code: "GH",
		idWindev: 82,
		alpha3Code: "GHA"
	},
	{
		name: {
			en: "Greece",
			fr: "Grèce"
		},
		flag: "https://flagpedia.net/data/flags/small/gr.png",
		code: "GR",
		idWindev: 84,
		alpha3Code: "GRC"
	},
	{
		name: {
			en: "Grenada",
			fr: "Grenade"
		},
		flag: "https://flagpedia.net/data/flags/small/gd.png",
		code: "GD",
		idWindev: 86,
		alpha3Code: "GRD"
	},
	{
		name: {
			en: "Guatemala",
			fr: "Guatemala"
		},
		flag: "https://flagpedia.net/data/flags/small/gt.png",
		code: "GT",
		idWindev: 89,
		alpha3Code: "GTM"
	},
	{
		name: {
			en: "Guinea",
			fr: "Guinée"
		},
		flag: "https://flagpedia.net/data/flags/small/gn.png",
		code: "GN",
		idWindev: 91,
		alpha3Code: "GIN"
	},
	{
		name: {
			en: "Guinea-Bissau",
			fr: "Guinée-Bissau"
		},
		flag: "https://flagpedia.net/data/flags/small/gw.png",
		code: "GW",
		idWindev: 92,
		alpha3Code: "GNB"
	},
	{
		name: {
			en: "Guyana",
			fr: "Guyana"
		},
		flag: "https://flagpedia.net/data/flags/small/gy.png",
		code: "GY",
		idWindev: 93,
		alpha3Code: "GUY"
	},
	{
		name: {
			en: "Haiti",
			fr: "Haïti"
		},
		flag: "https://flagpedia.net/data/flags/small/ht.png",
		code: "HT",
		idWindev: 94,
		alpha3Code: "HTI"
	},
	{
		name: {
			en: "Honduras",
			fr: "Honduras"
		},
		flag: "https://flagpedia.net/data/flags/small/hn.png",
		code: "HN",
		idWindev: 97,
		alpha3Code: "HND"
	},
	{
		name: {
			en: "Hungary",
			fr: "Hongrie"
		},
		flag: "https://flagpedia.net/data/flags/small/hu.png",
		code: "HU",
		idWindev: 99,
		alpha3Code: "HUN"
	},
	{
		name: {
			en: "Iceland",
			fr: "Islande"
		},
		flag: "https://flagpedia.net/data/flags/small/is.png",
		code: "IS",
		idWindev: 100,
		alpha3Code: "ISL"
	},
	{
		name: {
			en: "India",
			fr: "Inde"
		},
		flag: "https://flagpedia.net/data/flags/small/in.png",
		code: "IN",
		idWindev: 101,
		alpha3Code: "IND"
	},
	{
		name: {
			en: "Indonesia",
			fr: "Indonésie"
		},
		flag: "https://flagpedia.net/data/flags/small/id.png",
		code: "ID",
		idWindev: 102,
		alpha3Code: "IDN"
	},
	{
		name: {
			en: "Iran",
			fr: "Iran"
		},
		flag: "https://flagpedia.net/data/flags/small/ir.png",
		code: "IR",
		idWindev: 103,
		alpha3Code: "IRN"
	},
	{
		name: {
			en: "Iraq",
			fr: "Irak"
		},
		flag: "https://flagpedia.net/data/flags/small/iq.png",
		code: "IQ",
		idWindev: 104,
		alpha3Code: "IRQ"
	},
	{
		name: {
			en: "Ireland",
			fr: "Irlande"
		},
		flag: "https://flagpedia.net/data/flags/small/ie.png",
		code: "IE",
		idWindev: 105,
		alpha3Code: "IRL"
	},
	{
		name: {
			en: "Israel",
			fr: "Israël"
		},
		flag: "https://flagpedia.net/data/flags/small/il.png",
		code: "IL",
		idWindev: 107,
		alpha3Code: "ISR"
	},
	{
		name: {
			en: "Italy",
			fr: "Italie"
		},
		flag: "https://flagpedia.net/data/flags/small/it.png",
		code: "IT",
		idWindev: 108,
		alpha3Code: "ITA"
	},
	{
		name: {
			en: "Jamaica",
			fr: "Jamaïque"
		},
		flag: "https://flagpedia.net/data/flags/small/jm.png",
		code: "JM",
		idWindev: 109,
		alpha3Code: "JAM"
	},
	{
		name: {
			en: "Japan",
			fr: "Japon"
		},
		flag: "https://flagpedia.net/data/flags/small/jp.png",
		code: "JP",
		idWindev: 110,
		alpha3Code: "JPN"
	},
	{
		name: {
			en: "Jordan",
			fr: "Jordanie"
		},
		flag: "https://flagpedia.net/data/flags/small/jo.png",
		code: "JO",
		idWindev: 112,
		alpha3Code: "JOR"
	},
	{
		name: {
			en: "Kazakhstan",
			fr: "Kazakhstan"
		},
		flag: "https://flagpedia.net/data/flags/small/kz.png",
		code: "KZ",
		idWindev: 113,
		alpha3Code: "KAZ"
	},
	{
		name: {
			en: "Kenya",
			fr: "Kenya"
		},
		flag: "https://flagpedia.net/data/flags/small/ke.png",
		code: "KE",
		idWindev: 114,
		alpha3Code: "KEN"
	},
	{
		name: {
			en: "Kiribati",
			fr: "Kiribati"
		},
		flag: "https://flagpedia.net/data/flags/small/ki.png",
		code: "KI",
		idWindev: 115,
		alpha3Code: "KIR"
	},
	{
		name: {
			en: "Korea (North)",
			fr: "Corée (Nord)"
		},
		flag: "https://flagpedia.net/data/flags/small/kp.png",
		code: "KP",
		idWindev: 116,
		alpha3Code: "PRK"
	},
	{
		name: {
			en: "Kuwait",
			fr: "Koweït"
		},
		flag: "https://flagpedia.net/data/flags/small/kw.png",
		code: "KW",
		idWindev: 118,
		alpha3Code: "KWT"
	},
	{
		name: {
			en: "Kyrgyzstan",
			fr: "Kirghizistan"
		},
		flag: "https://flagpedia.net/data/flags/small/kg.png",
		code: "KG",
		idWindev: 119,
		alpha3Code: "KGZ"
	},
	{
		name: {
			en: "Laos",
			fr: "Laos"
		},
		flag: "https://flagpedia.net/data/flags/small/la.png",
		code: "LA",
		idWindev: 120,
		alpha3Code: "LAO"
	},
	{
		name: {
			en: "Latvia",
			fr: "Lettonie"
		},
		flag: "https://flagpedia.net/data/flags/small/lv.png",
		code: "LV",
		idWindev: 121,
		alpha3Code: "LVA"
	},
	{
		name: {
			en: "Lebanon",
			fr: "Liban"
		},
		flag: "https://flagpedia.net/data/flags/small/lb.png",
		code: "LB",
		idWindev: 122,
		alpha3Code: "LBN"
	},
	{
		name: {
			en: "Lesotho",
			fr: "Lesotho"
		},
		flag: "https://flagpedia.net/data/flags/small/ls.png",
		code: "LS",
		idWindev: 123,
		alpha3Code: "LSO"
	},
	{
		name: {
			en: "Liberia",
			fr: "Liberia"
		},
		flag: "https://flagpedia.net/data/flags/small/lr.png",
		code: "LR",
		idWindev: 124,
		alpha3Code: "LBR"
	},
	{
		name: {
			en: "Libya",
			fr: "Libye"
		},
		flag: "https://flagpedia.net/data/flags/small/ly.png",
		code: "LY",
		idWindev: 125,
		alpha3Code: "LBY"
	},
	{
		name: {
			en: "Liechtenstein",
			fr: "Liechtenstein"
		},
		flag: "https://flagpedia.net/data/flags/small/li.png",
		code: "LI",
		idWindev: 126,
		alpha3Code: "LIE"
	},
	{
		name: {
			en: "Lithuania",
			fr: "Lituanie"
		},
		flag: "https://flagpedia.net/data/flags/small/lt.png",
		code: "LT",
		idWindev: 127,
		alpha3Code: "LTU"
	},
	{
		name: {
			en: "Luxembourg",
			fr: "Luxembourg"
		},
		flag: "https://flagpedia.net/data/flags/small/lu.png",
		code: "LU",
		idWindev: 128,
		alpha3Code: "LUX"
	},
	{
		name: {
			en: "Madagascar",
			fr: "Madagascar"
		},
		flag: "https://flagpedia.net/data/flags/small/mg.png",
		code: "MG",
		idWindev: 131,
		alpha3Code: "MDG"
	},
	{
		name: {
			en: "Malawi",
			fr: "Malawi"
		},
		flag: "https://flagpedia.net/data/flags/small/mw.png",
		code: "MW",
		idWindev: 132,
		alpha3Code: "MWI"
	},
	{
		name: {
			en: "Malaysia",
			fr: "Malaisie"
		},
		flag: "https://flagpedia.net/data/flags/small/my.png",
		code: "MY",
		idWindev: 133,
		alpha3Code: "MYS"
	},
	{
		name: {
			en: "Maldives",
			fr: "Maldives"
		},
		flag: "https://flagpedia.net/data/flags/small/mv.png",
		code: "MV",
		idWindev: 134,
		alpha3Code: "MDV"
	},
	{
		name: {
			en: "Mali",
			fr: "Mali"
		},
		flag: "https://flagpedia.net/data/flags/small/ml.png",
		code: "ML",
		idWindev: 135,
		alpha3Code: "MLI"
	},
	{
		name: {
			en: "Malta",
			fr: "Malte"
		},
		flag: "https://flagpedia.net/data/flags/small/mt.png",
		code: "MT",
		idWindev: 136,
		alpha3Code: "MLT"
	},
	{
		name: {
			en: "Marshall Islands",
			fr: "Îles Marshall"
		},
		flag: "https://flagpedia.net/data/flags/small/mh.png",
		code: "MH",
		idWindev: 137,
		alpha3Code: "MHL"
	},
	{
		name: {
			en: "Mauritania",
			fr: "Mauritanie"
		},
		flag: "https://flagpedia.net/data/flags/small/mr.png",
		code: "MR",
		idWindev: 139,
		alpha3Code: "MRT"
	},
	{
		name: {
			en: "Mauritius",
			fr: "Maurice"
		},
		flag: "https://flagpedia.net/data/flags/small/mu.png",
		code: "MU",
		idWindev: 140,
		alpha3Code: "MUS"
	},
	{
		name: {
			en: "Mexico",
			fr: "Mexique"
		},
		flag: "https://flagpedia.net/data/flags/small/mx.png",
		code: "MX",
		idWindev: 142,
		alpha3Code: "MEX"
	},
	{
		name: {
			en: "Micronesia",
			fr: "Micronésie"
		},
		flag: "https://flagpedia.net/data/flags/small/fm.png",
		code: "FM",
		idWindev: 143,
		alpha3Code: "FSM"
	},
	{
		name: {
			en: "Moldova",
			fr: "Moldavie"
		},
		flag: "https://flagpedia.net/data/flags/small/md.png",
		code: "MD",
		idWindev: 144,
		alpha3Code: "MDA"
	},
	{
		name: {
			en: "Monaco",
			fr: "Monaco"
		},
		flag: "https://flagpedia.net/data/flags/small/mc.png",
		code: "MC",
		idWindev: 145,
		alpha3Code: "MCO"
	},
	{
		name: {
			en: "Mongolia",
			fr: "Mongolie"
		},
		flag: "https://flagpedia.net/data/flags/small/mn.png",
		code: "MN",
		idWindev: 146,
		alpha3Code: "MNG"
	},
	{
		name: {
			en: "Montenegro",
			fr: "Monténégro"
		},
		flag: "https://flagpedia.net/data/flags/small/me.png",
		code: "ME",
		idWindev: 147,
		alpha3Code: "MNE"
	},
	{
		name: {
			en: "Morocco",
			fr: "Maroc"
		},
		flag: "https://flagpedia.net/data/flags/small/ma.png",
		code: "MA",
		idWindev: 149,
		alpha3Code: "MAR"
	},
	{
		name: {
			en: "Mozambique",
			fr: "Mozambique"
		},
		flag: "https://flagpedia.net/data/flags/small/mz.png",
		code: "MZ",
		idWindev: 150,
		alpha3Code: "MOZ"
	},
	{
		name: {
			en: "Namibia",
			fr: "Namibie"
		},
		flag: "https://flagpedia.net/data/flags/small/na.png",
		code: "NA",
		idWindev: 152,
		alpha3Code: "NAM"
	},
	{
		name: {
			en: "Nauru",
			fr: "Nauru"
		},
		flag: "https://flagpedia.net/data/flags/small/nr.png",
		code: "NR",
		idWindev: 153,
		alpha3Code: "NRU"
	},
	{
		name: {
			en: "Nepal",
			fr: "Népal"
		},
		flag: "https://flagpedia.net/data/flags/small/np.png",
		code: "NP",
		idWindev: 154,
		alpha3Code: "NPL"
	},
	{
		name: {
			en: "Netherlands",
			fr: "Pays-Bas"
		},
		flag: "https://flagpedia.net/data/flags/small/nl.png",
		code: "NL",
		idWindev: 155,
		alpha3Code: "NLD"
	},
	{
		name: {
			en: "New Zealand",
			fr: "Nouvelle-Zélande"
		},
		flag: "https://flagpedia.net/data/flags/small/nz.png",
		code: "NZ",
		idWindev: 158,
		alpha3Code: "NZL"
	},
	{
		name: {
			en: "Nicaragua",
			fr: "Nicaragua"
		},
		flag: "https://flagpedia.net/data/flags/small/ni.png",
		code: "NI",
		idWindev: 159,
		alpha3Code: "NIC"
	},
	{
		name: {
			en: "Niger",
			fr: "Niger"
		},
		flag: "https://flagpedia.net/data/flags/small/ne.png",
		code: "NE",
		idWindev: 160,
		alpha3Code: "NER"
	},
	{
		name: {
			en: "Nigeria",
			fr: "Nigéria"
		},
		flag: "https://flagpedia.net/data/flags/small/ng.png",
		code: "NG",
		idWindev: 161,
		alpha3Code: "NGA"
	},
	{
		name: {
			en: "Norway",
			fr: "Norvège"
		},
		flag: "https://flagpedia.net/data/flags/small/no.png",
		code: "NO",
		idWindev: 165,
		alpha3Code: "NOR"
	},
	{
		name: {
			en: "Oman",
			fr: "Oman"
		},
		flag: "https://flagpedia.net/data/flags/small/om.png",
		code: "OM",
		idWindev: 166,
		alpha3Code: "OMN"
	},
	{
		name: {
			en: "Pakistan",
			fr: "Pakistan"
		},
		flag: "https://flagpedia.net/data/flags/small/pk.png",
		code: "PK",
		idWindev: 167,
		alpha3Code: "PAK"
	},
	{
		name: {
			en: "Palau",
			fr: "Palaos"
		},
		flag: "https://flagpedia.net/data/flags/small/pw.png",
		code: "PW",
		idWindev: 168,
		alpha3Code: "PLW"
	},
	{
		name: {
			en: "Panama",
			fr: "Panama"
		},
		flag: "https://flagpedia.net/data/flags/small/pa.png",
		code: "PA",
		idWindev: 170,
		alpha3Code: "PAN"
	},
	{
		name: {
			en: "Papua New Guinea",
			fr: "Papouasie-Nouvelle-Guinée"
		},
		flag: "https://flagpedia.net/data/flags/small/pg.png",
		code: "PG",
		idWindev: 171,
		alpha3Code: "PNG"
	},
	{
		name: {
			en: "Paraguay",
			fr: "Paraguay"
		},
		flag: "https://flagpedia.net/data/flags/small/py.png",
		code: "PY",
		idWindev: 172,
		alpha3Code: "PRY"
	},
	{
		name: {
			en: "Peru",
			fr: "Pérou"
		},
		flag: "https://flagpedia.net/data/flags/small/pe.png",
		code: "PE",
		idWindev: 173,
		alpha3Code: "PER"
	},
	{
		name: {
			en: "Philippines",
			fr: "Philippines"
		},
		flag: "https://flagpedia.net/data/flags/small/ph.png",
		code: "PH",
		idWindev: 174,
		alpha3Code: "PHL"
	},
	{
		name: {
			en: "Poland",
			fr: "Pologne"
		},
		flag: "https://flagpedia.net/data/flags/small/pl.png",
		code: "PL",
		idWindev: 176,
		alpha3Code: "POL"
	},
	{
		name: {
			en: "Portugal",
			fr: "Portugal"
		},
		flag: "https://flagpedia.net/data/flags/small/pt.png",
		code: "PT",
		idWindev: 177,
		alpha3Code: "PRT"
	},
	{
		name: {
			en: "Qatar",
			fr: "Qatar"
		},
		flag: "https://flagpedia.net/data/flags/small/qa.png",
		code: "QA",
		idWindev: 179,
		alpha3Code: "QAT"
	},
	{
		name: {
			en: "Romania",
			fr: "Roumanie"
		},
		flag: "https://flagpedia.net/data/flags/small/ro.png",
		code: "RO",
		idWindev: 181,
		alpha3Code: "ROU"
	},
	{
		name: {
			en: "Russia",
			fr: "Russie"
		},
		flag: "https://flagpedia.net/data/flags/small/ru.png",
		code: "RU",
		idWindev: 182,
		alpha3Code: "RUS"
	},
	{
		name: {
			en: "Rwanda",
			fr: "Rwanda"
		},
		flag: "https://flagpedia.net/data/flags/small/rw.png",
		code: "RW",
		idWindev: 183,
		alpha3Code: "RWA"
	},
	{
		name: {
			en: "Saint Kitts and Nevis",
			fr: "Saint-Kitts-et-Nevis"
		},
		flag: "https://flagpedia.net/data/flags/small/kn.png",
		code: "KN",
		idWindev: 187,
		alpha3Code: "KNA"
	},
	{
		name: {
			en: "Saint Lucia",
			fr: "Sainte-Lucie"
		},
		flag: "https://flagpedia.net/data/flags/small/lc.png",
		code: "LC",
		idWindev: 188,
		alpha3Code: "LCA"
	},
	{
		name: {
			en: "Saint Vincent and the Grenadines",
			fr: "Saint-Vincent-et-les-Grenadines"
		},
		flag: "https://flagpedia.net/data/flags/small/vc.png",
		code: "VC",
		idWindev: 190,
		alpha3Code: "VCT"
	},
	{
		name: {
			en: "Samoa",
			fr: "Samoa"
		},
		flag: "https://flagpedia.net/data/flags/small/ws.png",
		code: "WS",
		idWindev: 191,
		alpha3Code: "WSM"
	},
	{
		name: {
			en: "San Marino",
			fr: "Saint-Marin"
		},
		flag: "https://flagpedia.net/data/flags/small/sm.png",
		code: "SM",
		idWindev: 192,
		alpha3Code: "SMR"
	},
	{
		name: {
			en: "Sao Tome and Principe",
			fr: "Sao Tomé-et-Principe"
		},
		flag: "https://flagpedia.net/data/flags/small/st.png",
		code: "ST",
		idWindev: 194,
		alpha3Code: "STP"
	},
	{
		name: {
			en: "Saudi Arabia",
			fr: "Arabie Saoudite"
		},
		flag: "https://flagpedia.net/data/flags/small/sa.png",
		code: "SA",
		idWindev: 195,
		alpha3Code: "SAU"
	},
	{
		name: {
			en: "Senegal",
			fr: "Sénégal"
		},
		flag: "https://flagpedia.net/data/flags/small/sn.png",
		code: "SN",
		idWindev: 196,
		alpha3Code: "SEN"
	},
	{
		name: {
			en: "Serbia",
			fr: "Serbie"
		},
		flag: "https://flagpedia.net/data/flags/small/rs.png",
		code: "RS",
		idWindev: 197,
		alpha3Code: "SRB"
	},
	{
		name: {
			en: "Seychelles",
			fr: "Seychelles"
		},
		flag: "https://flagpedia.net/data/flags/small/sc.png",
		code: "SC",
		idWindev: 198,
		alpha3Code: "SYC"
	},
	{
		name: {
			en: "Sierra Leone",
			fr: "Sierra Leone"
		},
		flag: "https://flagpedia.net/data/flags/small/sl.png",
		code: "SL",
		idWindev: 199,
		alpha3Code: "SLE"
	},
	{
		name: {
			en: "Singapore",
			fr: "Singapour"
		},
		flag: "https://flagpedia.net/data/flags/small/sg.png",
		code: "SG",
		idWindev: 200,
		alpha3Code: "SGP"
	},
	{
		name: {
			en: "Slovakia",
			fr: "Slovaquie"
		},
		flag: "https://flagpedia.net/data/flags/small/sk.png",
		code: "SK",
		idWindev: 201,
		alpha3Code: "SVK"
	},
	{
		name: {
			en: "Slovenia",
			fr: "Slovénie"
		},
		flag: "https://flagpedia.net/data/flags/small/si.png",
		code: "SI",
		idWindev: 202,
		alpha3Code: "SVN"
	},
	{
		name: {
			en: "Solomon Islands",
			fr: "Îles Salomon"
		},
		flag: "https://flagpedia.net/data/flags/small/sb.png",
		code: "SB",
		idWindev: 203,
		alpha3Code: "SLB"
	},
	{
		name: {
			en: "Somalia",
			fr: "Somalie"
		},
		flag: "https://flagpedia.net/data/flags/small/so.png",
		code: "SO",
		idWindev: 204,
		alpha3Code: "SOM"
	},
	{
		name: {
			en: "South Africa",
			fr: "Afrique du Sud"
		},
		flag: "https://flagpedia.net/data/flags/small/za.png",
		code: "ZA",
		idWindev: 205,
		alpha3Code: "ZAF"
	},
	{
		name: {
			en: "South Korea",
			fr: "Corée du Sud"
		},
		flag: "https://flagpedia.net/data/flags/small/kr.png",
		code: "KR",
		idWindev: 117,
		alpha3Code: "KOR"
	},
	{
		name: {
			en: "South Sudan",
			fr: "Soudan du Sud"
		},
		flag: "https://flagpedia.net/data/flags/small/ss.png",
		code: "SS",
		idWindev: 209,
		alpha3Code: "SDN"
	},
	{
		name: {
			en: "Spain",
			fr: "Espagne"
		},
		flag: "https://flagpedia.net/data/flags/small/es.png",
		code: "ES",
		idWindev: 207,
		alpha3Code: "ESP"
	},
	{
		name: {
			en: "Sri Lanka",
			fr: "Sri Lanka"
		},
		flag: "https://flagpedia.net/data/flags/small/lk.png",
		code: "LK",
		idWindev: 208,
		alpha3Code: "LKA"
	},
	{
		name: {
			en: "Sudan",
			fr: "Soudan"
		},
		flag: "https://flagpedia.net/data/flags/small/sd.png",
		code: "SD",
		idWindev: 209,
		alpha3Code: "SDN"
	},
	{
		name: {
			en: "Suriname",
			fr: "Suriname"
		},
		flag: "https://flagpedia.net/data/flags/small/sr.png",
		code: "SR",
		idWindev: 210,
		alpha3Code: "SUR"
	},
	{
		name: {
			en: "Sweden",
			fr: "Suède"
		},
		flag: "https://flagpedia.net/data/flags/small/se.png",
		code: "SE",
		idWindev: 213,
		alpha3Code: "SWE"
	},
	{
		name: {
			en: "Switzerland",
			fr: "Suisse"
		},
		flag: "https://flagpedia.net/data/flags/small/ch.png",
		code: "CH",
		idWindev: 214,
		alpha3Code: "CHE"
	},
	{
		name: {
			en: "Syria",
			fr: "Syrie"
		},
		flag: "https://flagpedia.net/data/flags/small/sy.png",
		code: "SY",
		idWindev: 215,
		alpha3Code: "SYR"
	},
	{
		name: {
			en: "Taiwan",
			fr: "Taïwan"
		},
		flag: "https://flagpedia.net/data/flags/small/tw.png",
		code: "TW",
		idWindev: 216,
		alpha3Code: "TWN"
	},
	{
		name: {
			en: "Tajikistan",
			fr: "Tadjikistan"
		},
		flag: "https://flagpedia.net/data/flags/small/tj.png",
		code: "TJ",
		idWindev: 217,
		alpha3Code: "TJK"
	},
	{
		name: {
			en: "Tanzania",
			fr: "Tanzanie"
		},
		flag: "https://flagpedia.net/data/flags/small/tz.png",
		code: "TZ",
		idWindev: 218,
		alpha3Code: "TZA"
	},
	{
		name: {
			en: "Thailand",
			fr: "Thaïlande"
		},
		flag: "https://flagpedia.net/data/flags/small/th.png",
		code: "TH",
		idWindev: 219,
		alpha3Code: "THA"
	},
	{
		name: {
			en: "Timor-Leste (East Timor)",
			fr: "Timor-Leste (Timor Oriental)"
		},
		flag: "https://flagpedia.net/data/flags/small/tl.png",
		code: "TL",
		idWindev: 220,
		alpha3Code: "TLS"
	},
	{
		name: {
			en: "Togo",
			fr: "Togo"
		},
		flag: "https://flagpedia.net/data/flags/small/tg.png",
		code: "TG",
		idWindev: 221,
		alpha3Code: "TGO"
	},
	{
		name: {
			en: "Tonga",
			fr: "Tonga"
		},
		flag: "https://flagpedia.net/data/flags/small/to.png",
		code: "TO",
		idWindev: 223,
		alpha3Code: "TON"
	},
	{
		name: {
			en: "Trinidad and Tobago",
			fr: "Trinité-et-Tobago"
		},
		flag: "https://flagpedia.net/data/flags/small/tt.png",
		code: "TT",
		idWindev: 224,
		alpha3Code: "TTO"
	},
	{
		name: {
			en: "Tunisia",
			fr: "Tunisie"
		},
		flag: "https://flagpedia.net/data/flags/small/tn.png",
		code: "TN",
		idWindev: 225,
		alpha3Code: "TUN"
	},
	{
		name: {
			en: "Turkey",
			fr: "Turquie"
		},
		flag: "https://flagpedia.net/data/flags/small/tr.png",
		code: "TR",
		idWindev: 226,
		alpha3Code: "TUR"
	},
	{
		name: {
			en: "Turkmenistan",
			fr: "Turkménistan"
		},
		flag: "https://flagpedia.net/data/flags/small/tm.png",
		code: "TM",
		idWindev: 227,
		alpha3Code: "TKM"
	},
	{
		name: {
			en: "Tuvalu",
			fr: "Tuvalu"
		},
		flag: "https://flagpedia.net/data/flags/small/tv.png",
		code: "TV",
		idWindev: 229,
		alpha3Code: "TUV"
	},
	{
		name: {
			en: "Uganda",
			fr: "Ouganda"
		},
		flag: "https://flagpedia.net/data/flags/small/ug.png",
		code: "UG",
		idWindev: 230,
		alpha3Code: "UGA"
	},
	{
		name: {
			en: "Ukraine",
			fr: "Ukraine"
		},
		flag: "https://flagpedia.net/data/flags/small/ua.png",
		code: "UA",
		idWindev: 231,
		alpha3Code: "UKR"
	},
	{
		name: {
			en: "United Arab Emirates",
			fr: "Émirats Arabes Unis"
		},
		flag: "https://flagpedia.net/data/flags/small/ae.png",
		code: "AE",
		idWindev: 232,
		alpha3Code: "ARE"
	},
	{
		name: {
			en: "United Kingdom",
			fr: "Royaume-Uni"
		},
		flag: "https://flagpedia.net/data/flags/small/gb.png",
		code: "GB",
		idWindev: 233,
		alpha3Code: "GBR"
	},
	{
		name: {
			en: "United States",
			fr: "États-Unis"
		},
		flag: "https://flagpedia.net/data/flags/small/us.png",
		code: "US",
		idWindev: 234,
		alpha3Code: "USA"
	},
	{
		name: {
			en: "Uruguay",
			fr: "Uruguay"
		},
		flag: "https://flagpedia.net/data/flags/small/uy.png",
		code: "UY",
		idWindev: 236,
		alpha3Code: "URY"
	},
	{
		name: {
			en: "Uzbekistan",
			fr: "Ouzbékistan"
		},
		flag: "https://flagpedia.net/data/flags/small/uz.png",
		code: "UZ",
		idWindev: 237,
		alpha3Code: "UZB"
	},
	{
		name: {
			en: "Vanuatu",
			fr: "Vanuatu"
		},
		flag: "https://flagpedia.net/data/flags/small/vu.png",
		code: "VU",
		idWindev: 238,
		alpha3Code: "VUT"
	},
	{
		name: {
			en: "Vatican City",
			fr: "Cité du Vatican"
		},
		flag: "https://flagpedia.net/data/flags/small/va.png",
		code: "VA",
		idWindev: 96,
		alpha3Code: "VAT"
	},
	{
		name: {
			en: "Venezuela",
			fr: "Venezuela"
		},
		flag: "https://flagpedia.net/data/flags/small/ve.png",
		code: "VE",
		idWindev: 240,
		alpha3Code: "VEN"
	},
	{
		name: {
			en: "Vietnam",
			fr: "Viêt Nam"
		},
		flag: "https://flagpedia.net/data/flags/small/vn.png",
		code: "VN",
		idWindev: 241,
		alpha3Code: "VNM"
	},
	{
		name: {
			en: "Yemen",
			fr: "Yémen"
		},
		flag: "https://flagpedia.net/data/flags/small/ye.png",
		code: "YE",
		idWindev: 246,
		alpha3Code: "YEM"
	},
	{
		name: {
			en: "Zambia",
			fr: "Zambie"
		},
		flag: "https://flagpedia.net/data/flags/small/zm.png",
		code: "ZM",
		idWindev: 247,
		alpha3Code: "ZMB"
	},
	{
		name: {
			en: "Zimbabwe",
			fr: "Zimbabwe"
		},
		flag: "https://flagpedia.net/data/flags/small/zw.png",
		code: "ZW",
		idWindev: 248,
		alpha3Code: "ZWE"
	}
];
