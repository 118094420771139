import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import Layout from "../../components/navigation/Layout";

const Licences = () => {
	return (
		<Layout active_key="admin">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto">
				<iframe
					src="https://web-test.dag-system.com/licence"
					className="h-full w-full"
				/>
			</div>
			<BottomBarNavigation go_to="/" />
		</Layout>
	);
};

export default Licences;
