import { Tooltip, Typography } from "@material-tailwind/react";
import "chart.js/auto";
import Chart, { ChartData, Color, LegendItem } from "chart.js/auto";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import {
	AiOutlineClose,
	AiOutlineInfoCircle,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { BiBarChart } from "react-icons/bi";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import {
	acceptDocument,
	downloadDocument,
	getDocument,
	getEventDocsConfig,
	getEventIdentityConfig,
	getEventRunsConfig,
	getEventStatistics,
	getEventSubscriptions,
	refuseDocument,
	uploadDocuments
} from "../api/event";
import { ReactComponent as SvgCollecte } from "../assets/collecte.svg";
import { ReactComponent as SvgUser } from "../assets/user.svg";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import ImportExport from "../components/common/ImportExport";
import PageLoader from "../components/common/PageLoader";
import SectionHeading from "../components/common/SectionHeading";
import SendMail from "../components/common/SendMail";
import AddSubModal from "../components/modals/AddSubModal";
import CampaignModal from "../components/modals/CampaignModal";
import RegistrationEditModalLogic from "../components/modals/RegistrationEditModalLogic";
import SendMailModal from "../components/modals/SendMailModal";
import Layout from "../components/navigation/Layout";
import AddSubButton from "../components/registrations/AddSubButton";
import RegistrationTableItem from "../components/registrations/RegistrationTableItem";
import RegistrationsTableFooter from "../components/registrations/RegistrationsTableFooter";
import RegistrationsTableHeader from "../components/registrations/RegistrationsTableHeader";
import { AppContext } from "../contexts/AppContext";
import { LiveContext } from "../contexts/LiveContext";
import trad from "../lang/traduction";
import { IDocumentKey } from "../types/Lang";
import { fileToB64 } from "../utils/Base64";
import { capitalize } from "../utils/String";
import Toast from "../utils/Toasts";

const DOCUMENT_TYPES = {
	licence: 2,
	certificat: 4,
	autorisationParentale: 5,
	passSanitaire: 6,
	dechargeResponsabilite: 7
};

function RegistrationsDetails() {
	const { slug } = useParams();
	const { state } = useLocation();
	const location = useLocation();
	const {
		lang,
		subscriptionsFilters,
		setSubscriptionsFilter,
		subscriptionsSorting,
		setSubscriptionsSorting
	} = useContext(AppContext);
	const { liveConfig } = useContext(LiveContext);

	const [searchQuery, setSearchQuery] = useState("");
	const debouncedSearch = useDebounce<string>(searchQuery, 500);
	const debouncedFilters = useDebounce(subscriptionsFilters, 500);
	const debouncedSort = useDebounce(subscriptionsSorting, 500);
	const [openMail, setOpenMail] = useState<boolean>(false);
	const [openCampain, setOpenCampain] = useState<boolean>(false);
	const [openedDocument, setOpenedDocument] = useState<any>({});
	const [editedSubscription, setEditedSubscription] = useState<any>(null);
	const [uploadedDocuments, setUploadedDocuments] = useState({
		licence: "",
		certificat: "",
		autorisation: ""
	});
	const [loading, setLoading] = useState(false);
	const [nmbPerPage, setNmbPerPage] = useState("25");
	const [page, setPage] = useState(1);
	const [openedFile, setOpenedFile] = useState<{
		type: number;
		file: string;
	} | null>(null);
	const [openModalAddSub, setOpenModalAddSub] = useState<boolean>(false);
	const [raceData, setRaceData] = useState<any>(null);
	const [receiverMail, setReceiverMail] = useState<string | null>(null);
	const [receiverSubcriptionId, setReceiverSubcriptionId] = useState<number>(0);
	const [openedRefuseReason, setOpenedRefuseReason] = useState(false);
	const [refuseReason, setRefuseReason] = useState("");
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [onlyWithDocs, setOnlyWithDocs] = useState(false);
	const [showStats, setShowStats] = useState(true);
	const [chartData1, setChartData1] =
		useState<ChartData<"doughnut", number[], unknown>>();
	const [chartData2, setChartData2] =
		useState<ChartData<"doughnut", number[], unknown>>();
	const [chartData3, setChartData3] =
		useState<ChartData<"doughnut", number[], unknown>>();

	const {
		data = [],
		isLoading,
		error,
		refetch
	} = useQuery({
		queryKey: [
			"runners",
			slug,
			nmbPerPage,
			page,
			debouncedSearch,
			debouncedFilters,
			debouncedSort,
			onlyWithDocs
		],
		queryFn: () =>
			getEventSubscriptions(
				slug,
				debouncedSearch,
				nmbPerPage,
				page,
				debouncedFilters,
				debouncedSort,
				onlyWithDocs
			),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false
	});
	const debouncedData = useDebounce(data, 500);

	// Si un slug est présent, récupération des infos existantes
	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	// RUN INFOS

	const {
		data: RunsInfos = [],
		isLoading: RunsLoading,
		refetch: RefetchRuns
	} = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data: Statistics, isLoading: LoadingStats } = useQuery({
		queryKey: ["stats", slug],
		queryFn: () => getEventStatistics(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: DocsAsked, isLoading: DocsAskedLoading } = useQuery({
		queryKey: ["event_docs", slug],
		queryFn: () => getEventDocsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	// Prepare data for charts
	useEffect(() => {
		let data: any = {
			labels: [],
			datasets: [
				{
					label: trad[lang].registrations,
					data: [],
					backgroundColor: [
						"rgba(75, 192, 192, 1)",
						"rgba(153, 102, 255, 1)",
						"rgba(255, 159, 64, 1)",
						"rgba(255, 99, 132, 1)",
						"rgba(54, 162, 235, 1)",
						"rgba(255, 206, 86, 1)",
						"rgba(177, 177, 177, 1)",
						"#57E0B4",
						"#4D6061",
						"#61614D",
						"#E0DE41",
						"#614D51",
						"rgba(255, 99, 132, 1)",
						"rgba(54, 162, 235, 1)",
						"rgba(255, 206, 86, 1)",
						"rgba(75, 192, 192, 1)",
						"rgba(153, 102, 255, 1)"
					],
					borderWidth: 0
				}
			]
		};

		let dataGenders: any = {
			labels: [],
			datasets: [
				{
					label: "Genres",
					data: [],
					backgroundColor: [
						"rgba(255, 99, 132, 1)",
						"rgba(54, 162, 235, 1)",
						"rgba(253, 224, 71, 1)"
					],
					borderWidth: 0
				}
			]
		};

		let numM: number = 0;
		let numF: number = 0;
		let numX: number = 0;
		if (Statistics) {
			if (Statistics.subs) {
				for (const race of Statistics.subs) {
					data.labels.push(race.nom);
					data.datasets[0].data.push(parseInt(race.valid_quantity));
					numM += parseInt(race.gender_m_count);
					numF += parseInt(race.gender_f_count);
					numX += parseInt(race.gender_x_count);
				}

				dataGenders.datasets[0].data.push(numF);
				dataGenders.datasets[0].data.push(numM);
				dataGenders.datasets[0].data.push(numX);
			}
		}

		setChartData1(data);
		setChartData3(dataGenders);
	}, [Statistics]);

	const getGendersTooltip = (tooltipItems: any) => {
		let result: string = "";
		if (Statistics && Statistics.subs) {
			for (const race of Statistics.subs) {
				const dataIndex = tooltipItems.dataIndex;
				let m: number = parseInt(race.gender_m_count);
				let f: number = parseInt(race.gender_f_count);
				let x: number = parseInt(race.gender_x_count);
				result +=
					(dataIndex === 0 ? f : dataIndex === 1 ? m : x) +
					" : " +
					race.nom +
					"\n";
			}
		}
		return result;
	};

	useEffect(() => {
		let data: any = {
			labels: [trad[lang].processed + " : ", trad[lang].waiting + " : "],
			datasets: [
				{
					label: capitalize(trad[lang].registrations),
					data: [],
					backgroundColor: ["rgba(143, 240, 178, 1)", "rgba(253, 224, 71, 1)"],
					borderWidth: 0
				}
			]
		};

		if (debouncedData) {
			const delta = debouncedData.count - debouncedData.numToValidate;
			data.labels[0] += debouncedData.count - debouncedData.numToValidate;
			data.labels[1] += debouncedData.numToValidate;
			data.datasets[0].data.push(
				debouncedData.count - debouncedData.numToValidate
			);
			data.datasets[0].data.push(debouncedData.numToValidate + 0);
		}
		setChartData2(data);
	}, [debouncedData]);

	const closeDocuments = () => setOpenedDocument((old: any) => ({}));
	const closeFile = () => setOpenedFile(null);

	const handleFiles = async (type: string, file: any) => {
		try {
			const fileb64 = await fileToB64(file[0]);

			setUploadedDocuments((old) => ({ ...old, [type]: fileb64 }));
		} catch (error) {
			console.warn("[handleFiles]");
			throw error;
		}
	};

	const handleValidate = async () => {
		try {
			setLoading(true);
			const doc_data = {
				IDPersonne: openedDocument.ide_personne,
				IdInscription: openedDocument.idInscription,
				...uploadedDocuments
			};

			await uploadDocuments(doc_data);

			refetch();
			setOpenedDocument({});
			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			console.error("[handleValidate]");
			Toast.error(trad[lang].updateError);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const seeDocument = async (type: number) => {
		if (!slug) return;

		const doc_data = await getDocument(
			openedDocument.ide_personne,
			openedDocument.idInscription,
			openedDocument.document.id,
			slug
		);

		const extension = openedDocument.document.extension;

		let additional =
			extension == "pdf"
				? `application/${extension};base64`
				: `image/${extension};base64`;

		setOpenedFile({
			type: openedDocument.document.ide_type_document,
			file: `data:${additional},${doc_data}`
		});
	};

	const dlDocument = async (type: number) => {
		if (!slug) return;

		await downloadDocument(
			openedDocument.ide_personne,
			openedDocument.idInscription,
			openedDocument.document.id,
			slug,
			openedDocument.document.extension
		);
	};

	const resizeIframe = () => {
		const iframe: HTMLIFrameElement | null =
			document.querySelector("#document_file");
		if (!iframe) return;

		const scroll_height = iframe?.contentWindow?.document.body.scrollHeight;
		const scroll_width = iframe?.contentWindow?.document.body.scrollHeight;

		if (!scroll_height || !scroll_width) return;

		iframe.height = scroll_height?.toString();
		iframe.width = scroll_width?.toString();
	};

	const acceptDoc = async () => {
		try {
			setLoading(true);
			await acceptDocument(openedDocument.document.id);

			await refetch();

			closeDocuments();
			Toast.success(trad[lang].accept_document_success);
		} catch (error) {
			Toast.error(trad[lang].accept_document_error);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const refuseDoc = async () => {
		try {
			setLoading(true);
			await refuseDocument(openedDocument.document.id, refuseReason);
			await refetch();
			closeDocuments();
			setOpenedRefuseReason(false);
			Toast.success(trad[lang].refuse_document_success);
		} catch (error) {
			Toast.error(trad[lang].refuse_document_error);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setUploadedDocuments({
			licence: "",
			certificat: "",
			autorisation: ""
		});
	}, [openedDocument]);

	useEffect(() => {
		if (state?.status) {
			setSubscriptionsFilter({
				bib: "",
				run: "",
				contact: "",
				name: "",
				status: state.status,
				date: ""
			});
		}
	}, [state]);

	useEffect(() => {
		setPage(1);
	}, [
		debouncedSearch,
		debouncedFilters,
		debouncedSort,
		nmbPerPage,
		onlyWithDocs
	]);

	useLayoutEffect(() => {
		setSubscriptionsSorting({
			bib: null,
			run: null,
			contact: null,
			name: null,
			status: null,
			date: -1
		});
		setSubscriptionsFilter({
			bib: "",
			run: "",
			contact: "",
			name: "",
			status: location?.state?.status || "",
			date: ""
		});
	}, [location]);

	if (EventLoading || DocsAskedLoading) {
		return <PageLoader menu_key="registrations" />;
	}

	const getOrCreateTooltip = (chart: any) => {
		let tooltipEl = chart.canvas.parentNode.querySelector("div");

		if (!tooltipEl) {
			tooltipEl = document.createElement("div");
			tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
			tooltipEl.style.borderRadius = "3px";
			tooltipEl.style.color = "white";
			tooltipEl.style.opacity = 1;
			tooltipEl.style.pointerEvents = "none";
			tooltipEl.style.position = "absolute";
			tooltipEl.style.transform = "translate(-50%, 0)";
			tooltipEl.style.transition = "all .1s ease";
			tooltipEl.style.zIndex = "100";

			const table = document.createElement("table");
			table.style.margin = "0px";

			tooltipEl.appendChild(table);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		return tooltipEl;
	};

	const externalTooltipHandler = (context: any) => {
		// Tooltip Element
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}

		// Set Text
		if (tooltip.body) {
			const titleLines = tooltip.title || [];
			let bodyLines = tooltip.body.map((b: any) => b.lines);

			const tableHead = document.createElement("thead");

			titleLines.forEach((title: any) => {
				const tr = document.createElement("tr");
				const th = document.createElement("th");
				const text = document.createTextNode(title);

				th.appendChild(text);
				tr.appendChild(th);
				tableHead.appendChild(tr);
			});

			const tableBody = document.createElement("tbody");
			bodyLines = bodyLines[0].toString().split("\n");
			bodyLines.forEach((body: any, i: number) => {
				const colors = tooltip.labelColors[i];

				let span;
				let tr = document.createElement("tr");
				let td = document.createElement("td");
				td.style.borderWidth = "0px";
				td.style.fontFamily = "Montserrat";
				td.style.fontSize = "15px";
				if (i === 0) {
					td.style.fontWeight = "bold";
					span = document.createElement("span");
					span.style.background = colors?.backgroundColor;
					span.style.borderColor = colors?.borderColor;
					span.style.borderWidth = "0px";
					span.style.marginRight = "10px";
					span.style.height = "10px";
					span.style.width = "10px";
					span.style.display = "inline-block";

					if (tr.style) {
						tr.style.backgroundColor = "inherit";
						tr.style.borderWidth = "0px";
					}

					td.appendChild(span);
				}

				const text = document.createTextNode(body);
				td.appendChild(text);
				tr.appendChild(td);
				tableBody.appendChild(tr);
			});

			const tableRoot = tooltipEl.querySelector("table");

			// Remove old children
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}

			// Add new children
			tableRoot.appendChild(tableHead);
			tableRoot.appendChild(tableBody);
		}

		const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.left = positionX + tooltip.caretX + "px";
		tooltipEl.style.top = positionY + tooltip.caretY + "px";
		tooltipEl.style.font = tooltip.options.bodyFont.string;
		tooltipEl.style.padding =
			tooltip.options.padding + "px " + tooltip.options.padding + "px";
	};

	const emptyLegendItem: LegendItem = {
		text: ""
	};

	const shortenLabels = (chart: Chart): LegendItem[] => {
		if (chart.data.labels) {
			const ds = chart.data.datasets[0] || { backgroundColor: [] };
			const bc: Color[] = (ds.backgroundColor as Color[]) || [];
			return chart.data.labels.map((l, i) => {
				const bg: any = bc[i] || "rgba(255, 99, 132, 1)";
				const label = l as string;
				const maxChars = 19;
				return {
					datasetIndex: 0 as number,
					index: i,
					text:
						label.length > maxChars ? label.slice(0, maxChars) + "..." : label,
					fillStyle: bg,
					strokeStyle: bg,
					borderRadius: 0,
					hidden: !chart.getDataVisibility(i)
				};
			});
		}
		return [emptyLegendItem];
	};

	return (
		<Layout active_key="registrations">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: EventInfos?.name,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "subscriptions", to: "#", active: true }
					]}
				/>

				{/* Page heading */}
				<DetailsPageHeading
					pageTitle={`${EventInfos?.name} - ${trad[lang].registrations}`}
					count={debouncedData?.numFilteredResults || 0}
				/>

				<div className="flex w-full flex-col justify-start gap-3 md:flex-row">
					<AddSubButton
						lang={lang}
						refetch={refetch}
						setOpenModal={setOpenModalAddSub}
						setRaceData={setRaceData}
					/>
					<SendMail
						openMail={openMail}
						openCampain={openCampain}
						setOpenMail={setOpenMail}
						setOpenCampain={setOpenCampain}
						setReceiverMail={setReceiverMail}
						setReceiverSubcriptionId={setReceiverSubcriptionId}
						lang={lang}
						refetch={refetch}
					/>
					<ImportExport
						lang={lang}
						refetch={refetch}
						numSubs={
							debouncedData?.result?.filter((item: any) => {
								return item.ide_type_inscription == 21;
							})?.length || 0
						}
						eventName={EventInfos?.name}
						data={debouncedData}
					/>
					{/* BUTTON STATS */}
					<button
						type="button"
						className={`ml-auto flex w-auto flex-row items-center justify-center rounded-md border py-2.5 focus:outline-none focus:ring-0
                      ${
												showStats
													? "border-transparent bg-primary fill-white text-white"
													: "border-secondary bg-white fill-secondary text-secondary"
											}`}
						onClick={() => setShowStats((old) => !old)}
					>
						<BiBarChart className="ml-1 h-6 w-6 " />
						<p className="mr-3 ml-2 text-base font-medium">Stats</p>
					</button>
				</div>

				{/* STATS CHARTS */}
				{showStats && Statistics && (
					<div className={`relative ml-auto mb-3 select-none`}>
						<div
							className="absolute right-0 mt-1 flex flex-row bg-[#f6fafd] py-2 pr-2"
							style={{
								height: Math.max(140, 29 * Statistics.subs.length) + "px"
							}}
						>
							{/* NB INSCRITS + COLLECTE*/}
							<div className="w-26 ml-2 flex h-full flex-col justify-center 2lg:w-40">
								<div className="flex w-full flex-row rounded-lg bg-white p-1 shadow-md">
									<SvgUser className="invisible ml-1 h-0 w-0 fill-secondary 2lg:visible 2lg:h-12 2lg:w-12" />
									<div className="ml-0 flex w-full flex-col items-center justify-center">
										<p className="text-xs font-semibold">
											{trad[lang].registrations.toUpperCase()}
										</p>
										<p className="-mt-1 text-base font-bold">
											{Statistics.subs.reduce(
												(acc: number, cur: any) =>
													acc + parseInt(cur.valid_quantity),
												0
											)}
										</p>
									</div>
								</div>
								<div className="mt-1 flex w-full flex-row rounded-lg bg-white p-1 shadow-md">
									<SvgCollecte className="invisible ml-1 h-0 w-0 lg:visible lg:h-12 2lg:w-12" />
									<div className="ml-0 flex w-full flex-col items-center justify-center">
										<p className="text-xs font-semibold">
											{trad[lang].earnings.toUpperCase()}
										</p>
										<p className="-mt-1 text-base font-bold">
											{Statistics.total[1][0].TotalTTC}€
										</p>
									</div>
								</div>
							</div>

							{chartData1 &&
								debouncedData.count > 0 &&
								window.innerWidth > 850 && (
									<div className="ml-4 mr-0 mt-0 h-full w-[120px] pb-1 2lg:w-[150px]">
										<Doughnut
											data={chartData1}
											options={{
												responsive: true,
												aspectRatio: 3,
												maintainAspectRatio: false,
												plugins: {
													title: {
														display: true,
														text: trad[lang].chartTitleSubs,
														color: "#000",
														font: { size: 13, family: "Montserrat" }
													},
													legend: {
														display: false,
														position: "left",
														labels: {
															padding: 6,
															boxWidth: 20,
															boxHeight: 8,
															usePointStyle: false,
															generateLabels: shortenLabels,
															font: { size: 13, family: "Montserrat" }
														},
														maxWidth: window.innerWidth < 1180 ? 140 : 240
													},
													tooltip: {
														enabled: false,
														titleFont: { size: 10, family: "Montserrat" },
														bodyFont: { size: 14, family: "Montserrat" },
														external: externalTooltipHandler
													}
												}
											}}
										/>
									</div>
								)}

							{chartData3 && debouncedData.count > 0 && (
								<div className="ml-0 mr-0 mt-0 h-full w-[120px] pb-1 2lg:w-[150px]">
									<Doughnut
										data={chartData3}
										options={{
											responsive: true,
											aspectRatio: 1,
											maintainAspectRatio: false,
											plugins: {
												title: {
													display: true,
													text: trad[lang].chartTitleGenders,
													color: "#000",
													font: { size: 13, family: "Montserrat" }
												},
												legend: {
													display: false,
													position: "left",
													labels: {
														padding: 6,
														boxWidth: 20,
														boxHeight: 8,
														usePointStyle: false,
														generateLabels: shortenLabels,
														font: { size: 13, family: "Montserrat" }
													},
													maxWidth: window.innerWidth < 1180 ? 140 : 160
												},
												tooltip: {
													enabled: false,
													titleFont: { size: 10, family: "Montserrat" },
													bodyFont: { size: 14, family: "Montserrat" },
													external: externalTooltipHandler,
													callbacks: {
														label: function (this: any, tooltipItems: any) {
															let title: string =
																tooltipItems["label"] +
																tooltipItems["formattedValue"] +
																(tooltipItems.dataIndex === 0
																	? " " + trad[lang].female + "s"
																	: tooltipItems.dataIndex === 1
																	? " " + trad[lang].male + "s"
																	: " " + trad[lang].other + "s") +
																"\n";
															let details = getGendersTooltip(tooltipItems);
															return title + details;
														}
													}
												}
											}
										}}
									/>
								</div>
							)}

							{chartData2 && debouncedData.count > 0 && (
								<div className="ml-2 mr-2 h-full w-[220px] py-2 pt-5 2lg:w-[270px]">
									<label className="absolute -mt-3 text-sm font-bold">
										{trad[lang].chartTitleCases}
									</label>
									<Doughnut
										data={chartData2}
										options={{
											responsive: true,
											aspectRatio: 1,
											maintainAspectRatio: false,
											plugins: {
												legend: {
													display: true,
													position: "left",
													align: "center",
													labels: {
														padding: 6,
														boxWidth: 20,
														boxHeight: 8,
														usePointStyle: false,
														generateLabels: shortenLabels,
														font: { size: 14, family: "Montserrat" }
													},
													maxWidth: window.innerWidth < 1180 ? 140 : 160
												},
												tooltip: {
													titleFont: { size: 16, family: "Montserrat" },
													bodyFont: { size: 16, family: "Montserrat" }
												}
											}
										}}
									/>
								</div>
							)}
						</div>
						<div className="h-28 min-[1440px]:h-14"></div>
						<div
							style={{
								height: Math.max(0, 27 * Statistics.subs.length - 140) + "px"
							}}
						></div>
					</div>
				)}

				<SectionHeading
					count={debouncedData?.count || 0}
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					searchTrad={trad[lang].search_subscription}
				/>

				<div className="mb-2 flex w-full items-center justify-start gap-2">
					<input
						type="checkbox"
						name="all"
						id="all"
						checked={onlyWithDocs}
						onChange={() => setOnlyWithDocs((old) => !old)}
						className="rounded-sm"
					/>

					<label htmlFor="all" className="select-none">
						{trad[lang].onlyWithDocStart}
						<span className="font-bold">{trad[lang].onlyWithDocStrong}</span>
					</label>
				</div>

				<div className="w-full py-2 align-middle">
					<div className=" border border-gray-200">
						<table className="w-full divide-y divide-gray-200">
							<RegistrationsTableHeader />

							<tbody className="divide-y divide-gray-200 bg-white">
								{!debouncedData?.result?.length && isLoading ? (
									<tr className="flex flex-row items-center justify-center p-8">
										<td>
											<AiOutlineLoading3Quarters
												size={45}
												className="ml-2 animate-spin"
											/>
										</td>
									</tr>
								) : (
									debouncedData?.result?.map((item: any) => (
										<RegistrationTableItem
											nbMessageEvent={
												liveConfig?.observation
													? JSON.parse(liveConfig.observation)?.campains
															?.length || 0
													: 0
											}
											subscription={item}
											refetch={refetch}
											setEditedSubscription={setEditedSubscription}
											openDocument={setOpenedDocument}
											key={item.id}
											setReceiverMail={setReceiverMail}
											setOpenMail={setOpenMail}
											setReceiverSubcriptionId={setReceiverSubcriptionId}
											docsAsked={DocsAsked}
											setSubscriptionsFilter={setSubscriptionsFilter}
										/>
									))
								)}
							</tbody>

							<RegistrationsTableFooter
								nmbPerPage={nmbPerPage}
								setNmbPerPage={setNmbPerPage}
								page={page}
								setPage={setPage}
								count={debouncedData?.numFilteredResults}
								docsAsked={DocsAsked}
							/>
						</table>
					</div>
				</div>

				{openedDocument.id && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
						<div className="max-w-screen-lg rounded-md border bg-white">
							{/* Modal Header */}
							<div className="mb-3 flex items-start justify-between rounded-t border-b p-4">
								<h3 className="text-xl font-semibold">
									{trad[lang].manage_documents}
								</h3>
								<button
									type="button"
									className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
									onClick={closeDocuments}
								>
									<AiOutlineClose size={16} />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							{/* Modal Body */}
							<div>
								<h2 className="pb-3 text-center text-xl font-bold">
									{trad[lang][openedDocument.key as IDocumentKey]}
								</h2>
								<div className="px-6 pb-6">
									<div className="flex gap-6">
										<input
											className="w-full pb-3"
											type="file"
											onChange={(e) =>
												handleFiles(openedDocument.doc_key, e.target.files)
											}
										/>
									</div>

									{openedDocument.id != "new" && (
										<div className="mt-2 items-center justify-between rounded-md bg-green-100 py-1 px-2 text-green-800">
											<div className="flex flex-row justify-center gap-6">
												<p
													className="cursor-pointer font-bold"
													onClick={() => {
														dlDocument(
															(DOCUMENT_TYPES as any)[openedDocument.doc_key]
														);
													}}
												>
													{trad[lang].download_document}
												</p>
												-
												<div className="flex flex-row">
													<button
														className="cursor-pointer font-bold disabled:opacity-30"
														disabled={
															openedDocument.document.filesize > 2000000 &&
															openedDocument.document.extension === "pdf"
														}
														onClick={() =>
															seeDocument(
																(DOCUMENT_TYPES as any)[openedDocument.doc_key]
															)
														}
													>
														{trad[lang].see_document}
													</button>
													{/* Info bubble */}
													{openedDocument.document.filesize > 2000000 &&
														openedDocument.document.extension === "pdf" && (
															<Tooltip
																className="text-md z-50 text-center"
																content={
																	<Typography>
																		{trad[lang].cantPreview}
																	</Typography>
																}
															>
																<p className="mt-0.5 ml-1">
																	<AiOutlineInfoCircle />
																</p>
															</Tooltip>
														)}
												</div>
											</div>
										</div>
									)}
								</div>

								{/* Modal Footer */}
								<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
									<button
										type="button"
										className={`rounded-lg  px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 ${
											loading ||
											!uploadedDocuments[
												openedDocument.doc_key as keyof typeof uploadDocuments
											]
												? "bg-gray-400"
												: "bg-primary hover:bg-primarydark focus:ring-blue-300"
										}`}
										onClick={handleValidate}
										disabled={
											loading ||
											!uploadedDocuments[
												openedDocument.doc_key as keyof typeof uploadDocuments
											]
										}
									>
										{loading ? trad[lang].loading : trad[lang].send_documents}
									</button>
									<button
										type="button"
										className={`rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none ${
											loading || openedDocument.id == "new"
												? "cursor-default bg-gray-400"
												: "bg-red-600"
										}`}
										disabled={loading || openedDocument.id == "new"}
										onClick={() => setOpenedRefuseReason(true)}
									>
										{trad[lang].refuse_document}
									</button>
									<button
										type="button"
										className={`rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none ${
											loading || openedDocument.id == "new"
												? "cursor-default bg-gray-400"
												: "bg-green-600"
										}`}
										disabled={loading || openedDocument.id == "new"}
										onClick={acceptDoc}
									>
										{trad[lang].accept_document}
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{openedFile && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
						<div className="max-h-[100vh] w-full max-w-[300px] rounded-md border bg-white sm:max-w-[760px] md:max-w-[1200px]">
							<div className="flex items-start justify-between rounded-t border-b p-4">
								<h3 className="text-xl font-semibold text-gray-900">
									{trad[lang].manage_documents}
								</h3>
								<button
									type="button"
									className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
									onClick={closeFile}
								>
									<AiOutlineClose size={16} />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="flex h-full w-full items-center justify-center text-center">
								{openedFile.file.includes("data:image") ? (
									<img
										src={openedFile.file}
										className="m-auto h-[80vh] w-full object-contain"
									/>
								) : openedFile.file.length > 2000000 ? (
									<div className="py-8 px-4">
										<label className="text-center font-bold">
											{trad[lang].cantPreview}
										</label>
									</div>
								) : (
									<iframe
										src={openedFile.file}
										id="document_file"
										className="h-[80vh] w-full"
										onLoad={resizeIframe}
										seamless={true}
									/>
								)}
							</div>

							<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
								<button
									type="button"
									className="rounded-lg  bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
									onClick={() => dlDocument(openedFile.type)}
									disabled={loading}
								>
									{trad[lang].download_document}
								</button>

								<button
									type="button"
									className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
									onClick={closeFile}
								>
									{trad[lang].close_documents}
								</button>
							</div>
						</div>
					</div>
				)}

				{openedRefuseReason && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-0">
						<div className="max-h-[90vh] w-1/3 max-w-[95%] rounded-md border bg-white">
							<div className="flex items-start justify-between rounded-t border-b p-4">
								<h3 className="text-xl font-semibold text-gray-900">
									{trad[lang].manage_documents}
								</h3>
								<button
									type="button"
									className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
									onClick={() => setOpenedRefuseReason(false)}
								>
									<AiOutlineClose size={16} />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="w-full p-6">
								<h2 className="mb-2 text-xl">{trad[lang].refused_reason}</h2>
								<textarea
									className="block h-32 w-full resize-none rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
									onChange={(e) => {
										setRefuseReason(e.target.value);
									}}
								/>
							</div>

							<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
								<button
									type="button"
									className={`rounded-lg border px-5 py-2.5 text-center text-sm font-medium focus:outline-none ${
										loading
											? "cursor-default border-gray-400 text-gray-400"
											: "border-primary text-primary"
									}`}
									disabled={loading}
									onClick={() => setOpenedRefuseReason(false)}
								>
									{trad[lang].cancel}
								</button>

								<button
									type="submit"
									className={`rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none ${
										loading || !refuseReason.trim()
											? "cursor-default bg-gray-400"
											: "bg-red-600"
									}`}
									disabled={loading || !refuseReason.trim()}
									onClick={refuseDoc}
								>
									{trad[lang].refuse_document_and_notice}
								</button>
							</div>
						</div>
					</div>
				)}

				{editedSubscription !== null && (
					<RegistrationEditModalLogic
						refetch={refetch}
						editedSubscription={editedSubscription}
						setEditedSubscription={setEditedSubscription}
						bibList={data?.bibList}
					/>
				)}

				{openModalAddSub && (
					<AddSubModal
						open={openModalAddSub}
						setOpen={setOpenModalAddSub}
						raceData={raceData}
						lang={lang}
						slug={slug}
						refetch={refetch}
						bibList={data?.bibList}
					/>
				)}

				{openMail && (
					<SendMailModal
						open={openMail}
						setOpen={setOpenMail}
						lang={lang}
						receiver={receiverMail}
						idInscription={receiverSubcriptionId || 0}
						event={EventInfos}
						runs={RunsInfos}
						refetch={refetch}
					/>
				)}
				{
					<CampaignModal
						open={openCampain}
						setOpen={setOpenCampain}
						lang={lang}
					/>
				}
			</div>

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
}

export default RegistrationsDetails;
