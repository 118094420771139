import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";

function Announcements() {
	const { lang } = useContext(AppContext);
	const { announcements } = useContext(UserContext);

	const [currentAnnounceIndex, setCurrentAnnounceIndex] = React.useState(-1);

	const divRefs = useRef<Array<HTMLDivElement | null>>([]);
	let refContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		window.addEventListener("resize", updateLayout);
		return () => {
			window.removeEventListener("resize", updateLayout);
		};
	}, []);

	useEffect(() => {
		updateLayout();
		setCurrentAnnounceIndex(0);
		const interval = setInterval(switchAnnouncement, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [announcements]);

	// Resize height of container in function of divRefs clientHeight
	const updateLayout = () => {
		if (refContainer.current) {
			let maxHeight = 0;
			divRefs.current.forEach((div) => {
				if (div) {
					maxHeight = Math.max(maxHeight, div.clientHeight);
				}
			});
			maxHeight += 20;
			maxHeight = Math.max(maxHeight, 10);
			refContainer.current.style.height = `${maxHeight}px`;
		}
	};

	const switchAnnouncement = () => {
		updateLayout();
		if (announcements && announcements.length > 1)
			setCurrentAnnounceIndex(
				(prevIndex) => (prevIndex + 1) % announcements.length
			);
	};

	return (
		<div
			className="relative mt-1 mb-1 flex w-full shrink-0 items-center justify-center"
			ref={refContainer}
		>
			{announcements.map((item, index) => (
				<div
					ref={(element) => {
						divRefs.current[index] = element;
					}}
					key={index}
					className={`absolute h-fit w-fit select-none whitespace-pre-line ${
						item.type === 2 ? "bg-yellow-500" : "bg-primary"
					} flex flex-row items-center rounded-lg bg-opacity-20 px-4 py-2 text-center transition-all duration-500 ${
						index === currentAnnounceIndex
							? "translate-y-0 opacity-100"
							: "-translate-y-full opacity-0"
					}`}
				>
					{/* Add speaker icon */}
					<svg
						className="mr-1 shrink-0"
						id="Layer_1"
						height="24"
						viewBox="0 0 91 91"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
						data-name="Layer 1"
					>
						<g fill="rgb(0,0,0)">
							<path d="m58.67 28.94c-8.85-13.02-20.67-19.94-26.86-15.7a7.14 7.14 0 0 0 -1.75 1.76c0 .05-14.33 29.05-14.33 29.05l-8 5.42a11.3 11.3 0 0 0 -3 15.7l.36.53a11.32 11.32 0 0 0 15.63 3l12.85 18.93c1.89 2.33 5.52 4.65 10 1.92a6.77 6.77 0 0 0 3-4.6 7.16 7.16 0 0 0 -1.11-5.4l-11.32-16.7s26.76-2.13 26.86-2.17a6.41 6.41 0 0 0 2.33-1c3.18-2.16 4.32-6.69 3.22-12.76-.99-5.59-3.8-11.92-7.88-17.98zm-50.67 34.79-.36-.53a7.79 7.79 0 0 1 2.08-10.83l6.91-4.7 9.12 13.43-6.91 4.7a7.8 7.8 0 0 1 -10.84-2.07zm34.58 17.79a3.7 3.7 0 0 1 -1 5.13 3.71 3.71 0 0 1 -5.14-1l-12.81-18.87 5.15-3.5 1.36-.11zm-13.52-21.78-9.94-14.64 9.48-19.33c.92 5.43 3.61 11.88 7.91 18.21s9.3 11.21 14 14.07zm29.5-2.33c-4.78-.31-12.56-5.7-19.15-15.41s-8.73-18.9-7.26-23.47a4.26 4.26 0 0 1 4-3c4.74 0 12.81 5.42 19.59 15.41 3.82 5.62 6.6 11.5 7.36 16.64 1.49 9.83-4.54 9.83-4.54 9.83z" />
							<path d="m46.17 29.31a9.39 9.39 0 0 0 -7.12 1.47 1.75 1.75 0 1 0 2 2.89 6 6 0 1 1 6.74 9.93 1.75 1.75 0 0 0 1 3.19 1.71 1.71 0 0 0 1-.3 9.49 9.49 0 0 0 -3.55-17.18z" />
							<path d="m60.83 16.77h.17a1.75 1.75 0 0 0 1.74-1.57l1.35-13.12a1.75 1.75 0 1 0 -3.48-.36l-1.34 13.13a1.75 1.75 0 0 0 1.56 1.92z" />
							<path d="m83.45 14.07a1.76 1.76 0 0 0 -2.43-.5l-12.19 8a1.75 1.75 0 0 0 1 3.21 1.78 1.78 0 0 0 1-.28l12.19-8a1.75 1.75 0 0 0 .43-2.43z" />
							<path d="m87.18 35.43-14.18-2.55a1.75 1.75 0 0 0 -.62 3.45l14.17 2.55h.31a1.75 1.75 0 0 0 .31-3.48z" />
						</g>
					</svg>
					{lang === "en"
						? item.annonce_en.replaceAll("\\n", "\n")
						: item.annonce_fr.replaceAll("\\n", "\n")}
				</div>
			))}
		</div>
	);
}

export default Announcements;
