import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { updateFormConfig } from "../../../api/event";
import { getLiveConfig } from "../../../api/live";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IRunFormConfig } from "../../../types/EventForms/ISportRun";
import { classNames } from "../../../utils/Classes";
import { JSONParseSafe } from "../../../utils/Json";
import Toast from "../../../utils/Toasts";

const mandatory_fields = [
	"nom",
	"prenom",
	"sexe",
	"dateNaissance",
	"mail",
	"ffa"
];

const SportEventRunFormModal = ({
	idCalendrier,
	runForm,
	closeModal,
	fields,
	refetch
}: {
	idCalendrier: number;
	runForm: any;
	closeModal: any;
	fields: IRunFormConfig[];
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [isFFAEvent, setIsFFAEvent] = useState<boolean>(false);
	const formatedFields = Object.fromEntries(
		fields.map((item) => [
			item.type.nom_champs,
			{
				visible: item.type.visible,
				required: item.type.obligatoire,
				id: item.id
			}
		])
	);
	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (LiveConfig)
		{
			setIsFFAEvent((JSONParseSafe(LiveConfig?.observation)?.CMPCOD || "").length > 0);
		}
	}, [fields, LiveConfig]);

	const [localForm, setLocalForm] = useState<{
		[key: string]: { visible: boolean; required: boolean; id: number };
	}>({
		...formatedFields,
		...runForm
	});

	const handleValidate = (key: string, value: any) =>
		setLocalForm((old) => ({
			...old,
			[key]: {
				...old[key],
				visible: value,
				required: value == false ? false : old[key].required
			}
		}));

	const handleRequired = (key: string, value: any) =>
		setLocalForm((old) => ({
			...old,
			[key]: {
				...old[key],
				required: value,
				visible: value == true ? true : old[key].required
			}
		}));

	const validateForm = async () => {
		try {
			await updateFormConfig(idCalendrier, localForm);

			refetch();
			closeModal();

			Toast.success(trad[lang].success_updating_fields);
		} catch (error) {
			Toast.error(trad[lang].error_updating_fields);
		}
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white py-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 px-3 text-center text-2xl font-bold">
					{trad[lang].event_run_form_title}
				</h2>

				<div className="mb-5 max-h-[60vh] overflow-y-auto border border-gray-200">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="rounded-lg bg-gray-50">
							<tr>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Type
								</th>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Visible
								</th>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Obligatoire
								</th>
							</tr>
						</thead>

						<tbody className="divide-y divide-gray-200 bg-white">
							{fields.map((item) => (
								<tr key={item.id}>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										{/* @ts-ignore */}
										{trad[lang][`event_field_${item.type.nom_champs === "certificat" && isFFAEvent ? "pps" : item.type.nom_champs}_label`]}
									</td>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<input
											type="checkbox"
											checked={localForm[item.type.nom_champs]?.visible}
											onChange={(e) =>
												handleValidate(item.type.nom_champs, e.target.checked)
											}
											className={classNames(
												"h-4 w-4 rounded border-gray-300 focus:ring-0",
												mandatory_fields.includes(item.type.nom_champs)
													? "text-gray-300"
													: "text-primary focus:border-primary"
											)}
											disabled={mandatory_fields.includes(item.type.nom_champs)}
										/>
									</td>

									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<input
											type="checkbox"
											checked={localForm[item.type.nom_champs]?.required}
											onChange={(e) =>
												handleRequired(item.type.nom_champs, e.target.checked)
											}
											className={classNames(
												"h-4 w-4 rounded border-gray-300 focus:ring-0",
												mandatory_fields.includes(item.type.nom_champs)
													? "text-gray-300"
													: "text-primary focus:border-primary"
											)}
											disabled={mandatory_fields.includes(item.type.nom_champs)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="mt-3 flex flex-row gap-3 px-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={validateForm}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportEventRunFormModal;
