import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { resetPoint } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";

const ManageRunnerReset = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		selectedRunner,
		LiveConfig,
		source,
		openedReset,
		setOpenedReset,
		LiveDataRefetch
	} = useContext(ManageRunnerContext);
	const [loading, setLoading] = useState(false);
	const [time, setTime] = useState("");

	const close = () => setOpenedReset(0);

	const save = async () => {
		try {
			if (!slug || !LiveConfig.id) return;
			setLoading(true);

			await resetPoint(slug, LiveConfig.id, source[1], {
				bib: selectedRunner.e,
				time,
				idCalendrier: selectedRunner.z
			});

			await LiveDataRefetch();

			close();
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!openedReset) {
			setTime("");
		}
	}, [openedReset]);

	if (!openedReset) {
		return <></>;
	}

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-3/5 lg:w-2/5 ">
				{/* Modal Header */}
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold">{`${trad[lang].resetOnRunner} - ${selectedRunner.d} - n°${selectedRunner.e}`}</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={close}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				<div className="grid grid-cols-2 gap-5 p-5">
					<div className="col-span-1 flex flex-col gap-2">
						<p className="font-bold">{trad[lang].bibTime}</p>

						<input
							type="time"
							step={0.1}
							value={time}
							onChange={(e) => setTime(e.target.value)}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						/>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						type="button"
						className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading}
						onClick={close}
					>
						{trad[lang].close}
					</button>
					<button
						type="button"
						className={`rounded-lg border bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-primarymedium focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading}
						onClick={save}
					>
						{trad[lang].save}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageRunnerReset;
