import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { deleteDetections, getDetections, getLiveConfig } from "../api/live";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import Modal from "../components/common/Modal";
import PageLoader from "../components/common/PageLoader";
import SectionHeading from "../components/common/SectionHeading";
import ManageDetectionFilter from "../components/detections/ManageDetectionFilter";
import ManageDetectionFooter from "../components/detections/ManageDetectionFooter";
import ManageDetectionHeader from "../components/detections/ManageDetectionHeader";
import ManageDetectionsItem from "../components/detections/ManageDetectionItem";
import DeleteDetectionModal from "../components/modals/DeleteDetectionModal";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";
import Toast from "../utils/Toasts";

const ManageDetections = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [searchQuery, setSearchQuery] = useState("");
	const [detector, setDetector] = useState<number>(0);
	const debouncedSearch = useDebounce(searchQuery, 250);
	const debouncedDetector = useDebounce(detector, 250);
	const [nmbPerPage, setNmbPerPage] = useState("25");
	const [page, setPage] = useState(1);
	const [all, setAll] = useState(false);
	const [races, setRaces] = useState<any>([0]);
	const [open, setOpen] = useState<boolean>(false);
	const [count, setCount] = useState(0);
	const [openedDeleteDetectionsModal, setOpenedDeleteDetectionsModal] =
		useState(false);

	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data, isLoading, error, refetch } = useQuery({
		queryKey: [
			"detections",
			slug,
			debouncedSearch,
			debouncedDetector,
			nmbPerPage,
			page,
			races,
			all
		],
		queryFn: () =>
			getDetections(
				slug as string,
				debouncedSearch,
				nmbPerPage,
				page,
				debouncedDetector,
				races,
				all
			),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchInterval: 5000,
		enabled: !!slug && !!nmbPerPage && !!page
	});

	const computeFilteredCountedDetections = async () => {
		try {
			const filteredCountedDetections = await getDetections(
				slug as string,
				debouncedSearch,
				data?.count,
				1,
				debouncedDetector,
				races,
				all
			);
			setCount(filteredCountedDetections?.detections?.length);
		} catch (error) {
			console.error("Error fetching detections:", error);
		}
	};

	useEffect(() => {
		computeFilteredCountedDetections();
	}, [
		debouncedSearch,
		debouncedDetector,
		races,
		all,
		page,
		data?.count,
		nmbPerPage,
		searchQuery
	]);

	const selectedLibelle = LiveConfig?.calendrier_child?.find(
		(race: any) => race.id == races[0]
	)?.libelleCourse;

	const handleDeleteDetection = async () => {
		try {
			if (!slug) return;
			const deleteData = await deleteDetections(
				slug,
				debouncedDetector,
				races,
				all,
				debouncedSearch
			);
			setOpenedDeleteDetectionsModal(false);
			Toast.success(trad[lang].deleteDetectionsSuccess);
		} catch (error) {
			Toast.error(trad[lang].deleteDetectionsError);
			throw error;
		}
	};

	useEffect(() => {
		setPage(1);
	}, [debouncedDetector, nmbPerPage, debouncedSearch, races]);

	if (LiveConfigLoading) {
		return <PageLoader menu_key="live" />;
	}
	return (
		<Layout active_key="live">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto overflow-x-hidden p-8">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: LiveConfig?.nom,
							to: `/${slug}/event-details`,
							active: false
						},
						{
							key: "live_results",
							to: `/${slug}/live`,
							active: false
						},
						{
							key: "manage_detections",
							to: "#",
							active: true
						}
					]}
				/>
				<DetailsPageHeading pageTitle={trad[lang].manage_dashboard} />
				<SectionHeading
					title={trad[lang].manage_detections_title}
					count={count || 0}
				/>
				<ManageDetectionFilter
					setSearchQuery={setSearchQuery}
					setPage={setPage}
					detector={detector}
					setDetector={setDetector}
					refetch={refetch}
					credential={LiveConfig.credential}
					idCalendrier={LiveConfig.id}
					all={all}
					setAll={setAll}
					data={LiveConfig.calendrier_child}
					setRaces={setRaces}
					races={races}
					timezone={LiveConfig.timezone}
				/>
				{/* Detections Table */}
				<div className="flex w-full flex-col items-center gap-6 pb-8">
					<div className="mb-5 w-full overflow-x-auto border border-gray-200 align-middle">
						<table className="w-full divide-y divide-gray-200">
							<ManageDetectionHeader />

							<tbody className="divide-y divide-gray-200 bg-white">
								{data?.detections.map((item: any) => (
									<ManageDetectionsItem
										detection={item}
										key={item.IDDetection}
										refetch={refetch}
										credential={LiveConfig?.credential}
										timezone={LiveConfig?.timezone}
										idCalendrier={LiveConfig?.id}
										count={count}
										setCount={setCount}
									/>
								))}
							</tbody>

							<ManageDetectionFooter
								nmbPerPage={nmbPerPage}
								setNmbPerPage={setNmbPerPage}
								page={page}
								setPage={setPage}
								setCount={setCount}
								count={count}
							/>
						</table>
					</div>
				</div>
				<button
					className="flex h-12 w-fit flex-row items-center justify-center gap-2 rounded-md bg-orange-600 px-4 text-white transition-colors hover:bg-orange-700 focus:outline-none"
					onClick={() => setOpenedDeleteDetectionsModal(true)}
				>
					{trad[lang].deleteDetections}
				</button>
			</div>

			{openedDeleteDetectionsModal && (
				<Modal
					height="0%"
					closeModal={() => setOpenedDeleteDetectionsModal(false)}
					style={{ width: "50%" }}
					children={
						<div className="flex flex-col items-center justify-center gap-4">
							<h2>Voulez vous vraiment supprimer toutes les détections ?</h2>

							<div className="mt-5 flex justify-center gap-5">
								<button
									className="mt-auto flex h-12 w-fit flex-row items-center justify-center gap-2 rounded-md bg-red-600 px-4 text-white transition-colors hover:bg-red-700 focus:outline-none"
									onClick={() => setOpenedDeleteDetectionsModal(false)}
								>
									{trad[lang].cancel}
								</button>
								<button
									className="mt-auto flex h-12 w-fit flex-row items-center justify-center gap-2 rounded-md bg-green-600 px-4 text-white transition-colors hover:bg-green-700 focus:outline-none"
									onClick={handleDeleteDetection}
								>
									{trad[lang].confirm}
								</button>
							</div>
						</div>
					}
				/>
			)}

			<BottomBarNavigation
				go_to_trad="Coureurs"
				go_to={`/${slug}/manage-runners`}
				scd_go_to_trad="Configuration"
				scd_go_to={`/${slug}/live-details`}
			/>

			{open && (
				<DeleteDetectionModal
					lang={lang}
					open={open}
					setOpen={setOpen}
					refetch={refetch}
					selectedLibelle={selectedLibelle}
					name={LiveConfig?.nom}
					prestation={data?.prestation}
					races={races}
				/>
			)}
		</Layout>
	);
};

export default ManageDetections;
