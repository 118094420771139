import {
	HiOutlineArrowsRightLeft,
	HiOutlineCurrencyEuro,
	HiOutlinePaperClip
} from "react-icons/hi2";
import trad from "../lang/traduction";
import { ILang } from "../types/Lang";

export const AdminConfig = (lang: ILang) => [
	{
		key: "finances",
		name: trad[lang].finances,
		description: trad[lang].finances_desc,
		icon: HiOutlineCurrencyEuro,
		href: "/admin/finances"
	},
	{
		key: "finances_resume",
		name: trad[lang].finances_resume,
		description: trad[lang].finances_resume_desc,
		icon: HiOutlineArrowsRightLeft,
		href: "/admin/finances/resume"
	},
	{
		key: "licences",
		name: trad[lang].licences,
		description: trad[lang].licences_desc,
		icon: HiOutlinePaperClip,
		href: "/admin/licences"
	}
];
