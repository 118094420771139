import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function Error403() {
	const { lang } = useContext(AppContext);

	return (
		<div
			className="h-screen bg-cover bg-top sm:bg-top"
			style={{
				backgroundImage:
					'url("https://images.unsplash.com/photo-1510133768164-a8f7e4d4e3dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80")'
			}}
		>
			<div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
				<p className="text-sm font-semibold uppercase tracking-wide text-gloom">
					{trad[lang].error403}
				</p>
				<h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gloom sm:text-5xl">
					{trad[lang].restrictedPage}
				</h1>
				<p className="mt-2 text-lg font-medium text-primarydark">
					{trad[lang].contactOrga}
				</p>
				<div className="mt-6">
					<Link
						to="/"
						className="inline-flex items-center rounded-md border border-transparent bg-primary bg-opacity-75 px-4 py-2 text-sm font-medium text-white"
					>
						{trad[lang].goBackHome}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Error403;
