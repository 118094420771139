import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEventOptionsConfig, getSubscriptionOptions } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

function RegistrationEditOption({
	editedSubscription,
	slug,
	index,
	editFormOptions,
	setEditFormOptions
}: {
	editedSubscription?: any | null;
	slug?: string | undefined;
	index?: number;
	editFormOptions: any;
	setEditFormOptions: any;
}) {
	const { lang } = useContext(AppContext);
	const [form, setForm] = useState<any>();

	const { data: dataSubOptions, isLoading: isLoadingSubOptions } = useQuery({
		queryKey: ["subscription_option", editedSubscription?.id],
		queryFn: () => getSubscriptionOptions(editedSubscription?.id || 0),
		refetchOnWindowFocus: false,
		enabled: !!editedSubscription
	});

	const { data: dataSlugOptions, isLoading: isLoadingSlugOptions } = useQuery({
		queryKey: ["slug_options", slug],
		queryFn: () => getEventOptionsConfig(slug || ""),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (isLoadingSubOptions === false && dataSubOptions && editedSubscription) {
			setForm(JSON.parse(JSON.stringify(dataSubOptions)));
		}
	}, [dataSubOptions, isLoadingSubOptions]);

	useEffect(() => {
		if (
			isLoadingSlugOptions === false &&
			dataSlugOptions &&
			dataSlugOptions.options &&
			slug
		) {
			setForm(JSON.parse(JSON.stringify(dataSlugOptions.options)));
		}
	}, [dataSlugOptions, isLoadingSlugOptions]);

	useEffect(() => {
		if (form && (dataSubOptions || dataSlugOptions)) {
			if (
				JSON.stringify(form) !==
				JSON.stringify(dataSubOptions || dataSlugOptions.options)
			) {
				form.index = index;
				setEditFormOptions(form);
			}
		}
	}, [form]);

	const getOptionsDropdown = function (index: number, opt: any) {
		let options: any[] = [];
		let defaultValue: number = 0;

		// No value by default (--Select--)
		defaultValue = 0;
		options.push({ key: 0, value: trad[lang].select });

		// Numbers
		if (opt?.type == 3) {
			defaultValue = opt.Quantite;
			for (let i = opt.minQuantity; i <= opt.maxQuantity; i++) {
				options.push({ key: i, value: i });
			}
		}
		// Dropdown strings
		if (opt?.type == 1) {
			for (const drop of opt.products) {
				if (drop.Quantite > 0) defaultValue = drop.idProduit;
				options.push({ key: drop.idProduit, value: drop.designation });
			}
		}
		let label: string = opt.designation;

		return (
			<div className="w-full pt-1" key={index}>
				<label className="block text-left text-sm font-medium text-gray-700">
					{label}
				</label>
				<div className="relative mt-2 flex flex-grow items-stretch focus-within:z-10">
					<select
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
						defaultValue={defaultValue}
						onChange={(e) => {
							// Numbers
							if (opt.type == 3) {
								opt.Quantite = parseInt(e.target.value);
								setForm([...form]);
							} else if (opt.type == 1) {
								const idProduit = parseInt(e.target.value);

								// Reset quantity of products
								for (const optProduct of opt?.products) {
									optProduct.Quantite = 0;
								}

								// Set quantity
								let item = opt?.products?.find(
									(item: any) => item.idProduit == idProduit
								);
								if (item) {
									item.Quantite = 1;
									setForm([...form]);
								}
							}
						}}
					>
						{options.map((item: any, index: number) => {
							return (
								<option key={index} value={item.key}>
									{item.value}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		);
	};

	const getOptionCheckbox = function (index: number, opt: any) {
		let quantity = parseInt(opt.Quantite);
		return (
			<div className="w-full pt-1" key={index}>
				<label className="block text-left text-sm font-medium text-gray-700">
					{opt.designation}
				</label>
				<div className="relative mt-2 flex flex-grow items-stretch focus-within:z-10">
					<input
						type="checkbox"
						defaultChecked={quantity >= 1}
						onChange={(e) => {
							opt.Quantite = e.target.checked ? 1 : 0;
							setForm([...form]);
						}}
						className="h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-0"
					/>
				</div>
			</div>
		);
	};

	return (
		<>
			{!isLoadingSubOptions && (
				<div className="w-full">
					<div key="options">
						<div className="flex w-full flex-col items-center gap-4 pt-1">
							{form?.length == 0 && (
								<div className="text-center">{trad[lang].noOptions}</div>
							)}
							{form?.map((option: any, index: number) => {
								if (
									// Dropdown strings or numbers
									option.type == 1 ||
									option.type == 3
								)
									return getOptionsDropdown(index, option);
								else if (option.type == 2)
									// Checkbox
									return getOptionCheckbox(index, option);
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default RegistrationEditOption;
