import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { createParcours, updateParcours } from "../../../api/event";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { fileToB64 } from "../../../utils/Base64";
import Toast from "../../../utils/Toasts";

const SportParcoursModal = ({
	parcours,
	closeModal,
	refetch
}: {
	parcours: any;
	closeModal: any;
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { idetape } = useParams();
	const [parcoursForm, setParcoursForm] = useState({
		id: parcours.id,
		nom: parcours.nom,
		actif: parcours.actif,
		file: ""
	});

	const handleChange = (key: string, value: any) =>
		setParcoursForm((old: any) => ({ ...old, [key]: value }));

	const saveParcours = async () => {
		try {
			if (!parcoursForm.nom) {
				Toast.error(trad[lang].event_form_required);
				return;
			}

			if (parcoursForm.id) {
				await updateParcours(parcoursForm.id, parcoursForm);
			} else {
				await createParcours(parseInt(idetape as string, 10), parcoursForm);
			}

			refetch();
			Toast.success(trad[lang].success_creation_parcours);
			closeModal();
		} catch (error) {
			Toast.error(trad[lang].error_creation_parcours);
		}
	};

	const handleFiles = async (file: any) => {
		try {
			const fileb64 = await fileToB64(file[0]);

			setParcoursForm((old) => ({ ...old, file: fileb64 }));
		} catch (error) {
			console.warn("[handleFiles]");
			throw error;
		}
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[80vh] w-4/5 min-w-[320px] max-w-screen-lg overflow-y-auto rounded-md border bg-white p-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{parcours.nom || trad[lang].create_route}
				</h2>

				<div className="flex flex-col gap-3 md:grid md:grid-cols-2">
					{/* Nom de l'étape */}
					<div className={`col-span-2 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_name"}>
							{trad[lang].parcours_name_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="step_name"
							className="rounded-md"
							onChange={(e) => handleChange("nom", e.target.value)}
							value={parcoursForm.nom}
						/>
					</div>

					{/* Fichier GPX */}
					<div className={`col-span-2 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"gpxFile"}>
							{trad[lang].parcours_file_label}
						</label>

						<input
							id="gpxFile"
							type="file"
							className="rounded-md border border-gray-500 p-2 focus:outline-blue-600"
							// accept=".gpx"
							onChange={(e) => handleFiles(e.target.files)}
						/>
					</div>

					{/* Actif */}
					<div className={`col-span-2 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor={"step_name"}>
							{trad[lang].parcours_active_label}
						</label>

						<input
							type="checkbox"
							id="step_name"
							className="rounded-md"
							onChange={() => handleChange("actif", parcoursForm.actif ? 0 : 1)}
							checked={parcoursForm.actif ? true : false}
						/>
					</div>
				</div>

				<div className="mt-3 flex flex-row gap-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].cancel}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={saveParcours}
					>
						{parcoursForm.id
							? trad[lang].update_parcours
							: trad[lang].create_parcours}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportParcoursModal;
