import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFunnel } from "react-icons/bs";
import { HiOutlineXMark } from "react-icons/hi2";
import { TbFilterOff } from "react-icons/tb";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "regenerator-runtime";
import { getEventRunList, getExport } from "../../api/event";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { classNames } from "../../utils/Classes";
import {
	COLUMNS,
	GENDERS,
	STATUS,
	SUBSCRIPTION_TYPES,
	exportFormat
} from "../../utils/ExportFormat";
import Toast from "../../utils/Toasts";

const IMPORT_TYPE: any = { REPLACE: "replace", NOUPDATE: "noupdate" };

function ExportModal({
	open,
	setOpen,
	lang,
	refetch,
	numSubs,
	format
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	refetch: any;
	numSubs: number;
	format: string;
}) {
	const { slug } = useParams();
	const [errors, setErrors] = useState<string[]>([]);
	const [columns, setColumns] = useState<any[]>(
		JSON.parse(JSON.stringify(COLUMNS))
	);
	const [genders, setGenders] = useState<any>(GENDERS);
	const [status, setStatus] = useState<any>(STATUS);
	const [types, setTypes] = useState<any>(SUBSCRIPTION_TYPES);
	const [races, setRaces] = useState<any>([]);
	const [oneLine, setOneLine] = useState(false);

	const [loadedRaces, setLoadedRaces] = useState<any>([]);

	const { data: dataRuns, isLoading: isLoadingRuns } = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (dataRuns) {
			let listRaces: any[] = dataRuns.calendrier_child?.map((cal: any) => {
				return { value: cal.id, label: cal.nom };
			});
			setLoadedRaces(listRaces);
			setRaces(listRaces);
		}
	}, [dataRuns]);

	const animatedComponents = makeAnimated();

	// Handle filters choices
	const handleFilterChoice = (list: any, key: string) => {
		switch (key) {
			case "races":
				setRaces(list);
				break;
			case "genders":
				setGenders(list);
				break;
			case "status":
				setStatus(list);
				break;
			case "types":
				setTypes(list);
				break;
			default:
				break;
		}
	};

	// Handle columns choices
	const handleColumnChoice = (id: any, checked: any) => {
		// Setcolumns cheched or not
		const temp = columns;
		const newColumns = temp.map((column: any) => {
			if (column.id == id) {
				column.checked = !column.checked;
			}
			return column;
		});
		setColumns(newColumns);
	};

	// Handle send infos to API
	const save = async () => {
		try {
			// Check if datas not empty
			if (races && !races.length) {
				Toast.error(trad[lang].toast_raceChoice);
				return;
			}
			if (genders && !genders.length) {
				Toast.error(trad[lang].genderChoice);
				return;
			}
			if (status && !status.length) {
				Toast.error(trad[lang].toast_statusChoice);
				return;
			}
			if (types && !types.length) {
				Toast.error(trad[lang].typeChoice);
				return;
			}

			// Columns checks

			// Body
			const body: any = {
				races: races,
				genders: genders,
				status: status,
				types: types,
				columns: columns,
				oneLine
			};

			// API call
			const { data, headers } = await getExport(
				slug as string,
				format as string,
				body
			);

			// Convert Blob to File : according to extension...
			const type = exportFormat.filter(
				(type: any) => type.format === format
			)[0];

			const blob = new Blob([data], {
				type: type.mimeType
			});

			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			const eventName = dataRuns.nom;
			const extension = type.extension || "xlsx";
			link.href = url;
			link.download = `${
				eventName ? eventName : "Liste des inscriptions"
			}.${extension}`;
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
			link.remove();
		} catch (error) {
			// Toast error
			console.error(error);
			Toast.error(trad[lang].typeChoice);
		}
	};

	// Re-initialize filters to default values
	const reInitialize = () => {
		setRaces(loadedRaces);
		setStatus(STATUS);
		setTypes(SUBSCRIPTION_TYPES);
		setGenders(GENDERS);
		setColumns(JSON.parse(JSON.stringify(COLUMNS)));
		setOneLine(false);
	};

	const customNoOptionsMessage = () => {
		return <span>Aucun choix</span>;
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={setOpen}
				>
					<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle md:max-w-2xl lg:max-w-4xl">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="mb-6 w-full sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{trad[lang].exportTitle} : {format}
										</Dialog.Title>
									</div>

									<div className="mt-10 mb-0 flex w-full flex-col content-center justify-center text-center">
										{/* CHOIX DES FILTRES */}
										<div className="mr-5 flex w-full flex-row flex-wrap justify-center rounded-lg border-2 border-blue-100 p-4">
											<h1 className="mb-5 flex w-full justify-center font-bold">
												Choix des filtres{" "}
												<BsFunnel className="my-auto mx-2  text-red-900" />
											</h1>

											{/* Courses */}
											{!isLoadingRuns && (
												<div className="flex w-1/2 flex-col justify-between p-8 pt-1 md:flex-row md:items-center md:gap-6">
													<label className="block text-left text-sm font-medium text-gray-700">
														{trad[lang].raceChoice}
													</label>
													<Select
														className="w-full text-left"
														options={loadedRaces}
														isMulti
														components={animatedComponents}
														// defaultValue={loadedRaces}
														noOptionsMessage={customNoOptionsMessage}
														value={races}
														onChange={(e) => handleFilterChoice(e, "races")}
													/>
												</div>
											)}

											{/* Sexe */}
											<div className="flex w-1/2 flex-col justify-between p-8 pt-1 md:flex-row md:items-center md:gap-6">
												<label className="block text-left text-sm font-medium text-gray-700">
													{trad[lang].sexeChoice}
												</label>
												<Select
													className="w-full text-left"
													options={GENDERS}
													isMulti
													components={animatedComponents}
													defaultValue={GENDERS}
													noOptionsMessage={customNoOptionsMessage}
													value={genders}
													onChange={(e) => handleFilterChoice(e, "genders")}
												/>
											</div>

											{/* Statut inscription */}
											<div className="flex w-1/2 flex-col justify-between p-8 pt-1  md:flex-row md:gap-6">
												<label className="block text-left text-sm font-medium text-gray-700">
													{trad[lang].statusChoice}
												</label>
												<Select
													className="w-full text-left"
													options={STATUS}
													isMulti
													isClearable
													components={animatedComponents}
													defaultValue={STATUS}
													value={status}
													noOptionsMessage={customNoOptionsMessage}
													onChange={(e) => handleFilterChoice(e, "status")}
												/>
											</div>

											{/* Type inscription */}
											<div className="flex w-1/2 flex-col justify-between p-8 pt-1 md:flex-row md:items-center md:gap-6">
												<label className="block text-left text-sm font-medium text-gray-700">
													{trad[lang].subscriptionType}
												</label>
												<Select
													className="w-full text-left"
													options={SUBSCRIPTION_TYPES}
													isMulti
													closeMenuOnSelect={false}
													components={animatedComponents}
													defaultValue={SUBSCRIPTION_TYPES}
													value={types}
													noOptionsMessage={customNoOptionsMessage}
													onChange={(e) => handleFilterChoice(e, "types")}
												/>
											</div>
										</div>

										{/* CHOIX DES CHAMPS A EXPORTER */}
										<div className="mt-6 w-full rounded-lg border-2 border-blue-100 p-4">
											<h1 className="mb-5 flex justify-center font-bold">
												Choix des colonnes{" "}
												<AiOutlineUnorderedList className="my-auto mx-2 text-green-900" />
											</h1>
											<div className="flex-start flex w-full flex-row flex-wrap">
												{columns.length &&
													columns.map((column: any, index: number) => (
														<div
															key={index}
															className="m-auto flex w-1/3 justify-start rounded p-2 text-left"
														>
															<input
																className="mx-2 my-auto"
																type="checkbox"
																defaultChecked={column.checked}
																onChange={(e) => {
																	console.log(e.target.value);
																	console.log(e.target.checked);
																	handleColumnChoice(
																		e.target.value,
																		e.target.checked
																	);
																}}
																value={column.id}
															/>
															<label>{column.name}</label>
														</div>
													))}
											</div>
										</div>
									</div>
									<label className="m-auto flex w-1/3 justify-start rounded p-2 text-left">
										<input
											className="mx-2 my-auto"
											type="checkbox"
											checked={oneLine}
											onChange={(e) => setOneLine(e.target.checked)}
											value={"ONE_LINE"}
										/>
										Export de l'équipe sur 1 ligne
									</label>
								</div>
								{errors.length > 0 && (
									<div>
										<div className="mb-3 mt-6 inline-flex items-center gap-x-2 rounded-full border-2 border-solid border-orange-200 bg-orange-100/60 px-3 py-1 text-orange-500 dark:bg-gray-800">
											<h2 className="text-sm font-medium uppercase">
												{trad[lang].importWarning}
											</h2>
										</div>

										<div>
											<textarea
												rows={errors.length > 10 ? 10 : errors.length}
												name="description"
												id="description"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring-transparent sm:text-sm"
												value={errors.join("\n")}
												readOnly
											></textarea>
										</div>
									</div>
								)}

								<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
									<div>
										<button
											type="button"
											className={classNames(
												"mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
											)}
											onClick={() => {
												reInitialize();
											}}
										>
											Ré-initialiser les filtres et colonnes
											<TbFilterOff className="my-auto" />
										</button>
									</div>
									<button
										type="button"
										className={classNames(
											"mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
										)}
										onClick={() => save()}
									>
										{trad[lang].export}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
									>
										{trad[lang].cancel}
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default ExportModal;
