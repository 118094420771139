const trad = {
	/* ↓↓↓--FRENCH VERSION--↓↓↓ */
	fr: {
		delete_old_detections:
			"Souhaitez-vous conserver les détections de la dernière prestation ?",
		select_race: "Veuillez choisir une course",
		select_club: "Choix du club",
		select_team: "Choix de l'équipe",
		select_noClub: "Aucun club disponible",
		select_noTeam: "Aucune équipe disponible",
		select_gender: "Veuillez choisir le genre",
		select_bib: "Veuillez choisir un numéro de dossard",
		select_country: "Veuillez choisir un pays",
		select_countrySearch: "Rechercher...",
		select_countryNoResult: "Aucun résultat",
		published: "Publié",
		unpublished: "Non publié",
		edit: "Modifier",
		registered: "inscrit(s)",
		toValidate: "à valider",
		ofTurnover: "d'inscription",
		publishEvent: "Publier l'événement",
		unpublishEvent: "Dépublier l'événement",
		publishEventWarning:
			"Attention, vous êtes sur le point de publier cet événement, cela signifie qu'il sera visible par tous les utilisateurs de Chronometrage.com. Êtes-vous sûr de vouloir continuer ?",
		unpublishEventWarning:
			"Attention, vous êtes sur le point de dépublier cet événement, cela signifie qu'il ne sera plus visible par les utilisateurs de Chronometrage.com. Êtes-vous sûr de vouloir continuer ?",
		editEvent: "Modifier l'événement",
		editRunner: "Modifier le coureur",
		handleDetection: "Gestion des détections",
		eventName: "Nom de l'événement",
		category: "Catégorie",
		date: "Date",
		description: "Description",
		poster: "Affiche",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Localisation",
		rulesPDF: "Règlement PDF",
		website: "Site web",
		file: "Fichier",
		rib: "RIB",
		addRunner: "Ajouter un coureur",
		addRegistration: "Ajouter une inscription",
		sendEmailTitle: "Rédaction du mail à destination des inscrits",
		sendOneEmailTitle: "Rédaction du mail à destination de l'inscrit",
		insight: "Apercu du mail à envoyer",
		config_title: "Gestion",
		runs_title: "Courses",
		update_presentation: "Présentation",
		update_runs: "Courses",
		update_options: "Options",
		update_contacts: "Contacts",
		update_sponsors: "Sponsors",
		update_ribs: "RIBs",
		configure_steps: "Étapes",
		configure_live: "Suivi des coureurs",
		configure_results: "Publiez vos résultats",
		sync_folomi_error: "Il y a davantage d'inscrits que de coureurs.",
		sync_folomi_action: "Cliquer pour synchroniser",
		sync_folomi_button: "Synchroniser les coureurs",
		sync_folomi_race_subscribed: "inscrits mais seulement",
		sync_folomi_race_detected: "coureurs détectés",
		ask_validation: "Demander la publication",
		unpublish: "Dépublier l'évènement",
		validation_asked: "Publication demandée",
		preview: "Aperçu",
		preview_invoice: "Aperçu de la facture",
		edit_invoice: "Edition de la facture",
		commissions: "Information de commissions",
		invoice_title: "Facturation",
		option: "Option",
		configure_options: "Configurer les options",
		add_global_option: "Ajouter une option globale",
		add_price: "Ajouter un tarif",
		add_option: "Ajouter une option",
		add_product: "Ajouter un produit",
		min_quantity: "Quantité minimale",
		max_quantity: "Quantité maximale",
		designation: "Designation",
		option_type: "TYPE DE CHAMPS SOUHAITÉS",
		is_required: "SOUHAITEZ-VOUS RENDRE L'OPTION OBLIGATOIRE ?",
		product: "Produit",
		price: "Tarif",
		type_dropdown_description:
			"Liste de différents produits (Exemple: Taille T-shirt, S, M, L)",
		type_check_description: "Case à cocher (Exemple: Bus, Repas)",
		type_quantity_description:
			"Demande de quantité pour un produit (Exemple: Nombre de repas coureur)",
		delete_title: "Suppression",
		delete_message: "Êtes-vous sûr de vouloir supprimer",
		price_start_date: "DATE ET HEURE DE DÉBUT DU TARIF",
		price_end_date: "DATE ET HEURE DE FIN DU TARIF",
		warning_no_price:
			"Au moins un tarif (même gratuit) doit exister pour cette course pour ajouter une option.",
		warning_delete: "(L'opération sera effective après enregistrement)",
		error_qty_min_0: "Les quantités doivent être supérieures à 0",
		error_qty_min_max:
			"La quantité minimale doit être inférieure à la quantité maximale",
		error_too_small: "(Trop petit)",
		default: "Par défaut",
		prestation: "Lancer la prestation",
		loading: "Chargement",
		importResults: "Importer les résultats",
		set: "Paramétré",
		notSet: "Non paramétré",
		filesToBeChecked: "fichiers à vérifier",
		completedAndValidatedFiles: "fichiers complétés et validés",
		viaOptions: "via les options",
		confirm: "Confirmer",
		cancel: "Annuler",
		interrupt: "Interrompre",
		previous: "Précédent",
		next: "Suivant",
		save: "Enregistrer",
		search: "Rechercher",
		download: "Télécharger",
		allM: "Tous",
		allF: "Toutes",
		delete: "Supprimer",
		inTeam: " - En équipe",
		send: "Envoyer",
		create_event: "Créer un événement",
		required: "Obligatoire",
		discard_changes: "Abandonner les changements ?",
		preview_off: "Fermer l'aperçu",
		error_boundary_title: "Une erreur est survenue.",
		error_boundary_support:
			"Si le problème persiste, contacter le support en fournissant les informations suivantes :",
		back: "Retour",
		sendMailAllSubscribers: "Envoyer un mail collectif",
		sendMail: "Envoyer un mail",
		sendMailError: "Le mail n'a pas pu être envoyé",
		resetExpired: "Demande expirée. Retournez sur 'mot de passe oublié'",
		eventSearch: "Rechercher un événement",
		number_bib: "Numéro de dossard",
		number_line: "Numéro de ligne",
		time_detection: "Heure de passage",
		number_line_short: "Ligne",
		whatIsJetcode: "Qu'est-ce qu'un jetcode ?",
		jetcodeExplanation:
			"C’est un outil qui permet d’intégrer directement, sur votre sur votre site web, le contenu d’une autre page (formulaires d’inscriptions & paiement en ligne, compteurs de dossards restants, compte à rebours, logo chronometrage.com)",
		howIntegrateJetcodeToMyWebsite:
			"Comment intégrer un jetcode à mon site web ?",
		jetcodeIntegrationExplanation:
			"Il suffit de copier les codes fournis des jetcodes souhaités (inclure mes inscriptions et logo chronometrage.com) Puis de le coller sur une page de votre site web.",
		integrateSubscriptionsForm: "Intégrer le formulaire d'inscriptions",
		integrateSubscirbersList: "Intégrer la liste des inscrits",
		integrateOnlineSubscriptionLogo: 'Intégrer le logo "inscription en ligne"',
		engineCodeLabel:
			"Code du moteur à copier 1 fois dans le Header de votre site",
		french: "Français",
		english: "Anglais",
		copy: "Copier",
		copyDone: "Copié !",
		insertionCodeLabel:
			"Code d’insertion du panier, ce code est à insérer une seule fois par page",
		language: "Langue",
		runSelectLabel: "Course souhaitée pour le jetcode",
		priceJetcodeCodeLabel:
			"Paramétrage du jetcode Tarif à copier dans votre site",
		theme: "Thème",
		setup: "Configuration",
		seeTimer: "Voir le timer",
		seeSeconds: "Voir les secondes",
		seeBibs: "Voir les dossards",
		seeBibsLeft: "Voir les dossards restants",
		codeToCopyToYourWebsite: "Code à copier sur votre site web",
		downloadProcessLabel:
			"Télécharger la procédure au format PDF afin de la transmettre à votre webmaster",
		listSetup: "Configuration de la liste",
		selectARun: "Sélectionnez une course",
		generalSettings: "Paramètres généraux",
		dagLiveCredentials: "Credential DAG Live",
		sensorName: "Libellé",
		distance: "Distance (m)",
		passageMin: "Nbre passage min",
		passageMax: "Nbre passage max",
		sensor: "Détecteur",
		type: "Type",
		start: "Démarrer",
		stop: "Arrêter la prestation",
		deleteDetections: "Supprimer définitivement les détections",
		deleteDetectionsSuccess: "Suppression réussie",
		deleteDetectionsError: "Une erreur s'est produit pendant la suppression",
		prestationError: "ERREUR",
		sensorSettings: "Paramètres détecteur",
		addGpx: "Ajouter GPX",
		chooseSensorType: "Choisissez le type de détecteur",
		startLine: "Ligne de départ",
		finishLine: "Ligne d'arrivée",
		checkpoint: "Point de contrôle",
		addSensor: "Ajouter un détecteur",
		removeThisSensor: "Supprimer ce détecteur",
		handleRunners: "Coureurs",
		handleDetections: "Détections",
		dashboardDetections: "Résumé",
		global_configuration: "Configuration",
		route_configuration: "Parcours",
		delete_step: "Supprimer cette étape",
		edit_step: "Modifier cette étape",
		delete_parcours: "Supprimer ce parcours",
		edit_parcours: "Modifier ce parcours",
		delete_segment: "Supprimer ce segment",
		edit_segment: "Modifier ce segment",
		step: "Etape",
		parcours: "Parcours",
		segment: "Segment",
		configure_routes: "Parametrer les parcours",
		configure_segments: "Parametrer les segments",
		intermediary: "Intermédiaire",
		start_line: "Départ",
		end_line: "Arrivée",
		typeLine1: "Départ",
		typeLine2: "Intermédiaire",
		typeLine3: "Arrivée",
		grid: "Mise en grille",
		categoryReassignement: "Ré-assignation des catégories",
		handleResults: "Résultats",
		timezone: "Quelle timezone souhaitez-vous conserver ?",
		no_run_selected: "Aucune course selectionnée",
		placeholder_select_run: "Selectionner une course",
		placeholder_select_segment: "Selectionner un segment",
		log_title: "Connectez-vous à votre outil d'administration",
		forgotten_title: "Mot de passe oublié ?",
		reset_password: "Réinitialiser le mot de passe",
		back_to_home: "Revenir à l'accueil",
		passwords_not_match: "Les mots de passe ne correspondent pas",
		passsword_reset_success: "Votre mot de passe a été réinitialisé",
		password_too_short: "Votre mot de passe doit faire 6 caractères minimum",
		send_forgotten_mail_success: "Un email vous a été envoyé",
		email: "Adresse email",
		password: "Mot de passe",
		new_password: "Nouveau mot de passe",
		new_password_confirm: "Confirmez votre mot de passe",
		confirmPassword: "Confirmez votre mot de passe",
		submit: "Se connecter",
		social_reason: "Raison sociale",
		adresse: "Adresse",
		postal_code: "Code postal",
		city: "Ville",
		country: "Pays",
		noAccount: "Pas encore de compte ?",
		rememberMe: "Se souvenir de moi",
		signup_button: "Créer un compte",
		signup: "Je n'ai pas encore de compte",
		signup_title: "S'incrire à l'outil d'administration",
		forgotten_password: "Mot de passe oublié ?",
		already_account: "J'ai déjà un compte",
		lastname: "Nom",
		firstname: "Prénom",
		birthdate: "Date de naissance",
		sex: "Genre",
		acceptCGV: "J'accepte les conditions d'utilisation de la plateforme",
		signup_error_firstname: "Votre prénom doit faire plus de 1 lettre.",
		signup_error_lastname: "Votre nom doit faire plus de 1 lettre.",
		signup_error_birthdate:
			"Votre date de naissance doit être valide et vous devez avoir entre 13 ans et 100 ans.",
		signup_error_email: "Votre email n'est pas valide.",
		signup_error_password:
			"Votre mot de passe doit faire 6 charactère minimum.",
		signup_error_passwordConfirm: "Vos mots de passe ne sont pas identiques.",
		signup_error_CGU: "Vous devez accepter les CGU.",
		orga_title: "Devenir organisateur",
		runDelete: "Supprimer la course",
		runDeleteWarning:
			"Attention, vous êtes sur le point de supprimer cette course, cette action est irréversible. Êtes-vous sûr de vouloir continuer ?",
		runEdit: "Modifier la course",
		subEdit: "Modification de l'inscription",
		subscription: "Inscription",
		subOptions: "Options",
		runName: "Nom de la course",
		teamName: "Nom de l'équipe",
		runStartDate: "Date de départ",
		noClub: "Aucun club",
		runEndDate: "Date de fin",
		runMaxNbOfSlots: "Nombre maximum de places",
		runnerChart: "Charte des coureurs",
		yourParentalAuthorizationModel: "Votre modèle d'autorisation parentale",
		addBban: "Ajouter un RIB",
		editBban: "Modifier le RIB",
		bbanType: "Type de RIB",
		bbanName: "Nom du RIB",
		bankName: "Nom de la banque",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Êtes-vous le responsable légal ?",
		personInChargeInformations: "Informations du responsable légal",
		gender: "Genre",
		male: "Homme",
		female: "Femme",
		other: "Autre",
		legalEntity: "Raison sociale",
		address: "Adresse",
		team: "Équipe",
		race: "Course",
		bib: "Dossard",
		bibAvailable: "Dossard disponible",
		bibUnavailable: "Dossard déjà utilisé",
		invalid: "Invalide",
		bibInvalid: "Dossard invalide",
		runner: "Coureur",
		zipCode: "Code postal",
		birthDate: "Date de naissance",
		genderH: "H",
		genderF: "F",
		genderX: "X",
		editGeneral: "Général",
		editRace: "En course",
		nationality: "Nationalité",
		countryPlaceholder: "Sélectionnez un pays",
		firstPhone: "Téléphone 1",
		secondPhone: "Téléphone 2",
		marketer: "Commercialisateur",
		organizer: "Organisateur",
		yes: "Oui",
		no: "Non",
		bbanDelete: "Supprimer le RIB",
		bbanDeleteWarning:
			"Attention, vous êtes sur le point de supprimer ce RIB, cette action est irréversible. Êtes-vous sûr de vouloir continuer ?",
		addTeam: "Ajouter une équipe",
		club: "Club",
		duplicateEmail: "Cette adresse mail est déjà utilisée",
		alreadyInUse: "Déjà utilisé",
		editTimes: "Temps",
		object: "Objet",
		mailBody: "Corps du mail",
		swapBibs: "Échanger les dossards",
		swapFrom: "Avant",
		swapTo: "Après",
		nbDetections: "Détections",
		stageDepartureHour: "Heure de départ officielle",
		handicap: "Handicap",
		miniTime: "Temps minimum sur l'étape",
		error404: "Erreur 404",
		pageDoesNotExist: "Cette page n'existe pas",
		iThinkYouAreLost: "Je pense que vous êtes perdu",
		goBackHome: "Retourner à l'accueil",
		dashboard: "Tableau de bord",
		registrations: "Inscriptions",
		event: "Evenement",
		liveResults: "Chronométrage",
		menuResults: "Résultats",
		liveConfiguration: "Configuration du Live",
		statistics: "Statistiques",
		admin: "Administration",
		jetcodes: "Jetcodes",
		toCome: "À venir",
		today: "Aujourd'hui",
		past: "Passés",
		myAccount: "Mon compte",
		logout: "Déconnexion",
		manageRunners: "Gestion des coureurs",
		detections_dashboard: "Tableau de bord des détections",
		manage_dashboard: "Gestion des détections",
		registration_refund: "Remboursement d'inscription",
		registration_order: "Commande de l'inscription",
		cgu: "Conditions d'utilisation",
		cgv: "Conditions générales de vente",
		manage_results: "Gestion des résultats",
		startOn: "Débute le",
		registeredContestants: "Coureurs inscrits",
		displayRegistrations: "Afficher les inscriptions",
		completeSubscriptionsList: "Liste complète des inscriptions",
		bibs: "Dossard",
		bibsSearch: "Rechercher un dossard",
		firstnameSearch: "Rechercher un prénom",
		lastnameSearch: "Rechercher un nom",
		statusSearch: "Rechercher un état",
		loadingRegisterForm: "Chargement du formulaire d'inscription",
		thanksToWait: "Merci de patienter",
		noRace: "Aucune course configurée",
		teamOf: "ÉQUIPE de",
		invididual: "SOLO",
		importError: "Erreur lors de l'importation",
		preimportWarning: "ATTENTION",
		importWarning: "ATTENTION",
		preimportError: "ERREUR : CET INSCRIT NE SERA PAS IMPORTÉ...",
		preimportErrors: "ERREUR : CES INSCRITS NE SERONT PAS IMPORTÉ(S)...",
		exportTitle: "Exporter des inscriptions au format",
		raceChoice: "Courses",
		sexeChoice: "Sexe",
		statusChoice: "Statut",
		subscriptionType: "Type d'inscription",
		importTitle: "Importer des inscriptions",
		toImport: "Pour importer une liste d'inscriptions, utilisez",
		useThisModel: "ce modèle de fichier EXCEL",
		dragHere: "Glissez le fichier EXCEL (format .xlsx) ici...",
		dragHereOr:
			"Glissez le fichier EXCEL (format .xlsx) ici, ou cliquer pour le sélectionner",
		fileFormatNotSupported: "Format de fichier non supporté",
		existingSubs: "inscrits déjà importés précédemment.",
		replace: "Supprimer tout et remplacer",
		donotupdate: "Ajouter uniquement les nouveaux dossards",
		continue: "Continuer l'importation",
		entries: "ajouts sur",
		import: "Importer",
		nbImportedRunners: "coureur(s) importé(s)",
		pre: "Pré-",
		importing: "Importation...",
		existing: "déjà existant(s)",
		adds: "ajout(s) sur ",
		add: "ajout(s)",
		close: "Fermer",
		whattodo: "Que voulez-vous faire ?",
		line: "Ligne",
		invalidRace: "Course invalide",
		bibUsed: "Dossard déjà utilisé",
		missingRace: "Course non renseigné(s)",
		missingBib: "Dossard non renseigné(s)",
		yourEvents: "Vos événements",
		yourRuns: "Vos courses",
		bibsAllocation: "Attribution des dossards",
		discountVouchers: "Bons de réduction",
		form: "Formulaire",
		refunds: "Remboursements",
		accessRights: "Droits d'accès",
		bankBBAN: "RIB",
		manageBibsAllocation: "Gérer l'attribution des dossards",
		manageEventDiscountVouchers: "Gérer les bons de réduction de l'événement",
		manageEventForm: "Gérer le formulaire de l'événement",
		manageRefunds: "Gérer les remboursements",
		manageAccessRights: "Gérer les droits d'accès",
		manageYourBBAN: "Gérer votre RIB",
		manageFFAFFTRILicenses: "Gérer vos licence FFA/FFTRI",
		ribListLinkToThisEvent: "Liste des RIB liés à cet événement",
		addBBAN: "Ajouter un RIB",
		name: "Nom",
		bank: "Banque",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "Il y a actuellement",
		registrantsForThe: "inscrits pour la course",
		doYouReallyWantToReimburseThe: "Voulez-vous vraiment rembourser les",
		registrants: "inscrits",
		chargeAdditionalFeesPerRegistrations:
			"Frais supplémentaires par inscription (en €)",
		invoicingOnRunnerOrOrganizer:
			"Facturation sur le coureur ou l'organisateur",
		ifRegistrationFeeIncluded:
			"Si le prix d'inscription est inclus, prise en charge par",
		reimbursementCostsCoveredBy:
			"Les frais de remboursement sont pris en charge par",
		makeTheRefund: "Effectuer le remboursement",
		reason: "Motif",
		choose: "Choisir...",
		bibPlan: "Plan de numérotation",
		bibPlanAffectation: "Manière d'affecter les dossards",
		minBib: "Dossard Minimum",
		maxBib: "Dossard Maximum",
		bibExclusion: "Dossards à exclure",
		bibExlusionPlaceholder:
			"Exemple : \rListe = 1;2;8;10 (les dossards 1,2,8 et 10 ne seront pas pris en compte) \rTranche = 5:10 (les dossards de 5 à 10 inclus (5,6,7,8,9 et 10) ne seront pas pris en compte) \rCombinaison 1 = 2:4;7:8 (les dossards 2,3,4,7 et 8 ne seront pas pris en compte) \rCombinaison 2 = 2;4:6;8 (les dossards 2,4,5,6, et 8 ne seront pas pris en compte)",
		reaffectAllbibs:
			"Réaffecter tous les dossards de la course en validant la sélection",
		keepBibs: "Garder les dossards déjà attribué",
		applyToPending: "Appliquer aux inscriptions en attente",
		orderAlphabetic: "Réaffecter par ordre alphabétique des coureurs",
		list: "Par liste de dossards",
		range: "Par plage de dossards",
		filterByRun: "Filtrer par course",
		resolution: "Résolution",
		days: "Jours",
		weeks: "Semaines",
		months: "Mois",
		years: "Années",
		downloadInPdf: "Télécharger en PDF",
		nbOfSubscribers: "Nombre d'inscrits",
		maxInfMin:
			"Veuillez choisir une valeur maximale supérieure à la valeur minimale",
		errorBib: "Une erreur est survenie lors de la mise à jour des dossards",
		updatedBib: "Les numéros de dossard ont été mis à jour",
		loginSuccess: "Connexion réussie",
		loginError: "Erreur de connexion",
		logoutSuccess: "Déconnexion réussie",
		logoutError: "Erreur de déconnexion",
		updateError: "La mise à jour a rencontrée un problème",
		updateSuccess: "La mise à jour a été effectuée",
		updateCredentialSuccess: "La mise à jour a été effectuée",
		updateCredentialError: "La mise à jour a rencontrée un problème",
		startPrestation: "La prestation est lancée",
		liveTimezone:
			"Il a une une inconhérence sur la configuration de la Timezone de votre évènement.",
		stopPrestation: "La prestation est arrêtée",
		prestationStartError: "Erreur lors du lancement de la prestation",
		prestationStartErrorCredentiel:
			"Veuillez configurer votre credentiel avant de lancer la prestation",
		prestationStopError: "Erreur lors de l'arrêt de la prestation",
		updateCheckpointSuccess: "L'enregistrement des détecteurs a été effectué",
		updateCheckpointError: "Erreur lors de l'enregistrement des détecteurs",
		deletionSuccess: "Suppression réussie",
		deletionError: "Une erreur est survenue pendant la suppression",
		insertSuccess: "Création réussie",
		insertError: "Erreur pendant la création",
		insertErrorDetection:
			"La détection n'a pas pu être transmise. Vérifiez que vous avez une prestation en cours.",
		ppsError: "Numéro de Licence ou PPS non validé par la FFA",
		requiredRace: "Veuillez choisir une course",
		requiredBib: "Veuillez choisir un numéro de dossard",
		requiredBirthdate: "Veuillez renseigner la date de naissance",
		requiredGender: "Veuillez renseigner le genre",
		requiredTeamName: "Veuillez renseigner le nom de l'équipe",
		requiredTeam:
			"Veuillez choisir une équipe dans la liste ou en créer une nouvelle",
		requiredOneOrAllRunner: "Veuillez renseigner un ou tous les coureurs",
		emailSendedSuccess: "L'envoie des emails s'est effectué avec succès.",
		emailSendedError: "Une erreur s'est produite lors de l'envoie des emails.",
		login_expired: "Votre session a expiré",
		newRaceUpdateSuccess: "Le changement d'épreuve s'est effectué avec succès",
		newRaceUpdateError: "Une erreur est survenue lors du changement d'épreuve",
		bibChangeRaceChoice: "Veuillez faire un choix concernant les dossards",
		refundSuccess: "Remboursement effectué avec succès",
		refundError: "Le remboursement a rencontré une erreur",
		sport_identity_success: "Evénement enregistré avec succès",
		sport_runs_success: "Courses configurées avec succès",
		sport_options_success: "Options configurées avec succès",
		sport_identity_error:
			"Une erreur est survenue pendant la création de l'événement",
		sport_runs_error:
			"Une erreur est survenue pendant la configuration des courses",
		sport_options_error:
			"Impossible d'enregistrer les options. Veuillez vérifier que vous ne modifiez pas un tarif d'une option déjà souscrite",
		file_error: "Une erreur est survenue pendant la lecture du fichier",
		file_exist: "Ce fichier est déjà uploader",
		event_form_required: "Vous n'avez pas remplit tous les champs obligatoires",
		event_invalid_dates:
			"Attention la date de fin est la même que celle du début",
		error_creation_segment:
			"Une erreur est survenue pendant l'enregistrement du segment",
		success_creation_segment: "Segment enregistré avec succès",
		success_deletion_segment: "Segment supprimé",
		error_deletion_segment:
			"Une erreur est survenue pendant la suppression du segment",
		error_creation_parcours:
			"Une erreur est survenue pendant l'enregistrement du parcours",
		success_creation_parcours: "Parcours enregistré avec succès",
		success_deletion_parcours: "Parcours supprimé",
		error_deletion_parcours:
			"Une erreur est survenue pendant la suppression du parcours",
		error_creation_etape:
			"Une erreur est survenue pendant l'enregistrement de l'étape",
		success_creation_etape: "Etape enregistrée avec succès",
		success_deletion_etape: "Etape supprimée",
		error_deletion_etape:
			"Une erreur est survenue pendant la suppression de l'étape",
		success_creating_price: "Tarif enregistré avec succès",
		error_creating_price:
			"Une erreur est survenue pendant la création du tarif",
		success_deleting_run: "Course supprimée avec succès",
		error_deleting_run:
			"Une erreur est survenue pendant la suppression de la course",
		error_deleting_run_subs:
			"Impossible de supprimer une course où sont encore présents des inscrits",
		success_deleting_price: "Tarif supprimé avec succès",
		error_deleting_price:
			"Une erreur est survenue pendant la suppression du tarif",
		success_updating_fields: "Formulaire d'inscription mis à jour",
		error_updating_fields:
			"Une erreur est survenue pendant la mise à jour du formulaire",
		event_does_not_exist:
			"L'évènement que vous souhaitez configurer n'existe pas",
		success_updating_contact: "Contact enregistré",
		error_updating_contact:
			"Une erreur est survenue pendant l'enregistrement du contact",
		success_updating_rib: "RIB enregistré",
		error_updating_rib:
			"Une erreur est survenue pendant l'enregistrement du RIB. Le responsable légal possède peut être déjà un compte.",
		success_deleting_contact: "Contact supprimé",
		success_deleting_rib: "RIB supprimé",
		error_deleting_contact:
			"Une erreur est survenue pendant la suppression du contact",
		error_deleting_rib: "Une erreur est survenue pendant la suppression du RIB",
		error_signup:
			"Une erreur est survenue. Cet email est peut être déjà utilisé.",
		success_signup: "Votre compte a bien été créé",
		success_change_orga: "Vous êtes désormais organisateur, reconnecter vous.",
		error_change_orga: "Une erreur est survenue.",
		error_contact_empty:
			"Veuillez renseigner la raison sociale, le nom et prénom du contact.",
		success_ask_validation: "Un email a été envoyé a l'administrateur",
		error_ask_validation:
			"Une erreur est survenue pendant la demande de validation",
		import_error: "Une erreur est survenue pendant l'import",
		success_publish_event: "Evènement publié",
		success_unpublish_event: "Evènement dépublié",
		error_unpublish_event: "Une erreur est survenue",
		cant_delete_stage:
			"Veuillez supprimer les détections liées à cette étape avant de la supprimer",
		reassignCategorySuccess:
			"La ré-assignation des catégories s'est effectué avec succès",
		reassignCategoryError:
			"Une erreur est survenue lors de la ré-assignation des catégories",
		orgaSuccess: "Vous êtes désormais organisateur",
		orgaError: "Une erreur est survenue",
		toast_raceChoice: "Veuillez choisir au moins une course dans la liste",
		genderChoice: "Veuillez choisir au moins un genre dans la liste",
		toast_statusChoice:
			"Veuillez choisir au moins un statut d'inscription dans la liste",
		typeChoice: "Veuillez choisir au moins un type d'inscription dans la liste",
		changeTimezoneError:
			"Une erreur est survenue lors du changement de la timezone.",
		saveCredentialError:
			"Une erreur est survenue pendant l'enregistrement de votre identifiant.",
		saveConfigRunSuccess: "Votre configuration a été enregistrée.",
		saveConfigRunError:
			"Une erreur est survenue pendant la configuration de votre course.",
		saveConfigPointSuccess: "Votre point de détections à été enregistré.",
		saveConfigPointError:
			"Une erreur est survenue pendant l'enregistrement du point.",
		deleteConfigPointSuccess: "Votre point de détections à été supprimé.",
		deleteConfigPointError:
			"Une erreur est survenue pendant la suppression du point.",
		seeAllDetections: "Voir toutes les détections",
		saveCredentialSuccess: "Votre identifiant a été enregistré",
		delete_detections_choice: "Veuillez faire un choix.",
		no_delete: "Aucune détection n'a été supprimé",
		export_error: "Une erreur est survenue pendant l'export",
		accept_document_success: "Le document a été accepté",
		accept_document_error: "Une erreur est survenue.",
		refuse_document_success:
			"Le document a été refusé et un mail a été transmis au coureur.",
		refuse_document_error: "Une erreur est survenue.",
		category_or_team: "Cat / Équipe",
		run: "Course",
		status: "Statut",
		options: "Options",
		runners: "Coureurs",
		starter: "Partant",
		surrended: "Abandon",
		disqualified: "Disqualifié",
		filter: "Filtrer",
		man: "Homme",
		woman: "Femme",
		other_gender: "Autre",
		your_runners: "Vos coureurs",
		refused_reason: "Raison du refus",
		accept_document: "Accepter",
		refuse_document: "Refuser",
		refuse_document_and_notice: "Refuser et Envoyer email",
		your_subscriptions: "Vos inscriptons",
		contact: "Coordonnées",
		edit_subscription: "Modifier l'inscription",
		add_subscription: "Ajouter une inscription",
		message: "Envoyer un message",
		document: "Gérer les documents",
		actions: "Action",
		waiting: "En attente",
		valid: "Valide",
		reimbursed: "Remboursement",
		documents_sent: "Document(s) envoyé(s)",
		close_documents: "Fermer",
		manage_documents: "Gestion des documents",
		personnal_informations: "Informations personnelles :",
		age: "Age",
		phone: "Téléphone",
		emergency_phone: "Téléphone d'urgence",
		mail: "Email",
		run_informations: "Informations de la course",
		bib_info: "Dossard",
		licence: "Licence",
		validated: "validé",
		by: "par",
		la: "la",
		certificat: "Certificat Médical",
		autorisation: "Auto. Parentale",
		validation_date: "Date de validation :",
		sub_team: "Team / Association / Entreprise",
		sub_options: "Options de l'inscrit :",
		reimburse: "Rembourser",
		validate_subscription: "Valider l'inscription",
		refuse_subscription: "Refuser l'inscription",
		refuse_reason: "Quelle est la raison du refus ?",
		see_message_history: "Voir l'historique des mails",
		messages_history: "Historique des mails",
		close_history: "Fermer l'historique",
		close_comments: "Fermer les commentaires",
		"inscription.refus.title": "Inscription Refusée",
		no_messages: "Aucun message transmis à cet inscrit",
		changeRace: "Changer d'épreuve",
		changeRaceModalTitle: "Modification de l'inscription pour : ",
		currentlyRegistredFor: "Actuellement inscrit pour la course ",
		newRace: "Nouvelle épreuve",
		cannotChangeRace: "Changement d'épreuve impossible",
		teammatesWith: "Avec",
		bibMessageChangeRun:
			"1 - Souhaitez-vous conserver les numéros de dossard ?",
		automaticBibChangeRun:
			"2 - Souhaitez-vous qu'on attribue automatiquement les nouveaux dossards en fonction du plan de numérotation de la nouvelle course ?",
		bibAlreadyExistInNewRace:
			"a déjà le numéro de dossard dans la course choisie. Un nouveau dossard sera automatiquement affecté au coureur si un plan de numérotation a été défini pour la course choisie.",
		noBibInNewRace:
			"ATTENTION : Le dossard actuel sera libéré. Une affectation manuelle du dossard sera nécessaire.",
		noBibAssignmentInNewRace:
			"ATTENTION : Le dossard actuel sera libéré car la nouvelle course ne comporte pas de plan de numérotation.",
		see_order: "Voir la commande",
		no_document: "Aucun document",
		detectors: "Détecteurs",
		verification: "Vérification ok",
		percentage: "Pourcentage",
		finishers: "Finishers",
		number_runners: "Coureurs",
		non_starters: "Non partant",
		start_the: "Départ le :",
		abandon: "Abandons",
		official_starters: "Partants officiels",
		manage_detections_title: "Détections",
		hours: "Heure de passage",
		restore_detection: "Restaurer",
		delete_detections: "Supprimer les détections",
		choose_detectors: "Toutes les lignes",
		add_detection: "Ajouter une détection",
		edit_detection: "Modifier cette détection",
		date_format: "Format Date",
		chrono_format: "Format chrono",
		effective_start: "Départ effectif",
		races: "Toutes les courses",
		deleteAllDetections: "Voulez-vous supprimer toutes les détections ?",
		confirmStartSaveCredential:
			"Souhaitez vous enregistrer votre credential avant de lancer la prestation ?",
		deleteTitle: "Suppression des détections de la course",
		all: "Suppression de toutes les détections de l'évènement",
		upload_dag: "Importer un fichier DAG",
		insertion: "Insertion",
		estimation: "Estimation",
		current_time: "Durée",
		seconds: "secondes",
		detection_detail: "Detail des détections",
		already_exist: "Déjà existantes",
		inserted: "Insérées",
		runner_created: "Dont coureur créées",
		checkpoint_missing: "Checkpoint manquant",
		interpassage_ignored: "Ignorées dû au temps interpassage",
		search_runner: "Rechercher un coureur",
		search_event: "Rechercher un évènement",
		search_bib: "Rechercher un dossard",
		search_subscription: "Rechercher une inscription",
		autorisationParentale: "Autorisation Parentale",
		licence_already_sent: "Licence déjà envoyé",
		certificat_already_sent: "Certificat déjà envoyé",
		autorisation_already_sent: "Autorisation déjà envoyé",
		send_documents: "Envoyer les nouveaux documents",
		see_document: "Voir le document",
		download_document: "Télécharger",
		product_name: "Nom du produit",
		refund_price: "Prix TTC",
		refund_amount: "Montant a rembourser",
		subscription_fees: "Frais d'inscriptions",
		refund_reason: "Raison de votre remboursement",
		refund: "Rembourser",
		orga_sub_fees: "Frais d'inscription à votre charge ?",
		orga_refund_fees: "Frais de remboursement (1€ TTC) à votre charge ?",
		contact_doubt:
			"Merci de contacter le support en cas de doute sur vos manipulations",
		refund_summary: "Récapitulatif du remboursement",
		refund_close_summary: "Fermer le récapitulatif",
		refund_summary_start_text: "Vous allez rembourser ",
		refund_summary_end_text: " à ce coureur, souhaitez vous confirmer ?",
		confirm_refund: "Confirmer le remboursement",
		refund_paid: "Le coureur a payé",
		amount_to_refund: "Vous allez lui rembourser",
		refund_fees: "Frais de remboursement",
		refunded_amount: "Remboursement TTC",
		already_refunded: (amount: string, date: string) =>
			`Vous ne pouvez plus rembourser cette inscription car un remboursement de ${amount} € à déjà eu lieu le ${date}.`,
		refund_product: "Remboursement",
		choose_type: "Je veux organiser un événement",
		select: "Sélectionner",
		type_details: "Détails",
		sport_event_identity: "Présentation de l'évènement",
		sport_event_runs: "Configuration des courses",
		sport_event_options: "Configuration des options",
		go_back: "Retour",
		save_and_go_run: "Enregistrer et configurer les courses",
		save_and_go_options: "Configurer les options",
		save_and_finish: "Finir la configuration",
		event_name_label: "Nom de l'évènement",
		event_sport_label: "Sport",
		event_sport_option: "Selectionner un sport",
		event_start_date_label: "Date de début",
		event_end_date_label: "Date de fin",
		event_poster_label: "Image",
		event_banner_label: "Bannière",
		event_short_description_label: "Description courte",
		event_long_description_label: "Description longue",
		event_address_label: "Adresse",
		event_postal_code_label: "Code postal",
		event_city_label: "Ville",
		event_country_label: "Pays",
		event_name_error:
			"Le nom de votre évènement doit faire plus de 3 caractères",
		event_sport_error: "Vous devez choisir un sport",
		event_ffa_siffa_error:
			"Autorisation FFA manquante. Avez-vous bien lié votre évènement à DAG System sur CALORG ?",
		event_start_date_error:
			"La date ne peut précéder la date du jour ET doit correspondre avec la date saisie du SIFFA (si Code FFA))",
		event_end_date_error:
			"Vous devez choisir une date ultérieur à la date du jour",
		event_short_description_error:
			"La description courte doit être comprise entre 3 et 190 caractères",
		event_address_error: "Votre adresse doit faire plus de 3 caractères",
		event_postal_code_error:
			"Votre code postal doit faire plus de 3 caractères",
		event_city_error: "Votre ville doit faire plus de 3 caractères",
		event_country_error: "Vous devez choisir un pays",
		event_country_option: "Selectionner un pays",
		event_timezone_label: "Fuseau horaire",
		event_places_label: "Nombre de places disponibles",
		event_timezone_option: "Selectionner une fuseau horaire",
		event_rules_label: "Règlement de l'événement",
		event_run_name_label: "Nom de l'épreuve",
		event_run_date_label: "Date de l'épreuve",
		event_run_member_label: "Si par équipe, nombre d'équipiers",
		event_run_transfer_label: "Permettre le transfert d'inscription ?",
		event_run_details_label: "Informations supplémentaire",
		event_run_details:
			"Permet de donner des informations supplémentaire concernant la course (Retrait de dossard, etc.e)",
		event_run_number_members_label: "Nombre d'équipiers",
		event_run_places_label: "Nombre de places disponibles",
		event_run_start_date_label: "Date de début",
		event_run_end_date_label: "Date de fin",
		event_run_same_bib_label: "Même dossard pour l'équipe",
		event_run_fftri_code: "Code compétition FFTRI",
		event_run_fftri_distance: "Type de distance",
		event_run_same_bib_no: "Non",
		event_run_same_bib_yes: "Oui",
		event_add_run: "Ajouter une course",
		event_delete_run: "Supprimer une course",
		event_undo_delete_run: "Ne pas supprimer la course",
		event_prices: "Tarifs",
		event_run: "Epreuve",
		event_run_designation_label: "Désignation",
		edit_run_title: "Edition d'une course",
		edit_tarif_title: "Edition d'un tarif",
		edit_contact_title: "Edition d'un contact",
		edit_rib_title: "Edition d'un RIB",
		event_run_age_control_label: "Type de limite d'age",
		event_run_age_control_option: "Pas de limite d'age",
		event_run_age_min_label: "Age minimum",
		event_run_age_max_label: "Age maximum",
		event_run_add_price: "Ajouter un tarif",
		event_add_contact: "Ajouter un contact",
		event_add_rib: "Ajouter un RIB",
		validate: "Valider",
		fees_inclued: "inclusif",
		fees_exclued: "exclusif",
		event_fees_inclued: "Frais inclusifs",
		event_fees_exclued: "Frais exclusifs",
		event_starting_date: "Début :",
		event_ending_date: "Fin :",
		event_age_disponibility: (min: number, max: number) =>
			`Disponible de ${min} ans à ${max} ans`,
		event_price_fee_inclued: (fees: number) =>
			`dont ${fees.toFixed(2)}€ de frais`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ de frais`,
		event_run_price: "Prix",
		event_run_fees_type_label: "Type de frais",
		event_run_type_control_event: "Selon la date d'évènement",
		event_run_type_control_subscription: "Selon la date d'inscription",
		event_run_type_control_year_event: "Selon l'année d'évènement",
		event_run_free_run: "Course gratuite ?",
		event_no_free_run: "Non",
		event_free_run: "Oui",
		event_free_run_text: "Course gratuite",
		event_ffa_siffa_label: "Code compétition FFA",
		event_configure_sub_form: "Configurer le formulaire",
		event_edit_run: "Editer la course",
		event_run_form_title: "Configuration du formulaire d'inscription",
		event_tooltip_form: "Un formulaire est configuré pour ce tarif",
		event_field_nom_label: "Nom",
		event_field_prenom_label: "Prénom",
		event_field_sexe_label: "Genre",
		event_field_dateNaissance_label: "Date de naissance",
		event_field_mail_label: "Email",
		event_field_ville_label: "Ville",
		event_field_codePostal_label: "Code postal",
		event_field_pays_label: "Pays",
		event_field_nationalite_label: "Nationalité",
		event_field_telephone_label: "Téléphone",
		event_field_telephoneUrgence_label: "Téléphone d'urgence",
		event_field_numLicence_label: "Numéro de licence",
		addGpxFile: "Ajouter un gpx",
		gpxFile: "Fichier gpx",
		event_field_licence_label: "Licence",
		event_field_certificat_label: "Certificat médical",
		event_field_pps_label: "Numéro PPS (FFA: Parcours Prévention Santé)",
		event_field_adresse1_label: "Adresse",
		event_field_adresse2_label: "Complément d'adresse",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "CNIL FFA",
		event_field_autorisationParentale_label: "Autorisation parentale",
		event_field_tiers_label: "Tiers",
		enter_text: "Entrez votre description",
		enter_mail_content: "Écrivez le contenu de votre mail...",
		enter_mail_object: "Écrivez l'objet de votre mail...",
		sport_event_contacts: "Gestion des contacts",
		sport_event_ribs: "Gestion des RIBs",
		event_raisonSocial_label: "Raison sociale",
		event_nom_label: "Nom",
		event_prenom_label: "Prenom",
		event_adresse1_label: "Adresse",
		event_adresse2_label: "Complément d'adresse",
		event_ville_label: "Ville",
		event_codePostal_label: "Code postal",
		event_pays_label: "Pays",
		event_telephone1_label: "Téléphone",
		event_telephone2_label: "Téléphone secondaire",
		event_mail_label: "Email",
		event_siteWeb_label: "Site web",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Voulez-vous vraiment supprimer le tarif",
		delete_run_title: "Voulez-vous vraiment supprimer la course",
		restore_deletes: "Restaurer les éléments supprimer",
		restore: "Annuler les modifications",
		delete_contact_title: "Voulez-vous vraiment supprimer le contact",
		create_step: "Ajouter une étape",
		create_route: "Ajouter une étape",
		step_name_label: "Nom de l'étape",
		event_distance_label: "Distance (en mètre)",
		sport_event_steps: "Configuration des étapes",
		sport_event_parcours: "Configuration des parcours",
		sport_event_segments: "Configuration des segments",
		parcours_name_label: "Nom du parcours",
		segment_name_label: "Nom du segment",
		parcours_file_label: "Fichier GPX",
		create_parcours: "Ajouter un parcours",
		update_parcours: "Modifier un parcours",
		create_segment: "Ajouter un segment",
		update_segment: "Modifier un segment",
		delete_step_title: "Voulez-vous vraiment supprimer l'étape",
		delete_segment_title: "Voulez-vous vraiment supprimer le segment",
		delete_parcours_title: "Voulez-vous vraiment supprimer le parcours",
		checkpoint_debut_label: "Checkpoint de début",
		checkpoint_fin_label: "Checkpoint de fin",
		parcours_active_label: "Parcours actif",
		active: "actif",
		event_fees: "Frais",
		dag_fees: "Part DAG TTC",
		subscription_income: "Votre revenu",
		subscription_fees_sup: "Votre part TTC",
		subscription_fees_total: "Frais d'inscription TTC",
		event_sport_price: "Prix de la course",
		event_fftri_default_distance: "Choisissez une distance",
		designation_label: "Désignation",
		unlimited: "Illimité",
		nomBanque_label: "Nom Banque",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "Je suis le représentant légal",
		lastname_label: "Nom",
		firstname_label: "Prénom",
		birthdate_label: "Date de naissance",
		email_label: "Email",
		address_label: "Adresse",
		city_label: "Ville",
		postalCode_label: "Code postal",
		country_label: "Pays",
		phone_label: "Téléphone",
		sex_label: "Genre",
		genderHfull: "Homme",
		genderFfull: "Femme",
		genderXfull: "Autre",
		select_type_rib: "Selectioner un rôle",
		see_contract: "Voir contrat",
		add_general_option: "Ajouter une option globale",
		delete_stage_cannot_start_live:
			"ATTENTION : A défault de créer une autre étape, une fois supprimée vous ne pourrez plus faire de LIVE.",
		event_configuration: "Je veux organiser un évènement",
		event_identity: "Identité de l'évènement",
		event_runs: "Course",
		event_options: "Options",
		event_subscription: "Configuration des inscriptions",
		event_contacts: "Contacts",
		event_rib: "RIBs",
		subscriptions: "Inscriptions",
		live_results: "Live / Résultats",
		manage_detections: "Gestion des détections",
		detection_dashboard: "Résumé des détections",
		manage_runners: "Gestion des coureurs",
		manage_live: "Gestion du live",
		manage_steps: "Configurations des étapes",
		manage_parcours: "Configurations des parcours",
		manage_segments: "Configurations des segments",
		bib_plan_exemple_1:
			"Liste = 1;2;8;10 (les dossards 1,2,8 et 10 ne seront pas pris en compte)",
		bib_plan_exemple_2:
			"Tranche = 5:10 (les dossards de 5 à 10 inclus (5,6,7,8,9 et 10) ne seront pas pris en compte)",
		bib_plan_exemple_3:
			"Combinaison 1 = 2:4;7:8 (les dossards 2,3,4,7 et 8 ne seront pas pris en compte)",
		bib_plan_exemple_4:
			"Combinaison 2 = 2;4:6;8 (les dossards 2,4,5,6, et 8 ne seront pas pris en compte)",
		event_name: "Permet d'identifier l'activité lors de l'inscription.",
		event_places:
			"Permet de limiter les inscriptions à un nombre défini de places (Ne rien entrer pour un nombre de places illimité).",
		event_start_date: "Permet de définir la date de début de l'activité.",
		event_end_date: "Permet de définir la date de fin de l'activité.",
		event_team_number:
			"Permet de définir combien d'inscrits composent une équipe (Ne rien entrer si l'activité n'est pas en équipe).",
		event_transfer:
			"Permet de définir si le transfert d'inscription est autorisé d'un inscrit vers un nouveau coureur (vous aurez la main et pourrez accepter/refuser les demandes individuellement).",
		event_price_name:
			"Permet de décrire le prix de la course (Exemple : Pré-vente, Enfant, Adulte).",
		event_age: "Permet de définir un âge limite d'inscription.",
		event_min_age: "Définit l'âge minimum requis afin de pouvoir s'inscrire.",
		event_max_age: "Définit l'âge maximum requis afin de pouvoir s'inscrire.",
		event_price_start_date:
			"Permet de définir la date de début de disponibilité du tarif. Les tarifs ne seront pas visibles avant cette date.",
		event_price_end_date:
			"Permet de définir la date de fin de disponibilité du tarif. Les tarifs ne seront plus visibles après cette date.",
		event_price_free:
			"Permet de définir une gratuité pour le tarif. Le participant ne paiera pas les frais, mais ils vous seront facturés.",
		event_price_price:
			"Définit le prix de l'inscription par coureur (des frais peuvent s'ajouter).",
		event_price_fees_type:
			"Permet de définir si les frais sont exclusifs (non compris dans le prix de la course) ou inclusifs (compris dans le prix de la course).",
		event_price_fees:
			"Frais prélevés par la plateforme. Ces frais sont au minimum égaux aux frais supplémentaires ajoutés par le commercialisateur.",
		event_price_fees_sup:
			"Frais supplémentaires appliqués par le commercialisateur.",
		event_price_fees_calc:
			"Frais de la plateforme + Frais supplémentaires du commercialisateur.",
		event_timezone: "A bien choisir en cas de live pour le classement",
		event_defined_tz: "Timezone définie pour l'évènement",
		default_race:
			"Permet de classer un dossard inconnu détecté lors du live dans une course par défault si pas de plan de numérotation défini",
		finances: "Flux financiers",
		finances_desc: "Visualiser les flux financiers d'un évènement",
		finances_resume: "Gestion des virements",
		finances_resume_desc:
			"Visualiser l'état des virements pour les évènements de la plateforme",
		placeholder_select: "Selectionner un évènement",
		placeholder_search: "Rechercher un évènement",
		select_no_options: "Aucun évènement",
		report: "Rapport",
		time: "Temps",
		section: "Segment",
		filters: "Filtres",
		placeholder_select_gender: "Selectionner un sexe",
		placeholder_select_category: "Selectionner une catégorie",
		H: "Homme",
		F: "Femme",
		A: "Autre",
		export: "Exporter",
		export_format: "Format d'export",
		placeholder_select_format: "Selectionner un format",
		optional: "(facultatif)",
		warning_passage: (line: number, min: number, done: number) =>
			`Ligne ${line} : ${min} passage min, ${done} passage effectués`,
		inviteNewUser: "Nouvelle invitation",
		error403: "Erreur 403",
		contactOrga: "Contactez l'organisateur si vous avez besoin de cet accès",
		restrictedPage: "Cette page est restreinte",
		permissions: "Droits d'accès",
		roles: "Droits",
		search_permissions: "Rechercher un nom",
		invoice: "Facture",
		dag_share_ht: "Part Dag HT",
		orga_share_ht: "Part Orga HT",
		total_ht: "Total HT",
		total_ttc: "Total TTC",
		com_ht: "Commission HT",
		bill_com: "Virement Com",
		bill_orga: "Virement Orga",
		title: "Titre",
		quantity: "Quantité",
		unit_prix_ht: "P.U HT",
		unit_prix_ttc: "P.U TTC",
		nbSubs: "Inscrits",
		StartPeriode: "Debut de la période",
		EndPeriode: "Fin de la période",
		paymentDone: "Virement effectué",
		validatePayment: "Valider le paiement",
		toastPaymentSuccess: "Virement validé",
		toastPaymentError:
			"Une erreur est survenue pendant la validation du virement",
		downloadContractAskValidation: "Télécharger et demande la publication",
		contract_explanation:
			"Afin de finaliser la publication de votre événement, il est essentiel d'établir un contrat. En confirmant votre demande de publication, nous vous enverrons ce contrat par email, qui devra ensuite être complété et renvoyé à notre équipe : ",
		licences: "Licences",
		licences_desc: "Gérer les licences d'utilisation des clients DAG",
		toast_invoice_error:
			"Une erreur est survenue. Vérifier que l'evenement est bien clôturé et le rib présent.",
		documents: "Documents",
		total_subs: "Nombre d'inscrits total",
		total_subs_online: "Nombre d'inscrits en ligne",
		total_subs_manual: "Nombre d'inscrits manuel",
		total_subs_imported: "Nombre d'inscrits importé",
		total_subs_in_place: "Nombre d'inscrits sur place",
		docMissing: "Documents manquants",
		manualCancel: "Annulation",
		signup_error_country: "Le pays est invalide",
		signup_error_city: "La ville est invalide",
		signup_error_postal_code: "Le code postal est invalide",
		signup_error_adresse: "L'adresse est invalide",
		sub_validation_message: "La raison est manquante",
		loginMissing: "Identifiants manquants",
		createNewEdition: "Créer une nouvelle édition",
		disableSubscriptions: "Désactivez les inscriptions",
		activateSubscriptions: "Activez les inscriptions",
		inputs_edition_not_complete: "Veuillez compléter tous les champs",
		error_new_edition:
			"Une erreur est survenue pendant la tentative de création d'un nouvelle edition",
		country_home: "Pays de résidence",
		mailUsed: "Mail ignoré, déjà utilisé par autrui",
		mailUsedTooltip:
			"Vérifiez le nom et le prénom, ils sont peut-être orthographiés différemment.",
		succes_stopsubscription_event: "Les inscriptions ont été désactivés",
		error_stopsubscription_event:
			"Une erreur est survenue pendant la désactivations des inscriptions",
		results: "resultat",
		displayOrder: "Ordre d'affichage",
		moveAbove: "Remonter",
		moveBelow: "Descendre",
		moveTop: "Déplacer tout en haut",
		moveBottom: "Déplacer tout en bas",
		cannotDeleteRunWithSubscription:
			"Vous ne pouvez pas supprimer une course avec des inscrits",
		ShouldBeNotSameDate: "Les dates ne doivent pas être identiques",
		toastSuccessAddingRib: "Le rib a été ajouté",
		toastErrorAddingRib: "Une erreur est survenue pendant l'ajout du rib",
		placeholder_select_rib: "Selectionner un rib",
		processed: "Traité(s)",
		earnings: "Collecte",
		chartTitleSubs: "Inscrits / Course",
		chartTitleGenders: "Genres",
		chartTitleCases: "Dossiers",
		noOptions: "Aucune option disponible",
		saveOk: "Sauvegarde effectuée",
		saveError: "Une erreur est survenue pendant la sauvegarde",
		shareLiveTrackingUrl: "Partagez le lien du suivi",
		cantPreview:
			"Le document est trop volumineux pour être prévisualisé dans un navigateur. Téléchargez-le pour le consulter",
		liveTypeOfRun: "Quel est le type de votre course ?",
		liveDateOfRun: "Quelle est la date de départ de la course ?",
		bibNotFound:
			"Ce dossard ne fait pas partie de votre évènement. Cliquer pour ajouter aux inscrits",
		between: "entre",
		and: "et",
		none: "Aucun",
		bySubscriber: " /coureur",

		chooseCalendarRunType: "Choisissez un type de course",
		chooseCalendarRunTypeSimple: "Course simple (Départ - Inter. - Arrivée)",
		chooseCalendarRunTypeLaps: "Course à tour",
		chooseCalendarRunTypeSimpleVirtual:
			"Course simple virtuel (Application sportive)",
		chooseCalendarRunTypeLapsVirtual:
			"Course à tour virtuel (Application sportive)",
		chooseCalendarRunTypeTriathlon: "Triathlon",
		calendarRunDistance: "Quelle distance fait la course ? (en mètres)",
		calendarRunLaps: "Combien de tours fait votre course ?",
		calendarRunDetectionPoints: "Vos points de détections",
		calendarRunSegments: "Vos étapes",
		calendarRunDetectionAddPoint: "Ajouter un point de détection",
		calendarRunAddSegment: "Ajouter une étape",
		calendarRunDetectionConfigurePoint: "Configurez votre point de détection",
		calendarRunConfigureSegment: "Configurez votre étape",
		calendarRunDetectionType: "Quel est le type de votre point ?",
		calendarRunDetectionChooseType: "Choisissez un type de point",
		finish: "Arrivée",
		arrived: "Arrivé",
		advancedParams: "Configuration avancée",
		interpassTime: "Temps interpassage",
		maxNumberPass: "Nombre de passages maximum",
		minNumberPass: "Nombre de passages minimum",
		startNumberPass: "Numéro de passage de début",
		endNumberPass: "Numéro de passage de fin",
		numberPass: "Numéro de passage",
		startDetectionPoint: "Entrée",
		endDetectionPoint: "Sortie",
		latitude: "Latitude",
		longitude: "Longitude",
		order: "Ordre",
		radius: "Radius",
		altitude: "Altitude",
		runFormTypeError: "Vous devez choisir un type de course",
		runFormDistanceError:
			"La distance de votre course doit être supérieure à 0",
		runFormLapsError: "Le nombre de tour doit être supérieur à 0",
		runFormPointNameError: "Vous devez donner un nom à votre point",
		runFormPointDistanceError: "La distance de votre point doit être définit",
		runFormPointLineError: "Une ligne doit être définie",
		runFormPointTypeError: "Vous devez choisir un type de point",
		runFormPointSameError: "Point de départ et de fin identiques",
		runFormSegmentNotComplete: "Informations de l'étape incompletes",
		runFormSegmentErrorHappened:
			"Une erreur est survenue lors de l'enregistrement de l'étape",
		calendarRunDeleteSegment: "Supprimer l'étape",
		runFormDeleteSegmentConfirmation:
			"Etes vous sûr de vouloir supprimer cette étape ?",
		runFormDeleteSegmentSuccess: "Etape supprimé avec succès",
		runFormUpdateSegmentSuccess: "Etape mis à jour avec succès",
		runFormDistanceType: "Choisissez la distance",
		whichRunConfigure: "Quelle course souhaitez-vous configurer ?",
		credentialDagLive: "Votre identifiant DAG Live",
		rankingHeaderRank: "Cl.",
		rankingHeaderName: "Nom",
		rankingHeaderBib: "Doss.",
		rankingHeaderLastPassage: "Dernier passage",
		rankingHeaderDetections: "Détections",
		rankingHeaderTime: "Temps",
		rankingHeaderCategoryRank: "Cl. Cat.",
		rankingHeaderSexRank: "Cl. Sexe",
		rankingHeaderRun: "Course",
		rankingHeaderTeam: "Equipe",
		rankingHeaderActions: "Actions",
		editRunnerTooltip:
			"Attention, mettre à jour le coureur affectera toutes ses autres inscriptions",
		editRunnerRunTooltip:
			"La mise à jour des informations de course affectera le classement",
		selectRunPlaceholder: "Selectionner une course",
		chooseTeam: "Choisissez une équipe",
		createTeam: "Créez une équipe",
		resetOnRunner: "Recaler sur coureur",
		bibTime: "Temps du dossard",
		manageDetections: "Gérer les détections",
		totalPriceOfRun: "Prix total de la course (hors frais) :",
		invalidateSub: "Rendre l'inscription invalide ?",
		errorVerifyBibAlreadyUse:
			"Une erreur est survenue. Vérifier que le dossard n'est pas déjà utilisé.",
		addDetection: "Ajouter une détection",
		setRunnerSurrended: "Passer le coureur en abandon",
		setRunnerDisqualified: "Passer le coureur en disqualifié",
		setRunnerStarting: "Passer le coureur en partant",
		setRunnerNotStarting: "Passer le coureur en non partant",
		updateRunnerStatusSurrended:
			"Mettre à jour le statut du coureur en abandon ?",
		updateRunnerStatusDisqualified:
			"Mettre à jour le statut du coureur en disqualifié ?",
		updateRunnerStatusStarting:
			"Mettre à jour le statut du coureur en partant ?",
		updateRunnerStatusNotStarting:
			"Mettre à jour le statut du coureur en non partant ?",
		notStarting: "Non partant",
		ranked: "Classé",

		// PatchNotes
		patchNotes: "Notes de mise à jour",
		patchAddNotes: "Ajout des notes de mise à jour",
		patchAddJetcodes: "Mise à disposition des Jetcodes pour un événement",
		patchAddRoles: "Gestion des droits d'accès des utilisateurs",
		patchCorrectDocuments: "Correction de la visualisation des documents",
		patchFixInvoiceErrorMessage:
			"Nouveau message d'erreur concernant les factures",
		patchChangeDashboardLinks:
			"Changement des liens pour les cartes évènements dans le Tableau de bord",
		patchUpdateSendOneMail: "Mise a jour de l'envoie du mail a un coureur",
		patchMailHistory:
			"Visualisation de l'historique des mails envoyé à un coureur",
		patchResponsive: "Responsive et ergonomie améliorer sur mobile",
		patchHoursPrices:
			"Correction de la mise à jour de l'heure quand on souhaite mettre à jour un tarif",
		patchRedirectionToValidate:
			"Mise a jour de la redirection au clique sur X à valider",
		patchTraduction: "Traductions mise à jour",
		patchEventImages: "Amélioration de l'affichage des images",
		patchCreateEdition: "Ajout de la création d'édition",
		patchPictoDocs: "Ajout de pictogramme pour les documents",
		patchForgetPassword: "Ajout d'une fonctionnalités d'oublie de mot de passe",
		patchAddPdfExport: "Ajout de la possibilité d'exporter en PDF les inscrits",
		patchAddFilterDocuments:
			"Ajout d'un filter pour les inscriptions avec documents en attente",
		onlyWithDocStart: "Afficher seulement les inscriptions avec",
		onlyWithDocStrong: " documents en attente",
		patchReasignBibsAndKeepThem:
			"Mise à jour du comportement de réasignation des dossards avec possibilité de garder les dossards déjà attribué",
		patchAddRegistration: "Amélioration de l'ajout d'inscription",
		patchGenderX: "Affichage du genre X",
		patchRegistrations: "Amélioration de la gestion des inscriptions",
		patchSwitchSubscriptionStatus:
			"Il est désormais possible d'activer ou désactivés les inscriptions a un évènement",
		patchUpdateAdminStatistics:
			"Mise a jour de l'interface d'administration concernant les tableaux de données",
		patchAddRibSelector:
			"Ajout d'une fonctionnalité d'ajout rapide de RIB à partir d'une liste des RIB déjà utilisé par le passé",
		patchAddResults: "Ajout de la fonctionnalité d'import de résultats",
		patchDocumentPreview: "Améliore la prévisualisation des documents",
		patchEditOption: "Permet de configurer les options des inscrits",
		patchUX: "Amélioration de l'expérience utilisateur",
		patchPDFExportRaces:
			"Ajout de la séléction des courses lors de l'export PDF",

		deleteGpxFileConfirmation:
			"Etes vous sûr de vouloir supprimer ce fichier ?",
		deletedFile: "Fichier supprimé !",
		selectRunMail: "Selectionnez une ou plusieurs courses",
		detectionPointName: "Nom du point de détections",
		detectionLigne: "Ligne",
		verifyPrestation:
			"Vérifiez que vous avez une prestation en cours et que votre configuration DAG live est correcte.",
		verifyCredential:
			"Une erreur est survenue. Vérifiez votre identifiant DAG Live.",
		noPrestationStarted:
			"Démarrez une première fois la prestation pour afficher le classement.",
		missing: "manquant",

		ffa_error_001: "Erreur sur le format de la date.(1)",
		ffa_error_002: "Informations fournies inssufisantes.(2)",
		ffa_error_003: "Numéro de licence introuvable.(3)",
		ffa_error_004: "Numéro de TP introuvable.(4)",
		ffa_error_005: "Numéro de CF introuvable.(5)",
		ffa_error_006:
			"La relation (Licence/TP/PPS) ne sera plus valide au moment de la compétition.(6)",
		ffa_error_007:
			"Idetité différente: mal orthographié ou couple (Relation / Identité) faux.(7)",
		ffa_error_008: "Identité introuvable.(8)",
		ffa_error_009: "Réponse impossible en raison d'homonymie.(9)",
		ffa_error_010:
			"Service FFA invalide, contactez directement l'organisateur.(10)",
		ffa_error_011: "Vous n'êtes pas authorisé à utiliser ce service.(11)",
		ffa_error_012: "Le service est bloqué, contactez la FFA(12)",
		ffa_error_013: "Erreur sur le format de la date de la compétition.(13)",
		ffa_error_014:
			"La date de la compétition ne correspond pas à la date saisie dans le SIFFA.(14)",
		ffa_error_015: "Numéro de PPS introuvable.(15)",
		SUB: "INS",
		ARR: "ARR",
		DNS: "NP",
		DNF: "AB",
		DQ: "DQ",
		sport_identity_error_name_taken: "Le nom de l'évènement est déjà pris"
	},

	/* ↓↓↓--ENGLISH VERSION--↓↓↓ */
	en: {
		delete_old_detections:
			"Do you want to keep the detections from the last performance?",
		select_race: "Please select a race",
		select_club: "Club selection",
		select_team: "Team selection",
		select_noClub: "No club available",
		select_noTeam: "No team available",
		select_gender: "Please choose the gender",
		select_bib: "Please choose a bib number",
		select_country: "Please choose a country",
		select_countrySearch: "Search...",
		select_countryNoResult: "No results",
		published: "Published",
		unpublished: "Not published",
		edit: "Edit",
		registered: "registered",
		toValidate: "to validate",
		ofTurnover: "of turnover",
		publishEvent: "Publish Event",
		unpublishEvent: "Unpublish Event",
		publishEventWarning:
			"Warning, you are about to publish this event, which means it will be visible to all Chronometrage.com users. Are you sure you want to continue?",
		unpublishEventWarning:
			"Warning, you are about to unpublish this event, which means it will no longer be visible to Chronometrage.com users. Are you sure you want to continue?",
		editEvent: "Edit Event",
		editRunner: "Edit Runner",
		handleDetection: "Manage detections",
		eventName: "Event Name",
		category: "Category",
		date: "Date",
		description: "Description",
		poster: "Poster",
		ffaFFTRI: "FFA / FFTRI",
		ffa: "FFA",
		fftri: "FFTRI",
		location: "Location",
		rulesPDF: "Rules PDF",
		website: "Website",
		file: "File",
		rib: "RIB",
		addRunner: "Add Runner",
		addRegistration: "Add Registration",
		sendEmailTitle: "Compose the email for registered participants",
		sendOneEmailTitle: "Compose the email for the participant",
		insight: "Email preview",
		config_title: "Management",
		runs_title: "Races",
		update_presentation: "Presentation",
		update_runs: "Races",
		update_options: "Options",
		update_contacts: "Contacts",
		update_sponsors: "Sponsors",
		update_ribs: "RIBs",
		configure_steps: "Steps",
		configure_live: "Live tracking",
		configure_results: "Send results",
		sync_folomi_error: "There are more registrations than runners.",
		sync_folomi_action: "Click to synchronize",
		sync_folomi_button: "Synchronize runners",
		sync_folomi_race_subscribed: "registered but only",
		sync_folomi_race_detected: "detected runners",
		ask_validation: "Request publication",
		unpublish: "Unpublish event",
		validation_asked: "Publication requested",
		preview: "Preview",
		preview_invoice: "Invoice preview",
		edit_invoice: "Invoice editing",
		commissions: "Commission Information",
		invoice_title: "Billing",
		option: "Option",
		configure_options: "Configure options",
		add_global_option: "Add a global option",
		add_price: "Add a price",
		add_option: "Add an option",
		add_product: "Add a product",
		min_quantity: "Minimum quantity",
		max_quantity: "Maximum quantity",
		designation: "Designation",
		option_type: "DESIRED FIELD TYPE",
		is_required: "DO YOU WANT TO MAKE THE OPTION MANDATORY?",
		product: "Product",
		price: "Price",
		type_dropdown_description:
			"List of different products (Example: T-shirt size, S, M, L)",
		type_check_description: "Checkbox (Example: Bus, Meal)",
		type_quantity_description:
			"Quantity request for a product (Example: Number of runner meals)",
		delete_title: "Deletion",
		delete_message: "Are you sure you want to delete",
		price_start_date: "START DATE AND TIME OF PRICE",
		price_end_date: "END DATE AND TIME OF PRICE",
		warning_no_price:
			"At least one price (even free) must exist for this race to add an option.",
		warning_delete: "(The operation will take effect after saving)",
		error_qty_min_0: "Quantities must be greater than 0",
		error_qty_min_max:
			"The minimum quantity must be less than the maximum quantity",
		error_too_small: "(Too small)",
		default: "Default",
		prestation: "Launch the service",
		loading: "Loading",
		importResults: "Import results",
		set: "Set",
		notSet: "Not set",
		filesToBeChecked: "files to be checked",
		completedAndValidatedFiles: "completed and validated files",
		viaOptions: "via options",
		confirm: "Confirm",
		cancel: "Cancel",
		interrupt: "Interrupt",
		previous: "Previous",
		next: "Next",
		save: "Save",
		search: "Search",
		download: "Download",
		allM: "All",
		allF: "All",
		delete: "Delete",
		inTeam: " - In team",
		send: "Send",
		create_event: "Create an event",
		required: "Required",
		discard_changes: "Discard changes?",
		preview_off: "Close preview",
		error_boundary_title: "An error has occurred.",
		error_boundary_support:
			"If the problem persists, contact support and provide the following information:",
		back: "Back",
		sendMailAllSubscribers: "Send collective email",
		sendMail: "Send email",
		sendMailError: "An error occurred while sending the email",
		resetExpired: "Reset expired. Ask for a new reset",
		eventSearch: "Search for an event",
		number_bib: "Bib number",
		number_line: "Line number",
		time_detection: "Passing time",
		number_line_short: "Line",
		whatIsJetcode: "What is a jetcode?",
		jetcodeExplanation:
			"It is a tool that allows you to integrate the content of another page (registration forms & online payment, remaining bib counters, countdown, chronometrage.com logo) directly on your website.",
		howIntegrateJetcodeToMyWebsite:
			"How to integrate a jetcode into my website?",
		jetcodeIntegrationExplanation:
			"Simply copy the provided codes of the desired jetcodes (include my registrations and chronometrage.com logo) and paste them on a page of your website.",
		integrateSubscriptionsForm: "Integrate the registration form",
		integrateSubscirbersList: "Integrate the list of registrants",
		integrateOnlineSubscriptionLogo: "Integrate the 'online registration' logo",
		engineCodeLabel: "Engine code to be copied once in the header of your site",
		french: "French",
		english: "English",
		copy: "Copy",
		copyDone: "Copied!",
		insertionCodeLabel:
			"Basket insertion code, this code is to be inserted once per page",
		language: "Language",
		runSelectLabel: "Desired race for the jetcode",
		priceJetcodeCodeLabel: "Jetcode Pricing setup to copy into your site",
		theme: "Theme",
		setup: "Setup",
		seeTimer: "See timer",
		seeSeconds: "See seconds",
		seeBibs: "See bibs",
		seeBibsLeft: "See remaining bibs",
		codeToCopyToYourWebsite: "Code to copy to your website",
		downloadProcessLabel:
			"Download the procedure in PDF format to provide to your webmaster",
		listSetup: "List setup",
		selectARun: "Select a race",
		generalSettings: "General settings",
		dagLiveCredentials: "DAG Live Credentials",
		sensorName: "Label",
		distance: "Distance (m)",
		passageMin: "Min passages",
		passageMax: "Max passages",
		sensor: "Sensor",
		type: "Type",
		start: "Start",
		stop: "Stop performance",
		deleteDetections: "Delete definitively detections",
		deleteDetectionsSuccess: "Deletion completed",
		deleteDetectionsError: "Deletion failed",
		prestationError: "ERROR",
		sensorSettings: "Sensor settings",
		addGpx: "Add GPX",
		chooseSensorType: "Choose sensor type",
		startLine: "Start line",
		finishLine: "Finish line",
		checkpoint: "Checkpoint",
		addSensor: "Add a sensor",
		removeThisSensor: "Remove this sensor",
		handleRunners: "Runners",
		handleDetections: "Detections",
		dashboardDetections: "Summary",
		global_configuration: "Configuration",
		route_configuration: "Routes",
		delete_step: "Delete this step",
		edit_step: "Edit this step",
		delete_parcours: "Delete this route",
		edit_parcours: "Edit this route",
		delete_segment: "Delete this segment",
		edit_segment: "Edit this segment",
		step: "Step",
		parcours: "Route",
		segment: "Segment",
		configure_routes: "Configure routes",
		configure_segments: "Configure segments",
		intermediary: "Intermediary",
		start_line: "Start",
		end_line: "Finish",
		typeLine1: "Start",
		typeLine2: "Intermediary",
		typeLine3: "Finish",
		grid: "Grid layout",
		categoryReassignement: "Category reassignment",
		handleResults: "Results",
		timezone: "Which timezone do you want to keep?",
		no_run_selected: "No race selected",
		placeholder_select_run: "Select a race",
		placeholder_select_segment: "Select a segment",
		log_title: "Log in to your admin tool",
		forgotten_title: "Forgotten password ?",
		reset_password: "Reset password",
		back_to_home: "Back to homepage",
		passwords_not_match: "Passwords do not match",
		passsword_reset_success: "Your password has been reset",
		password_too_short: "Your password must be at least 6 characters long",
		send_forgotten_mail_success: "An email has been sent to you",
		email: "Email address",
		new_password: "New Password",
		new_password_confirm: "Confirm new Password",
		password: "Password",
		confirmPassword: "Confirm your password",
		submit: "Log In",
		social_reason: "Social reason",
		adresse: "Address",
		postal_code: "Postal code",
		city: "City",
		country: "Country",
		noAccount: "Don't have an account yet?",
		rememberMe: "Remember me",
		signup_button: "Create an account",
		signup: "I don't have an account yet",
		signup_title: "Sign up for the admin tool",
		already_account: "I already have an account",
		forgotten_password: "Forgotten password ?",
		lastname: "Last Name",
		firstname: "First Name",
		birthdate: "Date of Birth",
		sex: "Gender",
		acceptCGV: "I accept the conditions of use of the platform",
		signup_error_firstname: "Your first name must be more than 1 letter.",
		signup_error_lastname: "Your name must be more than 1 letter.",
		signup_error_birthdate:
			"Your date of birth must be valid and you must be between 13 and 100 years old.",
		signup_error_email: "Your email is invalid.",
		signup_error_password: "Your password must be at least 6 characters long.",
		signup_error_passwordConfirm: "Your passwords are not identical.",
		signup_error_CGU: "You must accept the T&Cs.",
		male: "Male",
		female: "Female",
		other: "Other",
		legalEntity: "Legal Entity",
		address: "Address",
		team: "Team",
		race: "Race",
		bib: "Bib",
		bibAvailable: "Available Bib",
		bibUnavailable: "Bib already used",
		invalid: "Invalid",
		bibInvalid: "Invalid Bib",
		runner: "Runner",
		zipCode: "Zip Code",
		birthDate: "Birth Date",
		genderH: "M",
		genderF: "F",
		genderX: "X",
		editGeneral: "General",
		editRace: "In race",
		nationality: "Nationality",
		countryPlaceholder: "Select a country",
		firstPhone: "Phone 1",
		secondPhone: "Phone 2",
		marketer: "Marketer",
		organizer: "Organizer",
		yes: "Yes",
		no: "No",
		bbanDelete: "Delete BBAN",
		bbanDeleteWarning:
			"Warning, you are about to delete this BBAN, this action is irreversible. Are you sure you want to continue?",
		addTeam: "Add a team",
		club: "Club",
		duplicateEmail: "This email address is already in use",
		alreadyInUse: "Already in use",
		editTimes: "Times",
		object: "Subject",
		mailBody: "Mail body",
		swapBibs: "Swap bibs",
		swapFrom: "From",
		swapTo: "To",
		nbDetections: "Detections",
		stageDepartureHour: "Official departure time",
		handicap: "Handicap",
		miniTime: "Minimum time on the stage",
		error404: "Error 404",
		pageDoesNotExist: "This page does not exist",
		iThinkYouAreLost: "I think you are lost",
		goBackHome: "Go back to homepage",
		dashboard: "Dashboard",
		registrations: "Registrations",
		event: "Event",
		liveResults: "Timing",
		menuResults: "Results",
		liveConfiguration: "Configuration du Live",
		statistics: "Statistics",
		admin: "Administration",
		jetcodes: "Jetcodes",
		toCome: "To come",
		today: "Today",
		past: "Past",
		myAccount: "My account",
		logout: "Logout",
		manageRunners: "Manage runners",
		detections_dashboard: "Detections dashboard",
		manage_dashboard: "Manage detections",
		registration_refund: "Registration refund",
		registration_order: "Registration order",
		cgu: "Terms of use",
		cgv: "General conditions of sale",
		manage_results: "Manage results",
		startOn: "Starts on",
		registeredContestants: "Registered runners",
		displayRegistrations: "Display registrations",
		completeSubscriptionsList: "Complete list of registrations",
		bibs: "Bibs",
		bibsSearch: "Search for a bib",
		firstnameSearch: "Search for a first name",
		lastnameSearch: "Search for a last name",
		statusSearch: "Search for a status",
		loadingRegisterForm: "Loading registration form",
		thanksToWait: "Thank you for waiting",
		noRace: "No race configured",
		teamOf: "TEAM of",
		invididual: "SOLO",
		importError: "Error during import",
		preimportWarning: "WARNING",
		importWarning: "WARNING",
		preimportError: "ERROR: THIS REGISTRATION WILL NOT BE IMPORTED...",
		preimportErrors: "ERROR: THESE REGISTRATIONS WILL NOT BE IMPORTED...",
		exportTitle: "Export registrations in",
		raceChoice: "Races",
		sexeChoice: "Gender",
		statusChoice: "Status",
		subscriptionType: "Subscription type",
		importTitle: "Import registrations",
		toImport: "To import a list of registrations, use",
		useThisModel: "this EXCEL file template",
		dragHere: "Drag the EXCEL file (.xlsx format) here...",
		dragHereOr:
			"Drag the EXCEL file (.xlsx format) here, or click to select it",
		fileFormatNotSupported: "File format not supported",
		existingSubs: "previously imported registrants.",
		replace: "Remove all and replace",
		donotupdate: "Add only new bibs",
		continue: "Continue import",
		entries: "additions on",
		import: "Import",
		nbImportedRunners: "imported runner(s)",
		pre: "Pre-",
		importing: "Importing...",
		existing: "already existing",
		adds: "additions on",
		add: "additions",
		close: "Close",
		whattodo: "What do you want to do?",
		line: "Line",
		invalidRace: "Invalid race",
		bibUsed: "Bib already used",
		missingRace: "Race not provided",
		missingBib: "Bib not provided",
		yourEvents: "Your events",
		yourRuns: "Your runs",
		bibsAllocation: "Bibs allocation",
		discountVouchers: "Discount vouchers",
		form: "Form",
		refunds: "Refunds",
		accessRights: "Access rights",
		bankBBAN: "BBAN",
		manageBibsAllocation: "Manage bibs allocation",
		manageEventDiscountVouchers: "Manage event discount vouchers",
		manageEventForm: "Manage event form",
		manageRefunds: "Manage refunds",
		manageAccessRights: "Manage access rights",
		manageYourBBAN: "Manage your BBAN",
		manageFFAFFTRILicenses: "Manage your FFA/FFTRI licenses",
		ribListLinkToThisEvent: "List of RIBs linked to this event",
		addBBAN: "Add a BBAN",
		name: "Name",
		bank: "Bank",
		ibanBban: "IBAN / BBAN",
		thereAreCurrently: "There are currently",
		registrantsForThe: "registrants for the race",
		doYouReallyWantToReimburseThe: "Do you really want to reimburse the",
		registrants: "registrants",
		chargeAdditionalFeesPerRegistrations:
			"Additional fees per registration (in €)",
		invoicingOnRunnerOrOrganizer: "Invoicing on the runner or the organizer",
		ifRegistrationFeeIncluded:
			"If the registration fee is included, covered by",
		reimbursementCostsCoveredBy: "Reimbursement costs covered by",
		makeTheRefund: "Make the refund",
		reason: "Reason",
		choose: "Choose...",
		bibPlan: "Bib numbering plan",
		bibPlanAffectation: "Way to assign bibs",
		minBib: "Minimum Bib",
		maxBib: "Maximum Bib",
		bibExclusion: "Excluded Bib",
		bibExlusionPlaceholder:
			"Example: \rList = 1;2;8;10 (bibs 1, 2, 8, and 10 will not be considered) \rRange = 5:10 (bibs from 5 to 10 inclusive (5, 6, 7, 8, 9, and 10) will not be considered) \rCombination 1 = 2:4;7:8 (bibs 2, 3, 4, 7, and 8 will not be considered) \rCombination 2 = 2;4:6;8 (bibs 2, 4, 5, 6, and 8 will not be considered)",
		reaffectAllbibs:
			"Reassign all bibs for the race by validating the selection",
		keepBibs: "Keep already assigned bibs",
		applyToPending: "Apply to Pending Registrations",
		orderAlphabetic: "Reassign bibs in alphabetical order of runners",
		list: "By bib list",
		range: "By range of bibs",
		filterByRun: "Filter by race",
		resolution: "Resolution",
		days: "Days",
		weeks: "Weeks",
		months: "Months",
		years: "Years",
		downloadInPdf: "Download in PDF",
		nbOfSubscribers: "Number of subscribers",
		maxInfMin: "Please choose a maximum value greater than the minimum value",
		errorBib: "An error occurred while updating the bibs",
		updatedBib: "Bib numbers have been updated",
		loginSuccess: "Login successful",
		loginError: "Login error",
		logoutSuccess: "Logout successful",
		logoutError: "Logout error",
		updateError: "Update encountered an issue",
		updateSuccess: "Update was successful",
		updateCredentialSuccess: "Update of credentials was successful",
		updateCredentialError: "Update of credentials encountered an issue",
		startPrestation: "The service has started",
		liveTimezone:
			"There is an inconsistency in the timezone configuration of your event.",
		stopPrestation: "The service has stopped",
		prestationStartError: "Error when starting the service",
		prestationStartErrorCredentiel:
			"Please configure your credential before starting the service",
		prestationStopError: "Error when stopping the service",
		updateCheckpointSuccess: "The detector recording was successful",
		updateCheckpointError: "Error during detector recording",
		deletionSuccess: "Deletion successful",
		deletionError: "An error occurred during deletion",
		insertSuccess: "Creation successful",
		insertError: "Error during creation",
		insertErrorDetection:
			"The detection could not be transmitted. Please check that you have a race in progress.",
		ppsError: "Invalid Licence or PPS (not validated by French Federation)",
		requiredRace: "Please choose a race",
		requiredBib: "Please choose a bib number",
		requiredBirthdate: "Please provide the date of birth",
		requiredGender: "Please provide the gender",
		requiredTeamName: "Please provide the team name",
		requiredTeam: "Please choose a team from the list or create a new one",
		requiredOneOrAllRunner: "Please provide one or all runners",
		emailSendedSuccess: "Emails sent successfully.",
		emailSendedError: "An error occurred while sending emails.",
		login_expired: "Your session has expired",
		newRaceUpdateSuccess: "Race change was successful",
		newRaceUpdateError: "An error occurred during race change",
		bibChangeRaceChoice: "Please make a choice regarding the bibs",
		refundSuccess: "Refund was successful",
		refundError: "Refund encountered an error",
		sport_identity_success: "Event registration successful",
		sport_runs_success: "Runs configured successfully",
		sport_options_success: "Options configured successfully",
		sport_identity_error: "An error occurred during event creation",
		sport_runs_error: "An error occurred during runs configuration",
		sport_options_error:
			"Unable to save the options. Please verify that you are not modifying a rate for an already subscribed option",
		file_error: "An error occurred while reading the file",
		file_exist: "This file is already uploaded",
		event_form_required: "You have not filled in all required fields",
		event_invalid_dates: "Warning: The end date is the same as the start date",
		error_creation_segment: "An error occurred during stage saved",
		success_creation_segment: "Stage saved successfully",
		success_deletion_segment: "Stage deleted",
		error_deletion_segment: "An error occurred during stage deletion",
		error_creation_parcours: "An error occurred during parcours recording",
		success_creation_parcours: "Parcours recorded successfully",
		success_deletion_parcours: "Parcours deleted",
		error_deletion_parcours: "An error occurred during parcours deletion",
		error_creation_etape: "An error occurred during étape recording",
		success_creation_etape: "Étape recorded successfully",
		success_deletion_etape: "Étape deleted",
		error_deletion_etape: "An error occurred during étape deletion",
		success_creating_price: "Price recorded successfully",
		error_creating_price: "An error occurred during price creation",
		success_deleting_run: "Race deleted successfully",
		error_deleting_run_subs: "You can't delete a run with subscriptions",
		error_deleting_run: "An error occurred during race deletion",
		success_deleting_price: "Price deleted successfully",
		error_deleting_price: "An error occurred during the deletion of the price",
		success_updating_fields: "Registration form updated",
		error_updating_fields: "An error occurred while updating the form",
		event_does_not_exist: "The event you wish to configure does not exist",
		success_updating_contact: "Contact registered",
		error_updating_contact: "An error occurred while registering the contact",
		success_updating_rib: "Bank account (RIB) registered",
		error_updating_rib:
			"An error occurred while registering the bank account (RIB). The legal representative may already have an account.",
		success_deleting_contact: "Contact deleted",
		success_deleting_rib: "Bank account (RIB) deleted",
		error_deleting_contact: "An error occurred while deleting the contact",
		error_deleting_rib:
			"An error occurred while deleting the bank account (RIB)",
		error_signup: "An error occurred. This email may already be in use.",
		success_signup: "Your account has been successfully created",
		success_change_orga: "You are now an organizer, please log in again",
		error_change_orga: "An error occurred.",
		error_contact_empty:
			"Please provide the company name, first name, and last name of the contact.",
		success_ask_validation: "An email has been sent to the administrator",
		error_ask_validation: "An error occurred while asking validation",
		import_error: "An error occurred during import",
		success_publish_event: "Event published",
		success_unpublish_event: "Event unpublished",
		error_unpublish_event: "An error occurred",
		cant_delete_stage:
			"Please delete the detections associated with this stage before deleting it",
		reassignCategorySuccess: "Categories successfully reassigned",
		reassignCategoryError: "An error occurred while reassigning categories",
		orgaSuccess: "You are now an organizer",
		orgaError: "An error occurred",
		toast_raceChoice: "Please select at least one race from the list",
		genderChoice: "Please select at least one gender from the list",
		toast_statusChoice:
			"Please select at least one registration status from the list",
		typeChoice: "Please select at least one registration type from the list",
		changeTimezoneError: "An error occurred during timezone change",
		delete_detections_choice: "Please make a choice",
		no_delete: "No detections have been deleted",
		export_error: "An error occurred during export",
		accept_document_success: "The document has been accepted",
		accept_document_error: "An error occurred",
		refuse_document_success:
			"The document has been refused, and an email has been sent to the runner",
		refuse_document_error: "An error occurred",
		category_or_team: "Category / Team",
		run: "Race",
		status: "Status",
		options: "Options",
		runners: "Runners",
		starter: "Starter",
		surrendered: "Surrendered",
		disqualified: "Disqualified",
		filter: "Filter",
		gender: "Gender",
		man: "Male",
		woman: "Female",
		other_gender: "Other",
		your_runners: "Your runners",
		refused_reason: "Reason for refusal",
		accept_document: "Accept",
		refuse_document: "Refuse",
		refuse_document_and_notice: "Refuse et Send mail",
		your_subscriptions: "Your subscriptions",
		contact: "Contact",
		edit_subscription: "Edit subscription",
		add_subscription: "Add subscription",
		message: "Send a message",
		document: "Manage documents",
		actions: "Action",
		waiting: "Waiting",
		valid: "Valid",
		reimbursed: "Reimbursed",
		documents_sent: "Document(s) sent",
		close_documents: "Close",
		manage_documents: "Document management",
		personnal_informations: "Personal information:",
		age: "Age",
		phone: "Phone",
		emergency_phone: "Emergency Phone",
		mail: "Email",
		run_informations: "Race Information",
		bib_info: "Bib Number",
		licence: "License",
		validated: "validated",
		by: "by",
		la: "the",
		validated_by_fede: "Validated by ",
		certificat: "Medical certificate",
		autorisation: "Parental Authorization",
		validation_date: "Validation Date:",
		sub_team: "Team / Association / Company",
		sub_options: "Subscriber Options:",
		reimburse: "Reimburse",
		validate_subscription: "Validate Subscription",
		refuse_subscription: "Refuse Subscription",
		refuse_reason: "What is the reason for refusal?",
		see_message_history: "See Mails history",
		messages_history: "Mails history",
		close_history: "Close History",
		close_comments: "Close comments",
		"inscription.refus.title": "Subscription Refused",
		no_messages: "No messages sent to this subscriber",
		changeRace: "Change Race",
		changeRaceModalTitle: "Subscription Modification for:",
		currentlyRegistredFor: "Currently registered for the race",
		newRace: "New Race",
		cannotChangeRace: "Race change not possible",
		teammatesWith: "With",
		bibMessageChangeRun: "1 - Do you wish to keep the bib numbers?",
		automaticBibChangeRun:
			"2 - Do you want to automatically assign new bib numbers based on the numbering plan of the new race?",
		bibAlreadyExistInNewRace:
			"already has the bib number in the selected race. A new bib number will be automatically assigned to the runner if a numbering plan has been defined for the chosen race.",
		noBibInNewRace:
			"ATTENTION: The current bib number will be released. Manual assignment of the bib number will be required.",
		noBibAssignmentInNewRace:
			"ATTENTION: The current bib number will be released as the new race does not have a numbering plan.",
		see_order: "View Order",
		no_document: "No Document",
		detectors: "Detectors",
		verification: "Verification OK",
		percentage: "Percentage",
		finishers: "Finishers",
		number_runners: "Runners",
		non_starters: "Non-Starters",
		start_the: "Starts on:",
		abandon: "Abandonments",
		official_starters: "Official Starters",
		manage_detections_title: "Detections",
		hours: "Passing Time",
		restore_detection: "Restore",
		delete_detections: "Delete Detections",
		choose_detectors: "Choose a line",
		add_detection: "Add Detection",
		edit_detection: "Edit This Detection",
		date_format: "Date Format",
		chrono_format: "Chrono Format",
		effective_start: "Effective Start",
		races: "All Races",
		deleteAllDetections: "Do you want to delete all detections?",
		confirmStartSaveCredential:
			"Do you want to save your credential before starting the live?",
		deleteTitle: "Deleting Race Detections",
		all: "Delete all event detections",
		upload_dag: "Import DAG File",
		insertion: "Insertion",
		estimation: "Estimation",
		current_time: "Duration",
		seconds: "seconds",
		detection_detail: "Detection Details",
		already_exist: "Already Exist",
		inserted: "Inserted",
		runner_created: "Including Runner Created",
		checkpoint_missing: "Missing Checkpoint",
		interpassage_ignored: "Ignored Due to Interpassage Time",
		search_runner: "Search for a Runner",
		search_event: "Search for an Event",
		search_bib: "Search for a Bib Number",
		search_subscription: "Search for a Subscription",
		autorisationParentale: "Parental Authorization",
		licence_already_sent: "License Already Sent",
		certificat_already_sent: "Certificate Already Sent",
		autorisation_already_sent: "Authorization Already Sent",
		send_documents: "Send New Documents",
		see_document: "View Document",
		download_document: "Download",
		product_name: "Product Name",
		refund_price: "Price (TTC)",
		refund_amount: "Refund Amount",
		subscription_fees: "Subscription Fees",
		refund_reason: "Reason for Your Refund (Required)",
		refund: "Refund",
		orga_sub_fees: "Subscription Fees at Your Charge?",
		orga_refund_fees: "Refund Fees (1€ TTC) at Your Charge?",
		contact_doubt:
			"Please contact support if you have any doubts about your actions",
		refund_summary: "Refund Summary",
		refund_close_summary: "Close Summary",
		refund_summary_start_text: "You are about to refund ",
		refund_summary_end_text: " to this runner, do you wish to confirm?",
		confirm_refund: "Confirm Refund",
		refund_paid: "The runner has paid",
		amount_to_refund: "You are going to refund",
		refund_fees: "Refund Fees",
		refunded_amount: "Total Refund Amount",
		already_refunded: (amount: string, date: string) =>
			`You can no longer refund this subscription because a refund of ${amount} € already took place on ${date}.`,
		refund_product: "Refund",
		choose_type: "I want to organize an event",
		select: "Select",
		type_details: "Details",
		sport_event_identity: "Event Presentation",
		sport_event_runs: "Race Configuration",
		sport_event_options: "Option Configuration",
		go_back: "Back",
		save_and_go_run: "Save and Configure Races",
		save_and_go_options: "Configure Options",
		save_and_finish: "Finish Configuration",
		event_name_label: "Event Name",
		event_sport_label: "Sport",
		event_sport_option: "Select a sport",
		event_start_date_label: "Start Date",
		event_end_date_label: "End Date",
		event_poster_label: "Image",
		event_banner_label: "Banner",
		event_short_description_label: "Short Description",
		event_long_description_label: "Long Description",
		event_address_label: "Address",
		event_postal_code_label: "Postal Code",
		event_city_label: "City",
		event_country_label: "Country",
		event_name_error: "The name of your event must be longer than 3 characters",
		event_sport_error: "You must choose a sport",
		event_start_date_error:
			"The date cannot be before today's date (or must match the date entered in the SIFFA if FFA Code)",
		event_ffa_siffa_error:
			"Missing FFA authorization. Have you linked your event to DAG System on CALORG?",
		event_end_date_error: "You must choose a date later than today's date",
		event_short_description_error:
			"The short description must be between 3 and 190 characters",
		event_address_error: "Your address must be longer than 3 characters",
		event_postal_code_error:
			"Your postal code must be longer than 3 characters",
		event_city_error: "Your city must be more than 3 characters",
		event_country_error: "You must choose a country",
		event_country_option: "Select a country",
		event_timezone_label: "Timezone",
		event_places_label: "Available Spots",
		event_timezone_option: "Select a timezone",
		event_rules_label: "Event Rules",
		event_run_name_label: "Race Name",
		event_run_date_label: "Race Date",
		event_run_member_label: "If by team, number of teammates",
		event_run_transfer_label: "Allows transfer of registrations ?",
		event_run_details_label: "Additional informations",
		event_run_details: "If you want to give more informations about the run",
		event_run_number_members_label: "Number of Teammates",
		event_run_places_label: "Available Spots",
		event_run_start_date_label: "Start Date",
		event_run_end_date_label: "End Date",
		event_run_same_bib_label: "Same bib for the team",
		event_run_fftri_code: "FFTRI Competition Code",
		event_run_fftri_distance: "Distance Type",
		event_run_same_bib_no: "No",
		event_run_same_bib_yes: "Yes",
		event_add_run: "Add Race",
		event_delete_run: "Delete Race",
		event_undo_delete_run: "Do Not Delete Race",
		event_prices: "Prices",
		event_run: "Race",
		event_run_designation_label: "Designation",
		edit_run_title: "Edit Race",
		edit_tarif_title: "Edit Price",
		edit_contact_title: "Edit Contact",
		edit_rib_title: "Edit Bank Account",
		event_run_age_control_label: "Age Limit Type",
		event_run_age_control_option: "No Age Limit",
		event_run_age_min_label: "Minimum Age",
		event_run_age_max_label: "Maximum Age",
		event_run_add_price: "Add Price",
		event_add_contact: "Add Contact",
		event_add_rib: "Add Bank Account",
		validate: "Validate",
		fees_inclued: "inclusive",
		fees_exclued: "exclusive",
		event_fees_inclued: "Inclusive Fees",
		event_fees_exclued: "Exclusive Fees",
		event_starting_date: "Start:",
		event_ending_date: "End:",
		event_age_disponibility: (min: number, max: number) =>
			`Available from ${min} years old to ${max} years old`,
		event_price_fee_inclued: (fees: number) =>
			`including ${fees.toFixed(2)}€ fees`,
		event_price_fee_exclued: (fees: number) => `+ ${fees.toFixed(2)}€ fees`,
		event_run_price: "Price",
		event_run_fees_type_label: "Fee Type",
		event_run_type_control_event: "According to event date",
		event_run_type_control_subscription: "According to registration date",
		event_run_type_control_year_event: "According to event year",
		event_run_free_run: "Free Race?",
		event_no_free_run: "No",
		event_free_run: "Yes",
		event_free_run_text: "Free Race",
		event_ffa_siffa_label: "FFA Competition Code",
		event_configure_sub_form: "Configure the form",
		event_edit_run: "Edit the race",
		event_run_form_title: "Registration Form Configuration",
		event_tooltip_form: "A form is configured for this rate",
		event_field_nom_label: "Last Name",
		event_field_prenom_label: "First Name",
		event_field_sexe_label: "Gender",
		event_field_dateNaissance_label: "Date of Birth",
		event_field_mail_label: "Email",
		event_field_ville_label: "City",
		event_field_codePostal_label: "Postal Code",
		event_field_pays_label: "Country",
		event_field_nationalite_label: "Nationality",
		event_field_telephone_label: "Telephone",
		event_field_telephoneUrgence_label: "Emergency Telephone",
		event_field_numLicence_label: "License Number",
		event_field_licence_label: "License",
		event_field_certificat_label: "Medical Certificate",
		event_field_pps_label: "PPS Number (FFA)",
		event_field_adresse1_label: "Address",
		event_field_adresse2_label: "Address Line 2",
		event_field_club_label: "Club",
		event_field_team_label: "Team",
		event_field_ffa_label: "FFA CNIL",
		event_field_autorisationParentale_label: "Parental Authorization",
		event_field_tiers_label: "Third Party",
		enter_text: "Enter your description",
		enter_mail_content: "Write your email content...",
		enter_mail_object: "Write your email subject...",
		sport_event_contacts: "Contact Management",
		sport_event_ribs: "Bank Account Management",
		event_raisonSocial_label: "Legal Name",
		event_nom_label: "Last Name",
		event_prenom_label: "First Name",
		event_adresse1_label: "Address",
		event_adresse2_label: "Address Line 2",
		event_ville_label: "City",
		event_codePostal_label: "Postal Code",
		event_pays_label: "Country",
		country_home: "Country",
		event_telephone1_label: "Telephone",
		event_telephone2_label: "Secondary Telephone",
		event_mail_label: "Email",
		event_siteWeb_label: "Website",
		event_facebook_label: "Facebook",
		event_twitter_label: "Twitter",
		event_instagram_label: "Instagram",
		delete_tarif_title: "Do you really want to delete the rate",
		delete_run_title: "Do you really want to delete the race",
		restore_deletes: "Restore Deleted Items",
		restore: "Undo Changes",
		delete_contact_title: "Do you really want to delete the contact",
		create_step: "Add a Step",
		create_route: "Add a Step",
		step_name_label: "Step Name",
		event_distance_label: "Distance (in meters)",
		sport_event_steps: "Step Configuration",
		sport_event_parcours: "Route Configuration",
		sport_event_segments: "Stage Configuration",
		parcours_name_label: "Route Name",
		segment_name_label: "Stage Name",
		parcours_file_label: "GPX File",
		create_parcours: "Add a Route",
		update_parcours: "Edit a Route",
		create_segment: "Add a Stage",
		update_segment: "Edit a Stage",
		delete_step_title: "Do you really want to delete the step",
		delete_segment_title: "Do you really want to delete the stage",
		delete_parcours_title: "Do you really want to delete the route",
		checkpoint_debut_label: "Start Checkpoint",
		checkpoint_fin_label: "End Checkpoint",
		parcours_active_label: "Active Route",
		active: "active",
		event_fees: "Fees",
		dag_fees: "DAG Fee (VAT included)",
		subscription_income: "Your Income",
		subscription_fees_sup: "Your VAT included share",
		subscription_fees_total: "Total Registration Fees (VAT included)",
		event_sport_price: "Race Price",
		event_fftri_default_distance: "Choose a distance",
		designation_label: "Designation",
		unlimited: "Unlimited",
		nomBanque_label: "Bank Name",
		iban_label: "IBAN",
		bic_label: "BIC",
		inCharge_label: "I am the legal representative",
		lastname_label: "Last Name",
		firstname_label: "First Name",
		birthdate_label: "Date of Birth",
		email_label: "Email",
		address_label: "Address",
		city_label: "City",
		postalCode_label: "Postal Code",
		country_label: "Country",
		phone_label: "Phone",
		sex_label: "Gender",
		genderHfull: "Male",
		genderFfull: "Female",
		genderXfull: "Other",
		select_type_rib: "Select a role",
		see_contract: "See contract",
		add_general_option: "Add a global option",
		delete_stage_cannot_start_live:
			"WARNING: If you delete this stage without creating another one, you won't be able to go live.",
		event_configuration: "I want to organize an event",
		event_identity: "Event Identity",
		event_runs: "Races",
		event_options: "Options",
		event_subscription: "Registration Configuration",
		event_contacts: "Contacts",
		event_rib: "Bank Accounts",
		subscriptions: "Subscriptions",
		live_results: "Live / Results",
		manage_detections: "Detection Management",
		detection_dashboard: "Detection Summary",
		manage_runners: "Runner Management",
		manage_live: "Live Management",
		manage_steps: "Step Configuration",
		manage_parcours: "Route Configuration",
		manage_segments: "Stage Configuration",
		bib_plan_exemple_1:
			"List = 1;2;8;10 (bib numbers 1, 2, 8, and 10 will not be considered)",
		bib_plan_exemple_2:
			"Range = 5:10 (bib numbers from 5 to 10 inclusive (5, 6, 7, 8, 9, and 10) will not be considered)",
		bib_plan_exemple_3:
			"Combination 1 = 2:4;7:8 (bib numbers 2, 3, 4, 7, and 8 will not be considered)",
		bib_plan_exemple_4:
			"Combination 2 = 2;4:6;8 (bib numbers 2, 4, 5, 6, and 8 will not be considered)",
		event_name: "Used to identify the activity during registration.",
		event_places:
			"Limits registrations to a defined number of spots (leave empty for unlimited spots).",
		event_start_date: "Defines the start date of the activity.",
		event_end_date: "Defines the end date of the activity.",
		event_team_number:
			"Defines how many registrants make up a team (leave empty if the activity is not team-based).",
		event_transfer:
			"Allows registrants to transfer their registration to another user.",
		event_price_name:
			"Describes the race price (e.g., Early Bird, Child, Adult).",
		event_age: "Defines an age limit for registration.",
		event_min_age: "Sets the minimum age required for registration.",
		event_max_age: "Sets the maximum age required for registration.",
		event_price_start_date:
			"Defines the start date when the rate becomes available. Rates won't be visible before this date.",
		event_price_end_date:
			"Defines the end date when the rate is no longer available. Rates won't be visible after this date.",
		event_price_free:
			"Indicates whether the rate is free. Participants won't pay fees, but they will be charged to you.",
		event_price_price: "Defines the race price (additional fees may apply).",
		event_price_fees_type:
			"Indicates whether fees are exclusive (not included in the race price) or inclusive (included in the race price).",
		event_price_fees:
			"Fees charged by the platform. These fees are at least equal to the additional fees added by the marketer.",
		event_price_fees_sup: "Additional fees applied by the marketer.",
		event_price_fees_calc: "Platform fees + Marketer's additional fees.",
		event_timezone: "Choose carefully for live ranking purposes.",
		event_defined_tz: "Timezone defined for the event",
		default_race:
			"Classify an unknown bib detected during live in a default race if no numbering plan is defined",
		finances: "Financial Flows",
		finances_desc: "View the financial flows of an event",
		finances_resume: "Transfers Management",
		finances_resume_desc: "View the status of transfers for platform events",
		placeholder_select: "Select an event",
		placeholder_search: "Search for an event",
		select_no_options: "No events",
		report: "Report",
		time: "Time",
		section: "Stage",
		filters: "Filters",
		placeholder_select_gender: "Select a gender",
		placeholder_select_category: "Select a category",
		H: "Male",
		F: "Female",
		A: "Other",
		export: "Export",
		export_format: "Export Format",
		placeholder_select_format: "Select a format",
		optional: "(optional)",
		warning_passage: (line: number, min: number, done: number) =>
			`Line ${line}: ${min} min passage, ${done} passages completed`,
		inviteNewUser: "Invite new user",
		error403: "Error 403",
		contactOrga: "Contact the organizer if you need this access",
		restrictedPage: "Restricted page",
		permissions: "Permissions",
		roles: "Permissions",
		search_permissions: "Search for a name",
		invoice: "Invoice",
		dag_share_ht: "DAG Shares ex. tax",
		orga_share_ht: "Orga Shares ex. tax",
		total_ht: "Total ex. tax",
		total_ttc: "Total inc. tax",
		com_ht: "Commission ex. tax",
		bill_com: "Invoice State",
		bill_orga: "Bill Orga",
		title: "Title",
		quantity: "Quantity",
		unit_prix_ht: "P.U ex. tax",
		unit_prix_ttc: "P.U inc. tax",
		nbSubs: "Subscribers",
		StartPeriode: "Period start",
		EndPeriode: "Period end",
		paymentDone: "Payment done",
		validatePayment: "Validate payment",
		toastPaymentSuccess: "Transfer validated",
		toastPaymentError: "An error occurred during transfer validation",
		downloadContractAskValidation: "Download and ask validation",
		contract_explanation:
			"In order to finalize the publication of your event, it is essential to establish a contract. Upon confirming your request for publication, we will send you this contract via email, which must then be completed and returned to our team : ",
		licences: "Licences",
		licences_desc: "Manage DAG Clients licences",
		toast_invoice_error:
			"An error has occurred. Check that the event is closed and the rib is present.",
		documents: "Documents",
		total_subs: "Total number of subscriptions",
		total_subs_online: "Total number of online subscriptions",
		total_subs_manual: "Number of total manual subscriptions",
		total_subs_imported: "Number of subscriptions imported",
		total_subs_in_place: "Number of subscriptions on site",
		docMissing: "Documents missing",
		manualCancel: "Cancel",
		orga_title: "Become an organizer",
		signup_error_country: "Counrty is not valid",
		signup_error_city: "City is invalid",
		signup_error_postal_code: "Postal code is invalid",
		signup_error_adresse: "Adress is invalid",
		sub_validation_message: "Reason is missing",
		surrended: "Surrended",
		runEndDate: "End date",
		runMaxNbOfSlots: "Maximum number of places",
		runnerChart: "Runners' charter",
		yourParentalAuthorizationModel: "Your parental authorization model",
		addBban: "Add a RIB",
		editBban: "Edit a RIB",
		bbanType: "RIB type",
		bbanName: "RIB name",
		bankName: "Name of the bank",
		iban: "IBAN",
		bicSwift: "BIC/SWIFT",
		areYouThePersonInCharge: "Are you the legal guardian?",
		personInChargeInformations: "Information from the legal guardian",
		subOptions: "Options",
		subEdit: "Subscription edit",
		subscription: "Subscription",
		runName: "Name of the race",
		teamName: "Team name",
		runStartDate: "Date of departure",
		noClub: "No club",
		loginMissing: "Identifiants manquants",
		createNewEdition: "Create a new edition",
		disableSubscriptions: "Disable subscriptions",
		activateSubscriptions: "Activate subscriptions",
		inputs_edition_not_complete: "Please, fill all inputs",
		error_new_edition: "Error happened while trying to create new edition",
		mailUsed: "Email ignored. Already used.",
		mailUsedTooltip:
			"Check firstname and lastname. They are maybe spelled wrong",
		succes_stopsubscription_event: "Subscriptions disabled successfuly",
		error_stopsubscription_event:
			"An error has occured while disabling subscriptions",
		results: "result",
		displayOrder: "Display order",
		moveAbove: "Move up",
		moveBelow: "Move down",
		moveTop: "Move to top",
		moveBottom: "Move to bottom",
		cannotDeleteRunWithSubscription:
			"You cannot delete a run with subscriptions",
		addGpxFile: "Add GPX File",
		gpxFile: "GPX File",
		ShouldBeNotSameDate: "Dates should not be the same",
		toastSuccessAddingRib: "Rib added successfuly",
		toastErrorAddingRib: "An error has occured while adding rib",
		placeholder_select_rib: "Choose a rib",
		processed: "Processed",
		earnings: "Earnings",
		chartTitleSubs: "Subs / Race",
		chartTitleGenders: "Genders",
		chartTitleCases: "Cases",
		noOptions: "No option available",
		saveOk: "Save successful",
		saveError: "An error has occured while saving",
		shareLiveTrackingUrl: "Share the live tracking URL",
		cantPreview:
			"The preview is not available for this document (too big). Please download it.",
		liveTypeOfRun: "What type of run?",
		liveDateOfRun: "When is the start date of the race ?",
		bibNotFound:
			"This bib is not part of your event. Click to add to subscribers",
		between: "between",
		and: "and",
		none: "None",
		bySubscriber: " /runner",

		chooseCalendarRunType: "Choose a type of race",
		chooseCalendarRunTypeSimple: "Single race (Start - Inter. - Finish)",
		chooseCalendarRunTypeLaps: "Lap race",
		chooseCalendarRunTypeSimpleVirtual:
			"Simple virtual race (Sports application)",
		chooseCalendarRunTypeLapsVirtual: "Virtual lap race (Sports application)",
		chooseCalendarRunTypeTriathlon: "Triathlon",

		calendarRunDistance: "How far is the race? (in meter)",
		calendarRunLaps: "How many laps does your race take?",
		calendarRunDetectionPoints: "Your detection points",
		calendarRunSegments: "Your stages",
		calendarRunDetectionAddPoint: "Add a detection point",
		calendarRunAddSegment: "Add a stage",
		calendarRunDetectionConfigurePoint: "Configure your detection point",
		calendarRunConfigureSegment: "Configure your stage",
		calendarRunDetectionType: "What is the type of your point ?",
		calendarRunDetectionChooseType: "Choose a type",
		finish: "Finish",
		arrived: "Arrived",
		advancedParams: "Advanced settings",
		interpassTime: "Interpass time",
		maxNumberPass: "Maximum number of passages",
		minNumberPass: "Minimum number of passages",
		startNumberPass: "Start number pass",
		endNumberPass: "End number pass",
		numberPass: "Number pass",
		startDetectionPoint: "Entry",
		endDetectionPoint: "Exit",
		latitude: "Latitude",
		longitude: "Longitude",
		order: "Order",
		radius: "Radius",
		altitude: "Altitude",
		runFormTypeError: "You must choose a type of race",
		runFormDistanceError: "The distance of your race must be greater than 0",
		runFormLapsError: "The number of turns must be greater than 0",
		runFormPointNameError: "You must give a name to your point",
		runFormPointDistanceError: "The distance of your point must be defined",
		runFormPointLineError: "A line must be defined",
		runFormPointTypeError: "You must choose a stitch type",
		runFormPointSameError: "Start and end points are the same",
		runFormSegmentNotComplete: "Stage information incomplete",
		runFormSegmentErrorHappened: "An error happened while saving the stage",
		calendarRunDeleteSegment: "Delete stage",
		runFormDeleteSegmentConfirmation:
			"Are you sure you want to delete this stage ?",
		runFormDeleteSegmentSuccess: "Stage deleted successfully",
		runFormUpdateSegmentSuccess: "Stage updated successfully",
		runFormDistanceType: "Choose distance type",
		whichRunConfigure: "Which run do you want to configure?",
		credentialDagLive: "Your DAG Live credential",
		saveCredentialSuccess: "Your credential as been saved",
		saveCredentialError: "An error as occured while saving your credential.",
		saveConfigRunSuccess: "You configuration has been saved.",
		saveConfigRunError:
			"An error as occured while updating your configuration.",
		saveConfigPointSuccess: "You point has been saved.",
		saveConfigPointError: "An error as occured while saving you point.",
		deleteConfigPointSuccess: "You configuration has deleted.",
		deleteConfigPointError: "An error as occured while deleting your point.",
		seeAllDetections: "See all detections",
		rankingHeaderRank: "Rk",
		rankingHeaderName: "Name",
		rankingHeaderBib: "Bib",
		rankingHeaderLastPassage: "Last passage",
		rankingHeaderDetections: "Detections",
		rankingHeaderTime: "Time",
		rankingHeaderCategoryRank: "Cat. Rk.",
		rankingHeaderSexRank: "Sex Rk.",
		rankingHeaderRun: "Run",
		rankingHeaderTeam: "Team",
		rankingHeaderActions: "Actions",
		editRunnerTooltip:
			"Please, not that update runner will also change all other subscriptions",
		editRunnerRunTooltip: "Updating race information will affect rankings",
		selectRunPlaceholder: "Select a race",
		chooseTeam: "Choose a team",
		createTeam: "Create a team",
		resetOnRunner: "Reset on runner",
		bibTime: "Bib time",
		manageDetections: "Manage detections",
		totalPriceOfRun: "Total price of the trip (excluding fees)",
		invalidateSub: "Make registration invalid ?",
		errorVerifyBibAlreadyUse:
			"An error has occurred. Check that the bib is not already used.",
		addDetection: "Add a detection",
		setRunnerSurrended: "Pass runner in surrended",
		setRunnerDisqualified: "Pass runner in disqualified",
		setRunnerStarting: "Pass runner in starting",
		setRunnerNotStarting: "Pass runner in not starting",
		updateRunnerStatusSurrended: "Update runner status to surrended ?",
		updateRunnerStatusDisqualified: "Update runner status to disqualified ?",
		updateRunnerStatusStarting: "Update runner status to starting ?",
		updateRunnerStatusNotStarting: "Update runner status to not starting ?",
		notStarting: "Not starting",

		// PatchNotes
		patchNotes: "Update notes",
		patchAddNotes: "Adding update notes",
		patchAddJetcodes: "Availability of Jetcodes for an event",
		patchAddRoles: "User access rights management",
		patchCorrectDocuments: "Correction of document visualization",
		patchFixInvoiceErrorMessage: "Correction of Invoice error message",
		patchChangeDashboardLinks: "Changed links for event cards in the Dashboard",
		patchUpdateSendOneMail: "Updated sending mail to a runner",
		patchMailHistory:
			"Correction of the display of the history of emails sent to a runner",
		patchResponsive:
			"Correction of responsiveness to improve usability on mobile",
		patchHoursPrices:
			"Correction of updating the time when you want to update a price",
		patchRedirectionToValidate:
			"Update of the redirection when clicking on X to validate",
		patchTraduction: "Fix translations",
		patchEventImages: "Fix event images",
		patchCreateEdition: "Add create new edition",
		patchPictoDocs: "Add Picto for docs",
		patchForgetPassword: "Add forget password",
		patchAddPdfExport: "Add pdf export",
		patchAddFilterDocuments:
			"Add a filter for subscription with pending documents",
		onlyWithDocStart: "Display only subscriptions with",
		onlyWithDocStrong: " pending documents",
		patchReasignBibsAndKeepThem:
			"Updated bib reassignment behavior with the possibility of keeping bibs already assigned",
		patchAddRegistration: "Improve add of registration",
		patchGenderX: "Display X gender",
		patchRegistrations: "Improve registrations management",
		patchSwitchSubscriptionStatus: "You can now switch subscription status",
		patchUpdateAdminStatistics:
			"Update of the administration interface regarding data tables",
		patchAddRibSelector:
			"Addition of a functionality for quickly adding RIBs from a list of RIBs already used by the user",
		patchEditOption: "Allows to configure registrations' options",
		patchAddResults: "Allows to add results as PDF files",
		patchDocumentPreview: "Improve document preview",
		patchUX: "Improve user experience",
		patchPDFExportRaces: "Add race selection for PDF export",

		deleteGpxFileConfirmation: "Are you sure you want to delete this file?",
		deletedFile: "File deleted!",
		selectRunMail: "Select a race",
		detectionPointName: "Detection point name",
		detectionLigne: "Line",
		verifyPrestation:
			"Check that you have a live in progress and you DAG Live configuration",
		verifyCredential: "An error has occurred. Check your DAG Live credential.",
		noPrestationStarted:
			"Start the live for the first time to show the ranking.",
		ranked: "Ranked",
		missing: "missing",

		ffa_error_001: "Error with the birthdate format",
		ffa_error_002: "Insufficient provided information",
		ffa_error_003: "Number not found",
		ffa_error_004: "TP number not found",
		ffa_error_005: "CF number not found",
		ffa_error_006:
			"The number will no longer be valid at the time of the competition",
		ffa_error_007:
			"The number does not match your name / first name / gender / birthdate",
		ffa_error_008:
			"Your information (name / first name / gender / birthdate) is not found",
		ffa_error_009: "Homonymy error, please contact the FFA directly",
		ffa_error_010: "FFA Service unavailable, contact the organizer (10)",
		ffa_error_011: "FFA Service unavailable, contact the organizer (11)",
		ffa_error_012: "FFA Service unavailable, contact the organizer (12)",
		ffa_error_013: "FFA Service unavailable, contact the organizer (13)",
		ffa_error_014: "FFA Service unavailable, contact the organizer (14)",
		ffa_error_015: "PPS number not found",
		SUB: "SUB",
		ARR: "ARR",
		DNS: "DNS",
		DNF: "DNF",
		DQ: "DQ",
		sport_identity_error_name_taken: "The event name is already taken"
	}
};

export default trad;
