import { useContext, useEffect, useState } from "react";
import { API } from "../../api/APIClient";
import {
	updateRegistration,
	updateRegistrationOptions,
	updateSubscription
} from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import { FFAErrorCode } from "../../types/FFA";
import { calculateAge } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";
import RegistrationEditModal from "./RegistrationEditModal";

interface RegistrationEditModalLogicProps {
	refetch?: any;
	editedSubscription?: any;
	setEditedSubscription: Function;
	bibList: number[];
}

const RegistrationEditModalLogic = ({
	editedSubscription,
	refetch,
	setEditedSubscription,
	bibList
}: RegistrationEditModalLogicProps) => {
	const { lang } = useContext(AppContext);
	const { liveConfig } = useContext(LiveContext);
	const [selectedTab, setSelectedTab] = useState<string>("general");
	const [editForm, setEditForm] = useState<any>({});
	const [editFormOptions, setEditFormOptions] = useState<any>({});
	const [canSave, setCanSave] = useState<boolean>(true);
	const [bibConflict, setBibConflict] = useState<boolean>(false);
	const [birthdateError, setBirthdateError] = useState<boolean>(false);

	const [ppsError, setPpsError] = useState<string>("");
	const [numLicenceError, setNumLicenceError] = useState<string>("");

	const observation = JSON.parse(liveConfig?.observation);


	const conflict: boolean = bibConflict === true;

	useEffect(() => {
		if (selectedTab == "options")
			setCanSave(Object.keys(editFormOptions).length > 0);
		else if (selectedTab == "general") {
			let conflict = false;
			let bib = editForm.bib ? parseInt(editForm.bib) : -1;
			if (bib >= 0 && bib != editedSubscription?.bib)
				conflict = bibList.includes(bib);
			setBibConflict(conflict);

			// Check birthdate
			let isBirthdateInvalid = false;
			if (editForm.birthdate) {
				isBirthdateInvalid = calculateAge(editForm.birthdate) < 3;
				setBirthdateError(isBirthdateInvalid);
			} else setBirthdateError(false);

			setCanSave(
				Object.keys(editForm).length > 0 &&
					conflict === false &&
					isBirthdateInvalid === false
			);
		}
	}, [editForm, editFormOptions, selectedTab]);

	useEffect(() => {
		setEditForm({});
		setEditFormOptions({});
	}, [selectedTab]);

	const getObsData = (property: string) => {
		if (observation) {
			return observation[property];
		}
		return undefined;
	};

	const onSaveClick = async () => {
		try {
			setCanSave(false);

			if (!editedSubscription?.id) {
				throw new Error("No registration id");
			}
			console.log("🟡 ---- selectedTab", selectedTab);
			switch (selectedTab) {
				case "general":
					let isFormValid:boolean = true;
					
					// Check pps via FFA API
					if (editForm.ppsNumber) {
						const hasFFAValidated = await validateLicence(editForm.ppsNumber, setPpsError);

						if (!hasFFAValidated) {
							Toast.error(trad[lang].ppsError);
							isFormValid = false;
						}
					}

					// Check numLicence via FFA API
					if (editForm.numLicence)
					{
						const hasFFAValidated = await validateLicence(editForm.numLicence, setNumLicenceError);
						if (!hasFFAValidated) {
							Toast.error(trad[lang].ppsError);
							isFormValid = false;
						}
					}

					// If form invalid, stop here, do not update registration on backend
					if (isFormValid === false)
						return false;

					// Otherwise, update
					await updateRegistration(editedSubscription.id, editForm);
					break;

				case "options":
					await updateRegistrationOptions(
						editedSubscription.id,
						editFormOptions
					);
					break;
			}

			setEditForm({});
			setEditFormOptions({});
			setEditedSubscription(null);

			Toast.success(trad[lang].updateSuccess);
			await refetch();
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].updateError);
		} finally {
			setCanSave(true);
		}
	};

	const validateLicence = async (numrel:string, setErrorMessage:React.Dispatch<React.SetStateAction<string>>) => {
		try {
			// Prepare data to send
			// Get and format birthdate
			const regex = /^\d{4}-\d{2}-\d{2}$/;
			let ddn = editForm.birthdate?.split("T")[0] || editedSubscription.person?.birthdate;

			if (regex.test(ddn)) {
				var parts = ddn.split("-");
				var year = parts[0];
				var month = parts[1];
				var day = parts[2];

				// Construct the new date format DD/MM/YYYY
				ddn = day + "/" + month + "/" + year;
			}

			const body: any = {};
			body.nom = editForm.lastname || editedSubscription.person?.lastname;
			body.prenom = editForm.firstname || editedSubscription.person?.firstname;
			body.numrel = numrel;
			body.sexe = editForm.gender || (editedSubscription.person?.sex === 1 ? "M" : "F");
			body.cnilCode = 1;
			body.cmpcod = getObsData("CMPCOD");
			body.cmpdate = getObsData("CMPDATE");
			body.dateNaissance = ddn;

			const headers =
				typeof window !== "undefined" && localStorage.getItem("token")
					? {
							headers: {
								authorization: localStorage.getItem("token") as string
							}
					  }
					: {};

			const res = await API.post(
				`event/interest/slug/sendDataFFA`,
				body,
				headers
			);
			const data = res.data;
			let message = data[data.length - 1].match(/PROx(\d+)/);
			let m = message;
			let errorCode: FFAErrorCode = m?.[1];

			if ((!data || message) && (data && data[0] != "O")) {
				if (editForm?.ppsNumber && message) {
					setErrorMessage(trad[lang][`ffa_error_${errorCode}`]);
					return false;
				}
				setErrorMessage(trad[lang][`ffa_error_${errorCode}`]);
				return false;
			}
			setErrorMessage("");
			await updateSubscription(editedSubscription.id, { pps: editForm.ppsNumber });
			return true;
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].error_ask_validation);
		}

		return false;
	};

	const handleInputChange = (field: string, value: string, maths?: boolean) => {
		setEditForm({
			...editForm,
			[field]: maths ? Math.abs(parseInt(value)) : value
		});
	};

	const handleDateChange = (field: string, value: string) => {
		setEditForm({
			...editForm,
			[field]: value + "T00:00:00.000Z"
		});
	};

	const closePopup = () => {
		setEditedSubscription(null);
	};

	return (
		<RegistrationEditModal
			handleInputChange={handleInputChange}
			handleDateChange={handleDateChange}
			closePopup={closePopup}
			onSaveClick={onSaveClick}
			setSelectedTab={setSelectedTab}
			conflict={conflict}
			canSave={canSave}
			birthdateError={birthdateError}

			ppsError={ppsError}
			numLicenceError={numLicenceError}

			selectedTab={selectedTab}
			editedSubscription={editedSubscription}
			editFormOptions={editFormOptions}
			setEditFormOptions={setEditFormOptions}
		/>
	);
};

export default RegistrationEditModalLogic;
