import { useState } from "react";

export const useLocalStorage = (key: string, initialValue: any) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const value = window.localStorage.getItem(key);

			if (value) {
				return JSON.parse(value);
			} else {
				window.localStorage.setItem(key, JSON.stringify(initialValue));
				return initialValue;
			}
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = (newValue: string) => {
		try {
			window.localStorage.setItem(key, JSON.stringify(newValue));
		} catch (err) {}

		setStoredValue(newValue);
	};

	return [storedValue, setValue];
};
