import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const nmb_per_page = ["10", "25", "50", "100"];

const ManageDetectionFooter = ({
	nmbPerPage,
	setNmbPerPage,
	page,
	setPage,
	setCount,
	count
}: {
	nmbPerPage: string;
	setNmbPerPage: Dispatch<SetStateAction<string>>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	setCount: Dispatch<SetStateAction<number>>;
	count: number;
}) => {
	const { lang } = useContext(AppContext);

	const MaxPage = useMemo(
		() => Math.ceil(count / parseInt(nmbPerPage)) || 1,
		[page, count]
	);

	const availablePage = useMemo(() => {
		const pages = [page];
		const minPage = 1;

		//Add next pages
		for (let i = page + 1; i <= page + 2; i++) {
			if (i <= MaxPage) {
				pages.push(i);
			}
		}

		// Number missing pages
		let missing_pages = 5 - pages.length;

		for (let i = page; i >= page - missing_pages; i--) {
			if (i >= minPage && i != page) {
				pages.unshift(i);
			}
		}

		const maxAvailablePage = pages[pages.length - 1];
		missing_pages = 5 - pages.length;

		for (
			let i = maxAvailablePage + 1;
			i <= maxAvailablePage + missing_pages;
			i++
		) {
			if (i <= MaxPage) {
				pages.push(i);
			}
		}

		return pages;
	}, [page, count, MaxPage]);

	const changeNumberPage = (value: any) => {
		setNmbPerPage(value);
		setPage(1);
		setCount(count);
	};

	return (
		<tfoot className="rounded-lg bg-gray-50">
			<tr>
				<td
					colSpan={7}
					className="justify-between py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
				>
					<div className="flex justify-between">
						<select
							name="nmb_page"
							id="nmb_page"
							className="appearance-none rounded-md border-gray-300 py-2 pr-10 placeholder-gray-400 focus:border-primary  focus:outline-none focus:ring-0 sm:text-sm"
							value={nmbPerPage}
							onChange={(e) => changeNumberPage(e.target.value)}
						>
							{nmb_per_page.map((option: any) => (
								<option value={option} key={option}>
									{option}
								</option>
							))}
						</select>

						<nav className="flex overflow-hidden rounded-md border border-gray-300">
							<p
								className={classNames(
									"flex items-center justify-center  bg-white px-3 text-base",
									page == 1
										? "cursor-auto select-none opacity-60"
										: "cursor-pointer hover:bg-gray-50"
								)}
								onClick={() => setPage(Math.max(1, page - 1))}
							>
								{trad[lang].previous}
							</p>

							{availablePage.map((item) => (
								<p
									className={classNames(
										"flex w-10 items-center justify-center border-l  text-base",
										item == page
											? "cursor-auto bg-blue-500 text-white"
											: "cursor-pointer bg-white hover:bg-gray-50"
									)}
									onClick={() => setPage(item)}
									key={item}
								>
									{item}
								</p>
							))}

							<p
								className={classNames(
									"flex items-center justify-center border-l bg-white px-3 text-base",
									page == MaxPage
										? "cursor-auto select-none opacity-60"
										: "cursor-pointer hover:bg-gray-50"
								)}
								onClick={() => setPage(Math.min(MaxPage, page + 1))}
							>
								{trad[lang].next}
							</p>
						</nav>
					</div>
				</td>
			</tr>
		</tfoot>
	);
};

export default ManageDetectionFooter;
