import { toast } from "react-toastify";

const Toast = {
	error: (message: string) =>
		toast.error(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			draggable: true,
			progress: undefined
		}),

	success: (message: string) =>
		toast.success(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			draggable: true,
			progress: undefined
		})
};

export default Toast;
