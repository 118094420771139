export const exportFormat: any = [
	{
		name: "Excel",
		icon: `<RiFileExcel2Fill color="green" />`,
		format: "excel",
		extension: "xlsx",
		mimeType: "application/octet-stream",
		active: true
	},
	{
		name: "CSV",
		icon: `<FaFileCsv color="black" />`,
		format: "csv",
		extension: "csv",
		mimeType: "text/csv",
		active: true
	},
	{
		name: "PDF",
		icon: `<BsFileEarmarkPdfFill color="red" />`,
		format: "pdf",
		extension: "pdf",
		mimeType: "application/pdf",
		active: false
	},
	{
		name: "GmDAG", // 1 ligne par inscription
		icon: `<BsFileEarmarkPdfFill color="green" />`,
		format: "gmdag",
		extension: "txt",
		mimeType: "text/csv",
		active: true
	},
	{
		name: "FFA / E-logic@",
		icon: `<BsFileEarmarkPdfFill color="green" />`,
		format: "ffa_elogica",
		extension: "gz",
		mimeType: "application/octet-stream",
		active: true
	}
];
export const exportFormatRanking: any = [
	{
		name: "Exporter Excel",
		icon: `<RiFileExcel2Fill color="green" />`,
		format: "excel",
		extension: "xlsx",
		mimeType:
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		active: true
	},
	{
		name: "Exporter CSV",
		icon: `<FaFileCsv color="black" />`,
		format: "csv",
		extension: "csv",
		mimeType: "text/csv",
		active: true
	},
	{
		name: "Exporter PDF",
		icon: `<BsFileEarmarkPdfFill color="red" />`,
		format: "pdf",
		extension: "pdf",
		mimeType: "application/pdf",
		active: true
	}
];

// Columns to export
export const COLUMNS: any = [
	{
		id: "DateInscription",
		default: false,
		checked: false,
		name: "Date d'inscription"
	},
	{ id: "IDCourse", default: false, checked: false, name: "ID de la course" },
	{ id: "Course", default: true, checked: true, name: "Nom de la course" },
	{ id: "Dossard", default: true, checked: true, name: "Numéro de Dossard" },
	{ id: "Nom", default: true, checked: true, name: "Nom" },
	{ id: "Prenom", default: true, checked: true, name: "Prénom" },
	{ id: "Telephone", default: false, checked: false, name: "Téléphone" },
	{
		id: "TelephoneUrgence",
		default: false,
		checked: false,
		name: "Téléphone d'urgence"
	},
	{ id: "Adresse1", default: false, checked: false, name: "Adresse 1" },
	{ id: "Adresse2", default: false, checked: false, name: "Adresse 2" },
	{ id: "CodePostal", default: false, checked: false, name: "Code postal" },
	{ id: "Ville", default: false, checked: false, name: "Ville" },
	{ id: "Pays", default: false, checked: false, name: "Pays" },
	{ id: "Sexe", default: true, checked: true, name: "Sexe" },
	{
		id: "DateNaissance",
		default: false,
		checked: false,
		name: "Date de naissance"
	},
	{ id: "NomCategorie", default: true, checked: true, name: "Catégorie" },
	{
		id: "AbbreviationCategorie",
		default: false,
		checked: false,
		name: "Abbréviation de la catégorie"
	},
	{ id: "Nationalite", default: false, checked: false, name: "Nationalité" },
	{ id: "Distance", default: false, checked: false, name: "Distance" },
	{ id: "Club", default: false, checked: false, name: "Club" },
	{ id: "Equipe", default: true, checked: true, name: "Equipe" },
	{ id: "Email", default: true, checked: true, name: "Email" },
	{
		id: "IDInscription",
		default: false,
		checked: false,
		name: "ID de l'inscription"
	},
	{
		id: "IDCommande",
		default: false,
		checked: false,
		name: "ID de la commande"
	},
	{
		id: "StatutInscription",
		default: true,
		checked: true,
		name: "Statut de l'inscription"
	},
	{
		id: "TypeInscription",
		default: true,
		checked: true,
		name: "Type d'inscription"
	},
	{
		id: "PrixInscription",
		default: false,
		checked: false,
		name: "Prix de l'inscription"
	},
	{ id: "Options", default: false, checked: false, name: "Options" },
	{ id: "Commentaires", default: false, checked: false, name: "Commentaires" },
	{ id: "Licence", default: false, checked: false, name: "Numéro de licence" },
	{ id: "PPS", default: false, checked: false, name: "Numéro PPS" }
];

// Get sexes
export const GENDERS: any = [
	//{ value: 0, label: "Tous" },
	{ value: 1, label: "♂ Hommes" },
	{ value: 2, label: "♀ Femmes" },
	{ value: 3, label: "⚤ Autres" }
];

// Get status
export const STATUS: any = [
	//{ value: 0, label: "Tous" },
	{ value: 1, label: "🟢 Valide" },
	{ value: 2, label: "🔴 Invalide ou Annulée" },
	{ value: 3, label: "🟡 En attente" },
	{ value: 4, label: "🟠 Remboursée" }
];

// Get subscription type
export const SUBSCRIPTION_TYPES: any = [
	//{ value: 0, label: "Tous" },
	{ value: 1, label: "En ligne" },
	{ value: 11, label: "Manuelle" },
	{ value: 21, label: "Importée" },
	{ value: 31, label: "Sur place" }
];
